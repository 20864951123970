import { Button, Form, Input, Collapse, Row, Col, message, Popconfirm, Select, Spin, DatePicker } from 'antd'
import icon_selected from 'assets/images/icon_selected.svg'
import reject from 'assets/images/icon_reject.svg'
import star from 'assets/images/star.svg'
import { DownOutlined, SearchOutlined, TeamOutlined } from '@ant-design/icons'
import { mediaImages } from 'config/media'
import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import axios from 'config/axios'
import { isNumber, titleCase } from 'utils'
import moment from 'moment'

const { Panel } = Collapse
const { Option } = Select
const { RangePicker } = DatePicker

const AMADiscord = ({ setTasks_arr, task, trans_code, isOpen = false }: any) => {
  const [isChecked, setIsChecked] = useState(true)
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [data, setData] = useState<any>([])
  const [value, setValue] = useState('')
  const [form] = Form.useForm()

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length || !value) return
    const start_time = parseInt(fieldValues.time[0].valueOf() / 1000 + '')
    const end_time = parseInt(fieldValues.time[1].valueOf() / 1000 + '')
    delete fieldValues.time
    const submitObj = {
      platform: 'discord',
      type: 'text_ama',
      start_time,
      end_time,
      ...fieldValues,
    }
    submitObj.mandatory = isChecked
    submitObj.room_id = value
    if (task.id) submitObj.id = task.id
    setLoading(true)
    const res = await axios.post('/api/admin/space/campaign', {
      step: 2,
      campaign_code: trans_code,
      task: JSON.stringify(submitObj),
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      const id = res.data.data.task_id
      setTasks_arr((state: any) => {
        const list = state
        for (const obj of list) {
          if (obj.uuid === task.uuid) {
            obj.id = id
            obj.mandatory = submitObj.mandatory
            obj.url = submitObj.url
            obj.votes = submitObj.votes
            obj.title = submitObj.title
            obj.start_time = submitObj.start_time
            obj.end_time = submitObj.end_time
            obj.room_id = value
            obj.name = res.data.data.name
            obj.logo_url = res.data.data.logo_url
            break
          }
        }
        const task_ids = list.filter((task: any) => task.id).map((task: any) => task.id)
        axios.post('/api/admin/space/campaign/sort', {
          campaign_code: trans_code,
          task_ids,
        })
        return [...list]
      })
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async () => {
    if (!task.id) {
      setTasks_arr((state: any) => {
        const list = state
        const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
        list.splice(index, 1)
        return [...list]
      })
      message.success('Success!')
    } else {
      const res = await axios.post('/api/admin/space/campaign/task/remove', {
        campaign_code: trans_code,
        task_id: task.id,
      })
      if (res.data.code === 0) {
        setTasks_arr((state: any) => {
          const list = state
          const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
          list.splice(index, 1)
          return [...list]
        })
        message.success('Success!')
      } else {
        message.error(res.data.message)
      }
    }
  }

  let timeout: any
  let currentValue: any

  const fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      setLoad(true)
      const res = await axios.get(`/api/admin/space/community/search?platform=discord&name=${value}`)
      setLoad(false)
      if (currentValue === value) {
        callback(res.data.data)
      }
    }

    timeout = setTimeout(fake, 300)
  }
  const handleSearch = (value: string) => {
    if (value) {
      fetch(value, (data: any) => setData(data))
    } else {
      setData([])
    }
  }
  const handleChange = (value: any) => {
    setValue(value)
  }
  const options = data.map((d: any) => {
    return (
      <Option className="border-b border-bord" key={d.community_code} value={d.community_code}>
        <div className="flex items-center">
          {d.logo_url && <img className="w-4 mr-1.5 rounded-full" src={d.logo_url} alt="" />}
          {!d.logo_url && <TeamOutlined className="mr-1.5" />}
          <span>{d.name}</span>
        </div>
      </Option>
    )
  })

  useEffect(() => {
    if (task.room_id) {
      setData([
        {
          community_code: task.room_id,
          logo_url: task.logo_url,
          name: task.name,
        },
      ])
      setValue(task.room_id)
    }
    setIsChecked(task.mandatory)
    form.setFieldsValue({
      url: task.url,
      votes: task.votes,
      title: task.title,
      time: task.start_time ? [moment(task.start_time * 1000), moment(task.end_time * 1000)] : [],
    })
  }, [task, form])

  return (
    <div className="relative w-fit">
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={isOpen ? [task.uuid] : []}
        expandIcon={({ isActive }) => <DownOutlined className="text-xs text-white" rotate={isActive ? 180 : 0} />}
        className={`rounded-md`}
      >
        <Panel
          header={
            <div className="w-500 flex">
              <img className="mr-2" src={mediaImages[task.platform]} alt="" />
              {task.mandatory && <img className="mr-1" src={star} alt="" />}
              <div className="flex">
                <div>Join</div>
                <div className="max-w-xs truncate mx-1">{task.title} Text AMA</div>
                <div>
                  on {titleCase(task.platform)} <span className="font-bold">+{task.votes}</span>
                </div>
              </div>
            </div>
          }
          key={task.uuid}
        >
          {
            <div>
              <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Title:"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'title is required!',
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <div className="">
                          <span className="text-st text-lg mr-1">*</span>
                          <span>Community Name:</span>
                        </div>
                      }
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Community name is required!',
                      //   },
                      // ]}
                    >
                      <div className="relative">
                        <Select
                          placeholder="Search community"
                          showSearch
                          value={value}
                          className="w-full"
                          dropdownClassName="border border-bord"
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={handleSearch}
                          onChange={handleChange}
                          notFoundContent={load ? <Spin size="small" /> : <div className="text-white">No results found</div>}
                        >
                          {options}
                        </Select>
                        <div className="absolute h-full flex items-center top-0 right-0 pr-4">
                          <SearchOutlined className="text-xl" />
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={16}>
                    <Form.Item
                      label="Worth # Entries:"
                      name="votes"
                      rules={[
                        {
                          required: true,
                          message: 'Worth is required!',
                        },
                        {
                          validator: (_, value) => {
                            if (value && !isNumber(value)) {
                              return Promise.reject('Worth can only be numbers!')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      <div className="flex items-center space-x-2 pt-9">
                        {!isChecked && <div onClick={() => setIsChecked(true)} className="border border-ci w-4 h-4 rounded-full cursor-pointer"></div>}
                        {isChecked && (
                          <div onClick={() => setIsChecked(false)} className="cursor-pointer">
                            <img className="rounded-full" src={icon_selected} alt="" />
                          </div>
                        )}
                        <div className="text-xs">
                          <Trans>Mandatory</Trans>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Discord Link:"
                      name="url"
                      rules={[
                        {
                          required: true,
                          message: 'Discord link is required!',
                        },
                      ]}
                    >
                      <Input placeholder="https://discord.com/invite/B9VCpjh8" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Time Period:"
                      name="time"
                      rules={[
                        {
                          required: true,
                          message: 'Time is required!',
                        },
                      ]}
                    >
                      <RangePicker
                        className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black"
                        showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')] }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="flex items-center space-x-4">
                  <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-8" type="primary">
                    <Trans>Save</Trans>
                  </Button>
                  <div className="text-yellow-500 rounded-md text-xs">
                    <Trans>
                      Add{' '}
                      <a className="underline" href="https://docs.port3.io/sograph-dao-tools-web3-bot/invite-bot" target="_blank" rel="noreferrer">
                        SoQuest Bot
                      </a>{' '}
                      to your server to verify entries. Admin required.
                    </Trans>
                  </div>
                </div>
              </Form>
            </div>
          }
        </Panel>
      </Collapse>
      <Popconfirm onConfirm={() => confirmFun()} title="Are you sure delete this task?" okText="Yes" cancelText="No">
        <img className="absolute top-1.5 -right-8 h-5 cursor-pointer" src={reject} alt="" />
      </Popconfirm>
    </div>
  )
}

export default AMADiscord
