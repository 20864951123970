import { useEffect, useState } from "react"
import axios from "config/axios"

const useCommunityDetail = (code: string) => {
  const [data, setData] = useState<any>({
    name: '',
    logo_url: '',
    link_url: '',
    platform: '',
    members: 0,
    daily_active_member_count: 0,
    introduction: '',
    active_members: [],
  })
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/community/?code=${code}`)
      setLoading(false)
      setData(res.data.data)
    } catch (error) {
      console.error("useCommunityDetail --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [code])
  return [data, loading]
}

export default useCommunityDetail
