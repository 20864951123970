import { Button, Form, Input, Collapse, Row, Col, message, Popconfirm, Select, DatePicker, Checkbox } from 'antd'
import icon_selected from 'assets/images/icon_selected.svg'
import reject from 'assets/images/icon_reject.svg'
import star from 'assets/images/star.svg'
import add_logo from 'assets/images/_icon_add.svg'
import { DownOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { mediaImages } from 'config/media'
import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import axios from 'config/axios'
import { isNumber } from 'utils'
import moment from 'moment'

const { Panel } = Collapse

const AnswerQuestion2 = ({ setTasks_arr, task, trans_code, isOpen = false }: any) => {
  const [isChecked, setIsChecked] = useState(true)
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState('')
  const [checkedObj, setCheckedObj] = useState<any>({})
  const [answerType, setAnswerType] = useState(task.type)
  const [form] = Form.useForm()

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    if (fieldValues.time) {
      fieldValues.time = parseInt(fieldValues.time.valueOf() / 1000 + '')
    }
    const options = []
    const answers = []
    if (answerType !== 'open_question') {
      for (const obj of fieldValues.options) {
        options.push(obj.answer)
        if (obj.isSelected?.length) {
          answers.push(obj.answer)
        }
      }
      if (answerType === 'quiz' && !fieldValues.time) {
        if (!answers.length) {
          message.error('Please select at least one option')
          return
        }
        if (fieldValues.select === 'single') {
          if (answers.length > 1) {
            message.error('Single-selection can only choose one answer')
            return
          }
        }
      }
    }
    const submitObj = {
      platform: 'all',
      type: answerType,
      ...fieldValues,
      repeat_submit: fieldValues.repeat_submit.length ? true : false,
    }
    if (answerType !== 'open_question') {
      submitObj.options = options
      if (!time) {
        submitObj.answers = answers
      }
    }
    submitObj.mandatory = isChecked
    if (task.id) submitObj.id = task.id
    setLoading(true)
    const res = await axios.post('/api/admin/space/campaign', {
      step: 2,
      campaign_code: trans_code,
      task: JSON.stringify(submitObj),
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      const id = res.data.data.task_id
      setTasks_arr((state: any) => {
        const list = state
        for (const obj of list) {
          if (obj.uuid === task.uuid) {
            obj.id = id
            obj.type = answerType
            obj.title = submitObj.title
            obj.mandatory = submitObj.mandatory
            obj.question = submitObj.question
            if (answerType !== 'open_question') obj.options = submitObj.options
            if (answerType === 'quiz' && !time) obj.answers = submitObj.answers
            if (answerType === 'quiz') obj.time = submitObj.time
            obj.repeat_submit = submitObj.repeat_submit
            obj.votes = submitObj.votes
            break
          }
        }
        const task_ids = list.filter((task: any) => task.id).map((task: any) => task.id)
        axios.post('/api/admin/space/campaign/sort', {
          campaign_code: trans_code,
          task_ids,
        })
        return [...list]
      })
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async () => {
    if (!task.id) {
      setTasks_arr((state: any) => {
        const list = state
        const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
        list.splice(index, 1)
        return [...list]
      })
      message.success('Success!')
    } else {
      const res = await axios.post('/api/admin/space/campaign/task/remove', {
        campaign_code: trans_code,
        task_id: task.id,
      })
      if (res.data.code === 0) {
        setTasks_arr((state: any) => {
          const list = state
          const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
          list.splice(index, 1)
          return [...list]
        })
        message.success('Success!')
      } else {
        message.error(res.data.message)
      }
    }
  }
  const answerTypeChange = (value: string) => {
    setAnswerType(value)
    if (value === 'vote') setCheckedObj({})
    if (value !== 'open_question') {
      const fields = form.getFieldsValue()
      if (!fields.options) {
        form.setFieldsValue({
          options: [{}],
        })
      }
    }
  }

  const timeChange = (_: any, dateString: string) => {
    setTime(dateString)
    if (dateString) {
      setCheckedObj({})
    } else {
      const fields = form.getFieldsValue()
      if (fields.options) {
        const checkedObj: any = {}
        for (const [index, obj] of fields.options.entries()) {
          checkedObj[index] = !!obj.isSelected?.length
        }
        setCheckedObj(checkedObj)
      }
    }
  }

  const checkboxChange = (e: any, index: number) => {
    const { checked } = e.target
    setCheckedObj((state: any) => ({ ...state, [index]: checked }))
  }

  useEffect(() => {
    setIsChecked(task.mandatory)
    form.setFieldsValue({
      type: task.type,
      question: task.question,
      answer: task.answer,
      votes: task.votes,
      title: task.title,
      repeat_submit: task.repeat_submit ? [true] : [],
    })
    if (task.type !== 'open_question') {
      const options = []
      if (task.type === 'quiz' && !time && !task.time) {
        const checkedObj: any = {}
        if (task.id) {
          for (const [index, option] of task.options.entries()) {
            checkedObj[index] = task.answers.includes(option) ? true : false
            options.push({
              answer: option,
              isSelected: task.answers.includes(option) ? [true] : [],
            })
          }
        }
        setCheckedObj(checkedObj)
      } else {
        if (task.id) {
          for (const option of task.options) {
            options.push({
              answer: option,
            })
          }
        }
      }
      form.setFieldsValue({
        select: task.select,
        options: options.length ? options : [{}],
      })
      if (task.time) {
        form.setFieldsValue({ time: moment(task.time * 1000) })
        setTime(moment(task.time * 1000).format())
      }
    }
    // eslint-disable-next-line
  }, [task, form])

  return (
    <div className="relative w-fit">
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={isOpen ? [task.uuid] : []}
        expandIcon={({ isActive }) => <DownOutlined className="text-xs text-white" rotate={isActive ? 180 : 0} />}
        className={`rounded-md`}
      >
        <Panel
          header={
            <div className="w-500 flex">
              <img className="mr-2" src={mediaImages['answer_question']} alt="" />
              {task.mandatory && <img className="mr-1" src={star} alt="" />}
              <div className="flex">
                <div className="capitalize">{task.type === 'open_question' ? 'OpenQuestion' : task.type}</div>
                <div className="max-w-xs truncate mx-1">{task.title}</div>
                <div>
                  <span className="font-bold">+{task.votes}</span>
                </div>
              </div>
            </div>
          }
          key={task.uuid}
        >
          {
            <div>
              <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                <Form.Item
                  label="Title:"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'title is required!',
                    },
                  ]}
                >
                  <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                </Form.Item>
                <Form.Item
                  label="Question:"
                  name="question"
                  rules={[
                    {
                      required: true,
                      message: 'Question is required!',
                    },
                  ]}
                >
                  <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                </Form.Item>
                <div className="grid grid-flow-row grid-cols-2 gap-x-6">
                  <Form.Item
                    label="Answer Type:"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: 'Question is required!',
                      },
                    ]}
                  >
                    <Select onChange={answerTypeChange} dropdownClassName="border border-bord">
                      <Select.Option value="quiz">Quiz</Select.Option>
                      <Select.Option value="vote">Vote</Select.Option>
                      <Select.Option value="open_question">Open Question</Select.Option>
                    </Select>
                  </Form.Item>
                  {answerType !== 'open_question' && (
                    <Form.Item
                      className="col-span-1"
                      label="Selection type:"
                      name="select"
                      rules={[
                        {
                          required: true,
                          message: 'Selection type is required!',
                        },
                      ]}
                    >
                      <Select dropdownClassName="border border-bord">
                        <Select.Option value="single">Single-selection</Select.Option>
                        <Select.Option value="multi">Multi-selection</Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </div>
                {answerType === 'quiz' && (
                  <div className="w-1/2 pr-3">
                    <Form.Item label="Answer release time:" name="time">
                      <DatePicker
                        onChange={timeChange}
                        className="w-full bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black"
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                      />
                    </Form.Item>
                  </div>
                )}
                {answerType !== 'open_question' && (
                  <div>
                    <div className="mb-2 star_req">Answer</div>
                    <Form.List name="options">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index: number) => (
                            <div key={key}>
                              <div className="flex gap-x-2">
                                <Form.Item
                                  className="flex-1"
                                  {...restField}
                                  name={[index, 'answer']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Option is required!',
                                    },
                                  ]}
                                >
                                  <Input
                                    prefix={<div className="absolute left-0 top-0 h-full flex items-center justify-center px-4 rounded-md bg-ci bg-opacity-20">{index + 1}</div>}
                                    className={`bg-nibg ${checkedObj[index] ? 'border-ci' : 'border-bord'} hover:border-ci focus:border-ci rounded-md pl-16`}
                                  />
                                </Form.Item>
                                {!time && answerType === 'quiz' && (
                                  <div>
                                    <Form.Item {...restField} name={[index, 'isSelected']}>
                                      <Checkbox.Group>
                                        <Checkbox onChange={(e) => checkboxChange(e, index)} value={true} className="flex flex-nowrap">
                                          Mark as answer
                                        </Checkbox>
                                      </Checkbox.Group>
                                    </Form.Item>
                                  </div>
                                )}
                                <div className="mt-1 ml-2">
                                  <MinusCircleOutlined onClick={() => remove(name)} />
                                </div>
                              </div>
                            </div>
                          ))}
                          <Form.Item className="mt-1">
                            <Button
                              disabled={fields.length >= 32}
                              onClick={() => add()}
                              className="pl-0 rounded-full flex items-center bg-transparent border-none opacity-70 hover:opacity-100"
                              type="primary"
                            >
                              <div>
                                <Trans>Add</Trans>
                              </div>
                              <img className={`ml-1 ${fields.length >= 32 ? 'opacity-30' : ''}`} src={add_logo} alt="" />
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>
                )}
                <Form.Item name="repeat_submit">
                  <Checkbox.Group>
                    <Checkbox value={true}>Allow users to resubmit answers</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Row gutter={24}>
                  <Col span={16} key={1}>
                    <Form.Item
                      label="Worth # Entries:"
                      name="votes"
                      rules={[
                        {
                          required: true,
                          message: 'Worth is required!',
                        },
                        {
                          validator: (_, value) => {
                            if (value && !isNumber(value)) {
                              return Promise.reject('Worth can only be numbers!')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                  <Col span={8} key={2}>
                    <Form.Item>
                      <div className="flex items-center space-x-2 pt-9">
                        {!isChecked && <div onClick={() => setIsChecked(true)} className="border border-ci w-4 h-4 rounded-full cursor-pointer"></div>}
                        {isChecked && (
                          <div onClick={() => setIsChecked(false)} className="cursor-pointer">
                            <img className="rounded-full" src={icon_selected} alt="" />
                          </div>
                        )}
                        <div className="text-xs">
                          <Trans>Mandatory</Trans>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-8" type="primary">
                  <Trans>Save</Trans>
                </Button>
              </Form>
            </div>
          }
        </Panel>
      </Collapse>
      <Popconfirm onConfirm={() => confirmFun()} title="Are you sure delete this task?" okText="Yes" cancelText="No">
        <img className="absolute top-1.5 -right-8 h-5 cursor-pointer" src={reject} alt="" />
      </Popconfirm>
    </div>
  )
}

export default AnswerQuestion2
