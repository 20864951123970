import { Trans } from '@lingui/macro'
import { Table } from 'antd'
import crowng from 'assets/images/crowng.svg'
import crowye from 'assets/images/crowye.svg'
import { formatNum } from 'utils'
import { wrapTitle } from './Actions'
import useReport from './hooks/useReport'
import ReportLine from './ReportLine'
import QuizAnswerPie from './QuizAnswerPie'

const Report = ({ campaign_code }: any) => {
  const reportData = useReport(campaign_code)
  const columns: any = [
    {
      title: (
        <div className="pl-2">
          <Trans>Task</Trans>
        </div>
      ),
      dataIndex: 'info',
      key: 'info',
      width: 300,
      render: (cur: any) => {
        return <div className="pl-2">{wrapTitle(cur)}</div>
      },
    },
    {
      title: (
        <div>
          <Trans>Total</Trans>
        </div>
      ),
      dataIndex: 'value',
      key: 'value',
      width: 30,
      render: (cur: number) => <div>{formatNum(cur)}</div>,
    },
  ]
  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="space-y-8">
          <div className="grid grid-cols-4 gap-6">
            <div className="bg-ncon border border-bord rounded-2xl px-6 py-5 space-y-3">
              <div className="flex items-center space-x-1.5">
                <div className="font-medium">
                  <Trans>Impression</Trans>
                </div>
                {/* <img src={note} alt="" /> */}
              </div>
              <div className="text-2xl font-bold">{formatNum(reportData.stats.exposures)}</div>
            </div>
            <div className="bg-ncon border border-bord rounded-2xl px-6 py-5 space-y-3">
              <div className="flex items-center space-x-1.5">
                <div className="font-medium">
                  <Trans>Users</Trans>
                </div>
                {/* <img src={note} alt="" /> */}
              </div>
              <div className="text-2xl font-bold">{formatNum(reportData.stats.users)}</div>
            </div>
            <div className="bg-ncon border border-bord rounded-2xl px-6 py-5 space-y-3">
              <div className="flex items-center space-x-1.5">
                <div className="font-medium">
                  <Trans>Actions</Trans>
                </div>
                {/* <img src={note} alt="" /> */}
              </div>
              <div className="text-2xl font-bold">{formatNum(reportData.stats.actions)}</div>
            </div>
            <div className="bg-ncon border border-bord rounded-2xl px-6 py-5 space-y-3">
              <div className="flex items-center space-x-1.5">
                <div className="font-medium">
                  <Trans>Convert Rate</Trans>
                </div>
                {/* <img src={note} alt="" /> */}
              </div>
              <div className="text-2xl font-bold">{formatNum(reportData.stats.covert_rate * 100)}%</div>
            </div>
          </div>
          <ReportLine campaign_code={campaign_code} />
          <div className={`grid gap-6 ${reportData.quiz_answers.length ? 'grid-cols-2' : 'grid-cols-1'}`}>
            <div className="bg-ncon rounded-lg border border-bord">
              <div className="px-6 py-4 text-base font-bold">
                <Trans>Tasks</Trans>
              </div>
              <div className="overflow-x-scroll">
                <Table
                  dataSource={reportData.tasks}
                  columns={columns}
                  pagination={false}
                  rowKey={(record: any) => record.info.id}
                  rowClassName={(_, index) => {
                    let className = 'nrodd'
                    if (index % 2 === 0) className = 'nreven'
                    return className
                  }}
                />
              </div>
            </div>
            {reportData?.quiz_answers.length ? (
              <QuizAnswerPie campaign_code={campaign_code} quiz_answers={reportData.quiz_answers} />
            ) : (
              <></>
            )}
          </div>
          <div className="bg-ncon rounded-lg border border-bord">
            <div className="px-6 py-4 text-base font-bold">
              <Trans>Rewards</Trans>
            </div>
            <div className="px-6 py-4 border-t border-bord flex">
              <div className="w-20">
                <img className="" src={crowye} alt="" />
              </div>
              <div className="w-80 text-pend">
                <Trans>Winner Candidates</Trans>
              </div>
              <div className="flex-1">{formatNum(reportData.stats.wait_users)}</div>
            </div>
            {reportData.rewards.map((reward: any, index: number) => (
              <div key={index} className="px-6 py-4 border-t border-bord flex">
                <div className="w-20">
                  <img className="" src={crowng} alt="" />
                </div>
                <div className="w-80 text-valid">{reward.info.title}</div>
                <div className="flex-1">{formatNum(reward.value)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report
