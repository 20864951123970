import { Select, Spin, Badge } from "antd"
import axios from "config/axios"
import { useEffect, useState } from "react"
import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import cookie from 'react-cookies'
import useTwitterDetail from "./hooks/useTwitterDetail"
import TwitterProfile from "./TwitterProfile"
import ActiveInfluence from "./ActiveInfluence"
import MentionedCoins from "./MentionedCoins"
import { Trans } from "@lingui/macro"
import useMentionedTopics from "./hooks/useMentionedTopics"
import { formatNum } from "utils"
import useAtPeople from "./hooks/useAtPeople"
import usePeopleAt from "./hooks/usePeopleAt"
import Atlas from "./Atlas"
import useActiveKOLs from "pages/Twitters/hooks/useActiveKOLs"

const { Option } = Select

const TwitterKOL = () => {
  const [{ list }, load] = useActiveKOLs(1)
  const hotOptions = list.slice(0, 8)
  const co = cookie.load('soad_twitter') || []
  const [data, setData] = useState([])
  const [value, setValue] = useState(co[0]?.screen_name)
  const [loading, setLoading] = useState(false)
  const [detail] = useTwitterDetail(value)
  const [topics] = useMentionedTopics('month', value)
  const [atPeople] = useAtPeople('month', value)
  const [peopleAt] = usePeopleAt('month', value)
  const [update, setUpdate] = useState(false)
  const [status, setStatus] = useState(false)

  let timeout: any
  let currentValue: any

  const fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      setLoading(true)
      const res = await axios.get(`/api/admin/twitter/search?name=${value}`)
      setLoading(false)
      if (currentValue === value) {
        callback(res.data.data)
      }
    }
    timeout = setTimeout(fake, 300)
  }
  const handleSearch = (value: string) => {
    setStatus(true)
    if (value) {
      fetch(value, (data: any) => setData(data))
    } else {
      setData(hotOptions)
    }
  }
  const handleChange = (value: any, opt: any) => {
    const arr = [{
      screen_name: opt.value,
      logo_url: opt.label.logo,
      name: opt.label.name,
    }]
    cookie.save('soad_twitter', arr, { path: '/' })
    setValue(value)
  }
  const options = data.map((d: any) => {
    return (
      <Option
        key={d.screen_name}
        label={{ logo: d.logo_url, name: d.name }}
        value={d.screen_name}
      >
        <div className='flex items-center'>
          {d.logo_url && <img className='w-4 mr-1.5 rounded-full' src={d.logo_url} alt="" />}
          <span>{d.name}</span>
        </div>
      </Option>
    )
  })

  useEffect(() => {
    if (detail.screen_name) {
      if (!status) {
        setData(co)
        setValue(co[0]?.screen_name)
      }
    }
    // eslint-disable-next-line
  }, [detail.screen_name])

  useEffect(() => {
    if (!co.length || (co.length && !detail.screen_name)) setData(hotOptions)
    // eslint-disable-next-line
  }, [hotOptions.length, detail.screen_name])

  useEffect(() => {
    if (detail.screen_name) {
      setData(co)
      setValue(co[0]?.screen_name)
    }
    // eslint-disable-next-line
  }, [update])

  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className={`flex-1 relative flex justify-center ${value ? 'mt-6' : 'mt-12'}`}>
        <Select
          placeholder='Search Twitters'
          showSearch
          value={(detail.screen_name && data.length) ? value : undefined}
          size='large'
          className='w-1/2 ant-select-open'
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={loading || load ? <Spin size="small" /> : <div className='text-white'>No results found</div>}
        >
          {options}
        </Select>
        <div className='absolute right-1/4 bottom-1.5 pr-4'><SearchOutlined className='text-ci text-xl' /></div>
      </div>
      {value && detail.screen_name && <div className="grid grid-cols-3 gap-10 mt-20">
        <div className="col-span-1">
          <TwitterProfile data={detail} setUpdate={setUpdate} />
        </div>
        <div className="col-span-2 space-y-10">
          <ActiveInfluence code={value} />
          <MentionedCoins code={value} />
          <div>
            <div className="text-lg font-medium mb-6"><Trans>Metioned Topics</Trans></div>
            <div className="flex flex-wrap gap-3">
              {topics.slice(0, 20).map((topic: any, index: any) => (
                <div key={index} className="flex bg-ci bg-opacity-20 border border-ci rounded px-2 text-ci text-bold w-fit">
                  <div>#{topic.sub_type?.toUpperCase()}</div>
                  <div className="border-l border-ci border-opacity-40 ml-2 pl-2">{formatNum(topic.value)}</div>
                </div>
              ))}
            </div>
            {!topics.length && <div>-</div>}
          </div>
          <div>
            <div>
              <div className="mb-8 text-lg font-medium"><Trans>Mentioned People</Trans></div>
              <div className="grid grid-cols-6 gap-3.5">
                {
                  atPeople.slice(0, 20).map((people: any, index: any) => (
                    <Badge key={index} size="small" showZero className="" count={people.value}>
                      <div onClick={() => {
                        if (!people.logo_url || people.screen_name === value) return
                        const arr = [{
                          screen_name: people.screen_name,
                          logo_url: people.logo_url,
                          name: people.name,
                        }]
                        cookie.save('soad_twitter', arr, { path: '/' })
                        setUpdate((state: any) => !state)
                        window.scrollTo(0, 0)
                      }} className="border border-ci border-opacity-20 rounded-lg px-4 py-1.5 cursor-pointer space-y-1">
                        {people.logo_url &&
                          <div className="flex justify-center"><img className="rounded-full w-6" src={people.logo_url} alt="" /></div>
                        }
                        {
                          !people.logo_url &&
                          <div className="flex justify-center"><UserOutlined className="text-base text-ci" /></div>
                        }
                        <div className="truncate text-center py-1" title={people.name ? people.name : people.screen_name}><a onClick={(e)=>{e.stopPropagation()}} className="text-white hover:underline hover:text-ci" href={`https://twitter.com/${people.screen_name}`} target='_blank' rel="noreferrer">{people.name ? people.name : people.screen_name}</a></div>
                      </div>
                    </Badge>
                  ))
                }
                {!atPeople.length && <div>-</div>}
              </div>
            </div>
            <div>
              <div className="mt-12 mb-8 text-lg font-medium"><Trans>Mentioned By People</Trans></div>
              <div className="grid grid-cols-6 gap-3.5">
                {
                  peopleAt.slice(0, 20).map((people: any, index: any) => (
                    <Badge key={index} size="small" showZero className="" count={people.value}>
                      <div onClick={() => {
                        if (!people.logo_url || people.screen_name === value) return
                        const arr = [{
                          screen_name: people.screen_name,
                          logo_url: people.logo_url,
                          name: people.name,
                        }]
                        cookie.save('soad_twitter', arr, { path: '/' })
                        setUpdate((state: any) => !state)
                        window.scrollTo(0, 0)
                      }} className="border border-ci border-opacity-20 rounded-lg px-4 py-1.5 cursor-pointer space-y-1">
                        {people.logo_url &&
                          <div className="flex justify-center"><img className="rounded-full w-6" src={people.logo_url} alt="" /></div>
                        }
                        {
                          !people.logo_url &&
                          <div className="flex justify-center"><UserOutlined className="text-lg text-ci" /></div>
                        }
                        <div className="truncate text-center py-1" title={people.name ? people.name : people.screen_name}><a onClick={(e)=>{e.stopPropagation()}} className="text-white hover:underline hover:text-ci" href={`https://twitter.com/${people.screen_name}`} target='_blank' rel="noreferrer">{people.name ? people.name : people.screen_name}</a></div>
                      </div>
                    </Badge>
                  ))
                }
                {!peopleAt.length && <div>-</div>}
              </div>
            </div>
          </div>
        </div>
      </div>}
      {value && detail.screen_name && <Atlas code={value} setUpdate={setUpdate} />}
    </div>
  )
}

export default TwitterKOL
