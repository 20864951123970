import { useState, useEffect } from "react"
import { formatBigNumber } from "utils"
import { useTokenContract } from "./useContract"
import { useWeb3React } from "@web3-react/core"
import { zeroAddress } from "config/Address"

function useTokenBalance(tokenAddress: string, update?: boolean) {
	const { chainId, account, library } = useWeb3React()
	const [balance, setBalance] = useState(['0', 18])
	const tokenContract = useTokenContract(tokenAddress)

	const fetchBalance = async () => {
		try {
			let formatBalance = ''
			let decimals = 18
			if (tokenAddress === zeroAddress) {
				const balance = await library?.getBalance(account)
				formatBalance = formatBigNumber(balance, decimals)
			} else {
				const balance = await tokenContract?.balanceOf(account)
				decimals = await tokenContract?.decimals()
				formatBalance = formatBigNumber(balance, decimals)
			}
			setBalance([formatBalance, decimals])
		} catch (error) {
			console.error("useTokenBalance --> " + error)
		}
	}

	useEffect(() => {
		if (account && tokenAddress) fetchBalance()
		// eslint-disable-next-line
	}, [account, chainId, tokenAddress, update])

	return balance
}

export default useTokenBalance
