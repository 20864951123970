import { Route, Switch, Redirect } from 'react-router-dom'
import Home from './Home'
import Projects from './Projects'
import Communities from './Communities'
import Twitters from './Twitters'
import TwitterKOL from './TwitterKOL'
import ProjectSpaces from './Space/ProjectSpaces'
import SpaceProfile from './Space/SpaceProfile'
import SpaceToken from './Space/SpaceToken'
import Campaigns from './Space/Campaigns'
import CampaignEvent from './Space/CampaignEvent'
import APIKey from './Space/APIKey'
import Detail from './Space/Rewards/Detail'
import SystemAPIKey from './Space/SystemAPIKey'
import Protocol from './Space/Protocol'
import Rewards from './Space/Rewards'
import DeployNFT from './Space/Rewards/DeployNFT'
import Feedback from './Space/Feedback'
import GemStone from './Space/GemStone'

function Routes() {
  return (
    <Switch>
      <Route exact strict path="/project-spaces" component={ProjectSpaces} />
      <Route exact strict path="/protocol" component={Protocol} />
      <Route exact strict path="/space-profile" component={SpaceProfile} />
      <Route exact strict path="/admin/space-tokens" component={SpaceToken} />
      <Route exact strict path="/admin/campaigns" component={Campaigns} />
      <Route exact strict path="/system-api-key" component={SystemAPIKey} />
      <Route exact strict path="/api-key" component={APIKey} />
      <Route exact strict path="/space-tokens" component={SpaceToken} />
      <Route exact strict path="/campaigns" component={Campaigns} />
      <Route exact strict path="/rewards" component={Rewards} />
      <Route exact strict path="/giveaway/detail" component={Detail} />
      <Route exact strict path="/campaigns/setup" component={CampaignEvent} />
      <Route exact strict path="/nft/deploy" component={DeployNFT} />
      <Route exact strict path="/statistics" component={Home} />
      <Route exact strict path="/project" component={Projects} />
      <Route exact strict path="/community" component={Communities} />
      <Route exact strict path="/twitter/statistics" component={Twitters} />
      <Route exact strict path="/twitter/kol" component={TwitterKOL} />
      <Route exact strict path="/feedback" component={Feedback} />
      <Route exact strict path="/gemstone" component={GemStone} />
      <Route path="*">
        <Redirect to="/space-tokens" />
      </Route>
    </Switch>
  )
}

export default Routes
