import { Pie } from '@ant-design/plots'
import { useEffect, useMemo, useState } from 'react'
import { Select, Spin } from 'antd'
import { formatNum } from 'utils'
import useQuizAnswerPie from './hooks/useQuizAnswerPie'
import { Trans } from '@lingui/macro'

const QuizAnswerPie = ({ campaign_code, quiz_answers }: any) => {
  const [defaultValue, setDefaultValue] = useState()
  const [currentValue, setCurrentValue] = useState<any>()
  const [data, loading] = useQuizAnswerPie(currentValue, campaign_code)

  const config: any = {
    appendPadding: 10,
    data: data.stats,
    width: 365,
    height: 365,
    angleField: 'value',
    colorField: 'type',
    radius: 0.6,
    label: {
      type: 'spider',
      content: (data: any) => {
        return `${data.type}\n${formatNum(data.percent * 100, 2)}%`
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  }
  const graph = useMemo(() => {
    return <Pie {...config} />
    // eslint-disable-next-line
  }, [data])

  const selectFun = (value: any) => {
    setCurrentValue(value)
  }

  useEffect(() => {
    if (!!quiz_answers.length) {
      setDefaultValue(quiz_answers[0].id)
      setCurrentValue(quiz_answers[0].id)
    }
    // eslint-disable-next-line
  }, [quiz_answers.length])

  return (
    <div className="bg-ncon px-6 pt-4 pb-7 rounded-md relative">
      <div className="flex">
        <div className="flex-1 mr-10 text-base font-bold">
          <Trans>Quiz Answers</Trans>
        </div>
        <div>{formatNum(data.completed_users)} users completed the task</div>
      </div>
      <div className="mt-6">
        <Select
          key={defaultValue}
          className="w-full"
          dropdownClassName="border border-bord"
          defaultValue={defaultValue}
          onSelect={selectFun}
        >
          {quiz_answers.map((quiz: any, index: number) => (
            <Select.Option key={index} value={quiz.id}>
              {quiz.question}
            </Select.Option>
          ))}
        </Select>
      </div>
      {loading && (
        <div className="absolute left-1/2 top-1/2 z-40">
          <Spin />
        </div>
      )}
      <div className="mt-10 md:mt-4 relative">{graph}</div>
    </div>
  )
}

export default QuizAnswerPie
