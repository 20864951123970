import { useEffect, useState } from "react"
import axios from "config/axios"
import { formatTime } from "utils"

const useActiveInfluence = (date: string, code: string) => {
  const [data, setData] = useState<any>([[], []])
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const ress = await Promise.all([
        await axios.get(`/api/admin/twitter/user/active?screen_name=${code}&date=${date}`),
        await axios.get(`/api/admin/twitter/user/influence?screen_name=${code}&date=${date}`),
      ])
      for (const res of ress) {
        for (const obj of res.data.data) {
          const fullTime = formatTime(date, obj.date, true)
          obj.date = formatTime(date, obj.date)
          obj.fullTime = fullTime
          if (obj.type === 'Influence') obj.count = obj.value
        }
      }
      setLoading(false)
      setData([...ress[0].data.data, ...ress[1].data.data])
    } catch (error) {
      console.error("useActiveInfluence --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [date, code])
  return [data, loading]
}

export default useActiveInfluence
