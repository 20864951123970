import axios from 'axios'
import { sha256 } from 'js-sha256'
import cookie from 'react-cookies'

export const dev = 'http://192.168.31.161:8088'
export const test = 'http://47.52.172.230:8088'
export const pro = 'https://api.sograph.xyz'
export const cur = pro

export const previewUrl = cur.includes('sograph.xyz') ? 'https://sograph.xyz/space/' : cur.includes('47.52.172.230') ? 'http://3.0.184.23:7777/space/' : 'http://192.168.31.108:3000/space/'
export const uploadUrl = cur.includes('sograph.xyz') ? 'https://upload.sograph.xyz' : cur.includes('47.52.172.230') ? 'http://47.52.172.230:8090' : 'http://192.168.31.161:8087'

// export const api_key = 'D770DDFAF38A4FF6C65FF7D1BD30E856'
// export const secret_key = 'f6FhRwtJfCCwfzPxeM7d4cFPrPTej2ODCH6Z0N7iDBs='

export const api_key = 'CC5393828E8D6530F0FF9ACC506A8F3B'
export const secret_key = 'SHHRUBSfNpDJvTmJ5YyoVA9H-b6fPxcPS7FRGyLpGhE='

axios.interceptors.request.use((config) => {
  const signed = cookie.load('soad_signed')
  const account = cookie.load('soad_acc')
  const space_code = cookie.load('soad_space_code')
  const timestamp = parseInt(new Date().getTime() / 1000 + '')
  const sha = sha256(api_key + secret_key + timestamp)

  config.headers = {
    APIKEY: api_key,
    TIME: timestamp,
    SIGN: sha,
    signature: signed,
    address: account + '',
    'Space-Code': space_code,
  }
  return config
})

export default axios
