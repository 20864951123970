import { useEffect, useState } from "react"
import axios from "config/axios"

const useTwitterAPI = () => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/twitter/apikey`)
      setLoading(false)
      setData(res.data.data.data)
    } catch (error) {
      console.error("useTwitterAPI --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])
  return [data, loading]
}

export default useTwitterAPI
