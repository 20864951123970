import { Table, Tooltip } from 'antd'
import { Trans } from "@lingui/macro"
import { formatNum, formatTimeAgo } from "utils"
import { useState } from 'react'
import useTrendingTweets from './hooks/useTrendingTweets'

const TrendingTweets = () => {
  const [page, setPage] = useState(1)
  const [{ list, total }, loading] = useTrendingTweets(page)
  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 60,
      render: (_cur: any, _record: any, index: number) => (<div>{(index + 1)+(page-1)*8}</div>)
    },
    {
      title: <div><Trans>Username</Trans></div>,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 160,
      render: (cur: any, record: any) => (
        <span className="flex items-center">
          {record.logo_url && <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />}
          <div className="truncate">
            <Tooltip placement="topLeft" title={cur}>
              {cur}
            </Tooltip>
          </div>
        </span>
      )
    },
    {
      title: <div><Trans>Tweet Content</Trans></div>,
      dataIndex: 'text',
      key: 'text',
      width: 560,
      ellipsis: true,
      render: (cur: any, record: any) => (
        <div className='truncate'>
          <Tooltip placement="topLeft" title={cur} overlayStyle={{ minWidth: 580 }} zIndex={9999}>
          <a className='text-white hover:underline hover:text-ci' href={record.link_url} target='_blank' rel="noreferrer">{cur}</a>
          </Tooltip>
        </div>
      )
    },
    {
      title: <div><Trans>Engagement(24H)</Trans></div>,
      dataIndex: 'engagement',
      key: 'engagement',
      width: 155,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
    {
      title: <div><Trans>Time</Trans></div>,
      dataIndex: 'action_time',
      key: 'action_time',
      width: 130,
      render: (cur: any) => (<div>{formatTimeAgo(cur)}</div>)
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded px-4 pt-4">
        <div className="text-xl font-medium pl-3"><Trans>Trending Tweets</Trans></div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table className="" loading={loading} dataSource={list} columns={columns}
            rowKey={(record) => record.link_url}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 8,
              total: total,
              // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  )
}

export default TrendingTweets
