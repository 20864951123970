import React from 'react'
import ReactDOM from 'react-dom'
import 'style/app.less'
import 'style/index.less'
import "@fontsource/fira-sans"
import "@fontsource/ibm-plex-sans"
import LanguageProvider from 'i18n'
import reportWebVitals from './reportWebVitals'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import store from 'state'
import { Provider } from 'react-redux'
import App from 'pages/App'
import axios from 'axios'
import Web3ReactManager from 'components/Web3ReactManager'
import { cur } from 'config/axios'

axios.defaults.baseURL = cur
axios.defaults.headers.post['Content-Type'] = 'application/json'

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <LanguageProvider>
          <Web3ReactManager>
            <Router>
              <Switch>
                <App />
              </Switch>
            </Router>
          </Web3ReactManager>
        </LanguageProvider>
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
