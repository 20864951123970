import { Trans } from '@lingui/macro'
import arrow from 'assets/images/icon_arrow.svg'
import { useQuery } from 'hooks/useQuery'
import { useEffect, useState } from 'react'
import useSetupData from './hooks/useSetupData'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import Rewards from './Rewards'
import Setup from './Setup'
import Tasks from './Tasks'
import Actions from './Actions'
import Report from './Report'
import Winners from './Winners'
import { isEqual } from 'lodash'

const CampaignEvent = () => {
  const history = useHistory()
  const query = useQuery()
  const campaign_code = query.get('campaign_code')
  const [code, setCode] = useState()
  const [tab, setTab] = useState('setup')
  const [step, setStep] = useState(1)
  const [menu, setMenu] = useState(1)
  const [update, setUpdate] = useState(false)
  const [setupData, setUpdata] = useState()
  const trans_code = campaign_code || code
  const eventData = useSetupData(trans_code, update)
  const [count, setCount] = useState(0)
  if (eventData === 7) history.push('/campaigns')

  const [tasks, setTasks] = useState([])
  const [prizes, setPrizes] = useState([])
  useEffect(() => {
    if (eventData && eventData !== 7) {
      setTasks(eventData.tasks)
      setPrizes(eventData.prizes)
      setUpdata(eventData)
    }
  }, [eventData])

  let location = useLocation()
  const { state }: { state: any } = location

  useEffect(() => {
    if (state?.tab) {
      setTab(state.tab)
      history.replace({
        ...location,
        state: undefined,
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="text-base font-semibold mb-6">
          <Trans>Campaign Event</Trans>
        </div>
        <div className="flex items-center">
          <div className="flex-1 flex space-x-8 font-semibold">
            <div onClick={() => setTab('setup')} className={`cursor-pointer ${tab === 'setup' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}>
              <Trans>Set up</Trans>
            </div>
            {campaign_code && (
              <div onClick={() => setTab('analytics')} className={`cursor-pointer ${tab === 'analytics' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}>
                <Trans>Analytics</Trans>
              </div>
            )}
          </div>
          {tab === 'setup' && (
            <div className="flex space-x-4">
              <div onClick={() => setStep(1)} className={`px-3 py-0.5 text-white rounded-full cursor-pointer hover:text-white ${step === 1 ? 'bg-ci' : 'text-opacity-60 bg-ncon border border-bord'}`}>
                <Trans>1.Set up</Trans>
              </div>
              <div>
                <img src={arrow} alt="" />
              </div>
              <div
                onClick={() => {
                  if (!trans_code) return
                  setStep(2)
                }}
                className={`${trans_code ? 'cursor-pointer hover:text-white' : 'cursor-not-allowed'} px-3 py-0.5 text-white rounded-full ${
                  step === 2 ? 'bg-ci' : 'text-opacity-60 bg-ncon border border-bord'
                }`}
              >
                <Trans>2.Tasks</Trans>
              </div>
              <div>
                <img src={arrow} alt="" />
              </div>
              <div
                onClick={() => {
                  if (!trans_code) return
                  setStep(3)
                }}
                className={`${trans_code ? 'cursor-pointer hover:text-white' : 'cursor-not-allowed'} px-3 py-0.5 text-white rounded-full ${
                  step === 3 ? 'bg-ci' : 'text-opacity-60 bg-ncon border border-bord'
                }`}
              >
                <Trans>3.Rewards</Trans>
              </div>
            </div>
          )}
        </div>
        {tab === 'setup' && (
          <div className="mt-8">
            {step === 1 && <Setup count={count} setCount={setCount} setStep={setStep} setUpdate={setUpdate} trans_code={trans_code} setupData={setupData} setCode={setCode} />}
            {step === 2 && <Tasks groups={eventData?.task_group || []} group_titles={eventData?.group_titles || []} setStep={setStep} setUpdate={setUpdate} tasks={tasks} trans_code={trans_code} />}
            {step === 3 && <Rewards setUpdate={setUpdate} prizes={prizes} trans_code={trans_code} setupData={setupData} />}
          </div>
        )}
        {tab === 'analytics' && (
          <div>
            <div className="mt-5 flex space-x-4 mb-8">
              <div onClick={() => setMenu(1)} className={`cursor-pointer px-4 py-1 w-fit text-white bg-ci rounded-md hover:text-opacity-100 ${menu === 1 ? '' : 'bg-opacity-20 text-opacity-80'}`}>
                <Trans>Actions</Trans>
              </div>
              <div onClick={() => setMenu(2)} className={`cursor-pointer px-4 py-1 w-fit text-white bg-ci rounded-md hover:text-opacity-100 ${menu === 2 ? '' : 'bg-opacity-20 text-opacity-80'}`}>
                <Trans>Report</Trans>
              </div>
              <div onClick={() => setMenu(3)} className={`cursor-pointer px-4 py-1 w-fit text-white bg-ci rounded-md hover:text-opacity-100 ${menu === 3 ? '' : 'bg-opacity-20 text-opacity-80'}`}>
                <Trans>Winners</Trans>
              </div>
              <div
                onClick={() => history.push('/rewards')}
                className={`cursor-pointer px-4 py-1 w-fit text-white bg-ci rounded-md hover:text-opacity-100 ${menu === 4 ? '' : 'bg-opacity-20 text-opacity-80'}`}
              >
                <Trans>Rewards</Trans>
              </div>
            </div>
            {menu === 1 && <Actions campaign_code={campaign_code} setMenu={setMenu} />}
            {menu === 2 && <Report campaign_code={campaign_code} />}
            {menu === 3 && <Winners campaign_code={campaign_code} />}
          </div>
        )}
      </div>

      <Prompt
        when={!isEqual(JSON.parse(localStorage.getItem('cacheStepForm') || '{}'), JSON.parse(localStorage.getItem('cacheStepFormInit') || '{}'))}
        message={() => {
          let leave = window.confirm('You haven’t save your data. Are you sure to leave current page?')
          // clear cache
          if (leave) {
            localStorage.removeItem('cacheStepForm')
            localStorage.removeItem('cacheStepFormInit')
          }
          return leave
        }}
      />
    </div>
  )
}

export default CampaignEvent
