import { useEffect, useRef, useState } from "react"
import G6 from '@antv/g6'
import ReactDOM from "react-dom"
import useFriends from "./hooks/useFriends"
import { Divider } from "antd"
import { formatNum } from "utils"
import cookie from 'react-cookies'

function refreshDragedNodePosition(e: any) {
  const model = e.item.get('model')
  model.fx = e.x
  model.fy = e.y
}

function clearAllStats(graph: any) {
  graph.setAutoPaint(false)
  graph.getNodes().forEach(function (node: any) {
    graph.clearItemStates(node)
  });
  graph.getEdges().forEach(function (edge: any) {
    graph.clearItemStates(edge)
  })
  graph.paint()
  graph.setAutoPaint(true)
}

const Atlas = ({ code, setUpdate }: any) => {
  const ref = useRef(null)
  let graph: any = null
  const [friends] = useFriends(code)
  const [current, setCurrent] = useState<any>([])
  const [target, setTarget] = useState<any>([])

  useEffect(() => {
    setCurrent([])
    setTarget([])
  }, [code])

  useEffect(() => {
    if (!graph && friends.nodes.length) {
      const config: any = {
        container: ReactDOM.findDOMNode(ref.current),
        width: 820,
        height: 900,
        layout: {
          type: 'force',
          edgeStrength: 0.3,
          preventOverlap: true,
        },
        defaultNode: {
          type: 'image',
        },
        defaultEdge: {
          style: {
            stroke: 'gray',
            opacity: 0.1,
          }
        },
        nodeStateStyles: {
          highlight: {
            opacity: 1,
          },
          dark: {
            opacity: 0.2,
          },
        },
        edgeStateStyles: {
          highlight: {
            stroke: '#7754F8',
            opacity: 0.5,
            lineWidth: 1,
          },
        },
      }
      // eslint-disable-next-line
      graph = new G6.Graph(config)
      friends.nodes.forEach((node: any) => {
        const size = Math.random() * 16 + 20
        node.size = node.id === code ? 50 : size
        const cfg = {
          show: true,
          type: 'circle',
          r: node.size / 2,
        }
        node.clipCfg = cfg
      })
      graph.data(friends)
      graph.render()
      graph.on('node:dragstart', function (e: any) {
        graph.layout()
        refreshDragedNodePosition(e)
      })
      graph.on('node:drag', function (e: any) {
        const forceLayout = graph.get('layoutController').layoutMethods[0]
        forceLayout.execute()
        refreshDragedNodePosition(e)
      })
      graph.on('node:dragend', function (e: any) {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })
      graph.on('node:mouseenter', function (e: any) {
        const item = e.item
        graph.setAutoPaint(false)
        graph.getNodes().forEach(function (node: any) {
          graph.clearItemStates(node)
          graph.setItemState(node, 'dark', true)
        })
        graph.setItemState(item, 'dark', false)
        graph.setItemState(item, 'highlight', true);
        graph.getEdges().forEach(function (edge: any) {
          if (edge.getSource() === item) {
            graph.setItemState(edge.getTarget(), 'dark', false)
            graph.setItemState(edge.getTarget(), 'highlight', true)
            graph.setItemState(edge, 'highlight', true)
            edge.toFront()
          } else if (edge.getTarget() === item) {
            graph.setItemState(edge.getSource(), 'dark', false)
            graph.setItemState(edge.getSource(), 'highlight', true)
            graph.setItemState(edge, 'highlight', true)
            edge.toFront()
          } else {
            graph.setItemState(edge, 'highlight', false)
          }
        })
        graph.paint()
        graph.setAutoPaint(true)
      })
      graph.on('node:mouseleave', () => clearAllStats(graph))
      graph.on('node:click', function (event: any) {
        const { item } = event
        const selected_twi = friends.nodes.filter((node: any) => node.id === item._cfg.id)
        setCurrent(selected_twi)
        const target_twi: any[] = []
        for (const obj of friends.edges) {
          if (obj.source === item._cfg.id) {
            target_twi.push(obj.target)
          }
          if (obj.target === item._cfg.id) {
            target_twi.push(obj.source)
          }
        }
        const relation_twi = friends.nodes.filter((node: any) => target_twi.includes(node.id))
        // setTarget(code === item._cfg.id ? friends.nodes.slice(1, friends.nodes.length) : relation_twi)
        setTarget(relation_twi)
      })
    }
    return () => graph?.destroy()
    // eslint-disable-next-line
  }, [friends])

  return (
    <div className="mt-16">
      <Divider orientation="left">
        <span className="text-lg">Graph</span>
      </Divider>
      <div className="flex">
        <div className="mt-6" style={{width: 380, paddingRight: 10}}>
          <div onClick={() => {
            if (current[0]?.id === code) return
            const arr = [{
              screen_name: current[0]?.id,
              logo_url: current[0]?.img,
              name: current[0]?.name,
            }]
            cookie.save('soad_twitter', arr, { path: '/' })
            setUpdate((state: any) => !state)
            window.scrollTo(0, 0)
          }} className="flex items-center cursor-pointer">
            <img className="h-6 rounded-full mr-1.5" src={current[0]?.img} alt="" />
            <span>{current[0]?.name}</span>
            {current[0]?.friends_count && <span className="ml-3 text-ci text-base">{formatNum(current[0]?.friends_count)}</span>}
          </div>
          <div className='space-y-4 mt-8 overflow-y-scroll' style={{ height: 800 }}>
            {target.map((tar: any, index: number) => (
              <div onClick={() => {
                const arr = [{
                  screen_name: tar.id,
                  logo_url: tar.img,
                  name: tar.name,
                }]
                cookie.save('soad_twitter', arr, { path: '/' })
                setUpdate((state: any) => !state)
                window.scrollTo(0, 0)
              }} key={index} className="flex items-center cursor-pointer">
                <img className="h-6 rounded-full mr-1.5" src={tar.img} alt="" />
                <span>{tar?.name}</span>
                <span className="ml-3 text-ci text-base">{formatNum(tar?.followers_count)}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1" ref={ref}></div>
      </div>
    </div>
  )
}
export default Atlas
