import { Button, Form, Input, Collapse, Row, Col, message, Popconfirm, Select, DatePicker } from 'antd'
import icon_selected from 'assets/images/icon_selected.svg'
import reject from 'assets/images/icon_reject.svg'
import arrow from 'assets/images/icon_arrow_right.svg'
import star from 'assets/images/star.svg'
import { DownOutlined } from '@ant-design/icons'
import { mediaImages } from 'config/media'
import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import axios from 'config/axios'
import { isNumber, titleCase } from 'utils'
import moment from 'moment'
import { blockchainName } from 'config/blockchain'

const { Panel } = Collapse
const { Option } = Select

const InteractProtocol = ({ setTasks_arr, task, trans_code, isOpen = false }: any) => {
  const [isChecked, setIsChecked] = useState(true)
  const [loading, setLoading] = useState(false)
  const [contractList, setContractList] = useState<any>([])
  const [methodList, setMethodList] = useState<any>([])
  const [form] = Form.useForm()

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    fieldValues.start_time = fieldValues.start_time ? parseInt(fieldValues.start_time.valueOf() / 1000 + '') : ''
    fieldValues.end_time = fieldValues.end_time ? parseInt(fieldValues.end_time.valueOf() / 1000 + '') : ''
    const submitObj = {
      platform: task.platform,
      type: 'onchain_interact',
      protocol_code: task.protocol_code,
      blockchain: task.blockchain,
      ...fieldValues,
    }
    submitObj.mandatory = isChecked
    if (task.id) submitObj.id = task.id
    setLoading(true)
    const res = await axios.post('/api/admin/space/campaign', {
      step: 2,
      campaign_code: trans_code,
      task: JSON.stringify(submitObj),
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      const id = res.data.data.task_id
      setTasks_arr((state: any) => {
        const list = state
        for (const obj of list) {
          if (obj.uuid === task.uuid) {
            obj.id = id
            obj.title = submitObj.title
            obj.mandatory = submitObj.mandatory
            obj.start_time = submitObj.start_time
            obj.end_time = submitObj.end_time
            obj.url = submitObj.url
            obj.votes = submitObj.votes
            obj.contract_address = submitObj.contract_address
            break
          }
        }
        const task_ids = list.filter((task: any) => task.id).map((task: any) => task.id)
        axios.post('/api/admin/space/campaign/sort', {
          campaign_code: trans_code,
          task_ids,
        })
        return [...list]
      })
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async () => {
    if (!task.id) {
      setTasks_arr((state: any) => {
        const list = state
        const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
        list.splice(index, 1)
        return [...list]
      })
      message.success('Success!')
    } else {
      const res = await axios.post('/api/admin/space/campaign/task/remove', {
        campaign_code: trans_code,
        task_id: task.id,
      })
      if (res.data.code === 0) {
        setTasks_arr((state: any) => {
          const list = state
          const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
          list.splice(index, 1)
          return [...list]
        })
        message.success('Success!')
      } else {
        message.error(res.data.message)
      }
    }
  }

  const fetchContract = async () => {
    const res = await axios.get(`/api/admin/project/protocol/all?protocol_code=${task.protocol_code}&blockchain=${task.blockchain}`)
    setContractList(res.data.data)
  }

  const contractSelect = (value: string) => {
    for (const obj of contractList) {
      if (obj.contract_address === value) {
        setMethodList(obj.hashes)
        return
      }
    }
  }

  useEffect(() => {
    fetchContract()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setIsChecked(task.mandatory)
    form.setFieldsValue({
      url: task.url,
      votes: task.votes,
      title: task.title,
      contract_address: task.contract_address,
      method: task.method,
      start_time: task.start_time ? moment(task.start_time * 1000) : '',
      end_time: task.end_time ? moment(task.end_time * 1000) : '',
    })
  }, [task, form])

  return (
    <div className="relative w-fit">
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={isOpen ? [task.uuid] : []}
        expandIcon={({ isActive }) => <DownOutlined className="text-xs text-white" rotate={isActive ? 180 : 0} />}
        className={`rounded-md`}
      >
        <Panel
          header={
            <div className="w-500 flex">
              <img className="mr-1" src={mediaImages[task.platform]} alt="" />
              {task.mandatory && <img className="mr-1" src={star} alt="" />}
              <div className="flex">
                <div className="max-w-xs truncate mx-1">{titleCase(task.title)}</div>
                <div>
                  on {task.platform} {` - ${blockchainName[task.blockchain]}`} <span className="font-bold">+{task.votes}</span>
                </div>
              </div>
            </div>
          }
          key={task.uuid}
        >
          {
            <div>
              <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Title:"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'title is required!',
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="URL:"
                      name="url"
                      rules={[
                        {
                          required: true,
                          message: 'url is required!',
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  <Form.Item
                    label="Contract:"
                    name="contract_address"
                    rules={[
                      {
                        required: true,
                        message: 'contract is required!',
                      },
                    ]}
                  >
                    <Select showArrow={true} onSelect={contractSelect} dropdownClassName="border border-bord">
                      {contractList.map(({ contract_address, contract_name }: any, index: number) => (
                        <Option value={contract_address} key={index}>
                          {contract_name} - {contract_address}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="Method:" name="method">
                    <Select allowClear={true} showArrow={true} dropdownClassName="border border-bord">
                      {methodList.map(({ name }: any, index: number) => (
                        <Option value={name} key={index}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <div className="mb-2">
                    <Trans>Require Time period:</Trans>
                  </div>
                  <div className="flex">
                    <Form.Item className="flex-1" name="start_time">
                      <DatePicker className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black w-full" showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
                    </Form.Item>
                    <div className="pt-1.5 px-3">
                      <img src={arrow} alt="" />
                    </div>
                    <Form.Item className="flex-1" name="end_time">
                      <DatePicker className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black w-full" showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
                    </Form.Item>
                  </div>
                </div>
                <Row gutter={24}>
                  <Col span={16} key={1}>
                    <Form.Item
                      label="Worth # Entries:"
                      name="votes"
                      rules={[
                        {
                          required: true,
                          message: 'Worth is required!',
                        },
                        {
                          validator: (_, value) => {
                            if (value && !isNumber(value)) {
                              return Promise.reject('Worth can only be numbers!')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                  <Col span={8} key={1}>
                    <Form.Item>
                      <div className="flex items-center space-x-2 pt-9">
                        {!isChecked && <div onClick={() => setIsChecked(true)} className="border border-ci w-4 h-4 rounded-full cursor-pointer"></div>}
                        {isChecked && (
                          <div onClick={() => setIsChecked(false)} className="cursor-pointer">
                            <img className="rounded-full" src={icon_selected} alt="" />
                          </div>
                        )}
                        <div className="text-xs">
                          <Trans>Mandatory</Trans>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-8" type="primary">
                  <Trans>Save</Trans>
                </Button>
              </Form>
            </div>
          }
        </Panel>
      </Collapse>
      <Popconfirm onConfirm={() => confirmFun()} title="Are you sure delete this task?" okText="Yes" cancelText="No">
        <img className="absolute top-1.5 -right-8 h-5 cursor-pointer" src={reject} alt="" />
      </Popconfirm>
    </div>
  )
}

export default InteractProtocol
