import { Table, Tooltip } from 'antd'
import { Trans } from "@lingui/macro"
import { formatNum } from "utils"
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'
import { useState } from 'react'
import useTrendingKOLs from './hooks/useTrendingKOLs'

const TrendingKOLs = () => {
  const history = useHistory()
  const handleClick = (e: any) => {
    e.stopPropagation()
  }
  const [page, setPage] = useState(1)
  const [{ list, total }, loading] = useTrendingKOLs(page)
  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 80,
      render: (_cur: any, _record: any, index: number) => (<div>{(index + 1)+(page-1)*8}</div>)
    },
    {
      title: <div><Trans>Username</Trans></div>,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200,
      render: (cur: any, record: any) => (
        <div className="flex items-center">
          {record.logo_url && <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />}
          <div className="truncate">
            <Tooltip placement="topLeft" title={cur}>
            <a onClick={handleClick} className='text-white hover:underline hover:text-ci' href={record.link_url} target='_blank' rel="noreferrer">{cur}</a>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      title: <div><Trans>New Followers(24H)</Trans></div>,
      dataIndex: 'value',
      key: 'value',
      width: 125,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded pt-4">
        <div className="text-xl font-medium pl-3"><Trans>Trending KOLs</Trans></div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
            rowKey={(record) => record.screen_name}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  const arr = [{
                    screen_name: record.screen_name,
                    logo_url: record.logo_url,
                    name: record.name,
                  }]
                  cookie.save('soad_twitter', arr, { path: '/' })
                  history.push(`/twitter/kol`)
                  window.scrollTo(0, 0)
                }
              }
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 8,
              total: total > 40 ? 40 : total,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  )
}

export default TrendingKOLs
