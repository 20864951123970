import { Trans } from "@lingui/macro"
import { Button, Form, Input, message, Popconfirm } from "antd"
import close from 'assets/images/_icon_close.svg'
import axios from "config/axios"
import { useEffect, useState } from "react"

const AddEmail = ({ setUpdate, emails }: any) => {
  const [emailList, setEmailList] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    setLoading(true)
    const res = await axios.post('/api/admin/space/profile', {
      ...fieldValues,
      type: 'add_email',
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async (email: string) => {
    const res = await axios.post('/api/admin/space/profile', {
      type: 'remove_email',
      email
    })
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  useEffect(() => {
    setEmailList(emails)
    // eslint-disable-next-line
  }, [emails])

  return (
    <div>
      <div><Trans>Admin Emails:</Trans></div>
      <div className="mt-4 w-fit relative">
        <Form
          name="basic"
          layout="horizontal"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label=""
            name="email"
            rules={[
              {
                validator: (_, value) => {
                  let reg = new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
                  if (value && !reg.test(value)) {
                    return Promise.reject('Please enter the correct email format!')
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
          >
            <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96' />
          </Form.Item>
          <Button loading={loading} htmlType="submit" className='rounded px-8 absolute top-0 -right-32' type='primary'><Trans>Save</Trans></Button>
        </Form>
      </div>
      <>
        {
          emailList?.map((email: string) => (
            <div key={email} className="bg-nibg border border-bord w-96 rounded-md px-2 py-1.5 text-xs flex items-center">
              <div className="flex-1">{email}</div>
              <Popconfirm onConfirm={() => confirmFun(email)} title="Are you sure delete this email?" okText="Yes" cancelText="No">
                <img className="ml-2 cursor-pointer" src={close} alt="" />
              </Popconfirm>
            </div>
          ))
        }
      </>
    </div>
  )
}

export default AddEmail