import React from 'react'
import Cropper from '../Cropper'

type Props = {
  aspect?: number
  children: any
}
interface UploadImg {
  (props: Props): JSX.Element | null
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const UploadImg: UploadImg = ({ aspect, children }) => {
  return (
    <>
      <Cropper aspect={aspect}>{children}</Cropper>
    </>
  )
}

export default UploadImg
