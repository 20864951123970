import { Table } from 'antd'
import { Trans } from "@lingui/macro"
import { formatNum } from "utils"
import useMentionedCoins from './hooks/useMentionedCoins'
import { useState } from 'react'
import { DollarOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

const MentionedCoins = ({ code }: any) => {
  const [time, setTime] = useState('week')
  const [data, loading] = useMentionedCoins(time, code)

  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 80,
      render: (_cur: any, _record: any, index: number) => (<div>{index + 1}</div>)
    },
    {
      title: <div><Trans>Symbol</Trans></div>,
      dataIndex: 'symbol',
      key: 'symbol',
      ellipsis: true,
      width: 130,
      render: (cur: any, record: any) => (
        <div className="flex items-center">
          {record.logo_url && <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />}
          {!record.logo_url && <DollarOutlined style={{ fontSize: 18 }} className='mr-1.5 text-ci' />}
          <div className="truncate">
            {/* <Tooltip placement="topLeft" title={cur}> */}
            {cur}
            {/* </Tooltip> */}
          </div>
        </div>
      )
    },
    {
      title: <div><Trans>Mentions</Trans></div>,
      dataIndex: 'value',
      key: 'value',
      width: 130,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
    {
      title: <div><Trans>Price</Trans></div>,
      dataIndex: 'price',
      key: 'price',
      width: 130,
      render: (cur: any) => (
        <div>
          {!!cur && <div>${formatNum(cur)}</div>}
          {!cur && <div>-</div>}
        </div>
      )
    },
    {
      title: <div><Trans>Volatility ({time === 'week' ? '7D' : '30D'})</Trans></div>,
      dataIndex: '',
      key: 'vol',
      width: 130,
      render: (_: any, record: any) => {
        const differ = record.price - record.yd_price
        const isUp = differ >= 0 ? true : false
        return (
          <div>
            {!!record.price && <div>
              {isUp && <CaretUpOutlined className={`mr-0.5 text-xs ${isUp ? 'text-green-500' : 'text-red-500'}`} />}
              {!isUp && <CaretDownOutlined className={`mr-0.5 text-xs ${isUp ? 'text-green-500' : 'text-red-500'}`} />}
              <span className={`${isUp ? 'text-green-500' : 'text-red-500'}`}>{formatNum(Math.abs(record.price ? (differ / record.price * 100) : 0))}%</span>
            </div>}
            {!record.price && <div>-</div>}
          </div>
        )
      }
    },
  ]
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded py-4">
        <div className="flex flex-col md:flex-row px-4">
          <div className="text-lg font-medium">Mentioned Coins</div>
          <div className="flex-1 flex md:justify-end text-xs mt-4 md:mt-0">
            <div onClick={() => setTime('week')} className={`${time === 'week' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-l py-1.5`}><Trans>7 Days</Trans></div>
            <div onClick={() => setTime('month')} className={`${time === 'month' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer py-1.5 border-l border-ci border-opacity-20`}><Trans>30 Days</Trans></div>
          </div>
        </div>
        <div className="mt-4 md:mt-6 py-1 overflow-x-scroll">
          <Table size='small' className="" loading={loading as boolean} dataSource={data.slice(0, 20)} columns={columns} pagination={false}
            rowKey={(record) => record.symbol}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MentionedCoins
