import { Dropdown, Menu, Select } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined, AppstoreOutlined, LineChartOutlined, ProjectOutlined, TwitterOutlined, TeamOutlined, ApartmentOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
// import profile from 'assets/images/_icon_avatar.png'
import meta from 'assets/images/meta.svg'
import user from 'assets/images/user.svg'
import mint from 'assets/images/mint.svg'
import logo from 'assets/images/logor.png'
import Routes from './routes'
import { NavLink, useLocation } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { injected } from 'connectors'
import { getSigner, shortenAddress, titleCase } from 'utils'
import cookie from 'react-cookies'
import useUserReport from './hooks/useUserReport'
import { blockchain_order, networkIcon, networkParam } from 'config/blockchain'
import Login from './Login'
import useGetSpaces from './hooks/useGetSpaces'
import { useDispatch } from 'react-redux'
import { updateSpace } from 'state/space'
// import { useHistory } from 'react-router-dom'

const { Option } = Select

const { SubMenu } = Menu

function App() {
  // const routeAuth = ['/project-spaces', '/admin/space-tokens', '/admin/campaigns', '/system-api-key', '/api-key', '/protocol', '/feedback', '/gemstone']
  // const history = useHistory()
  const dispatch = useDispatch()
  const { library, chainId, account, activate, deactivate, error } = useWeb3React()
  const [fold, setFold] = useState(false)
  const [openKeys, setOpenKeys] = useState<any>(['space'])
  // const [sign, setSign] = useState(false)
  const [signed, setSigned] = useState()
  const [login, setLogin] = useState(false)
  const [, setUpdate] = useState(false)
  const [update, setUpdateSpaceTree] = useState(false)
  const location = useLocation()

  useUserReport(login, setSigned)
  const [space, setData] = useGetSpaces(signed, update)

  let space_code = cookie.load('soad_space_code')

  if (space && space !== 401 && space !== 403 && space.length) {
    if (!space_code) cookie.save('soad_space_code', space[0].space_code, { path: '/' })
  }
  space_code = cookie.load('soad_space_code')

  const handleChange = (value: any) => {
    cookie.save('soad_space_code', value, { path: '/' })
    dispatch(updateSpace(value))
    setUpdate((state: any) => !state)
  }

  const rootSubmenuKeys = ['admin', 'space', 'twitter']
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1)

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const requestChain = async (param: any, isEth?: boolean) => {
    const ethLibrary = window.ethereum
    try {
      await (ethLibrary as any).request({
        method: isEth ? 'wallet_switchEthereumChain' : 'wallet_addEthereumChain',
        params: [isEth ? { chainId: '0x1' } : param, account],
      })
    } catch (error) {
      console.error(error)
    }
  }

  const acc = cookie.load('soad_acc')
  const isSigned = cookie.load('soad_signed')
  const role = cookie.load('soad_role')
  if (role && space_code) {
    if (['starshipfinance', 'createSpaceTest'].includes(space_code)) {
    } else if (+role !== 2) {
      window.location.replace('https://dashboard.soquest.xyz/')
    }
  }

  useEffect(() => {
    if ((library && !acc) || (library && space === 401)) {
      const signer = getSigner(library, account as string)
      const stamp = Date.now()
      // eslint-disable-next-line
      signer
        // eslint-disable-next-line no-useless-concat
        .signMessage('Welcome to SoGraph.xyz, Please sign this message to login.\n\n' + 'Timestamp: ' + stamp)
        .then((res) => {
          cookie.save('soad_acc', account === undefined ? '' : account, { path: '/' })
          cookie.save('soad_signature', res, { path: '/' })
          cookie.save('soad_signStamp', stamp, { path: '/' })
          setLogin((login) => !login)
        })
    }
    // eslint-disable-next-line
  }, [space, account])

  const foldSpace = (spa: any) => {
    if (spa.logo_url) {
      return <span className="text-transparent">1</span>
    } else {
      return spa.space_name.slice(0, 3)
    }
  }
  const selectedRoute = location.pathname === '/nft/deploy' || location.pathname === '/giveaway/detail' ? '/rewards' : location.pathname
  const beforeStatus = !!(space && space !== 401 && space !== 403 && Number(role) && acc && typeof isSigned === 'string')

  return (
    <div className="min-h-screen relative">
      <header className={`w-full h-16 flex items-center px-3 md:px-6 ${beforeStatus ? 'fixed border-b border-bord bg-ncon' : 'bg-lobg'} z-50`}>
        <img className={`cursor-pointer h-8`} src={logo} alt="" />
        <div className="w-full flex flex-row justify-end items-center space-x-5 md:space-x-5">
          {chainId && (
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={
                <div className="w-52 bg-drbg border border-drbor rounded-md mt-3 px-5 pt-5 pb-6">
                  <div className="text-white">
                    <Trans>Select a Network</Trans>
                  </div>
                  <div className="mt-4 space-y-3">
                    {[...blockchain_order.entries()].map(([key, value]: any, index: number) => (
                      <div
                        key={index}
                        onClick={() => {
                          if (Number(key) === chainId) return
                          requestChain(networkParam[key], Number(key) === 1 ? true : undefined)
                        }}
                        className={`bg-btbg px-4 py-1.5 rounded-full flex items-center border border-transparent ${
                          Number(key) === chainId ? 'border-ci' : ''
                        } hover:border-ci cursor-pointer space-x-2`}
                      >
                        <img className="w-4 rounded-full" src={networkIcon[key]} alt="" />
                        <span className={`text-white`}>{titleCase(value)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              }
            >
              <div className="bg-btbg px-4 py-1 rounded-full flex items-center justify-center border border-ci cursor-pointer space-x-1">
                <img className="w-4 rounded-full" src={networkIcon[chainId]} alt="" />
                <span className="text-white text-center">{titleCase(blockchain_order.get(chainId))}</span>
              </div>
            </Dropdown>
          )}
          {chainId && (
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={
                <div className="w-52 bg-drbg border border-drbor rounded-md mt-3 px-5 py-6">
                  <div className="flex justify-center">
                    <img src={mint} alt="" />
                  </div>
                  <div className="mt-4 text-white text-center text-base">{acc ? shortenAddress(acc as string) : ''}</div>
                  <div className="mt-4 space-y-4">
                    {/* <div className='bg-btbg px-4 py-1.5 rounded-full flex items-center justify-center border border-transparent hover:border-btbor cursor-pointer'>
                    <span className='text-white text-opacity-80 text-center'>Settings</span>
                  </div> */}
                    <div
                      onClick={() => {
                        deactivate()
                        cookie.remove('soad_signature', { path: '/' })
                        cookie.remove('soad_signStamp', { path: '/' })
                        cookie.remove('soad_signed', { path: '/' })
                        cookie.remove('soad_acc', { path: '/' })
                        cookie.remove('soad_role', { path: '/' })
                        setData(undefined)
                        cookie.remove('soad_space_code', { path: '/' })
                        setSigned(undefined)
                        localStorage.setItem('isDisconnect', '1')
                      }}
                      className="bg-btbg px-4 py-1.5 rounded-full flex items-center justify-center border border-transparent hover:border-ci cursor-pointer"
                    >
                      <span className="text-white text-opacity-80">Disconnect</span>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="cursor-pointer">
                <img src={user} alt="" />
              </div>
            </Dropdown>
          )}
          {!chainId && !error && (
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={
                <div className="h-32 w-52 bg-drbg border border-drbor rounded-md mt-3 px-5 py-5">
                  <div className="text-white">
                    <Trans>Connect your wallet</Trans>
                  </div>
                  <div className="mt-5">
                    <div
                      onClick={async () => {
                        await activate(injected)
                        // setSign((state: any) => !state)
                        localStorage.setItem('isDisconnect', '0')
                      }}
                      className="bg-metaskbg px-4 py-1.5 rounded-full flex items-center border border-transparent hover:border-ci cursor-pointer"
                    >
                      <div>
                        <img className="mr-4 rounded-full" src={meta} alt="" />
                      </div>
                      <span className="text-white">Metamask</span>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="butbg px-5 md:px-7 py-1.5 md:py-1.5 cursor-pointer">
                <Trans>Connect Wallet</Trans>
              </div>
            </Dropdown>
          )}
          {error && (
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={
                <div className="w-52 bg-drbg border border-drbor rounded-md mt-3 px-5 pt-5 pb-6">
                  <div className="text-white">
                    <Trans>Select a Network</Trans>
                  </div>
                  <div className="mt-4 space-y-3">
                    {[...blockchain_order.entries()].map(([key, value]: any, index: number) => (
                      <div
                        key={index}
                        onClick={() => {
                          if (Number(key) === chainId) return
                          requestChain(networkParam[key], Number(key) === 1 ? true : undefined)
                        }}
                        className={`bg-btbg px-4 py-1.5 rounded-full flex items-center border border-transparent ${
                          Number(key) === chainId ? 'border-ci' : ''
                        } hover:border-ci cursor-pointer space-x-2`}
                      >
                        <img className="w-4 rounded-full" src={networkIcon[key]} alt="" />
                        <span className="text-white text-opacity-30">{titleCase(value)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              }
            >
              <div className="bg-red-600 cursor-pointer rounded-full px-3 py-1">
                <Trans>Wrong Network</Trans>
              </div>
            </Dropdown>
          )}
        </div>
      </header>
      {beforeStatus && (
        <div className="">
          <div className="w-56 mt-16 fixed overflow-y-auto menuselect z-20">
            <Menu
              mode="inline"
              theme="dark"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              inlineCollapsed={fold}
              defaultSelectedKeys={[location.pathname]}
              selectedKeys={[selectedRoute]}
              defaultOpenKeys={['space']}
              className="relative min-h-screen"
            >
              <div className="mt-4 px-1.5 rmbor">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) => {
                    return option.key.split('-')[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  value={space_code}
                  className="py-0.5 w-full bg-black border border-bord rounded-md"
                  // bordered={false}
                  dropdownClassName="border border-bord bg-black"
                  onChange={handleChange}
                  notFoundContent={<div className="text-white text-center">No Data</div>}
                >
                  {space.map((spa: any) => (
                    <Option key={spa.space_code + '-' + spa.space_name} className="" value={spa.space_code}>
                      <div className="flex items-center">
                        {spa.logo_url && <img className="h-4 mr-1.5 rounded-full" src={spa.logo_url} alt="" />}
                        <span>{fold ? foldSpace(spa) : spa.space_name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </div>
              {Number(role) === 2 && (
                <SubMenu
                  key="admin"
                  title={
                    <span>
                      <ApartmentOutlined />
                      <span>Admin</span>
                    </span>
                  }
                >
                  <Menu.Item key="/project-spaces">
                    <NavLink to="/project-spaces">Spaces</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/admin/space-tokens">
                    <NavLink to="/admin/space-tokens">Tokens</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/admin/campaigns">
                    <NavLink to="/admin/campaigns">Campaigns</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/system-api-key">
                    <NavLink to="/system-api-key">System API Key</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/api-key">
                    <NavLink to="/api-key">API Key</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/protocol">
                    <NavLink to="/protocol">Protocol</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/feedback">
                    <NavLink to="/feedback">Feedback</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/gemstone">
                    <NavLink to="/gemstone">GemStone</NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              <SubMenu
                key="space"
                title={
                  <span>
                    <AppstoreOutlined />
                    <span>SoQuest Space</span>
                  </span>
                }
              >
                {/* {Number(role) === 2 && <Menu.Item key="/project-spaces"><NavLink to='/project-spaces'>Project Spaces</NavLink></Menu.Item>} */}
                {space_code && (
                  <Menu.Item key="/space-profile">
                    <NavLink to="/space-profile">Space Profile</NavLink>
                  </Menu.Item>
                )}
                <Menu.Item key="/space-tokens">
                  <NavLink to="/space-tokens">Space Tokens</NavLink>
                </Menu.Item>
                <Menu.Item key="/campaigns">
                  <NavLink to="/campaigns">Space Campaigns</NavLink>
                </Menu.Item>
                <Menu.Item key="/rewards">
                  <NavLink to="/rewards">Rewards</NavLink>
                </Menu.Item>
              </SubMenu>
              {Number(role) === 2 && (
                <Menu.Item key="/" title="Statistics">
                  <NavLink to="/statistics">
                    <LineChartOutlined />
                    <span>
                      <Trans>Statistics</Trans>
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              <Menu.Item key="/project" title="Project">
                <NavLink to="/project">
                  <ProjectOutlined />
                  <span>
                    <Trans>Project</Trans>
                  </span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/community" title="Community">
                <NavLink to="/community">
                  <TeamOutlined />
                  <span>
                    <Trans>Community</Trans>
                  </span>
                </NavLink>
              </Menu.Item>
              <SubMenu
                key="twitter"
                title={
                  <span>
                    <TwitterOutlined />
                    <span>Twitter</span>
                  </span>
                }
              >
                <Menu.Item key="/twitter/statistics">
                  <NavLink to="/twitter/statistics">Statistics</NavLink>
                </Menu.Item>
                <Menu.Item key="/twitter/kol">
                  <NavLink to="/twitter/kol">KOL</NavLink>
                </Menu.Item>
              </SubMenu>
            </Menu>
            <div onClick={() => setFold(!fold)} className={`fixed bottom-5 cursor-pointer px-6 ${fold ? 'w-20' : 'w-56'}`}>
              {fold ? <MenuUnfoldOutlined className="text-gray-400 hover:text-ci text-lg" /> : <MenuFoldOutlined className="text-gray-400 hover:text-ci text-lg" />}
            </div>
          </div>
          <div className={`${fold ? 'ml-20' : 'ml-56'} px-10 pb-10 pt-24`}>
            <Routes />
          </div>
        </div>
      )}
      {!beforeStatus && <Login setData={setData} setUpdateSpaceTree={setUpdateSpaceTree} />}
    </div>
  )
}

export default App
