import { Line } from '@ant-design/plots'
import { useMemo, useState } from 'react'
import { Spin } from 'antd'
import { formatNum, formatTooltip } from 'utils'
import useLines from './hooks/useLines'
import { isMobile } from 'react-device-detect'

const ReportLine = ({ campaign_code }: any) => {
  const [time, setTime] = useState('week')
  const [data, loading] = useLines(campaign_code, time)
  const datas = []
  for (const obj of data) {
    datas.push({
      value: obj.Impression,
      date: obj.date,
      fullTime: obj.fullTime,
      type: 'Impression',
    })
  }
  for (const obj of data) {
    datas.push({
      value: obj.Users,
      date: obj.date,
      fullTime: obj.fullTime,
      type: 'Users',
    })
  }
  for (const obj of data) {
    datas.push({
      value: obj.Actions,
      date: obj.date,
      fullTime: obj.fullTime,
      type: 'Actions',
    })
  }

  const config: any = {
    data: datas,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    height: isMobile ? 320 : 340,
    color: ['#FAAD14', '#21D08F', '#7754F8'],
    legend: {
      itemName: {
        style: {
          fill: 'white',
        },
      },
      background: {
        padding: [0, 0, 40, 0],
        style: {
          stroke: 'transparent',
        },
      },
      items: [
        {
          name: 'Impression',
          marker: {
            symbol: 'circle',
            style: {
              fill: '#FAAD14',
            },
          },
        },
        {
          name: 'Users',
          marker: {
            symbol: 'circle',
            style: {
              fill: '#21D08F',
            },
          },
        },
        {
          name: 'Actions',
          marker: {
            symbol: 'circle',
            style: {
              fill: '#7754F8',
            },
          },
        },
      ],
    },
    tooltip: {
      title: 'fullTime',
      formatter: (item: any) => {
        return { name: item.type, value: formatTooltip(item.value) }
      },
    },
    xAxis: {
      line: {
        style: {
          stroke: '#6C6C6C',
        },
      },
    },
    yAxis: {
      label: {
        formatter: (y: any) => formatNum(y),
      },
      grid: {
        line: {
          style: {
            stroke: 'rgba(31, 57, 77, 0.5)',
            lineDash: [4, 5],
          },
        },
      },
    },
  }
  const graph = useMemo(() => {
    return <Line {...config} />
    // eslint-disable-next-line
  }, [data])
  return (
    <div className="bg-ncon px-4 pt-1 pb-7 rounded-md relative">
      {loading && (
        <div className="absolute left-1/2 top-1/2 z-40">
          <Spin />
        </div>
      )}
      <div className="mt-10 md:mt-4 relative">
        {graph}
        <div className="absolute -top-6 md:top-1.5 md:right-0 flex space-x-4 text-xs">
          <div
            onClick={() => setTime('week')}
            className={`hover:text-date cursor-pointer ${time === 'week' ? 'text-ci' : 'text-white text-opacity-60'}`}
          >
            7 Days
          </div>
          <div
            onClick={() => setTime('month')}
            className={`hover:text-date cursor-pointer ${time === 'month' ? 'text-ci' : 'text-white text-opacity-60'}`}
          >
            30 Days
          </div>
          <div
            onClick={() => setTime('all')}
            className={`hover:text-date cursor-pointer ${time === 'all' ? 'text-ci' : 'text-white text-opacity-60'}`}
          >
            All
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportLine
