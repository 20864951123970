import { Trans } from "@lingui/macro"
import { formatNum } from "utils"
import ActiveKOLs from "./ActiveKOLs"
import useStatistics from "./hooks/useStatistics"
import TrendingKOLs from "./TrendingKOLs"
import TrendingProjects from "./TrendingProjects"
import TrendingTopics from "./TrendingTopics"
import TrendingTweets from "./TrendingTweets"

const Twitters = () => {
  const data = useStatistics()
  return (
    <div className='container mx-auto max-w-6.5xl space-y-10'>
      <div className="grid grid-cols-4 gap-5">
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Tracking KOLs</Trans></div>
          <div className="text-2xl font-bold">{formatNum(data.total_track_kols)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Active KOLs(24H)</Trans></div>
          <div className="text-2xl font-bold">{formatNum(data.active_kols)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Tweets(24H)</Trans></div>
          <div className="text-2xl font-bold">{formatNum(data.daily_tweets)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Social Coverage(24H)</Trans></div>
          <div className="text-2xl font-bold">{formatNum(data.daily_social_engagement)}</div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <TrendingProjects />
        <TrendingKOLs />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <TrendingTopics />
        <ActiveKOLs />
      </div>
      <TrendingTweets />
    </div>
  )
}

export default Twitters
