import { Trans } from '@lingui/macro'
import git from 'assets/images/git.svg'
import te from 'assets/images/te.svg'
import me from 'assets/images/me.svg'
import re from 'assets/images/re.svg'

function Footer() {
  return (
    <div className="w-full absolute bottom-0 bg-locon py-4 md:py-3">
      <div className='px-3'>
        <div className="container mx-auto max-w-4xl">
          <div className='grid grid-cols-1 md:grid-cols-2 relative'>
            <div className='space-y-4 md:space-y-1'>
              <div className='text-lg font-semibold firasans text-center md:text-left'><Trans>SoGraph</Trans></div>
              <div className='space-x-3 flex justify-center md:hidden'>
                <a href="https://github.com/Port3-Network" target='_blank' rel="noreferrer"><img className='md:h-5' src={git} alt="" /></a>
                <a href="https://t.me/port3network" target='_blank' rel="noreferrer"><img className='md:h-5' src={te} alt="" /></a>
                <a href="https://discord.com/invite/WnJGexPv8G" target='_blank' rel="noreferrer"><img className='md:h-5' src={me} alt="" /></a>
                <a href="https://medium.com/@Port3" target='_blank' rel="noreferrer"><img className='md:h-5' src={re} alt="" /></a>
              </div>
              <div className='text-center md:text-left text-xs text-white text-opacity-70'>{`Copyright © ${new Date().getFullYear()}, SoGraph`}</div>
            </div>
            <div className='hidden md:block'>
              <div className='space-x-3 flex absolute bottom-0 right-0'>
                <a href="https://github.com/Port3-Network" target='_blank' rel="noreferrer"><img className='h-5' src={git} alt="" /></a>
                <a href="https://t.me/port3network" target='_blank' rel="noreferrer"><img className='h-5' src={te} alt="" /></a>
                <a href="https://discord.com/invite/WnJGexPv8G" target='_blank' rel="noreferrer"><img className='h-5' src={me} alt="" /></a>
                <a href="https://medium.com/@Port3" target='_blank' rel="noreferrer"><img className='h-5' src={re} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
