import { Trans } from '@lingui/macro'
import { Button, Form, Input, message, Modal, Upload } from 'antd'
import axios, { api_key, cur, secret_key } from 'config/axios'
import { useEffect, useState } from 'react'
import note from 'assets/images/icon_note.svg'
import icon_upload from 'assets/images/icon_upload.svg'
import cookie from 'react-cookies'
import { useDispatch } from 'react-redux'
import { updateSpaceTree } from 'state/space'
import { LoadingOutlined } from '@ant-design/icons'
import { beforeUpload, isCode } from 'utils'
import { sha256 } from 'js-sha256'
import ImgCrop from 'antd-img-crop'

const { TextArea } = Input

const Information = ({ setUpdate, info }: any) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [fetchLoading, setFetchLoading] = useState(false)
  const [fetch, setFetch] = useState(false)
  const [form] = Form.useForm()

  const [loadImg, setLoadImg] = useState(false)
  const [imageUrl, setImageUrl] = useState()

  const signed = cookie.load('soad_signed')
  const account = cookie.load('soad_acc')
  const space_code = cookie.load('soad_space_code')
  const timestamp = parseInt(new Date().getTime() / 1000 + '')
  const sha = sha256(api_key + secret_key + timestamp)

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadImg(true)
      return
    }

    if (info.file.status === 'done') {
      setLoadImg(false)
      const arr = info.fileList
      if (arr[arr.length - 1].response.code === 0) setImageUrl(arr[arr.length - 1].response.data)
    }
  }

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    const submitObj = {
      type: 'info',
      ...fieldValues,
    }
    if (imageUrl) submitObj.logo_url = imageUrl
    setLoading(true)
    const res = await axios.post('/api/admin/space/profile', submitObj)
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      cookie.save('soad_space_code', fieldValues.space_code, { path: '/' })
      dispatch(updateSpaceTree())
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const onFetch = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    setFetchLoading(true)
    const res = await axios.post('/api/admin/space/profile', {
      type: 'info',
      ...fieldValues,
    })
    setFetchLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setFetch(false)
      cookie.save('soad_space_code', fieldValues.galaxy, { path: '/' })
      dispatch(updateSpaceTree())
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  useEffect(() => {
    if (info.logo_url) setImageUrl(info.logo_url)
    form.setFieldsValue({
      ...info,
    })
  }, [info, form])
  return (
    <div className="bg-ncon rounded-lg px-5 py-5 border border-bord">
      <div className="flex space-x-12">
        <Form form={form} name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
          <Form.Item
            label="Name:"
            name="space_name"
            rules={[
              {
                required: true,
                message: 'Please enter your name!',
              },
            ]}
          >
            <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label="Space URL:"
            name="space_code"
            rules={[
              {
                required: true,
                message: 'Please enter your code!',
              },
              {
                validator: (_, value) => {
                  if (value && !isCode(value)) {
                    return Promise.reject('Code can only be numbers or letters!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<div className="text-white text-opacity-40 mr-2">https://sograph.xyz/space/</div>} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label="Admin Telegram ID:"
            name="telegram_id"
            rules={[
              {
                required: true,
                message: 'Please enter your Telegram ID!',
              },
            ]}
          >
            <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label="Introduction:"
            name="introduction"
            rules={[
              {
                required: true,
                message: 'Please enter your space code!',
              },
            ]}
          >
            <TextArea placeholder="" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" rows={3} />
          </Form.Item>
          <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-8" type="primary">
            <Trans>Save</Trans>
          </Button>
          {/* <Button onClick={() => setFetch(true)} className='mt-2 md:mt-2 rounded px-4 ml-6' type='primary'><Trans>Fetch from Galaxy</Trans></Button> */}
        </Form>
        <div>
          <div className="text-center">
            <ImgCrop rotate>
              <Upload
                name="file"
                headers={{
                  APIKEY: api_key,
                  TIME: timestamp as any,
                  SIGN: sha,
                  signature: signed,
                  address: account + '',
                  'Space-Code': space_code,
                }}
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${cur}/api/admin/user/upload`}
                beforeUpload={(file) => beforeUpload(file, 5, 1, 1)}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <div className="relative img_wrap">
                    <img className="w-full rounded-full" src={imageUrl} alt="avatar" />
                    <div className="img_mask flex items-center justify-center">
                      <img src={icon_upload} alt="" />
                    </div>
                  </div>
                ) : (
                  <div>{loadImg ? <LoadingOutlined className="text-ci" /> : <img src={icon_upload} alt="" />}</div>
                )}
              </Upload>
            </ImgCrop>
          </div>
          <div className="text-yellow-600">PNG, JPG 1:1 logo (Max 5mb)</div>
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title="Fetch from Galaxy"
        width={418}
        visible={fetch}
        onCancel={() => {
          setFetch(false)
        }}
        footer={null}
        centered
      >
        <div>
          <div className="flex items-start">
            <img className="pt-0.8 mr-1.5" src={note} alt="" />
            <Trans>Please enter your project‘s galaxy URL,We will fill space information automatic.</Trans>
          </div>
          <div className="mt-6">
            <Form name="basic" layout="vertical" onFinish={onFetch} autoComplete="off">
              <Form.Item
                label="Galaxy URL:"
                name="galaxy"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your galxe code!',
                  },
                  {
                    validator: (_, value) => {
                      if (value && !isCode(value)) {
                        return Promise.reject('Code can only be numbers or letters!')
                      } else {
                        return Promise.resolve()
                      }
                    },
                  },
                ]}
              >
                <Input prefix={<div className="text-white text-opacity-40 mr-2">https://galxe.com/</div>} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
              </Form.Item>
              <Button loading={fetchLoading} htmlType="submit" size="large" className="mt-2 md:mt-2 rounded w-full" type="primary">
                <Trans>Fetch</Trans>
              </Button>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Information
