import { useEffect, useState } from "react"
import axios from "config/axios"

const useMentionedCoins = (date: string, code: string) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/twitter/user/token?screen_name=${code}&date=${date}`)
      setLoading(false)
      setData(res.data.data)
    } catch (error) {
      console.error("useMentionedCoins --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [code, date])
  return [data, loading]
}

export default useMentionedCoins
