import { Button, Form, Input, Collapse, Row, Col, message, Popconfirm, Select, AutoComplete } from 'antd'
import icon_selected from 'assets/images/icon_selected.svg'
import reject from 'assets/images/icon_reject.svg'
import star from 'assets/images/star.svg'
import { DownOutlined } from '@ant-design/icons'
import { mediaImages } from 'config/media'
import { memo, useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import axios from 'config/axios'
import { isNumber, titleCase } from 'utils'
import { nftBlockchainName, wrapBlockchain } from 'config/blockchain'

const { Panel } = Collapse
const { Option } = Select

const HoldingNft = ({ setTasks_arr, task, trans_code, isOpen = false }: any) => {
  const [isChecked, setIsChecked] = useState(true)
  const [isCheckedQuantity, setIsCheckedQuantity] = useState(false)
  const [loading, setLoading] = useState(false)
  const [symbol, setSymbol] = useState('')
  const [blockchain, setBlockchain] = useState('')
  const [options, setOptions] = useState<any>([])
  const [nftType, setNftType] = useState(null)
  const [form] = Form.useForm()

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    const submitObj = {
      platform: 'web3',
      type: 'holding_nft',
      ...fieldValues,
    }
    submitObj.dh_number = isCheckedQuantity
    submitObj.mandatory = isChecked
    if (task.id) submitObj.id = task.id
    setLoading(true)
    const res = await axios.post('/api/admin/space/campaign', {
      step: 2,
      campaign_code: trans_code,
      task: JSON.stringify(submitObj),
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      const id = res.data.data.task_id
      setTasks_arr((state: any) => {
        const list = state
        for (const obj of list) {
          if (obj.uuid === task.uuid) {
            obj.id = id
            obj.mandatory = submitObj.mandatory
            obj.votes = submitObj.votes
            obj.symbol = submitObj.symbol
            obj.blockchain = submitObj.blockchain
            obj.contract_address = submitObj.contract_address
            obj.threshold_balance = submitObj.threshold_balance
            obj.dh_number = submitObj.dh_number
            break
          }
        }
        const task_ids = list.filter((task: any) => task.id).map((task: any) => task.id)
        axios.post('/api/admin/space/campaign/sort', {
          campaign_code: trans_code,
          task_ids,
        })
        return [...list]
      })
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async () => {
    if (!task.id) {
      setTasks_arr((state: any) => {
        const list = state
        const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
        list.splice(index, 1)
        return [...list]
      })
      message.success('Success!')
    } else {
      const res = await axios.post('/api/admin/space/campaign/task/remove', {
        campaign_code: trans_code,
        task_id: task.id,
      })
      if (res.data.code === 0) {
        setTasks_arr((state: any) => {
          const list = state
          const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
          list.splice(index, 1)
          return [...list]
        })
        message.success('Success!')
      } else {
        message.error(res.data.message)
      }
    }
  }

  const blockchainSelect = (value: string) => {
    setBlockchain(value)
  }

  const symbolChange = (e: any) => {
    const { value } = e.target
    setSymbol(value)
  }

  const symbolSelect = (_: string, option: any) => {
    const symbol = option.label.props.children[1].props.children[0]
    form.setFieldsValue({
      symbol,
    })
  }

  useEffect(() => {
    isChecked && setIsChecked(task.mandatory)
    isCheckedQuantity && setIsCheckedQuantity(task.dh_number)
    task.symbol !== symbol && setSymbol(task.symbol)
    task.blockchain !== blockchain && setBlockchain(task.blockchain)
    form.setFieldsValue({
      url: task.url,
      votes: task.votes,
      symbol: task.symbol,
      token_id: task.token_id,
      blockchain: task.blockchain,
      contract_type: task.contract_type,
      contract_address: task.contract_address,
      threshold_balance: task.threshold_balance,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, form])

  const fetchNftAddress = async () => {
    const res = await axios.get(`/api/admin/project/nft/search?symbol=${symbol}&blockchain=${blockchain}`)
    const address_arr = []
    for (const obj of res.data.data) {
      address_arr.push({
        label: (
          <div className="flex items-center">
            <img className="w-4 mr-1.5 rounded-full" src={obj.logo_url} alt="" />
            <span>
              {obj.symbol}({obj.name})
            </span>
            <div className="ml-4 truncate flex-1">{obj.token_address}</div>
          </div>
        ),
        value: obj.token_address,
      })
    }
    setOptions(address_arr)
  }
  useEffect(() => {
    if (blockchain) fetchNftAddress()
    // eslint-disable-next-line
  }, [blockchain, symbol])

  return (
    <div className="relative w-fit">
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={isOpen ? [task.uuid] : []}
        expandIcon={({ isActive }) => <DownOutlined className="text-xs text-white" rotate={isActive ? 180 : 0} />}
        className={`rounded-md`}
      >
        <Panel
          header={
            <div className="w-500 flex">
              <img className="mr-2" src={mediaImages['icon_nft']} alt="" />
              {task.mandatory && <img className="mr-1" src={star} alt="" />}
              <div className="flex">
                <div>Holding {task.threshold_balance}</div>
                <div className="max-w-xs truncate mx-1">
                  {task.symbol} {task.symbol ? '' : 'NFT'}
                </div>
                <div>
                  {task.symbol ? 'on' : ''} {wrapBlockchain[task.blockchain]} <span className="font-bold">+{task.votes}</span>
                </div>
              </div>
            </div>
          }
          key={task.uuid}
        >
          {
            <div>
              <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                <Row>
                  <Col span={5}>
                    <Form.Item
                      label="Blockchain:"
                      name={'blockchain'}
                      rules={[
                        {
                          required: true,
                          message: 'blockchain is required!',
                        },
                      ]}
                    >
                      <Select onSelect={blockchainSelect} showArrow={true} dropdownClassName="border border-bord">
                        {Object.entries(nftBlockchainName).map(([sname, name]: any, index: number) => (
                          <Option value={sname} key={index}>
                            {titleCase(name)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="ml-5"
                      label="Symbol:"
                      name="symbol"
                      rules={[
                        {
                          required: true,
                          message: 'Symbol is required!',
                        },
                      ]}
                    >
                      <Input onChange={symbolChange} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>

                  <Col span={10}>
                    <Form.Item
                      className="ml-5"
                      label="Threshold Balance:"
                      name="threshold_balance"
                      rules={[
                        {
                          required: true,
                          message: 'Threshold balance is required!',
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="NFT Type:"
                      name="contract_type"
                      rules={[
                        {
                          required: true,
                          message: 'NFT type is required!',
                        },
                      ]}
                    >
                      <Select onSelect={(val) => setNftType(val)} showArrow={true} dropdownClassName="border border-bord">
                        <Option value="erc721">Common NFT</Option>
                        <Option value="erc1155">Blindbox NFT</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {nftType === 'erc1155' && (
                    <Col span={12}>
                      <Form.Item className="ml-5" label="TokenID:" name="token_id">
                        <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Contract Address:"
                      name="contract_address"
                      rules={[
                        {
                          required: true,
                          message: 'Contract address is required!',
                        },
                      ]}
                    >
                      <AutoComplete dropdownClassName="border border-bord" options={options} onSelect={symbolSelect}>
                        <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item label="Website link to obtain NFT" name="url">
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <div className="flex items-center space-x-2">
                        {!isCheckedQuantity && <div onClick={() => setIsCheckedQuantity(true)} className="border border-ci w-4 h-4 rounded-full cursor-pointer"></div>}
                        {isCheckedQuantity && (
                          <div onClick={() => setIsCheckedQuantity(false)} className="cursor-pointer">
                            <img className="rounded-full" src={icon_selected} alt="" />
                          </div>
                        )}
                        <div className="">
                          <Trans>Weight by holding quantity of NFTs</Trans>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={16}>
                    <Form.Item
                      label={`${isCheckedQuantity ? 'Each Worth # Entries:' : 'Worth # Entries:'}`}
                      name="votes"
                      rules={[
                        {
                          required: true,
                          message: 'Worth is required!',
                        },
                        {
                          validator: (_, value) => {
                            if (value && !isNumber(value)) {
                              return Promise.reject('Worth can only be numbers!')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      <div className="flex items-center space-x-2 pt-9">
                        {!isChecked && <div onClick={() => setIsChecked(true)} className="border border-ci w-4 h-4 rounded-full cursor-pointer"></div>}
                        {isChecked && (
                          <div onClick={() => setIsChecked(false)} className="cursor-pointer">
                            <img className="rounded-full" src={icon_selected} alt="" />
                          </div>
                        )}
                        <div className="text-xs">
                          <Trans>Mandatory</Trans>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="flex items-center space-x-4">
                  <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-8" type="primary">
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Form>
            </div>
          }
        </Panel>
      </Collapse>
      <Popconfirm onConfirm={() => confirmFun()} title="Are you sure delete this task?" okText="Yes" cancelText="No">
        <img className="absolute top-1.5 -right-8 h-5 cursor-pointer" src={reject} alt="" />
      </Popconfirm>
    </div>
  )
}
export default memo(HoldingNft)
