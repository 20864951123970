import { Trans } from "@lingui/macro"
import { Button, message, Popconfirm, Tooltip } from "antd"
import { CopyOutlined } from '@ant-design/icons'
import axios from "axios"
import { useState } from "react"
import useAPIKey from "./hooks/useAPIKey"
import { CopyToClipboard } from 'react-copy-to-clipboard'

const GenerateApiKey = () => {

  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [copy, setCopy] = useState(false)

  const api = useAPIKey(update)
  const { api_key } = api

  const clickFun = async () => {
    setLoading(true)
    const res = await axios.post('/api/admin/space/apikey', {
      type: 'generate'
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async () => {
    const res = await axios.post('/api/admin/space/apikey', {
      type: 'generate'
    })
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  return (
    <div>
      <div><Trans>API Key:</Trans></div>
      <div className="mt-4">
        {!api_key && <Button loading={loading} onClick={clickFun} className='rounded px-8' type='primary'><Trans>Generate API Key</Trans></Button>}
        {!!api_key && <Popconfirm placement="topLeft" onConfirm={confirmFun} title="Are you sure reset this API Key?" okText="Yes" cancelText="No">
          <Button className='rounded px-8' type='primary'><Trans>Reset API Key</Trans></Button>
        </Popconfirm>}
      </div>
      {!!api_key && <div className="bg-nibg border border-bord w-96 rounded-md px-2 py-1.5 text-xs flex items-center mt-4">
        <div className="flex-1">{api_key}</div>
        <div className="mr-0.5">
          <CopyToClipboard text={api_key}>
            <Tooltip visible={copy} className='cursor-pointer' overlay={'Copied'}>
              <span onClick={() => {
                setCopy(true)
                setTimeout(() => {
                  setCopy(false)
                }, 2000)
              }} className='cursor-pointer'><CopyOutlined className="text-white text-opacity-80" /></span>
            </Tooltip>
          </CopyToClipboard>
        </div>
      </div>}
    </div>
  )
}

export default GenerateApiKey