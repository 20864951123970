import { Trans } from '@lingui/macro'
import { Button, Form, Input, Spin, Dropdown, Typography, message, Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import add from 'assets/images/_icon_add.svg'
import icon_normal from 'assets/images/icon_normal.svg'
import icon_selected from 'assets/images/icon_selected_.svg'
import mores from 'assets/images/mores.svg'
import cp from 'assets/images/cp.svg'
import edit_ from 'assets/images/edit_.svg'
import delete_ from 'assets/images/delete_.svg'
import tip_d from 'assets/images/tip_d.svg'
import nodata from 'assets/images/nodata.svg'
import { useEffect, useState } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { blockchainName } from 'config/blockchain'
import { shortenAddress } from 'utils'
import { SyncOutlined } from '@ant-design/icons'
import icon_nfts from 'assets/images/ntfs.svg'

const { Paragraph } = Typography
let pages = 0

const NFTList = () => {
  const history = useHistory()
  const [connectCampaigns, setConnectCampaigns] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [deleteloading, setDeleteLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [update, setUpdate] = useState(false)
  const [data, setData] = useState<any>([])
  const [max, setMax] = useState(0)

  const loadMoreData = async () => {
    try {
      if (loading) return
      setLoading(true)
      const res = await axios.get(`/api/admin/giveaway/nft?&page=${++pages}&pagesize=10&name=${searchText}&bind_campaign=${connectCampaigns ? 1 : 0}`)
      setData([...data, ...res.data.data.data])
      setMax(res.data.data.total)
      setLoading(false)
    } catch (error) {}
  }

  useEffect(() => {
    if (!data.length) loadMoreData()
    return () => {
      pages = 0
    }
    // eslint-disable-next-line
  }, [update, connectCampaigns])

  const onFinish = (fieldValues: any) => {
    setData([])
    setSearchText(fieldValues.name ? fieldValues.name : '')
    setUpdate((state: boolean) => !state)
  }

  const removeFun = async () => {
    setDeleteLoading(true)
    const res = await axios.post(`/api/admin/giveaway/nft/${id}/remove`)
    setDeleteLoading(false)
    if (res.data.code === 0) {
      message.success('success!')
      setDeleteModal(false)
      setData([])
      setUpdate((state: boolean) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="bg-non rounded-2xl border border-bord">
          <div className="border-b border-bord pt-7 pb-1 px-6">
            <div className="flex">
              <div className="space-x-16 flex">
                <div>
                  <Form name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
                    <div className="flex space-x-4">
                      <Form.Item name="name">
                        <Input placeholder="Name/Symbol" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-72" />
                      </Form.Item>
                      <Button htmlType="submit" className="rounded-lg" type="primary">
                        <Trans>Search</Trans>
                      </Button>
                    </div>
                  </Form>
                </div>
                <div className="pt-1">
                  <div className="flex items-center space-x-3">
                    {connectCampaigns ? (
                      <img
                        className="cursor-pointer"
                        onClick={() => {
                          setData([])
                          setConnectCampaigns((state: boolean) => !state)
                        }}
                        src={icon_selected}
                        alt=""
                      />
                    ) : (
                      <img
                        className="cursor-pointer"
                        onClick={() => {
                          setData([])
                          setConnectCampaigns((state: boolean) => !state)
                        }}
                        src={icon_normal}
                        alt=""
                      />
                    )}
                    <span>
                      <Trans>Only NFTs already used in campaigns</Trans>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex justify-end">
                <Button onClick={() => history.push('/nft/deploy')} className="rounded-lg flex items-center" type="primary">
                  <Trans>Add NFT Badge</Trans>
                  <img className="ml-1" src={add} alt="" />
                </Button>
              </div>
            </div>
          </div>
          <div className="px-6 py-8">
            {!data.length && (
              <div className="py-6 flex flex-col items-center justify-center space-y-2">
                <img src={nodata} alt="" />
                <div>No Data</div>
              </div>
            )}
            {!!data.length && (
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={data.length < max}
                loader={
                  loading ? (
                    <div className="flex justify-center mt-4">
                      <Spin />
                    </div>
                  ) : (
                    ''
                  )
                }
                endMessage=""
              >
                <div className="flex gap-10 flex-wrap">
                  {data.map((nft: any) => (
                    <div onClick={() => history.push('/nft/deploy', nft)} key={nft.id} className="border border-bord rounded-xl relative cursor-pointer w-72">
                      {nft.is_draft && (
                        <Dropdown
                          trigger={['click']}
                          overlay={
                            <div className="bg-moresbg rounded-lg border border-bord px-1 py-3 space-y-1">
                              <div className="flex items-center space-x-3 hover:bg-black px-4 py-1.5 rounded-lg hover:bg-opacity-50 cursor-pointer">
                                <img src={edit_} alt="" />{' '}
                                <span>
                                  <Trans>Edit</Trans>
                                </span>
                              </div>
                              <div
                                onClick={(e: any) => {
                                  e.stopPropagation()
                                  setDeleteModal(true)
                                  setId(nft.id)
                                }}
                                className="flex items-center space-x-3 hover:bg-black px-4 py-1.5 rounded-lg hover:bg-opacity-50 cursor-pointer"
                              >
                                <img src={delete_} alt="" />{' '}
                                <span>
                                  <Trans>Delete</Trans>
                                </span>
                              </div>
                            </div>
                          }
                        >
                          <div onClick={(e) => e.stopPropagation()} className="absolute cursor-pointer top-1 right-1 px-0.5 py-0.5 bg-black bg-opacity-80 rounded-full">
                            <img src={mores} alt="" />
                          </div>
                        </Dropdown>
                      )}
                      <div className="flex justify-center h-72">
                        <img className="h-72 w-72" src={nft.image_url} alt="" />
                      </div>
                      <div className="p-4 border-t border-bord">
                        <div className="space-y-3">
                          <div className="flex ">
                            <div className="flex-1 text-xl truncate ">{nft.symbol}</div>
                            <div className="flex justify-end border border-bord rounded-full text-xs text-white text-opacity-50 px-2 py-1 h-fit">{blockchainName[nft.blockchain]}</div>
                          </div>
                          <div className="flex">
                            <div className="flex-1 text-white text-opacity-50">
                              <Trans>Contract</Trans>
                            </div>
                            {nft.contract && (
                              <div className="flex justify-end text-white text-opacity-50">
                                <Paragraph className="text-white text-opacity-50" copyable={{ text: nft.contract, icon: <img src={cp} alt="" /> }}>
                                  <a onClick={(e) => e.stopPropagation()} className="hover:underline text-white text-opacity-50" href={nft.contract_url} target="_blank" rel="noreferrer">
                                    {shortenAddress(nft.contract)}
                                  </a>
                                </Paragraph>
                              </div>
                            )}
                            {!nft.contract && nft.is_draft && <div className="flex justify-end text-white text-opacity-50">-</div>}
                            {!nft.contract && !nft.is_draft && (
                              <div className="flex justify-end text-white text-opacity-50">
                                <div>
                                  <SyncOutlined spin className="text-ci" />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex">
                            <div className="flex-1 text-white text-opacity-50">
                              <Trans>Claimed</Trans>
                            </div>
                            <div className="flex justify-end text-white text-opacity-50">{nft.claimed}</div>
                          </div>
                          <div className="flex space-x-4">
                            {nft?.image_type === 'many' && <img src={icon_nfts} alt="" />}
                            {nft.is_transferable && (
                              <div className="border border-valid text-valid rounded-full px-3 py-1 w-fit text-xs">
                                <Trans>Transferable</Trans>
                              </div>
                            )}
                            {nft.is_draft && (
                              <div className="border border-red-500 text-red-500 rounded-full px-3 py-1 w-fit text-xs">
                                <Trans>Draft</Trans>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={`Delete`}
        width={418}
        visible={deleteModal}
        onCancel={() => {
          setDeleteModal(false)
        }}
        footer={null}
        centered
      >
        <div className="">
          <div className="flex items-center space-x-2 my-2">
            <img src={tip_d} alt="" />
            <span>
              <Trans>Are you sure to delete this NFT Badge?</Trans>
            </span>
          </div>
          <div>
            <Button loading={deleteloading} onClick={removeFun} size="large" className="w-full mt-8 rounded" type="primary">
              <Trans>Comfirm</Trans>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default NFTList
