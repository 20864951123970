import { Trans } from '@lingui/macro'
import { Input, message, Tabs } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { filterTrim } from 'utils'
import TaskList from './TaskList'
const Groups: React.FC<{
  tasks_arr: any[]
  trans_code: string
  tasksGroupIds: string[]
  setTaskIds: any
  groups: any[]
  groupTitles: string[]
  setGroupTitles: any
}> = ({ tasks_arr, trans_code, tasksGroupIds, setTaskIds, groups, groupTitles, setGroupTitles }) => {
  const [activeKey, setActiveKey] = useState<any>(0)
  const [items, setItems] = useState<any>([])
  const newTabIndex = useRef(1)
  const taskListRef = useRef<any>(null)

  // let reg =
  //   /([0-9|*|#]\uFE0F\u20E3)|([0-9|#]\u20E3)|([\u203C-\u3299]\uFE0F\u200D)|([\u203C-\u3299]\uFE0F)|([\u2122-\u2B55])|(\u303D)|([(\A9)|(\AE)]\u3030)|(\uA9)|(\uAE)|(\u3030)|([\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF])|([\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F])/g

  // fetch --> campaign
  const reqCampaign = async (params: any, titles?: any) => {
    // const group_titles: string[] = []
    // items.forEach((element: any, index: number) => {
    //   if (typeof element?.label?.props?.children === 'object' && element?.label?.props?.children?.join('')) {
    //     group_titles[index] = element.label.props.children.join('')
    //   }
    //   if (typeof element?.label?.props?.children === 'string' && element?.label?.props?.children) {
    //     group_titles[index] = element?.label?.props?.children
    //   } else group_titles[index] = `Group ${index + 1}`
    // })

    const res = await axios.post('/api/admin/space/campaign', {
      step: 4,
      campaign_code: trans_code,
      groups: [...params],
      group_titles: titles || [...groupTitles],
    })
    return res.data
  }

  // tab --> change
  const onChange = (newActiveKey: string) => {
    const params = tasksGroupIds.map((item: any) => filterTrim(item))
    reqCampaign([...params])
    setActiveKey(+newActiveKey)
  }

  // tab --> add group
  const add = async () => {
    if (!filterTrim(tasksGroupIds[0]).length) {
      return message.error('Please select a task in the previous group')
    }
    const params = tasksGroupIds.map((item: any) => filterTrim(item))
    const data = await reqCampaign([...params])
    if (+data.code === 0) {
      message.success('Add success')
    } else return message.error(data.message || 'Add error')
    // new key
    const newActiveKey = newTabIndex.current++

    // add ids
    setTaskIds((preIds: any) => {
      preIds[preIds.length] = new Array(tasks_arr.length - 1)
      return [...preIds]
    })
    // use tabs label
    const newPanes = [...items]
    newPanes.push({
      label: (
        <span
          onDoubleClick={(e) => handleDblclick(e, newActiveKey)}
          style={{ maxWidth: 70 }}
          className="block whitespace-nowrap overflow-hidden overflow-ellipsis"
          title={`Group ${newActiveKey + 1}`}
        >{`Group ${newActiveKey + 1}`}</span>
      ),
      key: newActiveKey,
    })
    setGroupTitles([...groupTitles, `Group ${newActiveKey + 1}`])
    setItems(newPanes)
    setActiveKey(newActiveKey)
  }

  // tab --> remove group
  const remove = async (targetKey: number) => {
    const newTitles = groupTitles.filter((item: any, index: number) => index !== targetKey)
    const taskIdVar = [...tasksGroupIds]
    // remove axios ids
    setTaskIds((preTaskIds: any) => {
      taskIdVar.splice(targetKey, 1)
      return [...taskIdVar]
    })
    // let newActiveKey = activeKey
    reqCampaign([...taskIdVar], newTitles)
    // remove tabs label
    const newPanes = items.filter((item: any) => item.key !== targetKey)

    setGroupTitles(newTitles)
    setItems(newPanes)
    setActiveKey(newPanes.length - 1)
  }

  // tab edit --> add or remove
  const onEdit = (targetKey: any, action: any) => {
    if (action === 'add') {
      add()
    } else {
      remove(targetKey)
    }
  }

  // tab onDoubleClick
  const handleDblclick = (e: any, index: number) => {
    e.stopPropagation()
    setItems((preState: any) => {
      preState[index].label = (
        <Input
          style={{ width: 100 }}
          // maxLength={10}
          defaultValue={groupTitles[index] || ''}
          onBlur={(e) => handleInputChange(e, index)}
          onPressEnter={(e) => handleInputChange(e, index)}
          // onChange={(e) => {
          //   setTimeout(() => {
          //     e.target.value = e.target.value.replace(reg, '')
          //   }, 0)
          // }}
        />
      )
      return [...preState]
    })
  }

  // tab rename group
  const handleInputChange = (e: any, index: number) => {
    setGroupTitles((preState: any) => {
      preState[index] = e.target.value || preState[index] || `Group ${index + 1}`
      return preState
    })
    setItems((preState: any) => {
      preState[index].label = (
        <span
          onDoubleClick={(e) => handleDblclick(e, index)}
          style={{ maxWidth: 70 }}
          className="block whitespace-nowrap overflow-hidden overflow-ellipsis"
          title={e.target.value || groupTitles[index] || `Group ${index + 1}`}
        >
          {e.target.value || groupTitles[index] || `Group ${index + 1}`}
        </span>
      )
      return [...preState]
    })
  }

  useEffect(() => {
    let initialItems: any[] = [
      {
        label: (
          <span onDoubleClick={(e) => handleDblclick(e, 0)} style={{ maxWidth: 70 }} className=" block whitespace-nowrap overflow-hidden overflow-ellipsis" title="Group 1">
            Group 1
          </span>
        ),
        key: 0,
        closable: false,
      },
    ]
    if (tasksGroupIds && tasksGroupIds.length) {
      tasksGroupIds.forEach((item: any, index: number) => {
        initialItems[index] = {
          label: (
            <span
              onDoubleClick={(e) => handleDblclick(e, index)}
              style={{ maxWidth: 70 }}
              className="block whitespace-nowrap overflow-hidden overflow-ellipsis"
              title={groupTitles[index] ? groupTitles[index] : `Group ${index + 1}`}
            >
              {groupTitles[index] ? groupTitles[index] : `Group ${index + 1}`}
            </span>
          ),
          key: index,
          closable: index !== 0,
        }
      })
      newTabIndex.current = tasksGroupIds?.length || 1
    } else {
      groups.forEach((item: any, index: number) => {
        initialItems[index] = {
          label: (
            <span onDoubleClick={(e) => handleDblclick(e, index)} style={{ maxWidth: 70 }} className="block whitespace-nowrap overflow-hidden overflow-ellipsis">
              {groupTitles[index] ? groupTitles[index] : `Group ${index + 1}`}
            </span>
          ),
          key: index,
          closable: index !== 0,
        }
      })
      if (!groups.length) {
        setTaskIds([new Array(tasks_arr.length - 1)])
      } else {
        setTaskIds([...groups])
      }
      newTabIndex.current = groups?.length || 1
    }
    const initGroupTitles = [...groupTitles]
    if (!groupTitles.length && initialItems.length) {
      for (let index = 0; index < initialItems.length; index++) {
        initGroupTitles[index] = `Group ${index + 1}`
      }
      setGroupTitles([...initGroupTitles])
    }

    setItems([...initialItems])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let initialItems: any[] = [
      {
        label: (
          <span onDoubleClick={(e) => handleDblclick(e, 0)} style={{ maxWidth: 70 }} className="block whitespace-nowrap overflow-hidden overflow-ellipsis" title="Group 1">
            Group 1
          </span>
        ),
        key: 0,
        closable: false,
      },
    ]
    if (groupTitles && groupTitles.length) {
      groupTitles.forEach((item: any, index: number) => {
        initialItems[index] = {
          label: (
            <span
              onDoubleClick={(e) => handleDblclick(e, index)}
              style={{ maxWidth: 70 }}
              className="block whitespace-nowrap overflow-hidden overflow-ellipsis"
              title={item ? item : `Group ${index + 1}`}
            >
              {item ? item : `Group ${index + 1}`}
            </span>
          ),
          key: index,
          closable: index !== 0,
        }
      })
    }
    setItems([...initialItems])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  // ⬇
  useEffect(() => {
    if (groupTitles.length < tasksGroupIds.length) {
      setTaskIds((preState: any) => {
        preState.length = groupTitles.length
        return preState
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupTitles])
  return (
    <div className="space-y-4 ml-16 flex-1 ">
      <div className="">
        <div className="font-semibold">
          <Trans>Pick & Compose Task Groups</Trans>
        </div>
        <div className="text-xs text-white text-opacity-70">
          <Trans>Users who complete tasks in Any task group will be ellgible to go reward. </Trans>
        </div>
      </div>
      <hr className="border-bord" />
      <div className="w-96">
        <Tabs tabPosition="top" type="editable-card" onChange={onChange} activeKey={activeKey} onEdit={onEdit} items={items} />
        <hr className="border-bord" />
        <TaskList groups={groups} ref={taskListRef} tasks_arr={tasks_arr} setTaskIds={setTaskIds} activeKey={activeKey} tasksGroupIds={tasksGroupIds} />
      </div>
    </div>
  )
}

export default Groups
