import { Trans } from "@lingui/macro"
import { Button, Form, Input, Table, Select } from "antd"
import { useEffect, useState } from "react"
import add from 'assets/images/_icon_add.svg'
import edit from 'assets/images/icon_edit.svg'
import right from 'assets/images/icon_right.svg'
import down from 'assets/images/icon_down.svg'
import { useHistory, useLocation } from "react-router-dom"
import useGiveawayList from "./hooks/useGiveawayList"
import { SearchOutlined } from '@ant-design/icons'
import useTokenList from "./hooks/useTokenList"
import { formatNum } from "utils"

const { Option } = Select

const TokenGiveaway = () => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [checkId, setCheckId] = useState({
    token_code: '',
    token_logo: '',
    token_symbol: '',
    tokens: []
  })
  const [search_obj, setSearch_obj] = useState<any>({
    title: '',
    status: 'all',
  })
  const [tokenList] = useTokenList()

  const [{ list, total }, loading] = useGiveawayList(page, 10, checkId.token_code, search_obj)

  const columns: any = [
    {
      title: <div className="pl-2">Name</div>,
      dataIndex: 'title',
      key: 'title',
      width: 150,
      ellipsis: true,
      render: (cur: string) => (<div className="pl-2">{cur}</div>)
    },
    {
      title: <div><Trans>Status</Trans></div>,
      dataIndex: '',
      key: 'status',
      width: 180,
      render: (_: number, record: any) => (
        <div className="flex items-center space-x-1">
          <span className='text-xs'>{!record.is_draft && 'Sent'}</span>
          <div className={`w-fit text-xs px-3 py-1 rounded-full ${!record.is_draft ? 'bg-valid bg-opacity-20 text-valid' : 'bg-red-500 bg-opacity-20 text-red-500'}`}>
            {record.is_draft ? 'Draft' : record.send_amount + '/' + record.total_amount}
          </div>
        </div>
      )
    },
    {
      title: <div><Trans>Address</Trans></div>,
      dataIndex: 'send_address',
      key: 'send_address',
      width: 100,
      render: (cur: number, record: any) => (
        <div>{formatNum(cur) + ' / ' + formatNum(cur + record.wait_address)}</div>
      )
    },
    {
      title: <div><Trans>Operations</Trans></div>,
      dataIndex: '',
      key: 'operations',
      width: 80,
      render: (_: any, record: any) => (
        <div>
          <Button onClick={() => {
            history.push(`/giveaway/detail?id=${record.id}`)
          }} className="px-3.5 h-7 rounded-full flex items-center" type='primary'>
            <Trans>Detail</Trans>
            <img className="ml-0.5" src={edit} alt="" />
          </Button>
        </div>
      )
    },
  ]

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }

  const onSearchFinish = (fieldValues: any) => {
    const { title, status } = fieldValues
    setPage(1)
    setSearch_obj({
      title: title ? title : '',
      status
    })
  }

  let location = useLocation()
  const { state }: { state: any } = location

  useEffect(() => {
    if (tokenList.length) {
      if (state?.token_code) {
        for (const obj of tokenList) {
          if (obj.token_code === state.token_code) {
            setCheckId(obj)
            history.replace({
              ...location,
              state: undefined
            })
            break
          }
        }
      } else {
        setCheckId(tokenList[0])
      }
    }
    // eslint-disable-next-line
  }, [tokenList.length])

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        {!tokenList.length && <div className="bg-ncon rounded-2xl border border-bord h-600 flex items-center justify-center">
          <div className="space-y-6">
            <div className="text-center">No Tokens</div>
            <div onClick={() => history.push('/giveaway/detail')}><Button className="rounded-lg flex items-center" type='primary'><Trans>Add Giveaway</Trans><img className="ml-1" src={add} alt="" /></Button></div>
          </div>
        </div>}
        {!!tokenList.length && <div className="flex gap-x-6">
          <div className="bg-ncon rounded-2xl border border-bord w-1/4">
            {tokenList.map((token: any) => (
              <div onClick={() => {
                setCheckId(token)
                setSearch_obj({
                  title: '',
                  status: 'all'
                })
                window.scrollTo(0, 0)
              }} key={token.token_code} className={`border-b border-bord cursor-pointer hover:bg-lobor hover:bg-opacity-40 ${token.token_code === checkId.token_code ? 'bg-lobor bg-opacity-40' : ''}`}>
                <div className="pl-6 pr-4 py-6">
                  <div className="flex items-center">
                    <div className="w-11"><img className="h-6" src={token.token_logo} alt="" /></div>
                    <div className="flex-1 text-base font-semibold">{token.token_symbol}</div>
                    <img src={token.token_code === checkId.token_code ? right : down} alt="" />
                  </div>
                  <div className="ml-12 mt-5 space-y-4">
                    <div className="flex">
                      <div className="w-1/2 text-white text-opacity-60">Sent</div>
                      <div className="flex-1"><span>{formatNum(token.send_amount, 4)}</span><span className="text-white text-opacity-60"> / {formatNum(token.total_amount, 4)}</span></div>
                    </div>
                    {/* <div className="flex">
                      <div className="w-1/2 text-white text-opacity-60">Campaigns</div>
                      <div className="flex-1">{token.campaigns}</div>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
            <div onClick={() => history.push('/giveaway/detail')} className='pl-6 my-8'><Button className="rounded-full flex items-center" type='primary'><Trans>Add Giveaway</Trans><img className="ml-1" src={add} alt="" /></Button></div>
          </div>
          <div className="bg-ncon rounded-2xl border border-bord flex-1">
            <div className="flex items-center px-6">
              <div className="flex-1 py-4 text-base flex items-center space-x-4">
                <div><Trans>{checkId.token_symbol} Giveaway Records</Trans></div>
              </div>
            </div>
            <div className="flex items-center px-6 border-t border-bord">
              <div className="pt-6">
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={onSearchFinish}
                  autoComplete="off"
                >
                  <div className="flex space-x-4">
                    <Form.Item
                      name="title"
                    >
                      <Input placeholder="Name" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-80" />
                    </Form.Item>
                    <Form.Item
                      name="status"
                      initialValue='all'
                    >
                      <Select className="w-32">
                        <Option value='all'><Trans>All status</Trans></Option>
                        <Option value='sent'>Sent</Option>
                        <Option value='draft'>Draft</Option>
                      </Select>
                    </Form.Item>
                    <Button htmlType="submit" className='rounded-lg' type='primary'><Trans>Search</Trans> <SearchOutlined /></Button>
                  </div>
                </Form>
              </div>
              <div className="flex-1 flex justify-end"><Button onClick={() => history.push('/giveaway/detail', checkId)} className='rounded-full flex items-center' type='primary'><Trans>Add Giveaway</Trans> <img className="ml-1" src={add} alt="" /></Button></div>
            </div>
            <div className="">
              <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
                rowKey={(record) => {
                  return record.id
                }}
                rowClassName={(_, index) => {
                  let className = 'nrodd'
                  if (index % 2 === 0) className = 'nreven'
                  return className
                }}
                pagination={{
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                  size: 'small',
                  current: page,
                  pageSize: 10,
                  total: total,
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default TokenGiveaway
