import Mainnet from 'assets/networks/mainnet-network.png'
import Bsc from 'assets/networks/bsc-network.jpg'
import Matic from 'assets/networks/matic-network.jpg'

export enum ChainId {
	MAINNET = 1,
	BSC = 56,
	MATIC = 137,
	LOCAL = 31337,
}

export const NETWORK_ICON = {
	[ChainId.MAINNET]: Mainnet,
	[ChainId.BSC]: Bsc,
	[ChainId.MATIC]: Matic,
	[ChainId.LOCAL]: Mainnet,
}

export const NETWORK_LABEL = {
	[ChainId.MAINNET]: 'Ethereum',
	[ChainId.BSC]: 'BSC',
	[ChainId.MATIC]: 'Polygon',
	[ChainId.LOCAL]: 'Local'
}



