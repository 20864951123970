import { useEffect, useState } from "react"
import axios from "config/axios"

const useFlow = (code: string) => {
  const [data, setData] = useState<any>({
    chat_flows: [],
  })
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/project/flows?code=${code}`)
      setLoading(false)
      setData(res.data.data)
    } catch (error) {
      console.error("useFlow --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [code])
  return [data, loading]
}

export default useFlow
