import { Trans } from "@lingui/macro"
import { Button, Form, Input, Table, Tooltip } from "antd"
import { formatNum, shortenAddress } from "utils"
import { SearchOutlined } from '@ant-design/icons'
import uuid from 'react-uuid'
import { useEffect, useState } from "react"
import { blockchainScan } from "config/blockchain"
import moment from "moment"

const SentHistory = ({ send_datas, symbol, chain }: any) => {

  const [list, setList] = useState([])

  useEffect(() => {
    for (const obj of send_datas) {
      obj.id = uuid()
    }
    setList(send_datas)
  }, [send_datas])

  const columns: any = [
    {
      title: <div className="pl-2">#</div>,
      dataIndex: '',
      key: 'key',
      width: 70,
      render: (_cur: any, _record: any, index: number) => (<div className="pl-2">{index + 1}</div>)
    },
    {
      title: <div><Trans>Time</Trans></div>,
      dataIndex: 'create_time',
      key: 'create_time',
      width: 160,
      render: (cur: number) => {
        return <div>{moment(cur * 1000).format('YYYY-MM-DD HH:mm')}</div>
      }
    },
    {
      title: <div><Trans>Address</Trans></div>,
      dataIndex: 'address',
      key: 'address',
      width: 180,
      elippses: true,
      render: (cur: string) => {
        return <Tooltip overlay={cur}><span>{shortenAddress(cur, 6)}</span></Tooltip>
      }
    },
    {
      title: <div><Trans>Amount</Trans></div>,
      dataIndex: 'amount',
      key: 'amount',
      width: 280,
      render: (cur: string) => {
        return <div>{formatNum(cur, 4, true)}<span className="ml-2">{symbol}</span></div>
      }
    },
    {
      title: <div><Trans>Txid</Trans></div>,
      dataIndex: 'txid',
      key: 'txid',
      width: 200,
      elippses: true,
      render: (cur: string) => {
        return <a href={`${blockchainScan[chain]}/tx/${cur}`} className="flex items-center" target='_blank' rel="noreferrer">
          <span className="text-valid">{shortenAddress(cur, 2)}</span>
          <div className="pl-2 text-valid text-base">↗️</div>
        </a>
      }
    },
  ]

  const onFinish = (fieldvalues: any) => {
    const address = fieldvalues.address
    if (!address) {
      setList(send_datas)
      return
    }
    const filterList = list.filter((li: any) => li.address === address)
    setList(filterList)
  }

  return (
    <div className="bg-ncon rounded-lg border border-bord">
      <div className="px-6">
        <div className="py-4 text-base">
          <div className="mb-6"><Trans>Sent Detail</Trans></div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="flex space-x-4">
              <Form.Item
                name="address"
              >
                <Input placeholder="Address" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-400" />
              </Form.Item>
              <Button htmlType="submit" className='rounded-lg' type='primary'><Trans>Search</Trans> <SearchOutlined /></Button>
            </div>
          </Form>
        </div>
      </div>
      <div className="py-1 overflow-x-scroll max-h-600">
        <Table className="" dataSource={list} columns={columns} pagination={false}
          rowKey={(record: any) => {
            return record.id
          }}
          rowClassName={(_, index) => {
            let className = 'nrodd'
            if (index % 2 === 0) className = 'nreven'
            return className
          }}
        />
      </div>
    </div>
  )
}

export default SentHistory
