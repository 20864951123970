import React from 'react'
import { Column } from '@ant-design/charts'

const RanksColumn: React.FC<{ data: any[] }> = ({ data }) => {
  const config: any = {
    data,
    xField: 'identity',
    yField: 'count',
    label: {
      position: 'top',
      style: {
        fill: '#ffffff',
        opacity: 0.6,
      },
    },
    color: '#1ca9e6',
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        autoRotate: false,
      },
      grid: {
        line: {
          style: {
            opacity: 0.1,
          },
        },
      },
    },
    meta: {
      identity: {
        alias: 'LV',
      },
      count: {
        alias: 'Ranks Statistics',
      },
    },
  }
  return <Column {...config} />
}

export default RanksColumn
