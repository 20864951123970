import { useEffect, useState } from "react"
import axios from "config/axios"
import { formatTime } from "utils"

const useActiveIndex = (date: string, code: string) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const ress = await Promise.all([
        axios.get(`/api/admin/community/chart?code=${code}&date=${date}&type=total_user`),
        axios.get(`/api/admin/community/chart?code=${code}&date=${date}&type=active_user`)
      ])
      for (const res of ress) {
        for (const obj of res.data.data) {
          const time = formatTime(date, obj.date)
          const fullTime = formatTime(date, obj.date, true)
          obj.time = time
          obj.fullTime = fullTime
          if (obj.type === 'Member Growth') obj.type = 'Members Count'
        }
      }
      setLoading(false)
      setData([...ress[0].data.data, ...ress[1].data.data])
    } catch (error) {
      console.error("useActiveIndex --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [date, code])
  return [data, loading]
}

export default useActiveIndex
