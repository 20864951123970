import { Trans } from '@lingui/macro'
import { Button, Form, Input, Modal, Table, DatePicker, message, Select } from 'antd'
import { useState } from 'react'
import { isAddress, isCode, isNumber2 } from 'utils'
import add from 'assets/images/_icon_add.svg'
import edit from 'assets/images/icon_edit.svg'
import search from 'assets/images/icon_search.svg'
import verify from 'assets/images/verify.svg'
import TwitterAct from 'assets/images/twitter_act.svg'
import axios from 'axios'
import useSpaceList from './hooks/useSpaceList'
import moment from 'moment'
import debounce from 'lodash/debounce'
import useSpaceTypes from './hooks/useSpaceTypes'
import { mediaImages } from 'config/media'
import { useDispatch } from 'react-redux'
import { updateSpaceTree } from 'state/space'

const { Option } = Select
const { RangePicker } = DatePicker

const ProjectSpaces = () => {
  const dispatch = useDispatch()
  const space_types = useSpaceTypes()
  const [page, setPage] = useState(1)
  const [operation, setOperation] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [record, setRecord] = useState<any>('')
  const [update, setUpdate] = useState(false)
  const [title, setTitle] = useState('Add')
  const [searchText, setSearchText] = useState('')
  const [{ list, total }, loading] = useSpaceList(page, 10, searchText, update)

  const columns: any = [
    {
      title: <div className="pl-2">#</div>,
      dataIndex: '',
      key: 'key',
      width: 100,
      render: (_cur: any, _record: any, index: number) => <div className="pl-2">{index + 1 + (page - 1) * 10}</div>,
    },
    // {
    //   title: <div><Trans>Space Type</Trans></div>,
    //   dataIndex: 'space_type',
    //   key: 'space_type',
    //   width: 100,
    //   ellipsis: true,
    // },
    {
      title: (
        <div>
          <Trans>Space Name</Trans>
        </div>
      ),
      dataIndex: 'space_name',
      key: 'space_name',
      width: 180,
      ellipsis: true,
      render: (cur: any, record: any) => (
        <div className="flex items-center space-x-1">
          <div className="truncate" title={cur}>
            {cur}
          </div>
          {record.is_verify && <img src={verify} alt="" />}
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Tokens</Trans>
        </div>
      ),
      dataIndex: 'tokens',
      key: 'tokens',
      width: 180,
      render: (cur: any) => (
        <div className="flex space-x-2 truncate">
          {cur.map((item: any, index: number) => (
            <img className="h-5 rounded-full" key={index} src={item.logo_url} alt="" />
          ))}
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Links</Trans>
        </div>
      ),
      dataIndex: 'urls',
      key: 'urls',
      width: 140,
      render: (cur: any, _record: any) => (
        <div className="flex">
          {_record.twitter_verify && !Object.keys(cur).includes('twitter') && (
            <a className="mr-1.5" href={_record.twitter_url} target="_blank" rel="noreferrer">
              <img className="h-4" src={TwitterAct} alt="" />
            </a>
          )}
          {Object.keys(cur).map((key: string, index: number) => (
            <>
              {cur[key] && (
                <a className="mr-1.5" key={index} href={cur[key]} target="_blank" rel="noreferrer">
                  <img className="h-4" src={_record.twitter_verify && key === 'twitter' ? TwitterAct : mediaImages[key]} alt="" />
                </a>
              )}
            </>
          ))}
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Valid Time</Trans>
        </div>
      ),
      dataIndex: '',
      key: 'valid_time',
      width: 200,
      render: (_: any, record: any) => (
        <>
          {record.start_time && (
            <div className="flex">
              <div>{moment(record.start_time * 1000).format('YYYY-MM-DD')}</div>
              <div className="px-1">~</div>
              <div>{moment(record.end_time * 1000).format('YYYY-MM-DD')}</div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <Trans>Operations</Trans>
        </div>
      ),
      dataIndex: '',
      key: 'operations',
      width: 100,
      render: (_: any, record: any) => (
        <div>
          <Button
            onClick={() => {
              setRecord(record)
              setTitle('Edit')
              setOperation(true)
            }}
            className="px-3.5 h-7 rounded-full flex items-center"
            type="primary"
          >
            <Trans>Edit</Trans>
            <img className="ml-0.5" src={edit} alt="" />
          </Button>
        </div>
      ),
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  const onFinish = async (fieldsValue: any) => {
    const start_time = parseInt(fieldsValue.time[0].valueOf() / 1000 + '')
    const end_time = parseInt(fieldsValue.time[1].valueOf() / 1000 + '')
    delete fieldsValue.time
    const arg = {
      ...fieldsValue,
      start_time,
      end_time,
    }
    if (record) arg['space_id'] = record.space_id
    setAddLoading(true)
    const res = await axios.post('/api/admin/space/data', arg)
    setAddLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setOperation(false)
      setUpdate((state) => !state)
      dispatch(updateSpaceTree())
    } else {
      message.error(res.data.message)
    }
  }

  const getSearch = debounce((value: string) => {
    setPage(1)
    setSearchText(value)
  }, 500)

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="bg-ncon rounded-lg border border-bord">
          <div className="flex items-center px-6">
            <div className="flex-1 py-4 text-base flex items-center space-x-4">
              <div>
                <Trans>Project Spaces</Trans>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setRecord('')
                    setTitle('Add')
                    setOperation(true)
                  }}
                  className="px-3.5 h-7 rounded-full flex items-center"
                  type="primary"
                >
                  <div>
                    <Trans>Add</Trans>
                  </div>
                  <img className="ml-0.5" src={add} alt="" />
                </Button>
              </div>
            </div>
            <div className="flex-1 flex justify-end">
              <div className="relative">
                <Input
                  onChange={(e: any) => getSearch(e.target.value)}
                  placeholder="Search Space"
                  className="rounded-full w-64 pl-11 text-sm h-9 bg-transparent border border-bord hover:border-ci focus:border-ci"
                />
                <div className="absolute h-full flex items-center top-0 left-4">
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="py-1 overflow-x-scroll">
            <Table
              className=""
              loading={loading as boolean}
              dataSource={list}
              columns={columns}
              rowKey={(record) => {
                return record.space_id
              }}
              rowClassName={(_, index) => {
                let className = 'nrodd'
                if (index % 2 === 0) className = 'nreven'
                return className
              }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                size: 'small',
                current: page,
                pageSize: 10,
                total: total,
                // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={`${title} Space Profile`}
        width={418}
        visible={operation}
        onCancel={() => {
          setOperation(false)
        }}
        footer={null}
        centered
      >
        <div className="space-y-6">
          <Form
            name="basic"
            layout="vertical"
            initialValues={
              !record
                ? {}
                : {
                    space_name: record.space_name,
                    space_code: record.space_code,
                    space_type: record.space_type,
                    time: [moment(record.start_time * 1000), moment(record.end_time * 1000)],
                    verify: record.is_verify,
                    sort_index: record.sort_index,
                    telegram_id: record?.telegram_id,
                  }
            }
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Type:"
              name="space_type"
              rules={[
                {
                  required: true,
                  message: 'Please select your space type!',
                },
              ]}
            >
              <Select placeholder="Please select a space type" dropdownClassName="border border-bord" notFoundContent={<div className="text-center text-white">No Data</div>}>
                {space_types.map((space_type: any) => (
                  <Option key={space_type.key} value={space_type.key}>
                    {space_types.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Name:"
              name="space_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your name!',
                },
              ]}
            >
              <Input disabled={title === 'Edit' ? true : false} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
            </Form.Item>
            <Form.Item
              label="Code:"
              name="space_code"
              rules={[
                {
                  required: true,
                  message: 'Please enter your space code!',
                },
                {
                  validator: (_, value) => {
                    if (value && !isCode(value)) {
                      return Promise.reject('Code can only be numbers or letters!')
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input disabled={title === 'Edit' ? true : false} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
            </Form.Item>
            <Form.Item
              label="Valid Time:"
              name="time"
              rules={[
                {
                  required: true,
                  message: 'Please select your time!',
                },
              ]}
            >
              <RangePicker
                className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black"
                showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')] }}
              />
            </Form.Item>
            {!record && (
              <>
                <Form.Item
                  label="Owner Address:"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your address!',
                    },
                    {
                      validator: (_, value) => {
                        if (value && !isAddress(value)) {
                          return Promise.reject('Incorrect address format!')
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                >
                  <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Admin Telegram ID:"
              name="telegram_id"
              rules={[
                {
                  required: title === 'Edit' ? false : true,
                  message: 'Please enter your Telegram ID!',
                },
              ]}
            >
              <Input disabled={title === 'Edit' ? true : false} className="bg-ncon border-bord hover:border-ci focus:border-ci rounded-md" />
            </Form.Item>
            <Form.Item
              label="Verification:"
              name="verify"
              rules={[
                {
                  required: true,
                  message: 'Please select yes or no!',
                },
              ]}
            >
              <Select dropdownClassName="border border-bord">
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Sort Index:"
              name="sort_index"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !isNumber2(value)) {
                      return Promise.reject('Sort index can only be numbers!')
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
            </Form.Item>

            <Button loading={addLoading} htmlType="submit" size="large" className="w-full mt-2 md:mt-2 rounded" type="primary">
              <Trans>Save</Trans>
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default ProjectSpaces
