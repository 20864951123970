import { useTokenContract } from './useContract'
import { MaxUint256 } from '@ethersproject/constants'

function useTokenApprove(tokenAddress: string, vaultAddress: string) {
  const tokenContract = useTokenContract(tokenAddress)
  const approve = async () => {
    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }
    return tokenContract.approve(vaultAddress, MaxUint256).catch((error: Error) => {
      console.error('useTokenApprove --> ', error)
    })
  }
  return approve
}

export default useTokenApprove
