import { useEffect, useState } from "react"
import axios from "config/axios"

const useCategories = () => {
  const [data, setData] = useState<any>([])
  const fetchData = async () => {
    try {
      const res = await axios.get(`/api/admin/project/categora/list`)
      const info = res.data.data
      setData(info)
    } catch (error) {
      console.error("useCategories --> " + error)
    }
  }
  useEffect(() => {
   fetchData()
    // eslint-disable-next-line
  }, [])
  return data
}

export default useCategories
