import { Trans } from "@lingui/macro"
import { Button, Form, Input, Modal, Table, message, Select, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { isCodeNotUnderscore, isNumber, shortenAddress } from "utils"
import add from 'assets/images/_icon_add.svg'
import edit from 'assets/images/icon_edit.svg'
import axios from "axios"
import useSpaceList from "./hooks/useProtocolList"
import { blockchainName, protocolBlockchainName } from "config/blockchain"
import { SearchOutlined } from '@ant-design/icons'

const { Option } = Select

const Protocol = () => {
  const [page, setPage] = useState(1)
  const [operation, setOperation] = useState(false)
  const [approval_modal, setApproval_modal] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [record, setRecord] = useState<any>('')
  const [update, setUpdate] = useState(false)
  const [status, setStatus] = useState(false)
  const [isApt, setIsApt] = useState(false)
  const [title, setTitle] = useState('Add')
  const [search_obj, setSearch_obj] = useState<any>({
    protocol_code: '',
    blockchain: 'all',
  })
  const [{ list, total }, loading] = useSpaceList(page, 10, search_obj, update)

  const columns: any = [
    {
      title: <div className="pl-2">#</div>,
      dataIndex: '',
      key: 'key',
      width: 90,
      render: (_cur: any, _record: any, index: number) => (<div className="pl-2">{(index + 1) + (page - 1) * 10}</div>)
    },
    {
      title: <div><Trans>Protocol Code</Trans></div>,
      dataIndex: 'protocol_code',
      key: 'protocol_code',
      width: 180,
      ellipsis: true,
    },
    {
      title: <div><Trans>Contract Code</Trans></div>,
      dataIndex: 'contract_code',
      key: 'contract_code',
      width: 180,
      ellipsis: true,
    },
    {
      title: <div><Trans>Contract Address</Trans></div>,
      dataIndex: 'contract_address',
      key: 'contract_address',
      width: 160,
      ellipsis: true,
      render: (cur: string) => {
        return <Tooltip overlay={cur}><span>{shortenAddress(cur)}</span></Tooltip>
      }
    },
    {
      title: <div><Trans>Blockchain</Trans></div>,
      dataIndex: 'blockchain',
      key: 'blockchain',
      width: 140,
      render: (cur: any) => blockchainName[cur]
    },
    {
      title: <div><Trans>Current Height</Trans></div>,
      dataIndex: 'onchain_info',
      key: 'onchain_info',
      width: 160,
      render: (cur: any) => cur?.current_height ? cur.current_height : '-'
    },
    {
      title: <div><Trans>Status</Trans></div>,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (cur: any) => {
        return (
          <div className={`w-fit text-xs px-3 py-1 rounded-full ${cur !== 'pending' ? cur === 'normal' ? 'bg-valid bg-opacity-20 text-valid' : 'bg-red-500 bg-opacity-20 text-red-500' : 'bg-pend bg-opacity-20 text-pend'}`}>{cur}</div>
        )
      }
    },
    {
      title: <div><Trans>Operations</Trans></div>,
      dataIndex: '',
      key: 'operations',
      width: 200,
      render: (_: any, record: any) => (
        <div className="flex space-x-5">
          <Button onClick={() => {
            setRecord(record)
            setTitle('Edit')
            setOperation(true)
          }} className="px-3.5 h-7 rounded-full flex items-center" type='primary'>
            <Trans>Edit</Trans>
            <img className="ml-0.5" src={edit} alt="" />
          </Button>
          <Button onClick={() => {
            setRecord(record)
            setTitle('Audit')
            setApproval_modal(true)
          }} className="px-3.5 h-7 rounded-full flex items-center" type='primary'>
            <Trans>Audit</Trans>
            <img className="ml-0.5" src={edit} alt="" />
          </Button>
        </div>
      )
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  const onSearchFinish = async (fieldsValue: any) => {
    const { protocol_code, blockchain } = fieldsValue
    setPage(1)
    setSearch_obj({
      protocol_code: protocol_code ? protocol_code : '',
      blockchain: blockchain ? blockchain : '',
    })
  }
  const onFinish = async (fieldsValue: any) => {
    const arg = {
      ...fieldsValue,
    }
    if (record) arg['protocol_id'] = record.protocol_id
    setAddLoading(true)
    const res = await axios.post('/api/admin/project/protocol', arg)
    setAddLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setOperation(false)
      setUpdate((state) => !state)
    } else {
      message.error(res.data.message)
    }
  }
  const onApprovalFinish = async (fieldsValue: any) => {
    const arg = {
      ...fieldsValue,
    }
    if (record) arg['protocol_id'] = record.protocol_id
    setAddLoading(true)
    const res = await axios.post('/api/admin/project/protocol/audit', arg)
    setAddLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setOperation(false)
      setUpdate((state) => !state)
    } else {
      message.error(res.data.message)
    }
  }
  const statusSelect = (value: any) => {
    if (value === 'normal') {
      setStatus(true)
    } else {
      setStatus(false)
    }
  }

  const blockchainSelect = (value: any) => {
    if (value === 'aptostest' || value === 'aptos') {
      setIsApt(true)
    } else {
      setIsApt(false)
    }
  }

  useEffect(() => {
    if (record) {
      if (record.status === 'normal') {
        setStatus(true)
      } else {
        setStatus(false)
      }
      if (record.blockchain === 'aptostest' || record.blockchain === 'aptos') {
        setIsApt(true)
      } else {
        setIsApt(false)
      }
    }
  }, [record])

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="bg-ncon rounded-lg border border-bord">
          <div className="flex items-center px-6">
            <div className="flex-1 py-4 text-base flex items-center space-x-4">
              <div><Trans>Protocol</Trans></div>
              <div>
                <Button onClick={() => {
                  setRecord('')
                  setTitle('Add')
                  setOperation(true)
                }} className="px-3.5 h-7 rounded-full flex items-center" type='primary'>
                  <div><Trans>Add</Trans></div>
                  <img className="ml-0.5" src={add} alt="" />
                </Button>
              </div>
            </div>
            <div className="pt-7 flex items-center">
              <Form
                name="basic"
                layout="vertical"
                onFinish={onSearchFinish}
                autoComplete="off"
              >
                <div className="flex space-x-4">
                  <Form.Item
                    name="protocol_code"
                  >
                    <Input placeholder="Protocol Code" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-72" />
                  </Form.Item>
                  <Form.Item
                    name="blockchain"
                    initialValue='all'
                  >
                    <Select
                      className="w-56"
                      dropdownClassName="border border-bord"
                    >
                      <Option value='all'>All</Option>
                      {Object.entries(protocolBlockchainName).map(([sname, name]: any, index: number) => (
                        <Option key={index} value={sname}>{name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button htmlType="submit" className='rounded-lg' type='primary'><Trans>Search</Trans> <SearchOutlined /></Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="py-1 overflow-x-scroll">
            <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
              rowKey={(record) => {
                return record.protocol_id
              }}
              rowClassName={(_, index) => {
                let className = 'nrodd'
                if (index % 2 === 0) className = 'nreven'
                return className
              }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                size: 'small',
                current: page,
                pageSize: 10,
                total: total,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <Modal destroyOnClose={true} title={`${title} Protocol`} width={418} visible={operation} onCancel={() => {
        setOperation(false)
      }} footer={null} centered>
        <div className="space-y-6">
          <Form
            name="basic"
            layout="vertical"
            initialValues={!record ? {} : {
              contract_name: record.contract_name,
              contract_address: record.contract_address,
              protocol_name: record.protocol_name,
              protocol_code: record.protocol_code,
              contract_code: record.contract_code,
              blockchain: record.blockchain,
              abi_json: record.abi_json
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Protocol Name:"
              name="protocol_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your protocol name!',
                },
              ]}
            >
              <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>
            <Form.Item
              label="Protocol Code:"
              name="protocol_code"
              rules={[
                {
                  required: true,
                  message: 'Please enter your protocol code!',
                },
                {
                  validator: (_, value) => {
                    if (value && !isCodeNotUnderscore(value)) {
                      return Promise.reject('Please fill in the protocol code with Numbers or Letters ONLY!')
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <Input disabled={record.status === 'normal' ? true : false} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>
            <Form.Item
              label="Contract Name:"
              name="contract_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your contract name!',
                },
              ]}
            >
              <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>
            <Form.Item
              label="Contract Code:"
              name="contract_code"
              rules={[
                {
                  required: true,
                  message: 'Please enter your contract code!',
                },
                {
                  validator: (_, value) => {
                    if (value && !isCodeNotUnderscore(value)) {
                      return Promise.reject('Please fill in the contract code with Numbers or Letters ONLY!')
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <Input disabled={record.status === 'normal' ? true : false} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>
            <Form.Item
              label="Contract Address:"
              name="contract_address"
              rules={[
                {
                  required: true,
                  message: 'Please enter your contract address!',
                }
              ]}
            >
              <Input disabled={record.status === 'normal' ? true : false} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>
            <Form.Item
              label="Blockchain:"
              name="blockchain"
              rules={[
                {
                  required: true,
                  message: 'Please select your blockchain!',
                },
              ]}
            >
              <Select
                dropdownClassName="border border-bord"
                onSelect={blockchainSelect}
              >
                {Object.entries(protocolBlockchainName).map(([sname, name]: any, index: number) => (
                  <Option key={index} value={sname}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="ABI:"
              name="abi_json"
              rules={[
                {
                  required: !isApt,
                  message: 'Please enter your abi!',
                },
              ]}
            >
              <Input.TextArea className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>

            <Button loading={addLoading} htmlType="submit" size='large' className='w-full mt-2 md:mt-2 rounded' type='primary'><Trans>Save</Trans></Button>
          </Form>
        </div>
      </Modal>
      <Modal destroyOnClose={true} title={`${title} Protocol`} width={418} visible={approval_modal} onCancel={() => {
        setApproval_modal(false)
        setStatus(false)
      }} footer={null} centered>
        <div className="space-y-6">
          <Form
            name="basic"
            layout="vertical"
            initialValues={(!record?.onchain_info?.current_height) ? { status: record.status !== 'pending' ? record.status : '', } : {
              status: record.status !== 'pending' ? record.status : '',
              start_height: record.onchain_info.current_height,
            }}
            onFinish={onApprovalFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Status:"
              name="status"
              rules={[
                {
                  required: true,
                  message: 'Please select your status!',
                },
              ]}
            >
              <Select
                onSelect={statusSelect}
                dropdownClassName="border border-bord"
              >
                <Option value='normal'>Normal</Option>
                <Option value='invalid'>Invalid</Option>
                <Option value='pending'>Pending</Option>
                <Option value='del_api'>Del API</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Start Height:"
              name="start_height"
              rules={[
                {
                  required: status,
                  message: 'Please enter your Start height!',
                },
                {
                  validator: (_, value) => {
                    if (value && !isNumber(value)) {
                      return Promise.reject('Start height can only be numbers!')
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>

            <Button loading={addLoading} htmlType="submit" size='large' className='w-full mt-2 md:mt-2 rounded' type='primary'><Trans>Audit</Trans></Button>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default Protocol
