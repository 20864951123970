import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { getUuiD } from 'utils'
import TaskTitle from './TaskTitle'

const TaskList: React.ForwardRefRenderFunction<any, { activeKey: any; tasksGroupIds: any; setTaskIds: any; tasks_arr: any[]; groups: any }> = (
  { activeKey, tasksGroupIds, setTaskIds, tasks_arr, groups },
  forwardedRef,
) => {
  const [checkAll, setCheckAll] = useState(false)
  const [checks, setChecks] = useState<any>([])

  useImperativeHandle(forwardedRef, () => ({
    checks,
  }))

  const onChange = (e: CheckboxChangeEvent, item: any, index: number) => {
    if (e.target.checked) {
      setTaskIds((preIds: any) => {
        // preIds[activeKey][index] = tasks_arr[index].id
        preIds[activeKey].push(tasks_arr[index].id)
        return [...preIds]
      })
    } else
      setTaskIds((preIds: any) => {
        preIds[activeKey].forEach((element: any, idx: number) => {
          if (element === item.id) {
            preIds[activeKey][idx] = null
          }
        })
        // preIds[activeKey][index] = null
        return [...preIds]
      })
    setChecks((preChecks: any) => {
      preChecks[index] = e.target.checked
      if (preChecks.some((item: any) => !item)) {
        setCheckAll(false)
      } else setCheckAll(true)
      return [...preChecks]
    })
  }

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setChecks((preChecks: any) => {
        const allTrue = preChecks.fill(true)
        return [...allTrue]
      })
      setTaskIds((preIds: any) => {
        let params: any = []
        checks.fill(true).forEach((item: boolean, index: number) => {
          params[index] = tasks_arr[index].id
        })
        preIds[activeKey] = [...params]
        return [...preIds]
      })
    } else {
      setChecks((preChecks: any) => {
        const allFalse = preChecks.fill(false)
        return [...allFalse]
      })
      setTaskIds((preIds: any) => {
        preIds[activeKey] = []
        return [...preIds]
      })
    }
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    const checksArr: any = tasks_arr.map((item: any) => tasksGroupIds[activeKey]?.includes(item.id)) || []
    setChecks([...checksArr])
    setCheckAll(!checksArr.some((item: any) => !item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, groups])

  return (
    <>
      <div>
        <div className="flex justify-end mt-4">
          <Checkbox onChange={handleCheckAll} checked={checkAll} className={`mr-4`} />
          Select All
        </div>
        {tasks_arr &&
          tasks_arr.map((item: any, index: number) => (
            <div
              key={getUuiD(index)}
              style={{ border: checks[index] ? '1px solid bg-tab/50' : '1px solid #1F394D' }}
              className={`w-full mt-5 flex px-5 py-2 transition-all delay-200 rounded-md justify-between ${checks[index] ? 'bg-tab/50' : 'bg-nibg'} `}
            >
              <TaskTitle task={item} />
              <Checkbox key={getUuiD(index)} checked={checks[index]} onChange={(e) => onChange(e, item, index)} />
            </div>
          ))}
      </div>
    </>
  )
}

export default forwardRef(TaskList)
