import { Trans } from '@lingui/macro'
import { Column } from '@ant-design/plots'
import { useMemo, useState } from 'react'
import { Spin } from 'antd'
import useAttitude from './hooks/useAttitude'
import { fixedArr, formatNum, formatTooltip } from 'utils'

const Sentiment = ({ code }: { code: string }) => {
  const [time, setTime] = useState('week')
  const [data, loading] = useAttitude(time, code)
  fixedArr(data, 'action', 'Positive')
  const config: any = {
    data,
    isGroup: true,
    xField: 'time',
    yField: 'value',
    seriesField: 'action',
    tooltip: {
      title: 'fullTime',
      formatter: (item: any) => {
        return { name: item.action, value: formatTooltip(item.value) }
      },
    },
    yAxis: {
      label: {
        formatter: (y: any) => formatNum(y),
      },
      grid: {
        line: {
          style: {
            stroke: '#484646',
            lineDash: [4, 5],
          },
        },
      },
    },
    xAxis: {
      line: {
        style: {
          stroke: '#484646',
        },
      },
    },
  }
  const graph = useMemo(() => {
    return <Column {...config} />
    // eslint-disable-next-line
  }, [data])
  return (
    <div className="bg-con rounded px-4 py-4 relative">
      <div className="flex flex-col md:flex-row">
        <div className="text-lg font-medium">Positive vs Negative</div>
        <div className="flex-1 flex md:justify-end text-xs mt-4 md:mt-0">
          <div
            onClick={() => setTime('week')}
            className={`${
              time === 'week' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'
            } w-16 flex items-center justify-center cursor-pointer rounded-l py-1.5`}
          >
            <Trans>7 Days</Trans>
          </div>
          <div
            onClick={() => setTime('month')}
            className={`${
              time === 'month' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'
            } w-16 flex items-center justify-center cursor-pointer py-1.5 border-l border-r border-ci border-opacity-20`}
          >
            <Trans>30 Days</Trans>
          </div>
          <div
            onClick={() => setTime('all')}
            className={`${
              time === 'all' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'
            } w-16 flex items-center justify-center cursor-pointer rounded-r py-1.5`}
          >
            <Trans>All</Trans>
          </div>
        </div>
      </div>
      {loading && (
        <div className="absolute left-1/2 top-1/2 z-40">
          <Spin />
        </div>
      )}
      <div className="mt-14">{graph}</div>
    </div>
  )
}

export default Sentiment
