import { useEffect, useState } from "react"
import axios from "config/axios"

const useWinners = (page: number, pageSize = 10, campaign_code: string, search_obj: any, update: boolean) => {
  const { address, reward_id } = search_obj
  const [data, setData] = useState<any>({list: [], prizes: [], total: 0})
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/space/campaign/analyze/winners?page=${page}&pagesize=${pageSize}&campaign_code=${campaign_code}&address=${address}&reward_id=${reward_id}`)
      setLoading(false)
      setData({list: res.data.data.data, prizes: res.data.data.campaign.prizes, total: res.data.data.total})
    } catch (error) {
      console.error("useWinners --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [page, pageSize,search_obj, update])
  return [data, loading]
}

export default useWinners
