import { useEffect, useState } from "react"
import axios from "config/axios"

const useStatistics = () => {
  const [data, setData] = useState({
    communities: 0,
    memeber_count: 0,
    tracking_kols: 0,
    social_converage: 0,
    social_volume: 0,
    spam_volume: 0,
    social_engagement: 0,
    social_contributors: 0,
    traking_projects: 0,
    market_cap: 0,
    trading_volume: 0,
    tracking_wallets: 0,
    web2_accounts: 0,
    bind_web2_wallets: 0,
    campaign_actions: 0,
    campaigns: 0,
  })
  const fetchData = async () => {
    try {
      const res = await axios.get('/api/admin/stats/total')
      setData(res.data.data.total_stats)
    } catch (error) {
      console.error("useStatistics --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return data
}

export default useStatistics
