import { Trans } from "@lingui/macro"
import { Input, Table, message, Popconfirm } from "antd"
import { useState } from "react"
import { titleCase } from "utils"
import search from 'assets/images/icon_search.svg'
import icon_up from 'assets/images/icon_up.svg'
import icon_right from 'assets/images/icon_right.svg'
import approve from 'assets/images/icon_approve.svg'
import reject from 'assets/images/icon_reject.svg'
import axios from "axios"
import debounce from 'lodash/debounce'
import { mediaImages } from "config/media"
import { blockchainName, blockchainScan, networkNameIcon } from "config/blockchain"
import usePendingRequest from "./hooks/usePendingRequest"

const PendingRequest = () => {
  const [page, setPage] = useState(1)
  const [update, setUpdate] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [{ list, total }, loading] = usePendingRequest(page, 10, searchText, update)

  const approveFun = async (space_code: string, project_code: string) => {
    const res = await axios.post('/api/admin/project/token/pending/req', {
      status: 'valid',
      space_code: space_code,
      project_code: project_code
    })
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }
  const deleteFun = async (space_code: string, project_code: string) => {
    const res = await axios.post('/api/admin/project/token/pending/req', {
      status: 'disable',
      space_code: space_code,
      project_code: project_code
    })
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const columns: any = [
    {
      title: <div className="pl-2"><Trans>Token</Trans></div>,
      dataIndex: 'symbol',
      key: 'symbol',
      width: 150,
      render: (cur: any, record: any) => (
        <div className="flex items-center space-x-2 pl-2">
          <img className="h-5 rounded-full" src={record.logo_url} alt="" />
          <div>{cur}</div>
        </div>
      )
    },
    {
      title: <div><Trans>Applicant</Trans></div>,
      dataIndex: 'space_name',
      key: 'space_name',
      width: 180,
      ellipsis: true,
    },
    {
      title: <div><Trans>Chains</Trans></div>,
      dataIndex: 'blockchains',
      key: 'blockchains',
      width: 140,
      render: (cur: any) => (
        <div className="flex space-x-2">
          {cur.map((key: string, index: number) => (
            <img key={index} className="h-5 rounded-full" src={networkNameIcon[key]} alt="" />
          ))}
        </div>
      )
    },
    {
      title: <div><Trans>Links</Trans></div>,
      dataIndex: 'urls',
      key: 'urls',
      width: 140,
      render: (cur: any) => (
        <div className="flex">
          {Object.keys(cur).map((key: string, index: number) => (
            <>{mediaImages[key] && <a className="mr-1.5" key={index} href={cur[key]} target='_blank' rel="noreferrer"><img className="h-4" src={mediaImages[key]} alt="" /></a>}</>
          ))}
        </div>
      )
    },
    {
      title: <div><Trans>Status</Trans></div>,
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (cur: any) => (
        <div className={`w-fit ${cur !== 'pending' ? cur === 'valid' ? 'bg-valid bg-opacity-20 text-valid' : 'bg-red-500 bg-opacity-20 text-red-500' : 'bg-pend bg-opacity-20 text-pend'} px-3.5 py-1 rounded-full text-xs`}><Trans>{titleCase(cur)}</Trans></div>
      )
    },
    {
      title: <div><Trans>Operations</Trans></div>,
      dataIndex: '',
      key: 'operations',
      width: 100,
      render: (_: any, record: any) => (
        <div className="col-span-1 flex justify-center items-center space-x-2.5 pr-6">
          {record.status === 'pending' && <Popconfirm placement="topLeft" onConfirm={() => approveFun(record.space_code, record.project_code)} title="Are you sure agree this token?" okText="Yes" cancelText="No">
            <img className="h-5 cursor-pointer" src={approve} alt="" />
          </Popconfirm>}
          {record.status === 'pending' && <Popconfirm placement="topLeft" onConfirm={() => deleteFun(record.space_code, record.project_code)} title="Are you sure reject this token?" okText="Yes" cancelText="No">
            <img className="h-5 cursor-pointer" src={reject} alt="" />
          </Popconfirm>}
          {record.status !== 'pending' && '-'}
        </div>
      )
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }

  const getSearch = debounce((value: string) => {
    setSearchText(value)
  }, 500)

  return (
    <div>
      <div className="bg-ncon rounded-lg border border-bord">
        <div className="flex items-center px-6">
          <div className="flex-1 py-4 flex items-center font-semibold space-x-4">
            <div><Trans>Pending Request</Trans></div>
          </div>
          <div className="relative">
            <Input
              onChange={(e: any) => getSearch(e.target.value)} placeholder="Search Token" className="rounded-full w-80 pl-11 text-sm h-9 bg-transparent border border-bord hover:border-ci focus:border-ci" />
            <div className="absolute h-full flex items-center top-0 left-4"><img src={search} alt="" /></div>
          </div>
        </div>
        <div className="py-1 overflow-x-scroll">
          <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
            rowKey={(record) => record.project_code + record.space_code}
            rowClassName={(_, index) => {
              let className = 'nrodd'
              if (index % 2 === 0) className = 'nreven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 10,
              total: total,
              // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender: record => (
                <div className="pl-20 text-white text-opacity-75">
                  <div className="flex items-center space-x-3 border-b border-bord pb-4">
                    <div><Trans>Name:</Trans></div>
                    <div>{record.name}</div>
                  </div>
                  <div className="py-4 border-b border-bord">
                    <div><Trans>Contract Address:</Trans></div>
                    {record.token_addresses.map((chain: any, index: number) => (
                      <div key={index}>{blockchainName[chain.blockchain] && <div className="flex items-center mt-3 space-x-2">
                        <img className="h-5 rounded-full" src={networkNameIcon[chain.blockchain]} alt="" />
                        <div className="w-20">{titleCase(blockchainName[chain.blockchain])}:</div>
                        <a className="text-white text-opacity-75 hover:text-opacity-90 underline" href={`${blockchainScan[chain.blockchain]}/address/${chain.address}`} target='_blank' rel="noreferrer">{chain.address}</a>
                      </div>}</div>
                    ))}
                  </div>
                  <div className="pt-4">
                    <div><Trans>Links:</Trans></div>
                    {Object.keys(record.urls).map((url: any, index: number) => (
                      <div key={index}>{record.urls[url] && <div className="flex items-center mt-3 space-x-2">
                        <img className="h-5 rounded-full" src={mediaImages[url]} alt="" />
                        <div className="w-20">{titleCase(url)}:</div>
                        <a className="text-aco text-opacity-75 hover:text-opacity-90 underline" href={record.urls[url]} target='_blank' rel="noreferrer">{record.urls[url]}</a>
                      </div>}</div>
                    ))}
                  </div>
                </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <img className="h-5" src={icon_up} onClick={e => onExpand(record, e)} alt='' />
                ) : (
                  <img className="h-5" src={icon_right} onClick={e => onExpand(record, e)} alt='' />
                )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PendingRequest