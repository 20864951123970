import { utils } from 'ethers'
import { useAirdropContract } from 'hooks/useContract'
import { isChainCoin } from 'utils'

const useOperate = (airdrop_contract_address: string) => {
  const airdropContract = useAirdropContract(airdrop_contract_address)

  const sendToken = async (
    token: string,
    address_arr: string[],
    amount_arr: number[],
    amount: string,
    decimals: string | number,
  ) => {
    try {
      let tx
      let trans_amount_arr: any = []
      for (let num of amount_arr) {
        trans_amount_arr.push(utils.parseUnits(num.toString(), decimals))
      }
      if (isChainCoin(token)) {
        tx = await airdropContract?.disperseEther(address_arr, trans_amount_arr, {
          value: utils.parseUnits(amount, decimals),
        })
      } else {
        tx = await airdropContract?.disperseToken(token, address_arr, trans_amount_arr)
      }
      return tx.hash
    } catch (error) {
      console.error('sendToken --> ' + error)
    }
  }
  return { sendToken }
}

export default useOperate
