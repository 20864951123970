import { useEffect, useState } from 'react'
import axios from 'config/axios'

const useReport = (campaign_code: string | undefined) => {
  const [data, setData] = useState({
    quiz_answers: [],
    rewards: [],
    charts: [],
    tasks: [],
    stats: {
      exposures: 0,
      covert_rate: 0,
      actions: 0,
      users: 0,
      wait_users: 0,
    },
  })
  const fetchData = async () => {
    try {
      const res = await axios.get(`/api/admin/space/campaign/analyze/report?campaign_code=${campaign_code}`)
      if (res.data.code === 0) setData(res.data.data)
    } catch (error) {
      console.error('useReport --> ' + error)
    }
  }
  useEffect(() => {
    if (campaign_code) fetchData()
    // eslint-disable-next-line
  }, [])
  return data
}

export default useReport
