import { Table, Tooltip } from 'antd'
import { Trans } from "@lingui/macro"
import { formatNum, titleCase } from "utils"
import { useState } from 'react'
import useRelativeCommunities from './hooks/useRelativeCommunities'
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'

const RelativeCommunities = ({ code }: { code: string }) => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [{ list, total }, loading] = useRelativeCommunities(code, page)
  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 50,
      render: (_cur: any, _record: any, index: number) => (<div>{(index + 1)+(page-1)*8}</div>)
    },
    {
      title: <div><Trans>Community</Trans></div>,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 150,
      render: (cur: any, record: any) => (
        <div className="flex items-center">
          <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />
          <div className="truncate" title={cur}>
            <Tooltip placement="topLeft" title={cur}>
              {cur}
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      title: <div><Trans>Platform</Trans></div>,
      dataIndex: 'platform',
      key: 'platform',
      width: 90,
      render: (cur: any) => (<div>{titleCase(cur)}</div>)
    },
    {
      title: <div><Trans>Members</Trans></div>,
      dataIndex: 'member_count',
      key: 'member_count',
      width: 90,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
    {
      title: <div><Trans>Engagement</Trans></div>,
      dataIndex: 'engagement',
      key: 'engagement',
      width: 110,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded px-4 pt-4">
        <div className="text-lg font-medium pl-3"><Trans>Relative Communities</Trans></div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table loading={loading} dataSource={list} columns={columns}
            rowKey={(record: any) => record.community_code}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 8,
              total: total,
              // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  const arr = [{
                    community_code: record.community_code,
                    logo_url: record.logo_url,
                    name: record.name,
                  }]
                  cookie.save('soad_community', arr, { path: '/' })
                  history.push(`/community`)
                  window.scrollTo(0, 0)
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default RelativeCommunities
