import discord from 'assets/images/discord.svg'
import twitter from 'assets/images/twitter.svg'
import telegram from 'assets/images/telegram.svg'
import homepage from 'assets/images/homepage.svg'
import opensea from 'assets/images/opensea.svg'
import github from 'assets/images/git.svg'
import medium from 'assets/images/medium.svg'
import nftscan from 'assets/images/nftscan.svg'
import icon_token from 'assets/images/icon_token.svg'
import icon_nft from 'assets/images/icon_nft.svg'
import icon_invite from 'assets/images/icon_invite.svg'
import dapp from 'assets/images/dapp.svg'
import custom from 'assets/images/custom.svg'
import bind_account from 'assets/images/bind_account.svg'
import connect_wallet from 'assets/images/connect_wallet.svg'
import bsc from 'assets/images/bsc_task.svg'
import ether from 'assets/images/ether_task.svg'
import pan from 'assets/images/pan.svg'
import uni from 'assets/images/uni.svg'
import alpaca from 'assets/images/alpaca.svg'
import com from 'assets/images/com_con.svg'
import galaxy from 'assets/images/galaxy.svg'
import liquidswap from 'assets/images/liquidswap.svg'
import aptid from 'assets/images/aptid.svg'
import topaz from 'assets/images/topza.svg'
import oolongswap from 'assets/images/0olongswap.svg'
import ginfinance from 'assets/images/ginfinance.svg'
import bodhfinance from 'assets/images/bodhfinance.svg'
import channel from 'assets/images/channel.svg'
import venus from 'assets/images/venus.svg'
import proof from 'assets/images/proof.svg'
// import zenchafinance from 'assets/images/zenchafinance.svg'
// import senpaiswap from 'assets/images/senpaiswap.svg'


export const mediaImages: any = {
  'discord': discord,
  'twitter': twitter,
  'telegram': telegram,
  'homepage': homepage,
  'github': github,
  'medium': medium,
  'opensea': opensea,
  'nftscan': nftscan,
  'icon_token': icon_token,
  'icon_nft': icon_nft,
  'icon_invite': icon_invite,
  'download_app': dapp,
  'answer_question': custom,
  'bind_account': bind_account,
  'connect_wallet': connect_wallet,
  'bsc': bsc,
  'ether': ether,
  'PancakeSwap': pan,
  'uniswap': uni,
  'Pancake': pan,
  'Alpaca': alpaca,
  'com': com,
  'Galxe': galaxy,
  'Project Galaxy': galaxy,
  'LiquidSwap': liquidswap,
  'AptID': aptid,
  'Topaz': topaz,
  'OolongSwap': oolongswap,
  'Gin Finance': ginfinance,
  'Bodh Finance': bodhfinance,
  'channel': channel,
  'Venus Protocol': venus,
  'proof': proof
}
