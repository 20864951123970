import { Trans } from "@lingui/macro"
import { Table, Typography } from "antd"
import moment from "moment"
import { formatNum, shortenSystemAPIKey } from "utils"
import useTwitterAPI from "./hooks/useTwitterAPI"
const { Paragraph } = Typography

const TwitterAPIKey = () => {
  const [data, loading] = useTwitterAPI()

  const columns: any = [
    {
      title: <div className="pl-2">#</div>,
      dataIndex: '',
      key: 'key',
      width: 70,
      render: (_cur: any, _record: any, index: number) => (<div className="pl-2">{(index + 1)}</div>)
    },
    {
      title: <div><Trans>API Key</Trans></div>,
      dataIndex: 'api_key',
      key: 'api_key',
      width: 200,
      render: (cur: string) => (
        <div><Paragraph copyable={{ text: cur }}>{shortenSystemAPIKey(cur, 6)}</Paragraph></div>
      )
    },
    {
      title: <div><Trans>Today Usage</Trans></div>,
      dataIndex: 'daily_usage',
      key: 'daily_usage',
      width: 140,
      render: (cur: any) => (
        <div>{formatNum(cur, 0, true)}</div>
      )
    },
    {
      title: <div><Trans>Today Error</Trans></div>,
      dataIndex: 'daily_err_num',
      key: 'daily_err_num',
      width: 140,
      render: (cur: any) => (
        <div>{formatNum(cur, 0, true)}</div>
      )
    },
    {
      title: <div><Trans>Status</Trans></div>,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (cur: any) => {
        return (
          <div className={`w-fit text-xs px-3 py-1 rounded-full ${cur === 'normal' ? 'bg-valid bg-opacity-20 text-valid' : 'bg-red-500 bg-opacity-20 text-red-500'}`}>{cur}</div>
        )
      }
    },
  ]

  return (
    <div className="bg-ncon rounded-lg border border-bord">
      <div className="overflow-x-scroll max-h-600 noTop">
        <Table className="" loading={loading} dataSource={data} columns={columns} pagination={false}
          rowKey={(record: any) => {
            return record.api_key
          }}
          rowClassName={(_, index) => {
            let className = 'nrodd'
            if (index % 2 === 0) className = 'nreven'
            return className
          }}
        />
      </div>
    </div>
  )
}

export default TwitterAPIKey
