import { Trans } from '@lingui/macro'
import { Button, Form, Input, Table, Select, Tooltip, message, Popconfirm } from 'antd'
import { useState } from 'react'
import icon_explorer from 'assets/images/icon_explorer.svg'
import reject from 'assets/images/icon_reject.svg'
import { SearchOutlined, ExportOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { downloadFile, formatNum, getUuiD, shortenAddress } from 'utils'
import axios from 'axios'
import useWinners from './hooks/useWinners'
import { cur } from 'config/axios'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const { Option } = Select

const Winners = ({ campaign_code }: any) => {
  const [page, setPage] = useState(1)
  const [update, setUpdate] = useState(false)
  const [visible, setVisible] = useState(false)
  const [copy, setCopy] = useState(false)
  const [copyText, setCopyText] = useState('')
  const [reward_id, setReward_id] = useState()
  const [api_id, setApi_id] = useState()
  const [reward_all, setReward_all] = useState()
  const [loadExport, setLoadExport] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search_obj, setSearch_obj] = useState<any>({
    address: '',
    reward_id: 'all',
  })

  const [{ list, prizes, total }, loading] = useWinners(page, 10, campaign_code, search_obj, update)

  const columns: any = [
    {
      title: (
        <div>
          <Trans>User</Trans>
        </div>
      ),
      dataIndex: 'address',
      key: 'address',
      width: 200,
      elippses: true,
      render: (cur: string) => {
        return (
          <Tooltip overlay={cur}>
            <span>{shortenAddress(cur)}</span>
          </Tooltip>
        )
      },
    },
    {
      title: (
        <div>
          <Trans>Entries</Trans>
        </div>
      ),
      dataIndex: 'votes',
      key: 'votes',
      width: 180,
    },
    {
      title: (
        <div>
          <Trans>Result</Trans>
        </div>
      ),
      dataIndex: 'winners',
      key: 'winners',
      width: 340,
      render: (cur: any, record: any) => (
        <div className="flex flex-wrap items-center gap-3">
          {cur.map((prize: any, index: number) => (
            <div key={index} className="relative px-2.5 py-1.5 bg-prize bg-opacity-20 text-prize rounded-full text-xs">
              <img onClick={() => onSelectUndoPage(prize.id, [record.user_id])} className="h-4.5 absolute -top-2 -right-2" src={reject} alt="" />
              {prize.name}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Reward address</Trans>
        </div>
      ),
      dataIndex: 'reward_address',
      key: 'reward_address',
      width: 300,
      render: (cur: any, record: any) => (
        <div className="flex flex-wrap gap-3 flex-col">
          {record?.addresses?.evm && <span>EVM: {shortenAddress(record?.addresses?.evm)}</span>}
          {record?.addresses?.aptos && <span>Aptos: {shortenAddress(record?.addresses?.aptos)}</span>}
          {record?.addresses?.ton && <span>TON: {shortenAddress(record?.addresses?.ton)}</span>}
        </div>
      ),
    },
    {
      title: (
        <div>
          Block stats
          <Tooltip placement="top" title="Blocked address will be automatically excluded from winner selection.">
            {' '}
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'is_block',
      key: 'is_block',
      width: 140,
      render: (_: any, record: any, index: number) => {
        return (
          <Select className="w-50" key={getUuiD(index)} onSelect={(value: any) => onUpdateStatus(value, record.user_id)} placeholder="Noram" defaultValue={record.is_block ? 'block' : 'normal'}>
            <Option key="normal" value="normal">
              Normal
            </Option>
            <Option key="block" value="block">
              Block
            </Option>
          </Select>
        )
      },
    },
    {
      title: (
        <div>
          <Trans>Operation</Trans>
        </div>
      ),
      dataIndex: 'op',
      key: 'op',
      width: 160,
      render: (_: any, record: any, index: number) => {
        return (
          <Select className="w-52" key={getUuiD(index)} onSelect={(value: any) => onSelectStatus(value, record.user_id)} placeholder="Select a prize">
            {prizes.map((prize: any) => (
              <Option key={prize.id} value={prize.id}>
                {prize.name}
              </Option>
            ))}
          </Select>
        )
      },
    },
  ]

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }

  const onFinish = (fieldValues: any) => {
    const { address, reward_id } = fieldValues
    setPage(1)
    setSearch_obj({
      address: address ? address : '',
      reward_id: reward_id ? reward_id : '',
    })
  }

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 68,
    onChange: onSelectChange,
  }

  const onSelectStatus = async (value: any, id: any) => {
    const res = await axios.post('/api/admin/space/campaign/analyze/winners', {
      campaign_code,
      reward_id: value,
      user_ids: [id],
      type: 'batch',
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }

  const onUpdateStatus = async (status: any, id: any) => {
    const res = await axios.post('/api/admin/space/campaign/user/status', {
      campaign_code,
      user_id: id,
      type: status,
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }

  const onSelectStatusPage = async (value: string) => {
    if (!selectedRowKeys.length) return
    const res = await axios.post('/api/admin/space/campaign/analyze/winners', {
      campaign_code,
      reward_id: value,
      user_ids: selectedRowKeys,
      type: 'batch',
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }

  const onSelectUndoPage = async (value: string, ids: any) => {
    if (!ids.length) return
    const res = await axios.post('/api/admin/space/campaign/analyze/winner/undo', {
      campaign_code,
      reward_id: value,
      user_ids: ids,
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }

  const onSelectAll = (value: any) => {
    if (!value) return
    setReward_all(value)
    setVisible(true)
  }

  const markAllFun = async () => {
    if (!reward_all) return
    const res = await axios.post('/api/admin/space/campaign/analyze/winners', {
      campaign_code,
      reward_id: reward_all,
      type: 'batch',
    })
    if (res.data.code === 0) {
      setVisible(false)
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }

  const onSelectRandom = (value: any) => {
    if (!value) return
    setReward_id(value)
  }

  const onSelectAPI = (value: any) => {
    if (!value) return
    if (value === 'candidates') {
      const copyText = `${cur}/api/campaign/${campaign_code}/candidates`
      setCopyText(copyText)
    } else {
      const copyText = `${cur}/api/campaign/${campaign_code}/prize/${value}/winners`
      setCopyText(copyText)
    }
    setApi_id(value)
  }

  const generateRandom = async () => {
    if (!reward_id) return
    const res = await axios.post('/api/admin/space/campaign/analyze/winners', {
      campaign_code,
      reward_id: reward_id,
      type: 'random',
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }

  const prizeAPI = async () => {
    if (!api_id) return
    const res = await axios.get(`/api/admin/space/apikey`)
    const api_key = res.data?.data?.api_key
    if (!api_key) {
      message.info('Please generate API Key first!')
      return
    }
    if (api_id === 'candidates') {
      window.open(`${cur}/api/campaign/${campaign_code}/candidates?api-key=${api_key}`)
    } else {
      window.open(`${cur}/api/campaign/${campaign_code}/prize/${api_id}/winners?api-key=${api_key}`)
    }
  }

  const exportFun = async () => {
    const { address, reward_id } = search_obj
    const url = `/api/admin/space/campaign/analyze/winners?page=${1}&pagesize=${10}&campaign_code=${campaign_code}&address=${address}&reward_id=${reward_id}&is_export=${true}`
    setLoadExport(true)
    const res = await axios({
      url,
      method: 'get',
      responseType: 'blob',
    })
    setLoadExport(false)
    downloadFile(res.data, `${campaign_code}_campaign_winners`)
  }

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="bg-ncon rounded-lg border border-bord">
          <div className="border-b border-bord">
            <div className="px-6 py-6">
              <div>
                <Trans>
                  Publish Winners and Visit it on our API (
                  <a className="text-ci hover:underline" href="https://port3-network.gitbook.io/port3-network/guide/soquest-api-for-partners" target="_blank" rel="noreferrer">
                    docs
                  </a>
                  ) and Oracle.
                </Trans>
              </div>
              <div className="mt-5 space-x-6 flex items-center">
                {/* <Button disabled className='rounded-lg flex items-center space-x-1.5' type='primary'><Trans>API</Trans> <img className="opacity-30" src={icon_explorer} alt="" /></Button> */}
                <div className="flex space-x-6">
                  <Select onSelect={onSelectAPI} placeholder="Please select" className="w-56">
                    <Option value="candidates">Candidates</Option>
                    {prizes.map((prize: any) => (
                      <Option key={prize.id} value={prize.id}>
                        {prize.name}
                      </Option>
                    ))}
                  </Select>
                  <Button onClick={prizeAPI} type="primary" className="rounded-lg flex items-center">
                    <Trans>Preview API</Trans>
                    <img className="ml-2" src={icon_explorer} alt="" />
                  </Button>
                </div>
                <CopyToClipboard text={copyText}>
                  <Tooltip visible={copy} className="cursor-pointer" overlay={copyText ? 'Copied' : 'Select a item before copy'}>
                    <span
                      onClick={() => {
                        setCopy(true)
                        setTimeout(() => {
                          setCopy(false)
                        }, 2000)
                      }}
                      className="cursor-pointer"
                    >
                      <Button type="primary" className="rounded-lg flex items-center">
                        <Trans>Copy API URL</Trans>
                      </Button>
                    </span>
                  </Tooltip>
                </CopyToClipboard>
                <Button disabled className="rounded-lg" type="primary">
                  <Trans>Publish Oracle</Trans>
                </Button>
              </div>
              <div className="mt-6 flex flex-wrap">
                <div className="flex items-center space-x-1">
                  <div>
                    <Trans>Total:</Trans>
                  </div>
                  <div className="text-pend">{formatNum(total)}</div>
                </div>
                {prizes.map((prize: any, index: number) => (
                  <div key={index} className="flex">
                    <div className="mr-2">,</div>
                    <div className="flex space-x-1">
                      <span>{prize.name}:</span>
                      <span className="text-pend">
                        {formatNum(prize.use_shares)}/{formatNum(Number(prize.shares) === -1 ? 100000 : prize.shares)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex mt-6 space-x-6">
                <Select onSelect={onSelectRandom} placeholder="Select a prize" className="w-56">
                  {prizes.map((prize: any) => (
                    <Option key={prize.id} value={prize.id}>
                      {prize.name}
                    </Option>
                  ))}
                </Select>
                <Button onClick={generateRandom} type="primary" className="rounded-lg">
                  <Trans>Random Generate</Trans>
                </Button>
              </div>
            </div>
          </div>
          <div className="border-b border-bord">
            <div className="flex items-center px-6 pt-7 pb-1">
              <Form name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
                <div className="flex space-x-4">
                  <Form.Item name="address">
                    <Input placeholder="Address" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-400" />
                  </Form.Item>
                  <Form.Item name="reward_id" initialValue="all">
                    <Select className="w-56">
                      <Option value="all">
                        <Trans>All</Trans>
                      </Option>
                      {prizes.map((prize: any) => (
                        <Option key={prize.id} value={prize.id}>
                          {prize.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button htmlType="submit" className="rounded-lg" type="primary">
                    <Trans>Search</Trans> <SearchOutlined />
                  </Button>
                  <Button loading={loadExport} onClick={exportFun} className="rounded-lg" type="primary">
                    <Trans>Export</Trans> <ExportOutlined />
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="py-7 px-6 flex space-x-6">
            <div className="flex space-x-4">
              <Popconfirm visible={visible} onConfirm={markAllFun} title={`Are you sure mark all?`} onCancel={() => setVisible(false)} okText="Yes" cancelText="No">
                <Select onSelect={onSelectAll} placeholder="Mark all as" className="w-80">
                  {prizes.map((prize: any) => (
                    <Option key={prize.id} value={prize.id}>
                      Mark all as {prize.name}
                    </Option>
                  ))}
                </Select>
              </Popconfirm>
            </div>
            <div className="flex space-x-4">
              <Select onSelect={onSelectStatusPage} placeholder="Mark selected as" className="w-80">
                {prizes.map((prize: any) => (
                  <Option key={prize.id} value={prize.id}>
                    Mark selected as {prize.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex space-x-4">
              <Select onSelect={(value: any) => onSelectUndoPage(value, selectedRowKeys)} placeholder="Undo selected as" className="w-80">
                {prizes.map((prize: any) => (
                  <Option key={prize.id} value={prize.id}>
                    Undo selected as {prize.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="py-1 overflow-x-scroll">
            <Table
              className=""
              loading={loading as boolean}
              dataSource={list}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.user_id}
              rowClassName={(_, index) => {
                let className = 'nrodd'
                if (index % 2 === 0) className = 'nreven'
                return className
              }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                size: 'small',
                current: page,
                pageSize: 10,
                total: total,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Winners
