import { Button, Popconfirm } from 'antd'
import axios from 'axios'
import { CloseOutlined } from '@ant-design/icons'
import CheckTwitter from 'pages/Space/CheckModal/CheckTwitter'
import React, { useEffect, useState } from 'react'

const TwitterLink: React.FC<{ prefix: any; historyLink?: string; setUpdate: any }> = ({ prefix, historyLink, setUpdate }) => {
  const [checkTwitterOpen, setCheckTwitterOpen] = useState(false)
  const [verify, setVerify] = useState<any>({})
  const getTwitterVerify = async () => {
    const result = await axios.get('/api/admin/oauth/twitter/verify')
    setVerify({ ...result.data.data })
  }
  const handleVerify = () => {
    if (!verify?.is_verify || !verify?.twitter_code) return setCheckTwitterOpen(true)
  }
  const handleUnverified = (e: any) => {
    e.stopPropagation()
    setCheckTwitterOpen(true)
  }
  const handleUnBind = async () => {
    await axios.post('/api/admin/oauth/twitter/remove')
    getTwitterVerify()
    setUpdate((update: any) => !update)
  }
  useEffect(() => {
    getTwitterVerify()
  }, [])
  return (
    <>
      <div className="flex items-center border bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 h-8 px-2 transition-all duration-300" onClick={handleVerify}>
        {prefix}
        {verify?.twitter_code && <span className="ml-1">{verify?.twitter_code}</span>}
        {/* history link */}
        {historyLink && !verify?.is_verify && !verify?.twitter_code && (
          <>
            <span className="ml-1">{historyLink}</span>
            <Button className="ml-auto flex items-center bg-tab/40 border-none h-6 text-xs text-fff90" onClick={handleUnverified}>
              <span style={{ transform: 'scale(.8)', marginRight: 4, opacity: '0.6' }}>{prefix}</span>
              Unverified
            </Button>
          </>
        )}
        {/* new link */}
        {verify?.twitter_code && verify?.is_verify && (
          <>
            <Popconfirm title="Sure to disconnect current twitter account?" onConfirm={handleUnBind} okText="Disconnect" cancelText="Cancel">
              <Button prefix={prefix} className="ml-auto flex items-center bg-tab/40 border-none h-6 text-xs text-fff90">
                <span style={{ transform: 'scale(.8)', marginRight: 4, opacity: '0.6' }}>{prefix}</span>
                Verified
                <CloseOutlined style={{ opacity: '0.6' }} />
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
      <CheckTwitter visible={checkTwitterOpen} onCancel={() => setCheckTwitterOpen(false)} verify={verify} getTwitterVerify={getTwitterVerify} />
    </>
  )
}

export default TwitterLink
