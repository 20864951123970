import { useEffect, useState } from "react"
import axios from "config/axios"

const useStatistics = () => {
  const [data, setData] = useState({
    active_kols: 0,
    daily_social_engagement: 0,
    daily_tweets: 0,
    total_track_kols: 0,
  })
  const fetchData = async () => {
    try {
      const res = await axios.get('/api/admin/twitter/stats')
      setData(res.data.data)
    } catch (error) {
      console.error("useStatisticsByTwitters --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return data
}

export default useStatistics
