import { useState } from 'react'
import AddAddress from './AddAddress'
import AddEmail from './AddEmail'
import GenerateApiKey from './GenerateApiKey'
import useProfileData from './hooks/useProfileData'
import Information from './Information'
import Link from './Link'
import Portfolio from './Portfolio'
import RelateToken from './RelateToken'

const SpaceProfile = () => {
  const [update, setUpdate] = useState(false)
  const profile = useProfileData(update)
  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl space-y-6">
        <Information
          setUpdate={setUpdate}
          info={{
            space_name: profile.space_name,
            space_code: profile.space_code,
            introduction: profile.introduction,
            logo_url: profile.logo_url,
            space_type: profile.space_type,
            telegram_id: profile.telegram_id,
          }}
        />
        <Link urls={profile.urls} setUpdate={setUpdate} />
        <div className="bg-ncon rounded-lg px-5 py-5 border border-bord space-y-10">
          <AddAddress setUpdate={setUpdate} addresses={profile.addresses} />
          <GenerateApiKey />
          <AddEmail setUpdate={setUpdate} emails={profile.emails} />
        </div>
        <RelateToken setUpdate={setUpdate} relate_tokens={profile.relate_tokens} />
        <Portfolio setUpdate={setUpdate} portfolio_tokens={profile.portfolio_tokens} my_portfolio_tokens={profile.my_portfolio_tokens} />
      </div>
    </div>
  )
}

export default SpaceProfile
