import { Trans } from '@lingui/macro'
import { Button, Dropdown, Input, message, Switch, Table } from 'antd'
import { useEffect, useState } from 'react'
import add from 'assets/images/_icon_add.svg'
import search from 'assets/images/icon_search.svg'
import publish from 'assets/images/publish.svg'
import previewlogo from 'assets/images/preview.svg'
import edit_ from 'assets/images/edit_.svg'
import delete_ from 'assets/images/delete_.svg'
import mores from 'assets/images/mores.svg'
import cp from 'assets/images/cpw.svg'
import useCampaignList from './hooks/useCampaignList'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import cookie from 'react-cookies'
import { useQuery } from 'hooks/useQuery'
import axios, { previewUrl } from 'config/axios'
import debounce from 'lodash/debounce'
import { titleCase } from 'utils'
import { LineChartOutlined } from '@ant-design/icons'
import CheckTwitter from '../CheckModal/CheckTwitter'

const Campaigns = () => {
  const role = cookie.load('soad_role')
  const query = useQuery()
  const defaultTab = query.get('tab')
  const history = useHistory()
  let location = useLocation()
  const isAdmin = location.pathname.slice(1, 6) === 'admin'
  const [page, setPage] = useState(1)
  const [update, setUpdate] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [tab, setTab] = useState(defaultTab ? defaultTab : 'all')
  const [{ list, total }, loading] = useCampaignList(page, 10, isAdmin, tab, searchText, update)

  const [checkTwitterOpen, setCheckTwitterOpen] = useState(false)
  const [verify, setVerify] = useState<any>({})

  const getTwitterVerify = async () => {
    const result = await axios.get('/api/admin/oauth/twitter/verify')
    setVerify({ ...result.data.data })
  }
  const release = async (e: any, campaign_code: string) => {
    e.stopPropagation()
    if (!verify.space_verify) {
      if (!verify?.is_verify || !verify?.twitter_code) return setCheckTwitterOpen(true)
    }

    const res = await axios.post('/api/admin/space/campaign/publish', {
      campaign_code,
    })
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const preview = async (e: any, space_code: string, campaign_code: string) => {
    e.stopPropagation()
    window.open(`${previewUrl}${space_code}/campaign/${campaign_code}`, '_blank')
  }

  const deleteCampaign = async (e: any, campaign_code: string) => {
    e.stopPropagation()
    const res = await axios.post(`/api/admin/space/campaign/${campaign_code}/remove`)
    if (res.data.code === 0) {
      message.success('success!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const columns: any = [
    {
      title: <div className="pl-2">#</div>,
      dataIndex: '',
      key: 'key',
      width: 60,
      render: (_cur: any, _record: any, index: number) => <div className="pl-2">{index + 1 + (page - 1) * 10}</div>,
    },
    {
      title: (
        <div>
          <Trans>Title</Trans>
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
      width: 160,
      ellipsis: true,
      render: (cur: any, record: any) => (
        <div className="flex items-center space-x-2">
          <div className="flex justify-center">{record.cover_image_url && <img className="w-16 h-8" src={record.cover_image_url} alt="" />}</div>
          <div className="flex-1 truncate" title={cur}>
            {cur}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Owner</Trans>
        </div>
      ),
      dataIndex: 'space_name',
      key: 'space_name',
      width: 120,
      ellipsis: true,
    },
    {
      title: (
        <div>
          <Trans>Valid Time</Trans>
        </div>
      ),
      dataIndex: '',
      key: 'valid_time',
      width: 190,
      render: (_: any, record: any) => (
        <>
          {record.start_time && (
            <div className="flex">
              <div>{moment(record.start_time * 1000).format('YYYY-MM-DD')}</div>
              <div className="px-1">~</div>
              <div>{moment(record.end_time * 1000).format('YYYY-MM-DD')}</div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <Trans>Visible</Trans>
        </div>
      ),
      dataIndex: 'is_private',
      key: 'is_private',
      width: 70,
      render: (cur: any) => (
        <div className="relative h-3 w-fit">
          <div className="flex items-center h-full">
            <div className="bg-vibg rounded-full w-7 h-2"></div>
          </div>
          <div className={`absolute top-0 w-3 h-3 rounded-full ${!cur ? 'bg-valid right-0' : 'bg-nvi left-0'}`}></div>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Status</Trans>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      width: 130,
      render: (cur: any, record: any) => (
        <>
          <div
            className={`${
              cur !== 'expired' && cur !== 'nstarted' ? (cur === 'active' ? 'bg-valid bg-opacity-20 text-valid' : 'bg-red-500 bg-opacity-20 text-red-500') : 'bg-pend bg-opacity-20 text-pend'
            } rounded-full text-xs w-fit flex justify-center px-3 py-1`}
          >
            {cur === 'expired' ? 'Finished' : titleCase(cur)}
          </div>
          {record?.is_recommend && <div className={`bg-red-500 bg-opacity-20 text-red-500 rounded-full text-xs w-fit flex justify-center px-3 py-1 mt-1`}>Featured</div>}
        </>
      ),
    },
    {
      title: (
        <div>
          <Trans>Action</Trans>
        </div>
      ),
      dataIndex: '',
      key: 'operation',
      width: 160,
      render: (_: any, record: any) => {
        const space_code = cookie.load('soad_space_code')
        const owner = record.space_code === space_code
        return (
          <div className="flex space-x-10">
            {record.status === 'draft' && owner && (
              <div onClick={(e) => release(e, record.campaign_code)} className="flex items-center space-x-1">
                <div>
                  <Trans>Publish</Trans>
                </div>
                <img src={publish} alt="" />
              </div>
            )}
            {
              <div onClick={(e) => preview(e, record.space_code, record.campaign_code)} className="flex items-center space-x-1">
                <div>
                  <Trans>Preview</Trans>
                </div>
                <img src={previewlogo} alt="" />
              </div>
            }
            {record.status !== 'draft' && (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  history.push(`/campaigns/setup?campaign_code=${record.campaign_code}`, { tab: 'analytics' })
                  window.scrollTo(0, 0)
                }}
                className="flex items-center space-x-1"
              >
                <div>
                  <Trans>Analytics</Trans>
                </div>
                <LineChartOutlined />
              </div>
            )}
            {/* {<Button type="primary" className="px-3 h-7 rounded-full flex items-center"><Trans>{isAdmin ? owner ? 'Edit' : 'View' : 'Edit'}</Trans></Button>}
          {
            owner && <Button onClick={async (e: any) => {
              e.stopPropagation()
              const res = await axios.post('/api/admin/space/campaign/copy', { campaign_code: record.campaign_code })
              const campaign_code = res.data.data.campaign_code
              history.push(`/campaigns/setup?campaign_code=${campaign_code}`)
              window.scrollTo(0, 0)
            }} type="primary" className="px-3 h-7 rounded-full flex items-center"><Trans>Duplicate</Trans></Button>
          } */}
          </div>
        )
      },
    },
    {
      title: <div></div>,
      dataIndex: 'preview',
      key: 'preview',
      width: 50,
      render: (_: any, record: any) => {
        const space_code = cookie.load('soad_space_code')
        const owner = record.space_code === space_code
        const text = isAdmin ? (owner ? 'Edit' : 'View') : 'Edit'
        const handleFeatured = async (value: any, e: any) => {
          await axios.post(`/api/admin/space/campaign/status?campaign_code=${record.campaign_code}&status=${value ? 'featured' : 'unfeatured'}`)
          setUpdate((update) => !update)
        }
        return (
          <>
            <Dropdown
              trigger={['click']}
              overlay={
                <div className="bg-moresbg rounded-lg border border-bord px-1 py-3 space-y-1">
                  {+role === 2 && (
                    <div className="flex items-center space-x-3 hover:bg-black px-4 py-1.5 rounded-lg hover:bg-opacity-50 cursor-pointer" onClick={(e) => e.stopPropagation()}>
                      <Switch defaultChecked={record?.is_recommend} onChange={handleFeatured} />
                      <span>
                        <Trans>Featured</Trans>
                      </span>
                    </div>
                  )}
                  <div className="flex items-center space-x-3 hover:bg-black px-4 py-1.5 rounded-lg hover:bg-opacity-50 cursor-pointer">
                    <img src={text === 'Edit' ? edit_ : previewlogo} alt="" />{' '}
                    <span>
                      <Trans>{text}</Trans>
                    </span>
                  </div>

                  {owner && (
                    <div
                      onClick={async (e: any) => {
                        e.stopPropagation()
                        const res = await axios.post('/api/admin/space/campaign/copy', {
                          campaign_code: record.campaign_code,
                        })
                        const campaign_code = res.data.data.campaign_code
                        history.push(`/campaigns/setup?campaign_code=${campaign_code}`)
                        window.scrollTo(0, 0)
                      }}
                      className="flex items-center space-x-3 hover:bg-black px-4 py-1.5 rounded-lg hover:bg-opacity-50 cursor-pointer"
                    >
                      <img src={cp} alt="" />{' '}
                      <span>
                        <Trans>Duplicate</Trans>
                      </span>
                    </div>
                  )}
                  {record.status === 'draft' && owner && (
                    <div onClick={(e) => deleteCampaign(e, record.campaign_code)} className="flex items-center space-x-3 hover:bg-black px-4 py-1.5 rounded-lg hover:bg-opacity-50 cursor-pointer">
                      <img src={delete_} alt="" />{' '}
                      <span>
                        <Trans>Delete</Trans>
                      </span>
                    </div>
                  )}
                </div>
              }
            >
              <div onClick={(e) => e.stopPropagation()} className="cursor-pointer py-1 flex justify-center">
                <img src={mores} alt="" />
              </div>
            </Dropdown>
          </>
        )
      },
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }

  const getSearch = debounce((value: string) => {
    setPage(1)
    setSearchText(value)
  }, 500)

  useEffect(() => {
    getTwitterVerify()
  }, [])
  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="flex items-center flex-col md:flex-row mb-6">
          <div className="flex-1 flex items-center space-x-6 font-semibold">
            <div
              onClick={() => {
                setPage(1)
                setTab('all')
              }}
              className={`cursor-pointer ${tab === 'all' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}
            >
              <Trans>All</Trans>
            </div>
            <div
              onClick={() => {
                setPage(1)
                setTab('active')
              }}
              className={`cursor-pointer ${tab === 'active' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}
            >
              <Trans>Active</Trans>
            </div>
            <div
              onClick={() => {
                setPage(1)
                setTab('featured')
              }}
              className={`cursor-pointer ${tab === 'featured' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}
            >
              <Trans>Featured</Trans>
            </div>
            <div
              onClick={() => {
                setPage(1)
                setTab('finished')
              }}
              className={`cursor-pointer ${tab === 'finished' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}
            >
              <Trans>Finished</Trans>
            </div>
            <div
              onClick={() => {
                setPage(1)
                setTab('draft')
              }}
              className={`cursor-pointer ${tab === 'draft' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}
            >
              <Trans>Draft</Trans>
            </div>
          </div>
          <div className="relative">
            <Input
              onChange={(e: any) => getSearch(e.target.value)}
              placeholder="Search Campaign"
              className="rounded-full w-64 pl-11 text-sm h-9 bg-transparent border border-bord hover:border-ci focus:border-ci"
            />
            <div className="absolute h-full flex items-center top-0 left-4">
              <img src={search} alt="" />
            </div>
          </div>
          <div className="ml-20">
            <Button onClick={() => history.push('/campaigns/setup')} className="px-3.5 h-9 rounded-full flex items-center" type="primary">
              <div>
                <Trans>Create Campaigns</Trans>
              </div>
              <img className="ml-0.5" src={add} alt="" />
            </Button>
          </div>
        </div>
        <div className="bg-ncon rounded-lg border border-bord">
          <div className="overflow-x-scroll noTop">
            <Table
              className=""
              loading={loading as boolean}
              dataSource={list}
              columns={columns}
              rowKey={(record) => record.campaign_code}
              rowClassName={(_, index) => {
                let className = 'nrodd'
                if (index % 2 === 0) className = 'nreven'
                return className
              }}
              onRow={(record: any) => {
                return {
                  onClick: () => {
                    history.push(`/campaigns/setup?campaign_code=${record.campaign_code}`)
                    window.scrollTo(0, 0)
                  },
                }
              }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                size: 'small',
                current: page,
                pageSize: 10,
                total: total,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <CheckTwitter visible={checkTwitterOpen} onCancel={() => setCheckTwitterOpen(false)} verify={verify} getTwitterVerify={getTwitterVerify} />
    </div>
  )
}

export default Campaigns
