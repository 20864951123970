import { Button, Form, Input, Collapse, Row, Col, message, Popconfirm } from 'antd'
import icon_selected from 'assets/images/icon_selected.svg'
import reject from 'assets/images/icon_reject.svg'
import star from 'assets/images/star.svg'
import { DownOutlined } from '@ant-design/icons'
import { mediaImages } from 'config/media'
import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import axios from 'config/axios'
import { isNumber, isPositiveIntegerAndZero, titleCase } from 'utils'

const { Panel } = Collapse

const VisitWebsite = ({ setTasks_arr, task, trans_code, isOpen = false }: any) => {
  const [isChecked, setIsChecked] = useState(true)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    const submitObj = {
      platform: 'website',
      type: 'visit',
      ...fieldValues,
    }
    submitObj.mandatory = isChecked
    if (task.id) submitObj.id = task.id
    setLoading(true)
    const res = await axios.post('/api/admin/space/campaign', {
      step: 2,
      campaign_code: trans_code,
      task: JSON.stringify(submitObj),
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      const id = res.data.data.task_id
      setTasks_arr((state: any) => {
        const list = state
        for (const obj of list) {
          if (obj.uuid === task.uuid) {
            obj.id = id
            obj.title = submitObj.title
            obj.mandatory = submitObj.mandatory
            obj.url = submitObj.url
            obj.votes = submitObj.votes
            obj.action = submitObj.action
            obj.countdown = submitObj.countdown
            break
          }
        }
        const task_ids = list.filter((task: any) => task.id).map((task: any) => task.id)
        axios.post('/api/admin/space/campaign/sort', {
          campaign_code: trans_code,
          task_ids,
        })
        return [...list]
      })
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async () => {
    if (!task.id) {
      setTasks_arr((state: any) => {
        const list = state
        const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
        list.splice(index, 1)
        return [...list]
      })
      message.success('Success!')
    } else {
      const res = await axios.post('/api/admin/space/campaign/task/remove', {
        campaign_code: trans_code,
        task_id: task.id,
      })
      if (res.data.code === 0) {
        setTasks_arr((state: any) => {
          const list = state
          const index = list.indexOf(list.find((li: any) => li.uuid === task.uuid))
          list.splice(index, 1)
          return [...list]
        })
        message.success('Success!')
      } else {
        message.error(res.data.message)
      }
    }
  }

  useEffect(() => {
    setIsChecked(task.mandatory)
    form.setFieldsValue({
      url: task.url,
      votes: task.votes,
      title: task.title,
      action: task.action ? task.action : 'visit',
      countdown: task.countdown,
    })
  }, [task, form])

  return (
    <div className="relative w-fit">
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={isOpen ? [task.uuid] : []}
        expandIcon={({ isActive }) => <DownOutlined className="text-xs text-white" rotate={isActive ? 180 : 0} />}
        className={`rounded-md`}
      >
        <Panel
          header={
            <div className="w-500 flex">
              <img className="mr-2" src={mediaImages['homepage']} alt="" />
              {task.mandatory && <img className="mr-1" src={star} alt="" />}
              <div className="flex">
                <div>{titleCase(task.action ? task.action : 'visit')}</div>
                <div className="max-w-xs truncate mx-1">{task.title}</div>
                <div>
                  {titleCase(task.platform)} <span className="font-bold">+{task.votes}</span>
                </div>
              </div>
            </div>
          }
          key={task.uuid}
        >
          {
            <div className="w-500">
              <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Action:"
                      name="action"
                      rules={[
                        {
                          required: true,
                          message: 'Action is required!',
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Title:"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'Title is required!',
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={16} key={1}>
                    <Form.Item
                      label="Website URL:"
                      name="url"
                      rules={[
                        {
                          required: true,
                          message: 'Website url is required!',
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                  <Col span={8} key={2}>
                    <Form.Item
                      label="Countdown:"
                      name="countdown"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && !isPositiveIntegerAndZero(value)) {
                              return Promise.reject('Please enter positive integer or zero!')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input suffix={<div className="text-white text-opacity-60">s</div>} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                  <Col span={16} key={3}>
                    <Form.Item
                      label="Worth # Entries:"
                      name="votes"
                      rules={[
                        {
                          required: true,
                          message: 'Worth is required!',
                        },
                        {
                          validator: (_, value) => {
                            if (value && !isNumber(value)) {
                              return Promise.reject('Worth can only be numbers!')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                    </Form.Item>
                  </Col>
                  <Col span={8} key={4}>
                    <Form.Item>
                      <div className="flex items-center space-x-2 pt-9">
                        {!isChecked && <div onClick={() => setIsChecked(true)} className="border border-ci w-4 h-4 rounded-full cursor-pointer"></div>}
                        {isChecked && (
                          <div onClick={() => setIsChecked(false)} className="cursor-pointer">
                            <img className="rounded-full" src={icon_selected} alt="" />
                          </div>
                        )}
                        <div className="text-xs">
                          <Trans>Mandatory</Trans>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-8" type="primary">
                  <Trans>Save</Trans>
                </Button>
              </Form>
            </div>
          }
        </Panel>
      </Collapse>
      <Popconfirm onConfirm={() => confirmFun()} title="Are you sure delete this task?" okText="Yes" cancelText="No">
        <img className="absolute top-1.5 -right-8 h-5 cursor-pointer" src={reject} alt="" />
      </Popconfirm>
    </div>
  )
}

export default VisitWebsite
