import { useEffect, useState } from "react"
import axios from "config/axios"
import { formatTime } from "utils"

const useLines = (selectedTime: string) => {
  const [data, setData] = useState<any>({
    communities: [],
    members: [],
    contributors: [],
    social_index: [],
    sentiment: [],
    trading_volme: [],
    spam_makers: [],
    wallet_info: [],
    campaign_info: [],
  })
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/stats/chart?date=${selectedTime}`)
      for (const value of Object.values(res.data.data)) {
        for (const obj of value as any) {
          const fullTime = formatTime(selectedTime, obj.date, true)
          obj.date = formatTime(selectedTime, obj.date)
          obj.fullTime = fullTime
          if (obj.type === 'Members' || obj.type === 'Sentiment' || obj.type === 'Spam Makers') obj.count = obj.value
          if (obj.type === 'Spam Makers') obj.type = 'Spammer'
        }
      }
      setLoading(false)
      setData(res.data.data)
    } catch (error) {
      console.error("useLines --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [selectedTime])
  return [data, loading]
}

export default useLines
