import { useEffect, useState } from "react"
import axios from "config/axios"
import { RootState } from "state"
import { useSelector } from "react-redux"

const useTokenList = (update?: boolean) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const space_update = useSelector((state: RootState) => state.space.space_code)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/giveaway/token/type`)
      setLoading(false)
      if (res.data.code === 0) {
        setData(res.data.data.data)
      }
    } catch (error) {
      console.error("useTokenList --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [space_update, update])
  return [data, loading]
}

export default useTokenList
