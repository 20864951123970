import { Trans } from '@lingui/macro'
import { useRequest } from 'ahooks'
import { Table, Typography, Image, Button, Modal, Form, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { shortenSystemAPIKey } from 'utils'
import { Input } from 'antd'
const { Paragraph } = Typography
const { TextArea } = Input
// const bug_type = {
//   bug_report: 'Bug Report',
//   suggestion: 'Product Suggestion',
//   business_coop: 'Business Cooperation',
//   scam_report: 'Scam report',
// }
const bugName = (type: any) => {
  switch (type) {
    case 'bug_report':
      return 'Bug Report'
    case 'suggestion':
      return 'Product Suggestion'
    case 'business_coop':
      return 'Business Cooperation'
    case 'scam_report':
      return 'Scam report'
  }
}
const Feedback: React.FC = () => {
  const [page, setPage] = useState({ page: 1, pagesize: 10 })
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState<any>({})
  const [update, setUpdate] = useState(false)
  const { data, loading } = useRequest(() => axios.get(`/api/admin/comm/user/feedback?page=${page.page}&pagesize=${page.pagesize}`), {
    refreshDeps: [page.page, update],
  })

  const handleTableChange = (pagination: any) => {
    setPage({ ...page, page: pagination.current })
  }
  const columns: any = [
    {
      title: <div className="pl-2">#</div>,
      dataIndex: '',
      key: 'key',
      width: 30,
      render: (_cur: any, _record: any, index: number) => <div className="pl-2">{index + 1 + (page.page - 1) * page.pagesize}</div>,
    },
    {
      title: (
        <div>
          <Trans>address</Trans>
        </div>
      ),
      dataIndex: 'address',
      key: 'address',
      width: 140,
      render: (cur: string) => (
        <div>
          <Paragraph copyable={{ text: cur }}>{shortenSystemAPIKey(cur, 6)}</Paragraph>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Type</Trans>
        </div>
      ),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (cur: string) => <div>{bugName(cur)}</div>,
    },
    {
      title: (
        <div>
          <Trans>Time</Trans>
        </div>
      ),
      dataIndex: 'create_time',
      key: 'create_time',
      width: 140,
      render: (cur: number) => <div>{moment(cur * 1000).format('YYYY-MM-DD HH:mm')}</div>,
    },
    {
      title: (
        <div>
          <Trans>Status</Trans>
        </div>
      ),
      dataIndex: 'reply',
      key: 'reply',
      width: 140,
      render: (cur: any, _record: any) => (
        <div
          className={`${cur ? 'bg-valid bg-opacity-20 text-valid' : 'bg-pend bg-opacity-20 text-pend'} rounded-full text-xs w-fit flex justify-center px-3 py-1`}
          onClick={() => {
            !cur && setCurrent({ ..._record })
            !cur && setOpen(true)
          }}
        >
          {cur ? 'Replied' : 'Reply'}
        </div>
      ),
    },
  ]
  const onFinish = async (values: any) => {
    const params = {
      ...values,
      id: current?.id,
    }
    const result = await axios.post('/api/admin/comm/user/feedback', params)
    if (+result?.data?.code === 0) {
      setUpdate((update) => !update)
      setOpen(false)
      message.success('reply success!')
    } else message.error(result?.data?.message || 'reply error')
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="bg-ncon rounded-lg border border-bord">
          <div className="flex items-center px-6">
            <div className="flex-1 py-4 text-base flex items-center space-x-4">
              <div>
                <Trans>Feedback</Trans>
              </div>
            </div>
          </div>
          <div className="overflow-x-scroll">
            <Table
              className=""
              loading={loading as boolean}
              dataSource={data?.data.data.data}
              columns={columns}
              rowKey={(record) => record.campaign_code}
              rowClassName={(_, index) => {
                let className = 'nrodd'
                if (index % 2 === 0) className = 'nreven'
                return className
              }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                size: 'small',
                current: page.page,
                pageSize: page.pagesize,
                total: data?.data.data.total,
              }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => {
                  setCurrent({ ...record })
                  setOpen(true)
                },
              })}
            />
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title="Reply"
        width={418}
        visible={open}
        onCancel={() => {
          setOpen(false)
        }}
        footer={null}
        centered
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-3">
            <span className=" opacity-40 text-xs">Address: </span>
            <div className="flex gap-2">{current?.address}</div>
          </div>

          <div className="flex flex-col gap-3">
            <span className=" opacity-40 text-xs">Create Time: </span>
            <div className="flex gap-2">{moment(current?.create_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
          </div>

          <div className="flex flex-col gap-3">
            <span className=" opacity-40 text-xs">Feedback Type: </span>
            <div className="flex gap-2">{}</div>
          </div>

          <div className="flex flex-col gap-3">
            <span className=" opacity-40 text-xs">Descriptions: </span>
            <div className="flex gap-2">{current?.content}</div>
          </div>

          <div className="flex flex-col gap-3">
            <span className=" opacity-40 text-xs">Images: </span>
            <div className="flex gap-2">
              <Image.PreviewGroup>
                {current?.image_urls?.map((img: string | undefined) => (
                  <Image key={img} width={80} height={80} src={img} className=" object-cover" />
                ))}
              </Image.PreviewGroup>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <span className=" opacity-40 text-xs">Replay: </span>
            {!!current.reply ? (
              <div className="flex gap-2 mb-6">{current?.reply}</div>
            ) : (
              <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                <Form.Item name="reply" rules={[{ required: true, message: 'Please input your reply!' }]}>
                  <TextArea placeholder="reply ..." rows={4} allowClear />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Feedback
