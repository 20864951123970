import { Table, Tooltip } from 'antd'
import { Trans } from "@lingui/macro"
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'
import { ReactComponent as Cart } from 'assets/images/add.svg'
import { useState } from 'react'
import useNewProjects from './hooks/useNewProjects'
import { formatNum, formatTimeAgo } from 'utils'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

const NewProjects = () => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [{ list, total }, loading] = useNewProjects(page)

  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 50,
      render: (_cur: any, _record: any, index: number) => (<div>{(index + 1)+(page-1)*8}</div>)
    },
    {
      title: <div><Trans>Symbol (Name)</Trans></div>,
      dataIndex: 'symbol',
      key: 'symbol',
      width: 120,
      ellipsis: true,
      render: (cur: any, record: any) => (
        <div className="flex items-center">
          <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />
          <Tooltip placement="topLeft" title={`${cur} (${record.name})`}><div className='truncate'>{cur} ({record.name})</div></Tooltip>
        </div>
      )
    },
    {
      title: <div><Trans>Price/Volatility (24H)</Trans></div>,
      dataIndex: 'price',
      key: 'price',
      width: 180,
      render: (cur: any, record: any) => {
        const differ = record.price - record.yd_price
        const isUp = differ >= 0 ? true : false
        return (
          <div className='flex flex-wrap'>
            <div>{`$${formatNum(cur, 2, true)} `}</div>
            <div>({isUp && <CaretUpOutlined className={`mr-0.5 text-xs ${isUp ? 'text-green-500' : 'text-red-500'}`} />}
            {!isUp && <CaretDownOutlined className={`mr-0.5 text-xs ${isUp ? 'text-green-500' : 'text-red-500'}`} />}
              <span className={`${isUp ? 'text-green-500' : 'text-red-500'}`}>{formatNum(Math.abs(record.price ? (differ / record.price * 100) : 0))}%</span>)
            </div>
          </div>
        )
      }
    },
    {
      title: <div><Trans>Add Time</Trans></div>,
      dataIndex: 'add_time',
      key: 'add_time',
      width: 140,
      render: (cur: any) => (
        <div>{formatTimeAgo(cur, true)}</div>
      )
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded px-4 pt-4 pb-0">
        <div className="text-lg font-medium pl-3 flex items-center"><Cart className='w-6 h-6 mr-2' /><Trans>Recently Added Projects</Trans></div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
            rowKey={(record) => record.name}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 8,
              total: total > 40 ? 40 : total,
              // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  const arr = [{
                    project_code: record.project_code,
                    logo_url: record.logo_url,
                    name: record.name,
                    symbol: record.symbol,
                  }]
                  cookie.save('soad_project', arr, { path: '/' })
                  history.push(`/project`)
                  window.scrollTo(0, 0)
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default NewProjects
