import { useEffect, useState } from "react"
import axios from "config/axios"
import { formatTime } from "utils"

const useSentiment = (date: string, code: string) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/project/sentiment/chart?code=${code}&date=${date}&type=sentiment`)
      for (const obj of res.data.data) {
        const time = formatTime(date, obj.date)
        const fullTime = formatTime(date, obj.date, true)
        obj.date = time
        obj.fullTime = fullTime
      }
      setLoading(false)
      setData(res.data.data)
    } catch (error) {
      console.error("useSentiment --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [date, code])
  return [data, loading]
}

export default useSentiment
