import { Button, message, Modal } from 'antd'
import React, { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import un_check from '../../../assets/images/un_check.svg'
import checked from '../../../assets/images/checked.svg'
import Twitter from '../../../assets/images/twitter.svg'
import IconArrow from '../../../assets/images/icon_arrow.svg'
import axios from 'axios'
import cookie from 'react-cookies'
import { useCountDown } from 'ahooks'

const CheckTwitter: React.FC<{ visible: boolean; onCancel: any; getTwitterVerify?: any; verify?: any }> = ({ visible, onCancel, verify, getTwitterVerify }) => {
  const [load, setLoad] = useState(false)
  const [targetDate, setTargetDate] = useState<number>()
  const [clicked, setClicked] = useState(false)
  const { is_verify, url, twitter_code } = verify

  //   bind
  const twi_oauth = async () => {
    const data = (await axios.post('/api/admin/oauth/twitter/token')) as any
    const resObj = data.data.data
    if (!Object.keys(resObj).length) {
      return message.error(data.message)
    }
    const url = `${resObj.authorize_url}?oauth_token=${resObj.oauth_token}`

    const popup = window.open(url, '_blank', 'left=500,top=140,width=400,height=500')
    function checkClose() {
      if (!popup || popup.closed) {
        return axios.post('/api/admin/oauth/indentity/report', { platform: 'twitter' }).then((res: any) => {
          if (res?.data?.code !== 0) {
            message.error(res?.data?.message || 'bind failed')
          } else {
            getTwitterVerify()
          }
        })
      }
      setTimeout(checkClose, 100)
    }
    if (popup) checkClose()
  }
  //   unbind
  const changeStatus = async () => {
    await axios.post('/api/admin/oauth/twitter/remove')
    getTwitterVerify()
  }

  const handleVerify = async () => {
    setLoad(true)
    const res = await axios.post('/api/admin/oauth/twitter/verify')
    if (res?.data?.code === 0) {
      setLoad(false)
      getTwitterVerify()
    } else {
      setLoad(false)
      message.error(res?.data?.message || 'verify failed')
    }
  }

  const handleNavTwitter = () => {
    twitter_code && window.open(url, '_blank')
    setClicked(true)
    setTargetDate(Date.now() + 10 * 1000)
  }

  const [countdown] = useCountDown({
    targetDate,
  })

  const space_code = cookie.load('soad_space_code')

  return (
    <Modal width={550} title="Verify Offcial Identity" visible={visible} onCancel={onCancel} footer={null} destroyOnClose>
      {visible && is_verify && twitter_code ? (
        <div>
          <h3 className="text-fff60 mb-10">Your space identity has been verified successfully and now you can publish your campaigns.</h3>
          <a className="flex justify-between items-center px-5 py-6 bg-tbg/20 rounded-lg" href="https://t.me/Bellamar22" target={'_blank'} rel="noreferrer">
            <div>
              <h3 className="mb-2" style={{ color: '#ffffff95' }}>
                Tips: Contact us to get more exposure
              </h3>
              <h3 className="text-fff60">Further verification with us will get you better exposure.</h3>
            </div>
            <img src={IconArrow} alt="" />
          </a>
        </div>
      ) : (
        <div>
          <h3 className="text-fff60 mb-10">In order to protect our users from phishing traps, please verify your official identity before publishing campaigns.</h3>
          <div className="flex flex-col gap-7 mb-4">
            {/* step 1 */}
            <div className="flex items-center">
              <img src={!!twitter_code ? checked : un_check} width={24} height={24} alt="" />
              <h3 className="h-4 ml-3 text-fff80" style={{ color: '#ffffff95' }}>
                Connect Twitter account
              </h3>
              {!!twitter_code ? (
                <Button className="flex items-center ml-auto bg-tab/40 border-none hover:text-white focus:text-white" onClick={changeStatus}>
                  <img src={Twitter} className="mr-1" width={24} height={24} alt="" /> Connected <CloseOutlined />
                </Button>
              ) : (
                <Button className="ml-auto" type="primary" onClick={twi_oauth}>
                  Connect
                </Button>
              )}
            </div>
            <div style={{ transform: 'translateX(12px)' }} className="border-fff40 border-l h-6"></div>
            {/* step 2 */}
            <div className={`flex items-center ${twitter_code ? '' : 'opacity-50'}`}>
              <img src={is_verify ? checked : un_check} width={24} height={24} alt="" />
              <h3 className="h-4 ml-3" style={{ color: '#ffffff95' }}>
                Publish Tweet to verify
              </h3>
              {!is_verify && twitter_code && (
                <>
                  {!clicked ? (
                    <Button className="flex items-center ml-auto bg-tab/40 border-none hover:text-white focus:text-white" onClick={handleNavTwitter}>
                      Tweet
                    </Button>
                  ) : (
                    <Button disabled={!!countdown} className="ml-auto flex items-center" type="primary" onClick={handleVerify}>
                      <span>{load ? 'Verifying...' : 'Verify'}</span>
                      {!!countdown && <div className="pl-1">({Math.round(countdown / 1000)}s)</div>}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          <a className={`text-fff60 ${twitter_code ? '' : 'opacity-50'}`} href={`https://soquest.xyz/space/${space_code}`} target="_blank" rel="noreferrer">
            We are creating a space on SoQuest @Port3Network. Visit our space here: https://soquest.xyz/space/{space_code}
          </a>
        </div>
      )}
    </Modal>
  )
}

export default CheckTwitter
