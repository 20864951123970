import { Trans } from '@lingui/macro'
import { useHistory, useLocation } from 'react-router-dom'
import arrow_left from 'assets/images/icon_arrow_left.svg'
import { Button, Form, Input, message, Progress, Select, Upload, Spin } from 'antd'
import { nftDeployBlockchain } from 'config/blockchain'
import { beforeUpload, getfilesize } from 'utils'
import axios, { api_key, secret_key, uploadUrl } from 'config/axios'
import { sha256 } from 'js-sha256'
import cookie from 'react-cookies'
import { useEffect, useState } from 'react'
import icon_upload from 'assets/images/uploadnft.svg'
import icon_del from 'assets/images/delete.svg'
import icon_replace from 'assets/images/replace.svg'
import icon_note2 from 'assets/images/icon_note2.svg'
import note from 'assets/images/icon_note.svg'
import transfer from 'assets/images/transfer.svg'
import non_transfer from 'assets/images/non-transfer.svg'
import random from 'lodash/random'
import { LoadingOutlined } from '@ant-design/icons'
import UploadImg from 'components/UploadImg'
import TextArea from 'antd/lib/input/TextArea'
// import ImgCrop from 'antd-img-crop'
const { Option } = Select

const DeployNFT = () => {
  const history = useHistory()
  const { state }: { state: any } = useLocation()
  // 标记blockchain的选择替换form label
  const [aptos, setAptos] = useState(false)

  const [form] = Form.useForm()
  const signed = cookie.load('soad_signed')
  const account = cookie.load('soad_acc')
  const space_code = cookie.load('soad_space_code')
  const [draftLoad, setDraftLOad] = useState(false)
  const [deployLoad, setDeployLOad] = useState(false)
  const [loadImg, setLoadImg] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const [fileObj, setFileObj] = useState({
    size: '',
    name: '',
  })
  const [percent, setPercent] = useState(0)
  const [transferable, setTransferable] = useState(false)
  const timestamp = parseInt(new Date().getTime() / 1000 + '')
  const sha = sha256(api_key + secret_key + timestamp)
  //多nft切换
  const [imgSelect, setSelect] = useState('')
  //文件列表
  const [fileList, setFileList] = useState<any[]>([])
  const handleChange = (info: any) => {
    const { event } = info
    if (event) setPercent(event.percent - random(1, 20))
    if (info.file.status === 'uploading') {
      setLoadImg(true)
      return
    }
    if (info.file.status === 'done' && +info.file.response?.code === 0) {
      setFileObj({
        size: getfilesize(info.file.size),
        name: info.file.name,
      })
      setPercent(100)
      setTimeout(() => {
        setLoadImg(false)
        const arr = info.fileList
        if (arr[arr.length - 1].response.code === 0) setImageUrl(arr[arr.length - 1].response.data)
        setPercent(0)
      }, 800)
    } else message.error(info?.response?.message || 'Upload failed')
  }
  let deploy_id = ''
  const saveFun = async (e: any) => {
    e.preventDefault()
    const fieldValues = await form.validateFields()
    const arg: any = {
      ...fieldValues,
    }
    if (state?.id) {
      arg.id = state.id
    } else {
      if (deploy_id) arg.id = deploy_id
    }
    arg.is_transferable = transferable
    arg.image_type = imgSelect
    if (imgSelect === 'only') {
      if (!imageUrl) {
        message.error('Please upload your nft image')
        return
      }
      arg.image_urls = [imageUrl]
    } else {
      arg.image_urls = []
      arg.image_names = []
      for (let index = 0; index < fileList.length; index++) {
        arg.image_urls[index] = fileList[index].nftUrl
        arg.image_names[index] = fieldValues[fileList[index].formName]
      }
    }
    setDraftLOad(true)

    const res = await axios.post('/api/admin/giveaway/nft', arg)
    setDraftLOad(false)
    if (res.data.code === 0) {
      message.success('success!')
      history.push('/rewards')
    } else {
      message.error(res.data.message)
    }
  }

  const deployFinish = async (fieldValues: any) => {
    const arg: any = {
      ...fieldValues,
    }
    if (state?.id) {
      arg.id = state.id
    } else {
      if (deploy_id) arg.id = deploy_id
    }
    arg.is_transferable = transferable
    arg.image_type = imgSelect
    if (imgSelect === 'only') {
      if (!imageUrl) {
        message.error('Please upload your nft image')
        return
      }
      arg.image_urls = [imageUrl]
    } else {
      arg.image_urls = []
      arg.image_names = []
      for (let index = 0; index < fileList.length; index++) {
        arg.image_urls[index] = fileList[index].nftUrl
        arg.image_names[index] = fieldValues[fileList[index].formName]
      }
    }

    setDeployLOad(true)
    const res = await axios.post('/api/admin/giveaway/nft', arg)
    if (res.data.code === 0) {
      const resp = await axios.post(`/api/admin/giveaway/nft/${res.data.data.id}/deploy`)
      setDeployLOad(false)
      if (resp.data.code === 0) {
        message.success('success!')
        history.push('/rewards')
      } else {
        message.error(resp.data.message)
        deploy_id = res.data.data.id
      }
    } else {
      message.error(res.data.message)
      setDeployLOad(false)
    }
  }

  const handleChangeBolckchain = (value: any) => {
    if (value.includes('aptos')) {
      setAptos(true)
    } else setAptos(false)
  }

  useEffect(() => {
    if (state) {
      // 因为后端返回没有标志的数组格式，所以在这了要进行带key：value形势供表单识别
      let formNfts: any = {}
      state.image_names &&
        state.image_names?.forEach((element: any, index: any) => {
          formNfts[`nfts${index}`] = element
        })
      form.setFieldsValue({
        ...state,
        ...formNfts,
      })
      setAptos(state?.blockchain?.includes('aptos'))
      setTransferable(state.is_transferable)
      setImageUrl(state.image_url)
      setSelect(state.image_type)
      state.image_urls &&
        !!state.image_urls?.length &&
        setFileList((preState) => {
          let arr: any = []
          for (let index = 0; index < state.image_urls.length; index++) {
            let obj = {
              nftUrl: '',
              formName: '',
            }
            obj.nftUrl = state.image_urls[index]
            obj.formName = 'nfts' + index
            arr[index] = obj
          }
          return [...arr]
        })
    }
  }, [state, form])

  const isDeployed = state?.is_draft === false
  // nft盲盒imgType
  const handleNftType = (value: string) => {
    setSelect(value)
  }
  // 保存多张nft
  const onChange = (info: any, index?: number, order?: string) => {
    if (info.file.status === 'uploading') {
      setLoadImg(true)
      return
    }
    if (info.file.status === 'done') {
      setLoadImg(false)
      if (+info.file.response?.code === 0 && info.file.response?.data) {
        let obj = {
          ...info.file,
          formName: 'ntfs' + fileList.length,
          nftUrl: info.file.response.data,
        }
        //替换
        if (order === 'replace' && (index === 0 || !!index)) {
          setFileList((preState) => {
            fileList[index].nftUrl = info.file.response.data
            return [...fileList]
          })
        } else setFileList([...fileList, obj])
      } else message.error(info?.response?.message || 'Upload failed')
    }
  }
  // 删除当前nft
  const handleDel = (idx: number) => {
    if (idx === 0 && fileList.length === 1) {
      setFileList([])
    } else {
      fileList.splice(idx, 1)
      setFileList([...fileList])
    }
  }
  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl space-y-6">
        <div className="flex space-x-3">
          <img
            className="cursor-pointer"
            onClick={() => {
              history.push('/rewards')
            }}
            src={arrow_left}
            alt=""
          />
          <div className="text-base font-medium">
            <Trans>Deploy NFT Badge</Trans>
          </div>
        </div>
        <div className="bg-ncon rounded-lg px-5 py-6 border border-bord deploy-nft">
          <Form name="basic" form={form} layout="vertical" className="w-3/5" onFinish={deployFinish} autoComplete="off">
            <Form.Item
              label="Blockchain:"
              name="blockchain"
              rules={[
                {
                  required: true,
                  message: 'Please select your blockchain!',
                },
              ]}
            >
              <Select disabled={isDeployed ? true : false} showArrow={true} dropdownClassName="border border-bord" onChange={handleChangeBolckchain}>
                {Object.entries(nftDeployBlockchain).map(([sname, name]: any, index: number) => (
                  <Option key={index} value={sname}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={aptos ? 'Collection Name:' : 'Name:'}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your name!',
                },
              ]}
            >
              <Input disabled={isDeployed ? true : false} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
            </Form.Item>
            {aptos && (
              <Form.Item
                label="Collection Description"
                name="collection_description"
                rules={[
                  {
                    required: true,
                    message: 'Collection Description enter your symbol!',
                  },
                ]}
              >
                <TextArea disabled={isDeployed ? true : false} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
              </Form.Item>
            )}
            <Form.Item
              label={aptos ? 'Token Name:' : 'Symbol:'}
              name="symbol"
              rules={[
                {
                  required: true,
                  message: 'Please enter your symbol!',
                },
              ]}
            >
              <Input disabled={isDeployed ? true : false} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
            </Form.Item>
            <Form.Item
              label="NFT Type:"
              name="image_type"
              rules={[
                {
                  required: true,
                  message: 'Please select your NFT type!',
                },
              ]}
            >
              <Select disabled={isDeployed ? true : false} showArrow={true} onChange={handleNftType} dropdownClassName="border border-bord">
                <Option value="only">General Badge</Option>
                {!aptos && <Option value="many">NFT Blind Box</Option>}
                {/* <Option className='text-white text-opacity-50' value="many" disabled>One Image for one NFTs(not supported yet)</Option> */}
              </Select>
            </Form.Item>
            {!aptos && (
              <div className="flex items-center space-x-3">
                <span>Transferable</span>
                {transferable ? (
                  <img className={`${isDeployed ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => setTransferable((state: boolean) => !state)} src={transfer} alt="" />
                ) : (
                  <img
                    className={`${isDeployed ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'}`}
                    onClick={() => setTransferable((state: boolean) => !state)}
                    src={non_transfer}
                    alt=""
                  />
                )}
              </div>
            )}
            {imgSelect === 'only' && (
              <div className="mt-8 relative">
                {/* <div className="star_req mb-2">
                  <Trans>BAYC NFT collection</Trans>
                </div> */}
                {/* <ImgCrop rotate> */}
                <UploadImg aspect={1 / 1}>
                  <Upload
                    disabled={isDeployed ? true : false}
                    name="file"
                    headers={{
                      APIKEY: api_key,
                      TIME: timestamp as any,
                      SIGN: sha,
                      signature: signed,
                      address: account + '',
                      'Space-Code': space_code,
                    }}
                    listType="picture-card"
                    className="avatar-uploader z-30"
                    showUploadList={false}
                    action={`${uploadUrl}/api/upload/nft`}
                    beforeUpload={(file) => beforeUpload(file, 5, 1, 1)}
                    onChange={handleChange}
                  >
                    <div>
                      {!loadImg && (
                        <div>
                          <div className="flex justify-center">
                            <img src={icon_upload} alt="" />
                          </div>
                          <div>
                            <Trans>Drag or click to upload</Trans>
                          </div>
                        </div>
                      )}
                    </div>
                  </Upload>
                </UploadImg>
                {/* </ImgCrop> */}
                {loadImg && (
                  <div className="absolute top-28 left-24 w-2/3">
                    <Progress strokeColor="#7754F8" percent={percent} showInfo={false} status="active" />
                  </div>
                )}
                <div className="flex items-center opacity-50 space-x-2">
                  <img src={note} alt="" />
                  <span>PNG, JPG, GIF, 1:1, Max 5MB</span>
                </div>
                {imageUrl && (
                  <div className="mt-4 flex space-x-4">
                    <img className="max-w-20 max-h-20 rounded" src={imageUrl} alt="avatar" />
                    <div className="space-y-1">
                      <div>{fileObj.name}</div>
                      <div>{fileObj.size}</div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {imgSelect === 'many' && (
              <div className="mt-8 relative w-full">
                {/* <div className="star_req mb-2">
                  <Trans>BAYC NFT collection</Trans>
                </div> */}
                <div className="grid  md:grid-cols-3 gap-5 md:gap-10 pt-3">
                  {fileList.map((item, index) => (
                    <div className="mb-4 bg-ncon border border-mline p-2 pb-4 rounded-2xl h-326" key={item.formName}>
                      <img src={item.nftUrl} alt={item.name} className=" border rounded-2xl mb-2 h-174 w-full object-cover" />
                      <Form.Item
                        key={item.nftUrl}
                        name={item.formName}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your nft name!',
                          },
                        ]}
                      >
                        <Input
                          disabled={isDeployed ? true : false}
                          placeholder="Input NFT Title"
                          style={{ background: 'rgba(0,19,33,0.5)' }}
                          className=" bg-nibg border-bord hover:border-ci focus:border-ci border h-10 rounded-lg"
                        />
                      </Form.Item>
                      {!isDeployed && (
                        <div className="flex justify-between mt-4" style={{ padding: '0 0 0 11px' }}>
                          {/* <ImgCrop rotate> */}
                          <UploadImg aspect={1 / 1}>
                            <Upload
                              headers={{
                                APIKEY: api_key,
                                TIME: timestamp as any,
                                SIGN: sha,
                                signature: signed,
                                address: account + '',
                                'Space-Code': space_code,
                              }}
                              action={`${uploadUrl}/api/upload/nft`}
                              onChange={(file) => onChange(file, index, 'replace')}
                              showUploadList={false}
                              beforeUpload={(file) => beforeUpload(file, 5, 1, 1)}
                            >
                              <span className="text-fff60 cursor-pointer flex ">
                                Replace
                                <img className="ml-1" src={icon_replace} alt="replace" />
                              </span>
                            </Upload>
                          </UploadImg>
                          {/* </ImgCrop> */}
                          <span className="text-fff60 cursor-pointer flex" onClick={() => handleDel(index)}>
                            Delete
                            <img className="ml-1" src={icon_del} alt="delete" />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                  {!isDeployed && (
                    // <ImgCrop rotate>
                    <UploadImg aspect={1 / 1}>
                      <Upload
                        headers={{
                          APIKEY: api_key,
                          TIME: timestamp as any,
                          SIGN: sha,
                          signature: signed,
                          address: account + '',
                          'Space-Code': space_code,
                        }}
                        className="bg-nibg"
                        action={`${uploadUrl}/api/upload/nft`}
                        onChange={(file) => onChange(file)}
                        showUploadList={false}
                        beforeUpload={(file) => beforeUpload(file, 5, 1, 1, fileList, 10)}
                      >
                        <div className="border  border-mline p-2 pb-4 rounded-2xl h-326">
                          <div className="h-174 w-full border border-dashed border-mline rounded-2xl flex flex-col justify-center items-center" style={{ background: 'rgba(0,19,33,0.5)' }}>
                            {loadImg ? (
                              <Spin indicator={<LoadingOutlined style={{ fontSize: 34 }} spin />} />
                            ) : (
                              <>
                                <div className="flex justify-center">
                                  <img src={icon_upload} alt="" />
                                </div>
                                <div>
                                  <span className="text-fff60">Drag or click to upload</span>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="flex flex-wrap opacity-50 space-x-2 mt-4" style={{ color: '#ffffff30' }}>
                            <img src={icon_note2} alt="" />
                            <span>PNG, JPG, GIF</span>
                            <span>, 1:1</span>
                            <span>, Max 5MB</span>
                          </div>
                        </div>
                      </Upload>
                    </UploadImg>
                    // </ImgCrop>
                  )}
                </div>
              </div>
            )}
            {!isDeployed && (
              <div className="flex space-x-6 mt-4">
                <Button loading={draftLoad} onClick={saveFun} size="large" className="mt-6 rounded w-full bg-sendbg hover:bg-ci hover:bg-opacity-40 border-none" type="primary">
                  <Trans>Save as draft</Trans>
                </Button>
                <Button loading={deployLoad} htmlType="submit" size="large" className="mt-6 rounded w-full" type="primary">
                  <Trans>Deploy</Trans>
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default DeployNFT
