import { Trans } from "@lingui/macro"
import { message, Select, Table, Tooltip } from "antd"
import { useState } from "react"
import { formatNum, getUuiD, shortenAPIKey } from "utils"
import { CaretDownOutlined } from '@ant-design/icons'
import useAPIKeyList from "./hooks/useAPIKeyList"
import axios from "axios"

const { Option } = Select

const APIKey = () => {

  const [page, setPage] = useState(1)
  const [update, setUpdate] = useState(false)
  const [{ list, total }, loading] = useAPIKeyList(page, 10, update)

  const columns: any = [
    {
      title: <div className="pl-2">#</div>,
      dataIndex: '',
      key: 'key',
      width: 70,
      render: (_cur: any, _record: any, index: number) => (<div className="pl-2">{(index + 1) + (page - 1) * 10}</div>)
    },
    {
      title: <div><Trans>Owner</Trans></div>,
      dataIndex: 'space_name',
      key: 'space_name',
      width: 140,
      ellipsis: true
    },
    {
      title: <div><Trans>API Key</Trans></div>,
      dataIndex: 'api_key',
      key: 'api_key',
      width: 160,
      render: (cur: string) => (
        <Tooltip overlay={cur}><div>{shortenAPIKey(cur, 6)}</div></Tooltip>
      )
    },
    {
      title: <div><Trans>Usage</Trans></div>,
      dataIndex: 'usage',
      key: 'usage',
      width: 100,
      render: (cur: any) => (
        <div>{formatNum(cur, 0, true)}</div>
      )
    },
    {
      title: <div><Trans>Credit Left</Trans></div>,
      dataIndex: 'credit',
      key: 'credit',
      width: 100,
      render: (cur: any, record: any) => (
        <div>{formatNum(cur - record.usage, 0, true)}</div>
      )
    },
    {
      title: <div><Trans>Status</Trans></div>,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (cur: any, record: any, index: number) => {
        return (
          <div className="rmbor">
            <Select key={getUuiD(index)} onSelect={(value: any) => onSelectStatus(value, record.id)} suffixIcon={<CaretDownOutlined className={`${cur === 'valid' ? 'text-valid' : 'text-red-500'}`} />} dropdownMatchSelectWidth={60} className={`${cur === 'valid' ? 'text-valid bg-valid bg-opacity-20' : 'text-red-500 bg-red-500 bg-opacity-20'} rounded-full text-xs`} defaultValue={cur}>
              <Option className='flex items-center text-xs' value='valid'><Trans>Valid</Trans></Option>
              <Option className='flex items-center text-xs' value='invalid'><Trans>Invalid</Trans></Option>
            </Select>
          </div>
        )
      }
    },
  ]

  const onSelectStatus = async (value: any, id: any) => {
    const res = await axios.post('/api/admin/space/apikey/manage', {
      id,
      status: value,
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }
  
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="bg-ncon rounded-lg border border-bord">
        <div className="flex items-center px-6">
            <div className="flex-1 py-4 text-base flex items-center space-x-4">
              <div><Trans>API Key</Trans></div>
            </div>
          </div>
          <div className="overflow-x-scroll">
            <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
              rowKey={(record) => record.campaign_code}
              rowClassName={(_, index) => {
                let className = 'nrodd'
                if (index % 2 === 0) className = 'nreven'
                return className
              }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                size: 'small',
                current: page,
                pageSize: 10,
                total: total,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default APIKey
