import { Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import NFTList from "./NFTList"
import TokenGiveaway from "./TokenGiveaway"

const Rewards = () => {
  const [tab, setTab] = useState(2)
  let location = useLocation()
  const { state }: { state: any } = location

  useEffect(() => {
    if (state?.tab) {
      setTab(1)
      // history.replace({
      //   ...location,
      //   state: undefined
      // })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="flex-1 flex items-center space-x-6 font-semibold mb-8">
          <div onClick={() => setTab(1)} className={`cursor-pointer text-base ${tab === 1 ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}><Trans>Token Reward</Trans></div>
          <div onClick={() => setTab(2)} className={`cursor-pointer text-base ${tab === 2 ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}><Trans>NFT Reward</Trans></div>
        </div>
      </div>
      {tab === 1 && <TokenGiveaway />}
      {tab === 2 && <NFTList />}
    </div>
  )
}

export default Rewards