import { useWeb3React } from "@web3-react/core"
import { zeroAddress } from "config/Address"
import { useEffect, useState } from "react"
import { formatBigNumber } from "utils"
import { useTokenContract } from "./useContract"

function useAllowance(tokenAddress: string, vaultAddress: string, update?: boolean) {
  const { account, chainId } = useWeb3React()
  const [allowance, setAllowance] = useState(0)
  const tokenContract = useTokenContract(tokenAddress)

  const fetchAllowance = async () => {
    try {
      const value = await tokenContract?.allowance(account, vaultAddress)
      const decimals = await tokenContract?.decimals()
      const formatBalance = Number(formatBigNumber(value, decimals))
      setAllowance(formatBalance)
    } catch (error) {
      console.error("useAllowance --> " + error)
    }
  }

  useEffect(() => {
    if (account && vaultAddress && (tokenAddress && tokenAddress !== zeroAddress)) fetchAllowance()
    // eslint-disable-next-line
  }, [account, chainId, tokenAddress, update])
  return allowance
}

export default useAllowance
