import { Trans } from "@lingui/macro"
import { Button, message, Select, Spin, Popconfirm } from "antd"
import reject from 'assets/images/icon_reject.svg'
import axios from "config/axios"
import { useState } from "react"
import { SearchOutlined } from '@ant-design/icons'
import { titleCase } from "utils"

const { Option } = Select

const RelateToken = ({ setUpdate, relate_tokens }: any) => {
  const [loading, setLoading] = useState(false)
  const [applyLoading, setApplyLoading] = useState(false)
  const [data, setData] = useState([])
  const [value, setValue] = useState('')

  let timeout: any
  let currentValue: any

  const fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      setLoading(true)
      const res = await axios.get(`/api/admin/space/token/search?type=nspace&symbol=${value}`)
      setLoading(false)
      if (currentValue === value) {
        callback(res.data.data)
      }
    }

    timeout = setTimeout(fake, 300)
  }
  const handleSearch = (value: string) => {
    if (value) {
      fetch(value, (data: any) => setData(data))
    } else {
      setData([])
    }
  }
  const handleChange = (value: any) => {
    setValue(value)
  }
  const options = data.map((d: any) => {
    return (
      <Option
        className="border-b border-bord"
        key={d.project_code}
        value={d.project_code}
      >
        <div className='flex items-center'>
          <img className='w-4 mr-1.5 rounded-full' src={d.logo_url} alt="" />
          <span>{d.symbol}({d.name})</span>
        </div>
      </Option>
    )
  })

  const applyFun = async () => {
    if (!value) return
    setApplyLoading(true)
    const res = await axios.post('/api/admin/space/profile', {
      type: 'apply_token',
      project_code: value
    })
    setApplyLoading(false)
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('succees!')
    } else {
      message.error(res.data.message)
    }
  }

  const deleteMyFun = async (code: string) => {
    const res = await axios.post('/api/admin/space/profile', {
      type: 'remove_token',
      project_code: code
    })
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  return (
    <div className="bg-ncon rounded-lg px-5 py-5 border border-bord">
      <div className="flex flex-col md:flex-row mt-6 gap-4">
        <div className="bg-nibg rounded-md border border-bord divide-y divide-bord w-96 h-fit">
          <div className="bg-ncon1 py-2 px-4 rounded-t-md">
            <Trans>Relate Token</Trans>
          </div>
          <div className="pb-4">
            <div className="flex items-center mt-4 space-x-3 px-4">
              <div className="relative flex-1">
                <Select
                  placeholder='Search Relate Tokens'
                  showSearch
                  value={value}
                  className='w-full'
                  dropdownClassName='border border-bord'
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  onChange={handleChange}
                  notFoundContent={loading ? <Spin size="small" /> : <div className='text-white'>No results found</div>}
                >
                  {options}
                </Select>
                <div className='absolute h-full flex items-center top-0 right-0 pr-4'><SearchOutlined className='text-xl' /></div>
              </div>
              <div><Button loading={applyLoading} onClick={applyFun} className="rounded px-3" type="primary"><Trans>Apply</Trans></Button></div>
            </div>
          </div>
          <div className="grid grid-cols-5 px-4 py-2 font-medium">
            <div className="col-span-1"><Trans>Token</Trans></div>
            <div className="col-span-3 text-center"><Trans>Status</Trans></div>
            <div className="col-span-1 text-center"><Trans>Operations</Trans></div>
          </div>
          {
            relate_tokens && relate_tokens.map((token: any) => (
              <div key={token.project_code} className="grid grid-cols-5 px-4 py-2">
                <div className="col-span-1 flex items-center space-x-1">
                  <img className="h-4" src={token.logo_url} alt="" />
                  <div>{token.symbol}</div>
                </div>
                <div className="col-span-3 flex justify-center">
                  <div className={`${token.status !== 'pending' ? token.status === 'valid' ? 'bg-valid bg-opacity-20 text-valid' : 'bg-red-500 bg-opacity-20 text-red-500' : 'bg-pend bg-opacity-20 text-pend'} px-4 rounded-full text-xs flex justify-center items-center`}><Trans>{titleCase(token.status)}</Trans></div>
                </div>
                <div className="col-span-1 flex justify-center items-center space-x-2.5">
                <Popconfirm onConfirm={() => deleteMyFun(token.project_code)} title="Are you sure delete this token?" okText="Yes" cancelText="No">
                  <img className="h-5 cursor-pointer" src={reject} alt="" />
                </Popconfirm>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default RelateToken