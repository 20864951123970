import { useCallback, useRef, useState } from 'react'
import { Modal, Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Crop from 'react-cropper'
import GIF from 'gif.js'
import { GifToCanvas } from './gifToCanvas'
import 'cropperjs/dist/cropper.css'

type Props = {
  children: any
  aspect?: number
  quality?: number
}
interface Cropper {
  (props: Props): JSX.Element | null
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Cropper: Cropper = ({ children, aspect, quality }) => {
  const [src, setSrc] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const beforeUploadRef = useRef<any>()
  const fileRef = useRef<any>()
  const resolveRef = useRef<(value: unknown) => void>()
  const rejectRef = useRef<(reason?: any) => void>()
  const [cropper, setCropper] = useState<globalThis.Cropper>()

  // upload组件
  const renderUpload = useCallback(() => {
    const upload = Array.isArray(children) ? children[0] : children
    const { beforeUpload, ...restProps } = upload.props
    beforeUploadRef.current = beforeUpload
    return {
      ...upload,
      props: {
        ...restProps,
        beforeUpload: (file: File) => {
          return new Promise((resolve, reject) => {
            fileRef.current = file
            resolveRef.current = resolve
            rejectRef.current = reject
            setSrc(URL.createObjectURL(file))
          })
        },
      },
    }
  }, [children])

  // 取消
  const onCancel = () => {
    setSrc('')
  }
  // 裁剪图片
  const cropImgHandle = async () => {
    return new Promise((resolve, reject) => {
      const { type, name, uid } = fileRef.current
      cropper?.getCroppedCanvas().toBlob(
        async (blob) => {
          if (blob) {
            const nArr = name.split('.')
            const suffix = nArr[nArr.length - 1]
            let fileName = name
            if (suffix === 'gif') {
              fileName = [...nArr.slice(0, -1), blob.type.split('/')[1]].join('.')
            }
            let newFile: any = new File([blob], fileName, { type: blob.type })
            newFile.uid = uid
            if (typeof beforeUploadRef.current !== 'function') {
              return resolve(newFile)
            }
            const res = await beforeUploadRef.current(newFile, [newFile])
            if (!res) return reject('')

            if (typeof res !== 'boolean' && !res) {
              console.error('beforeUpload must return a boolean or Promise')
              return reject('beforeUpload must return a boolean or Promise')
              // return
            }
            if (res === true) {
              return resolve(newFile)
            }
            if (res === false) {
              return reject('not upload')
            }

            if (res && typeof res.then === 'function') {
              try {
                const passedFile = await res
                const type = Object.prototype.toString.call(passedFile)
                if (type === '[object File]' || type === '[object Blob]') {
                  newFile = passedFile
                }
                resolve(newFile)
              } catch (err) {
                reject(err)
              }
            }
          }
        },
        type,
        quality,
      )
    })
  }

  // 裁剪GIF
  const cropGifHandle = () => {
    return new Promise<Blob>((resolve, reject) => {
      if (cropper) {
        const url = (cropper as any).url
        const cropBoxData = cropper.getCropBoxData()
        const canvasData = cropper.getCanvasData()
        const gifToCanvas = new GifToCanvas(url, {
          targetOffset: {
            dx: cropBoxData.left - canvasData.left,
            dy: cropBoxData.top - canvasData.top,
            width: canvasData.width,
            height: canvasData.height,
            sWidth: cropBoxData.width,
            sHeight: cropBoxData.height,
          },
        })

        const gif = new GIF({
          workers: 2,
          quality: 10,
          workerScript: '/static/js/gif.worker.js',
        })
        const addFrame = (canvas: HTMLCanvasElement, delay: number) => {
          gif.addFrame(canvas, { copy: true, delay })
        }
        gifToCanvas.on('progress', (canvas, delay) => {
          addFrame(canvas, delay)
        })

        gifToCanvas.on('finished', (canvas, delay) => {
          addFrame(canvas, delay)
          gif.render()
        })
        gif.on('finished', (blob: Blob | PromiseLike<Blob>) => {
          resolve(blob)
        })
        gifToCanvas.init()
      } else {
        reject()
      }
    })
  }

  const getPreviewSrc = async () => {
    const hasGifFile = fileRef.current.type.includes('gif')
    if (hasGifFile) {
      const blob = await cropGifHandle()
      return URL.createObjectURL(blob)
    } else {
      return cropper?.getCroppedCanvas().toDataURL()
    }
  }

  const onPreview = async () => {
    setLoading(true)
    const url = await getPreviewSrc()
    setLoading(false)
    Modal.info({
      title: 'Picture preview ',
      // eslint-disable-next-line jsx-a11y/alt-text
      content: <img className="preview-img" src={url} />,
      closable: true,
      keyboard: true,
      mask: true,
    })
  }

  const onOk = async () => {
    setLoading(true)
    try {
      const hasGifFile = fileRef.current.type.includes('gif')
      if (hasGifFile) {
        const blob = await cropGifHandle()
        const { name, uid } = fileRef.current
        const newFile: any = new File([blob], name, { type: blob.type })
        newFile.uid = uid
        resolveRef.current?.(newFile)
      } else {
        const newFile = await cropImgHandle()
        resolveRef.current?.(newFile)
      }
    } catch (err) {
      rejectRef.current?.(err)
    }
    setLoading(false)
    onCancel()
  }
  return (
    <>
      {renderUpload()}
      {src && (
        <Modal
          title="Crop Picture"
          visible={true}
          onCancel={onCancel}
          footer={
            <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
              <Button key="preview" ghost onClick={onPreview}>
                Preview
              </Button>
              <Button key="back" ghost onClick={onCancel}>
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={onOk}>
                Ok
              </Button>
            </Spin>
          }
        >
          {/* 
          <Cropper
            src={this.state.srcCropper || ''} // 图片路径，即是base64的值，在Upload上传的时候获取到的
            ref={cropper => {
              this.cropper = cropper;
            }}
            preview=".uploadCrop"
            viewMode={1} // 定义cropper的视图模式
            zoomable={false} // 是否允许放大图像
            // movable
            guides={false} // 显示在裁剪框上方的虚线
            background={false} // 是否显示背景的马赛克
            rotatable={false} // 是否旋转
            autoCropArea={1} // 默认值0.8（图片的80%）。--0-1之间的数值，定义自动剪裁区域的大小
            style={{ width: '100%', height: '400px' }}
            aspectRatio={1152 / 382} // 固定为1:1  可以自己设置比例, 默认情况为自由比例
            // cropBoxResizable={false} // 默认true ,是否允许拖动 改变裁剪框大小
            // cropBoxMovable  // 是否可以拖拽裁剪框 默认true
            dragMode="move" // 拖动模式, 默认crop当鼠标 点击一处时根据这个点重新生成一个 裁剪框，move可以拖动图片，none:图片不能拖动
            center
          /> */}
          <Crop
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={aspect || 2 / 1}
            accept={'' + aspect || '2 /1'}
            aspectRatio={aspect || 2 / 1}
            src={src}
            viewMode={1}
            guides={false}
            rotatable={true}
            minCropBoxHeight={10}
            minCropBoxWidth={20}
            background={false}
            // responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance)
            }}
          />
        </Modal>
      )}
    </>
  )
}

export default Cropper
