import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
	space_code: any,
	space_tree: any,
}

const initialState: CounterState = {
	space_code: undefined,
	space_tree: false,
}

export const spaceSlice = createSlice({
	name: 'space',
	initialState,
	reducers: {
		updateSpace: (state, action: PayloadAction<any>) => {
			state.space_code = action.payload
		},
		updateSpaceTree: (state) => {
			state.space_tree = !state.space_tree
		},
	}
})

export const { updateSpace, updateSpaceTree } = spaceSlice.actions
export default spaceSlice.reducer
