import { useEffect, useState } from "react"
import axios from "config/axios"

const useStatistics = (code: string) => {
  const [data, setData] = useState({
    covered_communities: 0,
    communities_members: 0,
    daily_active_index: 0,
    daily_volumn: 0,
    kols: 0,
    market_cap: 0,
    price: 0,
    sentiment: 0,
    social_coverage: 0,
    social_volume: 0,
    spam_volume: 0,
    twitter_followers: 0,
    yday_price: 0,
  })
  const fetchData = async () => {
    try {
      const res = await axios.get(`/api/admin/project/stats?code=${code}`)
      setData(res.data.data)
    } catch (error) {
      console.error("useStatistics --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [code])
  return data
}

export default useStatistics
