import { Trans } from "@lingui/macro"
import { formatNum } from "utils"
import Communities from "./Communities"
import Sentiment from "./Sentiment"
import Social from "./Social"
import Contributors from "./Contributors"
import useStatistics from "./hooks/useStatistics"
import HotProjects from "./HotProjects"
import HotCommunities from "./HotCommunities"
import NewProjects from "./NewProjects"
import NewCommunities from "./NewCommunities"
import WalletsAndAccounts from "./WalletsAndAccounts"
import Campaigns from "./Campaigns"

const Home = () => {
  const statistics = useStatistics()
  return (
    <div className='container mx-auto max-w-6.5xl'>
      <div className="grid grid-cols-4 gap-5">
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Covered Communities</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.communities)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Covered Communities Members</Trans></div>
          {/* <div className="text-2xl font-bold">{formatNum(statistics.memeber_count)}</div> */}
          <div className="text-2xl font-bold">{formatNum(3890000)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Tracking KOLs</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.tracking_kols)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Social Coverage</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.social_converage)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Social Volume(24H)</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.social_volume)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Spam Volume(24H)</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.spam_volume)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Social Engagement(24H)</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.social_engagement)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Social Contributors(24H)</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.social_contributors)}</div>
        </div>
        <div className="bg-b2 rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Tracking Projects</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.traking_projects)}</div>
        </div>
        <div className="bg-b2 rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Tracking Market Cap</Trans></div>
          <div className="text-2xl font-bold">${formatNum(statistics.market_cap)}</div>
        </div>
        <div className="bg-b2 rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Trading Volume(24H)</Trans></div>
          <div className="text-2xl font-bold">${formatNum(statistics.trading_volume)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Tracking Wallets</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.tracking_wallets)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Web2 IDs</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.web2_accounts)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Bind Web2 Wallets</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.bind_web2_wallets)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Campaign Actions</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.campaign_actions)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium"><Trans>Campaigns</Trans></div>
          <div className="text-2xl font-bold">{formatNum(statistics.campaigns)}</div>
        </div>
      </div>
      <div className="space-y-10 mt-10">
      <div className="grid grid-cols-2 gap-4">
          <WalletsAndAccounts />
          <Campaigns />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Communities />
          <Sentiment />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Social />
          <Contributors />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <HotProjects />
          <HotCommunities />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <NewProjects />
          <NewCommunities />
        </div>
      </div>
    </div >
  )
}

export default Home
