import { Table, Tooltip } from 'antd'
import { Trans } from "@lingui/macro"
import useCommunityDetail from './hooks/useCommunityDetail'
import { formatNum } from 'utils'

const ActiveMember = ({ code }: { code: string }) => {
  const [data, loading] = useCommunityDetail(code)
  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 100,
      render: (_cur: any, _record: any, index: number) => (<div>{index + 1}</div>)
    },
    {
      title: <div><Trans>Username</Trans></div>,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200,
      render: (cur: any, record: any) => (
        <div className="flex items-center">
          <div className="truncate">
            <Tooltip placement="topLeft" title={cur}>
              {cur}
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      title: <div><Trans>Active Index</Trans></div>,
      dataIndex: 'active',
      key: 'active',
      width: 100,
      render: (cur: number) => <div>{formatNum(cur)}</div>
    },
  ]
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded py-4">
        <div className="text-lg font-medium pl-3"><Trans>Active Members</Trans></div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table className="" loading={loading} dataSource={data.active_members.slice(0, 8)} columns={columns} pagination={false}
            rowKey={(record: any) => record.name}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ActiveMember
