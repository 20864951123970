import { Trans } from '@lingui/macro'
import { MinusCircleOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Select } from 'antd'
import rewardlogo from 'assets/images/rewardlogo.svg'
import { isNumber2 } from 'utils'
import useNFTList from './hooks/useNFTList'
import { useEffect, useState } from 'react'

const { Option } = Select

const RewardNFT = ({ reward, index, setRewardsList }: any) => {
  const [form] = Form.useForm()
  const [update, setUpdate] = useState(false)
  const [nftList, loading] = useNFTList(update)

  // 标记blockchain的选择替换gas fee 的可选项
  const [aptos, setAptos] = useState(false)
  const deleteFun = () => {
    setRewardsList((state: any) => {
      const list = state
      const index = list.indexOf(list.find((li: any) => li.uuid === reward.uuid))
      list.splice(index, 1)
      return [...list]
    })
  }

  const modeSelect = (value: any) => {
    if (Number(value) === 3) {
      form.setFieldsValue({
        shares: -1,
      })
    } else {
      form.setFieldsValue({
        shares: '',
      })
    }
  }

  const createNFTFun = () => {
    window.open(`${window.location.origin}/nft/deploy`, '_blank')
  }

  const handleChangeBolckchain = (value: any) => {
    if (value.includes('aptos')) {
      setAptos(true)
    } else setAptos(false)
  }

  useEffect(() => {
    const formObj = reward.name
      ? {
          ...reward,
        }
      : {
          type: 'nft',
        }
    form.setFieldsValue(formObj)
    setAptos(formObj?.chain_type?.includes('aptos'))
    // eslint-disable-next-line
  }, [])

  return (
    <div className="bg-resbg px-4 border border-bord rounded-md">
      <div className="py-3 border-b border-bord">
        <div className="flex items-center">
          <img src={rewardlogo} alt="" />
          <div className="text-valid ml-2">Reward-{index + 1}</div>
          <div className="ml-6 px-4 py-1 text-xs rounded-full bg-valid bg-opacity-20 text-valid">#NFT</div>
          <div className="flex-1 flex justify-end -mr-12">
            <MinusCircleOutlined onClick={deleteFun} className="text-base cursor-pointer" />
          </div>
        </div>
      </div>
      <div className="py-4">
        <Form name={'form' + (index + 1)} form={form} layout="vertical" autoComplete="off">
          <div className="grid grid-cols-3 gap-4">
            <Form.Item className=" hidden" label="Reward Type:" name="type">
              <Select disabled>
                <Option value="nft">NFT</Option>
                <Option value="token">Token</Option>
                <Option value="white_list">White List</Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="col-span-2"
              label="Reward Name:"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Reward is required!',
                },
              ]}
            >
              <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
            <Form.Item
              label="Blockchain type:"
              name="chain_type"
              rules={[
                {
                  required: true,
                  message: 'Blockchain is required!',
                },
              ]}
            >
              <Select placeholder="Select chain type" showArrow={true} dropdownClassName="border border-bord" onChange={handleChangeBolckchain}>
                <Option value="evm">EVM</Option>
                <Option value="aptos">Aptos</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <Form.Item
              className="col-span-2"
              label="Source:"
              name="giveaway"
              rules={[
                {
                  required: true,
                  message: 'Please select a source!',
                },
              ]}
            >
              <Select placeholder="Select or create new" notFoundContent={<div className="text-white text-center">No Data</div>}>
                {nftList.map(({ code, symbol }: any) => (
                  <Option key={code} value={code}>
                    {symbol}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="col-span-1 flex items-center space-x-4 pt-1">
              <div className="flex-1">
                <div className="w-fit" onClick={() => setUpdate((state: boolean) => !state)}>
                  <SyncOutlined spin={loading} className="cursor-pointer text-white text-opacity-70 hover:text-opacity-100" />
                </div>
              </div>
              <div onClick={createNFTFun} className="space-x-1 cursor-pointer text-white text-opacity-70 hover:text-opacity-100">
                <span>
                  <Trans>Add NFT Reward</Trans>
                </span>
                <PlusOutlined />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <Form.Item
              label="Mode:"
              name="mode"
              rules={[
                {
                  required: true,
                  message: 'Mode is required!',
                },
              ]}
            >
              <Select placeholder="Select a mode" showArrow={true} dropdownClassName="border border-bord" onSelect={modeSelect}>
                <Option value={1}>First come first served</Option>
                <Option value={2}>Manually Generated</Option>
                <Option value={3}>Unlimited</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Number of winners:"
              name="shares"
              rules={[
                {
                  required: true,
                  message: 'Number of winners is required!',
                },
                {
                  validator: (_, value) => {
                    if (value && !isNumber2(value)) {
                      return Promise.reject('Number of winners can only be numbers!')
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input placeholder="1000" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
            {/* <Form.Item
              label='Claim Button Text:'
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Claim Button Text is required!',
                },
              ]}
            >
              <Input placeholder="Claim OAT NFT" className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
            </Form.Item> */}
            <Form.Item
              label="Gas fee:"
              name="mint_type"
              rules={[
                {
                  required: true,
                  message: 'Gas fee is required!',
                },
              ]}
            >
              <Select
                placeholder="Select a Gas fee"
                // defaultValue={'free_mint'}
                showArrow={true}
                dropdownClassName="border border-bord"
              >
                {!aptos && <Option value={'free_mint'}>Paid by SoQuest</Option>}

                <Option value={'self_mint'}>Paid by user</Option>
              </Select>
            </Form.Item>
            <Form.Item className="hidden" label="id:" name="id">
              <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default RewardNFT
