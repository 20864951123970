import { Trans } from "@lingui/macro"
import { Tooltip } from "antd"
import { formatFulltime, formatNum } from "utils"
import cookie from 'react-cookies'

const TwitterProfile = ({ data, setUpdate }: any) => {
  
  return (
    <div>
      <div><img className="rounded-full w-16" src={data?.logo_url} alt="" /></div>
      <div className="mb-2 mt-3 text-2xl text-bold"><a className="text-white hover:underline hover:text-ci" href={`https://twitter.com/${data?.screen_name}`} target='_blank' rel="noreferrer">{data?.name}</a></div>
      <div className="mb-3"><span className="text-gray-400"><Trans>Followed by</Trans></span> <span className="text-bold text-15">{formatNum(data?.followers_count)}</span></div>
      <div>{data?.description}</div>
      {/* <div className="flex space-x-3 mt-4">
        <div className="bg-ci bg-opacity-20 border border-ci rounded px-2 text-ci text-bold">Tag1</div>
        <div className="bg-ci bg-opacity-20 border border-ci rounded px-2 text-ci text-bold">Tag2</div>
        <div className="bg-ci bg-opacity-20 border border-ci rounded px-2 text-ci text-bold">Tag3</div>
        <div className="bg-ci bg-opacity-20 border border-ci rounded px-2 text-ci text-bold">Tag4</div>
      </div> */}
      <div className="bg-ci bg-opacity-20 grid grid-cols-2 rounded-lg px-4 py-3 mt-8">
        <div className="space-y-2">
          <div className="text-medium"><Trans>Active Index (30D)</Trans></div>
          <div className="text-lg text-bold">{formatNum(data?.month_active_index)}</div>
        </div>
        <div className="space-y-2">
          <div className="text-medium"><Trans>Influence (30D)</Trans></div>
          <div className="text-lg text-bold">{formatNum(data?.month_influence)}</div>
        </div>
      </div>
      <div className="mt-7">
        {!data?.kol_follower.length && 'No super KOL'}
        {!!data?.kol_follower.length && <div><div className="text-gray-300 text-bold"><Trans>Followed by super KOL</Trans></div>
        <div className="mt-6 flex gap-x-4 gap-y-2 flex-wrap">
          {data?.kol_follower.slice(0, 20).map((kol: any, index: number) => (
            <div className="cursor-pointer" onClick={() => {
              const arr = [{
                screen_name: kol.screen_name,
                logo_url: kol.logo_url,
                name: kol.name,
              }]
              cookie.save('soad_twitter', arr, { path: '/' })
              setUpdate((state: any) => !state)
              window.scrollTo(0, 0)
            }} key={index}>
              <Tooltip title={kol.name}><img className="rounded-full w-7" src={kol.logo_url} alt="" /></Tooltip>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <span className="text-gray-400"><Trans>Followed by</Trans></span>
          <p>
            {data?.kol_follower.slice(0, 20).map((kol: any, index: number) => (
              <span className="text-gray-300 text-bold" key={index}>{kol.name} {index === (data?.kol_follower.slice(0, 20).length - 1) ? '' : ','} </span>
            ))}
          </p>
        </div></div>}
      </div>
      <div className="border-t border-ci border-opacity-30 mt-6 pt-6 space-y-4">
        {/* <div className="text-gray-300 text-bold"><Trans>Stats</Trans></div> */}
        <div><span className="text-gray-300 text-bold"><Trans>Join Since :</Trans></span> <span className="text-gray-300">{formatFulltime(data?.join_since_time)}</span></div>
      </div>
    </div>
  )
}

export default TwitterProfile
