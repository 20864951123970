import { useEffect, useState } from 'react'
import axios from 'config/axios'
import cookie from 'react-cookies'
import { RootState } from 'state'
import { useSelector } from 'react-redux'

const useGetSpaces = (signed: any, update: boolean) => {
  const isSigned = cookie.load('soad_signed')
  const [data, setData] = useState<any>()
  const space_tree = useSelector((state: RootState) => state.space.space_tree)
  const fetchData = async () => {
    try {
      const res = await axios.get(`/api/admin/space/tree`)
      const info = res.data.code === 401 || res.data.code === 403 ? res.data.code : res.data.data
      if (res.data.code === 401) {
        cookie.remove('soad_signature', { path: '/' })
        cookie.remove('soad_signStamp', { path: '/' })
        cookie.remove('soad_signed', { path: '/' })
        cookie.remove('soad_acc', { path: '/' })
        cookie.remove('soad_role', { path: '/' })
        cookie.remove('soad_space_code', { path: '/' })
      }
      setData(info)
    } catch (error) {
      console.error('useGetSpaces --> ' + error)
    }
  }
  useEffect(() => {
    if (isSigned) fetchData()
    // eslint-disable-next-line
  }, [signed, space_tree, update])
  return [data, setData]
}

export default useGetSpaces
