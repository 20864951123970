import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Contract, utils } from 'ethers'
import { ChainId } from 'config/networks'
import { blockchainScan, chainidScan } from 'config/blockchain'
import moment from 'moment'
import { format } from 'friendly-numbers'
import { message } from 'antd'
import { zeroAddress } from 'config/Address'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

const builders = {
  etherscan: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://${chainName ? `${chainName}.` : ''}etherscan.io`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },
  bscscan: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://${chainName ? `${chainName}.` : ''}bscscan.com`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },
  matic: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://explorer-${chainName}.maticvigil.com`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      case 'token':
        return `${prefix}/tokens/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },
}
const chains = {
  [ChainId.MAINNET]: {
    chainName: '',
    builder: builders.etherscan,
  },
  [ChainId.BSC]: {
    chainName: '',
    builder: builders.bscscan,
  },
  [ChainId.MATIC]: {
    chainName: 'mainnet',
    builder: builders.matic,
  },
  [ChainId.LOCAL]: {
    chainName: '',
    builder: builders.etherscan,
  },
}

export function getExplorerLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address' | 'block'): string {
  const chain = chains[chainId]
  return chain.builder(chain.chainName, data, type)
}

// account is not optional1
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function formatNum(number: any, decimals?: number, price?: boolean) {
  if (!number) return 0
  const num = typeof number === 'string' ? parseFloat(number) : number
  if (price && num < 0.01) {
    return num
  }
  if (num >= 10000 && !price) {
    return format(num, {
      formattedDecimals: 2,
    })
  } else {
    let str = utils.commify(num.toFixed(decimals ? decimals : 2))
    if (str.endsWith('.0')) str = str.substring(0, str.length - 2)
    return str
  }
}

export function formatTooltip(number: number, dollar?: boolean) {
  if (!number) return dollar ? '$' + 0 : 0
  const num = typeof number === 'string' ? parseFloat(number) : number
  let str = utils.commify(num)
  if (str.endsWith('.0')) str = str.substring(0, str.length - 2)
  return dollar ? '$' + str : str
}

export function formatBigNumber(bignumber: any, decimals: number) {
  let str = utils.formatUnits(bignumber, decimals)
  if (str.endsWith('.0')) str = str.substring(0, str.length - 2)
  return str
}

export function shortenAddress(address?: string, chars = 4): string {
  if (!address) return ''
  return `${address?.substring(0, chars + 1)}...${address?.substring(address.length - chars)}`
}

export function shortenAPIKey(key?: string, chars = 4): string {
  return `${key?.substring(0, chars + 2)}...${key?.substring(32 - chars)}`
}

export function shortenSystemAPIKey(key?: string, chars = 4): string {
  return `${key?.substring(0, chars)}...${key?.substring(key.length - 10)}`
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function formatTime(date: string, timestamp: number, fullTime?: boolean) {
  let time
  const dateTime = moment(timestamp * 1000)
  if (fullTime) {
    time = dateTime.format('MMM DD, YYYY, HH:00')
  } else {
    if (date === 'daily') {
      time = dateTime.format('HH:00')
    } else {
      time = dateTime.format('MMM DD')
    }
  }
  return time
}

export function formatFulltime(timestamp: number) {
  const dateTime = moment(timestamp * 1000)
  return dateTime.format('MMM DD, YYYY, HH:mm')
}

export function formatTimeAgo(timestamp: number, noHM?: boolean) {
  const dateNow = new Date()
  const dateTime = moment(timestamp * 1000)
  const timeDiff = dateNow.getTime() - timestamp * 1000
  const isMoreDay = timeDiff / (24 * 3600 * 1000) > 1 ? true : false
  return isMoreDay ? (noHM ? dateTime.format('MMM DD, YYYY') : dateTime.format('MMM DD, YYYY, HH:mm')) : dateTime.fromNow()
}

export function formatLanguage(lang: string) {
  let language
  if (lang === 'en_US') {
    language = 'English'
  }
  return language
}

export function titleCase(str: string) {
  const newStr = str.slice(0, 1).toUpperCase() + str.slice(1)
  return newStr === 'Nstarted' ? 'Coming Soon' : newStr
}

export function getAddressScan(chain: number | string, address: string) {
  const url = typeof chain === 'number' ? chainidScan[chain] : blockchainScan[chain]
  const scan = url + '/address/' + address
  return scan
}

export function fixedArr(arr: any, type: string, str: string) {
  if (arr[0]) {
    if (arr[0][type] !== str) {
      let _index
      for (const [index, obj] of arr.entries()) {
        if (obj[type] === str) {
          _index = index
          break
        }
      }
      arr[0] = arr.splice(_index, 1, arr[0])[0]
    }
  }
}

export function isAddress(address: string) {
  return utils.isAddress(address)
}

const checkWH = (file: any, par1: number, par2: number) => {
  return new Promise<void>((resolve, reject) => {
    const url = window.URL || window.webkitURL
    const img = new Image()
    img.onload = () => {
      const valid = img.width / img.height === par1 / par2 || img.height * 2 + 1 === img.width || img.height * 2 + -1 === img.width
      valid ? resolve() : reject()
    }
    img.src = url.createObjectURL(file)
  }).then(
    () => {
      return true
    },
    () => {
      message.error(`Image must is ${par1}:${par2}!`)
      return false
    },
  )
}

export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const beforeUpload = async (file: any, mb: number, par1?: number, par2?: number, fileList?: any, maxNum?: number) => {
  let isMaxNum = true
  if (fileList && maxNum && fileList.length >= maxNum) {
    isMaxNum = false
    message.error('Maximum 10 images')
  }
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF file!')
  }

  const isLtMB = file.size / 1024 / 1024 <= mb

  if (!isLtMB) {
    message.error(`Image must smaller and equal than ${mb}MB!`)
  }

  let isValid = true
  if (par1 && par2) isValid = await checkWH(file, par1, par2)

  if (fileList && maxNum) return isJpgOrPng && isLtMB && isValid && isMaxNum
  else return isJpgOrPng && isLtMB && isValid
}

// only include number letter '-' and '_'
export const isCode = (value: any) => {
  const reg = new RegExp(/^[0-9a-zA-Z\-_]*$/g)
  return reg.test(value)
}

export const isCodeNotUnderscore = (value: any) => {
  // eslint-disable-next-line
  const reg = new RegExp(/^[0-9a-zA-Z\-]*$/g)
  return reg.test(value)
}

// only include number
export const isNumber = (value: any) => {
  const reg = new RegExp(/^[0-9.]*$/g)
  return reg.test(value)
}

export const isPositiveNumber = (value: any) => {
  const reg = new RegExp(/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/)
  return reg.test(value)
}

export const isNumber2 = (value: any) => {
  const reg = new RegExp(/^[-]{0,1}[0-9.]*$/g)
  return reg.test(value)
}

export const isPositiveInteger = (value: any) => {
  const reg = new RegExp(/^[1-9]\d*$/)
  return reg.test(value)
}

export const isPositiveIntegerAndZero = (value: any) => {
  const reg = new RegExp(/^([0]|[1-9]\d*)$/)
  return reg.test(value)
}

// url format
export const isUrl = (value: any) => {
  const reg = new RegExp(/(http|https):\/\/([\w.]+\/?)\S*/)
  return reg.test(value)
}

export function getUuiD(index: number) {
  return Number(index.toString() + Date.now()).toString(36)
}

export const downloadFile = (data: any, name: any) => {
  if (!data) return
  const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  let url = window.URL.createObjectURL(new Blob([data], { type: mimeType }))
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name + '.xlsx')
  document.body.appendChild(link)
  link.click()
}

export const getRandomNum = (n: number, total: number, decimals: number) => {
  const result: string[] = []
  let range = new BigNumber(total).toFixed()
  for (let i = 0; i < n - 1; i++) {
    const item_ori = _.random(
      1 / 10 ** decimals,
      new BigNumber(range)
        .div(n - i)
        .times(2)
        .toNumber(),
    )
    const item = subsByNums(new BigNumber(item_ori).toFixed(), decimals)
    result.push(item)
    range = new BigNumber(range).minus(item_ori).toFixed()
  }
  result.push(subsByNums(range, decimals))
  return result
}

export const isChainCoin = (address: string) => {
  return address === zeroAddress
}

export function subsByNums(str: string, nums: number) {
  if (str.includes('.') && str.split('.')[1]?.length > nums) {
    const left = str.split('.')[0]
    const right = str.split('.')[1].substring(0, nums)
    const str_ = left + '.' + right
    return str_
  }
  return str
}

export function getfilesize(size: number) {
  if (!size) return ''
  var num = 1024.0 //byte
  if (size < num) return size + 'B'
  if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'KB' //kb
  if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + 'MB' //M
  if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + 'G' //G
  return (size / Math.pow(num, 4)).toFixed(2) + 'T' //T
}

export const filterTrim = (r: any) => {
  const INVALID_ORDER = ['', null, undefined, NaN, false, true]
  const r_c = r?.filter((s: any) => !INVALID_ORDER.includes(s))
  return r_c ? [...r_c] : []
}
