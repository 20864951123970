import ethereum from 'assets/images/ethereum.svg'
import binance from 'assets/images/binance.png'
import polygon from 'assets/images/polygon.png'
// import huobi from 'assets/images/huobi.png'
import fantom from 'assets/images/fantom.png'
import avalanche from 'assets/images/avalanche.png'
import aurora from 'assets/images/aurora.png'
import okxchain from 'assets/images/okexchain.png'
import kcc from 'assets/images/kcc.svg'
import boba from 'assets/images/boba.svg'

export const blockchain_order: any = new Map([
  [1, 'ethereum'],
  [56, 'BNB Chain'],
  [137, 'polygon'],
  [250, 'fantom'],
  [43114, 'avalanche'],
  [1313161554, 'aurora'],
  [321, 'KCC'],
  [66, 'OKXChain'],
  [288, 'Boba'],
  [97, 'BNB Test'],
])

export const blockchain: any = {
  1: 'ethereum',
  56: 'bsc',
  137: 'polygon',
  // 128: 'heco',
  1313161554: 'aurora',
  43114: 'avalanche',
  250: 'fantom',
  66: 'okchain',
  321: 'kcc',
  288: 'boba',
  97: 'bnb test',
}

export const wrapBlockchain: any = {
  ethereum: 'Ethereum',
  bsc: 'BNB Chain',
  polygon: 'Polygon',
  aurora: 'Aurora',
  avalanche: 'Avalanche',
  fantom: 'Fantom',
  okchain: 'OKXChain',
  kcc: 'KCC',
  boba: 'Boba',
  aptos: 'Aptos Mainnet',
  aptostest: 'Aptos Testnet',
}

export const blockchainName: any = {
  ethereum: 'Ethereum',
  bsc: 'BNB Chain',
  polygon: 'Polygon',
  aurora: 'Aurora',
  avalanche: 'Avalanche',
  fantom: 'Fantom',
  okchain: 'OKXChain',
  kcc: 'KCC',
  boba: 'Boba',
  aptos: 'Aptos Mainnet',
  aptostest: 'Aptos Testnet',
}

export const blockchainContract: any = {
  ethereum: 'ethereum',
  bsc: 'BNB Chain',
  polygon: 'polygon',
  avalanche: 'avalanche',
  fantom: 'fantom',
  boba: 'Boba',
  kcc: 'KCC',
  aptos: 'Aptos Mainnet',
  aptostest: 'Aptos Testnet',
}

export const giveawayContract: any = {
  ethereum: 'Ethereum',
  bsc: 'BNB Chain',
  avalanche: 'Avalanche',
  polygon: 'polygon',
  kcc: 'KCC',
}

export const nftBlockchainName: any = {
  ethereum: 'Ethereum',
  bsc: 'BNB Chain',
  polygon: 'Polygon',
  aptos: 'Aptos Mainnet',
  aptostest: 'Aptos Testnet',
}

export const protocolBlockchainName: any = {
  bsc: 'BNB Chain',
  boba: 'Boba',
  kcc: 'KCC',
  aptos: 'Aptos Mainnet',
  aptostest: 'Aptos Testnet',
  arbitrum: 'Arbitrum',
  avalanche: 'Avalanche',
}

export const nftDeployBlockchain: any = {
  aptos: 'Aptos Mainnet',
  // aptostest: 'Aptos Testnet',
  polygon: 'Polygon',
  bsc: 'BNB Chain',
  okchain: 'OKXChain',
}

export const networkIcon: any = {
  1: ethereum,
  56: binance,
  137: polygon,
  // 128: huobi,
  1313161554: aurora,
  43114: avalanche,
  250: fantom,
  66: okxchain,
  321: kcc,
  288: boba,
}

export const networkNameIcon: any = {
  ethereum: ethereum,
  bsc: binance,
  polygon: polygon,
  aurora: aurora,
  avalanche: avalanche,
  fantom: fantom,
  okchain: okxchain,
  kcc: kcc,
  boba: boba,
}

export const networkParam: any = {
  1: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3'],
    blockExplorerUrls: ['https://etherscan.com'],
  },
  56: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  137: {
    chainId: '0x89',
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  // 128: {
  //   chainId: '0x80',
  //   chainName: 'Heco',
  //   nativeCurrency: {
  //     name: 'Heco Token',
  //     symbol: 'HT',
  //     decimals: 18
  //   },
  //   rpcUrls: ['https://http-mainnet.hecochain.com'],
  //   blockExplorerUrls: ['https://hecoinfo.com']
  // },
  1313161554: {
    chainId: '0x4e454152',
    chainName: 'Aurora MainNet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'aETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.aurora.dev'],
    blockExplorerUrls: ['https://explorer.mainnet.aurora.dev'],
  },
  43114: {
    chainId: '0xA86A',
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'Avalanche Token',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://explorer.avax.network'],
  },
  250: {
    chainId: '0xfa',
    chainName: 'Fantom',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    blockExplorerUrls: ['https://ftmscan.com'],
  },
  66: {
    chainId: '0x42',
    chainName: 'OKXChain Mainnet',
    nativeCurrency: {
      name: 'OKXChain Global Utility Token',
      symbol: 'OKT',
      decimals: 18,
    },
    rpcUrls: ['https://exchainrpc.okex.org'],
    blockExplorerUrls: ['https://www.oklink.com/en/okc'],
  },
  321: {
    chainId: '0x141',
    chainName: 'KCC Mainnet',
    nativeCurrency: {
      name: 'KuCoin Token',
      symbol: 'KCS',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.kcc.network'],
    blockExplorerUrls: ['https://explorer.kcc.io'],
  },
  288: {
    chainId: '0x120',
    chainName: 'Boba Network',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.boba.network/'],
    blockExplorerUrls: ['https://bobascan.com'],
  },
}

export const chainidScan: any = {
  1: 'https://etherscan.io',
  56: 'https://bscscan.com',
  137: 'https://polygonscan.com',
  // 128: 'https://hecoinfo.com',
  1313161554: 'https://explorer.mainnet.aurora.dev',
  43114: 'https://snowtrace.io',
  250: 'https://snowtrace.io',
  66: 'https://www.oklink.com/en/okc',
  321: 'https://explorer.kcc.io',
  288: 'https://bobascan.com',
}

export const blockchainScan: any = {
  ethereum: 'https://etherscan.io',
  bsc: 'https://bscscan.com',
  polygon: 'https://polygonscan.com',
  // 'heco': 'https://hecoinfo.com',
  aurora: 'https://explorer.mainnet.aurora.dev',
  avalanche: 'https://snowtrace.io',
  fantom: 'https://snowtrace.io',
  okchain: 'https://www.oklink.com/en/okc',
  kcc: 'https://explorer.kcc.io',
  boba: 'https://bobascan.com',
}
