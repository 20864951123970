import { Trans } from '@lingui/macro'
import publish from 'assets/images/publish.svg'
import preview from 'assets/images/preview.svg'
import add from 'assets/images/_icon_add.svg'
import { Button, Form, message, Dropdown } from 'antd'
import { useEffect, useState } from 'react'
import axios, { previewUrl } from 'config/axios'
import { useHistory } from 'react-router-dom'
import uuid from 'react-uuid'
import RewardNFT from './RewardNFT'
import RewardToken from './RewardToken'
import RewardWhiteList from './RewardWhiteList'
import cookie from 'react-cookies'
import CheckTwitter from '../CheckModal/CheckTwitter'

const Rewards = ({ setUpdate, prizes, trans_code, setupData }: any) => {
  const history = useHistory()
  const [saveloading, setSaveLoading] = useState(false)
  const [publishloading, setPublishLoading] = useState(false)
  const [rewardsList, setRewardsList] = useState<any>([])

  const [checkTwitterOpen, setCheckTwitterOpen] = useState(false)
  const [verify, setVerify] = useState<any>({})
  for (const obj of rewardsList) {
    if (!obj.uuid) obj.uuid = uuid()
  }

  const onFinish = async (fieldValues: any, isPublish: boolean) => {
    for (const obj of fieldValues.prizes) {
      if (!obj.access_token) delete obj.access_token
      if (!obj.credential_id) delete obj.credential_id
      if (!obj.sync_type) delete obj.sync_type
    }
    if (!Object.keys(fieldValues.prizes).length) return

    for (const [index, obj] of fieldValues.prizes.entries()) {
      if (obj.mode === 3) {
        if (Number(obj.shares) !== -1) {
          message.error(`Reward${index + 1} amount must be -1`)
          return
        }
      } else {
        if (Number(obj.shares) < 0) {
          message.error(`Reward${index + 1} amount must not be negative`)
          return
        }
      }
    }

    if (!isPublish) {
      setSaveLoading(true)
    } else {
      setPublishLoading(true)
    }
    const res = await axios.post('/api/admin/space/campaign', {
      step: 3,
      campaign_code: trans_code,
      prizes: JSON.stringify(fieldValues.prizes),
    })
    if (!isPublish) setSaveLoading(false)
    if (+res.data.code === 0) {
      if (!isPublish) {
        message.success('succees!')
        if (setupData?.status === 'active') {
          history.push('/campaigns?tab=active')
        } else if (setupData?.status === 'expired') {
          history.push('/campaigns?tab=finished')
        } else {
          history.push('/campaigns?tab=draft')
        }
      } else {
        const resp = await axios.post('/api/admin/space/campaign/publish', {
          campaign_code: trans_code,
        })
        if (isPublish) setPublishLoading(false)
        if (resp.data.code === 0) {
          message.success('succees!')
          history.push('/campaigns')
        } else {
          message.error(resp.data.message)
          setUpdate((state: any) => !state)
        }
      }
    } else {
      message.error(res.data.message)
      if (isPublish) setPublishLoading(false)
      setUpdate((state: any) => !state)
    }
  }

  useEffect(() => {
    for (const obj of prizes) {
      if (!obj.mode) {
        if (Number(obj.shares) === -1) {
          obj.mode = 3
        } else {
          obj.mode = 2
        }
      }
    }
    setRewardsList(prizes)
  }, [prizes])

  const getTwitterVerify = async () => {
    const result = await axios.get('/api/admin/oauth/twitter/verify')
    setVerify({ ...result.data.data })
  }
  const onFormFinish = async (name: string, { forms }: { forms: any }) => {
    if (!verify.space_verify) {
      if (name === 'publish' && (!verify?.is_verify || !verify?.twitter_code)) return setCheckTwitterOpen(true)
    }
    try {
      const formsSubmit = Object.entries(forms).filter(([key]: any) => key !== 'publish' && key !== 'save')
      const validateFieldsArr = formsSubmit.map(([_, form]: any) => form.validateFields())
      await Promise.all([...validateFieldsArr])
      const getFieldsValueArr = formsSubmit.map(([_, form]: any) => form.getFieldsValue())
      for (const obj of getFieldsValueArr) {
        if (!obj.id) delete obj.id
      }
      onFinish({ prizes: getFieldsValueArr }, name === 'publish' ? true : false)
    } catch (error) {}
  }

  const previewFun = async (e: any) => {
    e.stopPropagation()
    const space_code = cookie.load('soad_space_code')
    window.open(`${previewUrl}${space_code}/campaign/${trans_code}`, '_blank')
  }

  useEffect(() => {
    getTwitterVerify()
  }, [])
  useEffect(() => {
    let t = document.documentElement.scrollHeight
    window.scroll({ top: t, left: 0, behavior: 'smooth' })
  }, [rewardsList])
  return (
    <div className="bg-ncon rounded-lg mb-10">
      <div className="px-5 py-6">
        <div className="mt-2 rewards w-800 space-y-10">
          <Form.Provider onFormFinish={onFormFinish}>
            {/* <div className="flex space-x-4 items-center">
              {setupData?.status === 'draft' && (
                <Form name="publish">
                  <div>
                    <Button htmlType="submit" loading={publishloading} type="primary" className="bg-sendbg hover:bg-ci hover:bg-opacity-40 border-none rounded flex items-center">
                      <img className="mr-1" src={publish} alt="" />
                      <Trans>Publish</Trans>
                    </Button>
                  </div>
                </Form>
              )}
              <div>
                <Button onClick={previewFun} type="primary" className="bg-sendbg hover:bg-ci hover:bg-opacity-40 border-none rounded flex items-center">
                  <img className="mr-1" src={preview} alt="" />
                  <Trans>Preview</Trans>
                </Button>
              </div>
              <Form name="save">
                <div>
                  <Button loading={saveloading} htmlType="submit" className="rounded" type="primary">
                    <Trans>{setupData?.status === 'draft' ? 'Save as draft' : 'Save'}</Trans>
                  </Button>
                </div>
              </Form>
            </div> */}
            {rewardsList.length < 10 && (
              <div className="flex justify-end">
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <div className="bg-ncon1 rounded-xl border border-bord divide-y divide-bord cursor-pointer w-32">
                      <div
                        onClick={() => {
                          setRewardsList((state: any) => [
                            ...state,
                            {
                              type: 'nft',
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>NFT</Trans>
                      </div>
                      <div
                        onClick={() => {
                          setRewardsList((state: any) => [
                            ...state,
                            {
                              type: 'token',
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Token</Trans>
                      </div>
                      <div
                        onClick={() => {
                          setRewardsList((state: any) => [
                            ...state,
                            {
                              type: 'white_list',
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Whitelist</Trans>
                      </div>
                    </div>
                  }
                >
                  <Button type="primary" className="rounded-full flex items-center w-fit">
                    <Trans>Add Reward</Trans>
                    <img className={`ml-1`} src={add} alt="" />
                  </Button>
                </Dropdown>
              </div>
            )}
            <div className="space-y-6">
              {rewardsList.map((reward: any, index: number) => {
                let ele
                if (reward.type === 'nft') {
                  ele = <RewardNFT key={reward.uuid} reward={reward} index={index} setRewardsList={setRewardsList} />
                } else if (reward.type === 'token') {
                  ele = <RewardToken key={reward.uuid} reward={reward} index={index} setRewardsList={setRewardsList} />
                } else {
                  ele = <RewardWhiteList key={reward.uuid} reward={reward} index={index} setRewardsList={setRewardsList} />
                }
                return ele
              })}
            </div>

            <div className="bg-ncon fixed bottom-0 right-0 w-full" style={{ zIndex: 10 }}>
              <div className="flex ml-64 justify-start  py-5 px-6">
                <div className="w-fit">
                  <div className="flex space-x-4 items-center">
                    {setupData?.status === 'draft' && (
                      <Form name="publish">
                        <div>
                          <Button htmlType="submit" loading={publishloading} type="primary" className="bg-sendbg hover:bg-ci hover:bg-opacity-40 border-none rounded flex items-center">
                            <img className="mr-1" src={publish} alt="" />
                            <Trans>Publish</Trans>
                          </Button>
                        </div>
                      </Form>
                    )}
                    <div>
                      <Button onClick={previewFun} type="primary" className="bg-sendbg hover:bg-ci hover:bg-opacity-40 border-none rounded flex items-center">
                        <img className="mr-1" src={preview} alt="" />
                        <Trans>Preview</Trans>
                      </Button>
                    </div>
                    <Form name="save">
                      <div>
                        <Button loading={saveloading} htmlType="submit" className="rounded" type="primary">
                          <Trans>{setupData?.status === 'draft' ? 'Save as draft' : 'Save'}</Trans>
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Form.Provider>
        </div>
      </div>
      <CheckTwitter visible={checkTwitterOpen} onCancel={() => setCheckTwitterOpen(false)} verify={verify} getTwitterVerify={getTwitterVerify} />
    </div>
  )
}

export default Rewards
