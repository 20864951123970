import { useEffect, useState } from "react"
import axios from "config/axios"

const useTrendingProject = (page: number, pageSize = 8) => {
  const [data, setData] = useState<any>({list: [], total: 0})
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/twitter/trending/project?page=${page}&pagesize=${pageSize}`)
      setLoading(false)
      setData({list: res.data.data.data, total: res.data.data.total})
    } catch (error) {
      console.error("useTrendingProject --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [page])
  return [data, loading]
}

export default useTrendingProject
