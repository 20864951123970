import { useEffect, useState } from "react"
import axios from "config/axios"

const useRelativeCommunities = (code: string, page: number) => {
  const [data, setData] = useState<any>({ list: [], total: 0 })
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/project/relative/community?code=${code}&page=${page}`)
      setLoading(false)
      setData({ list: res.data.data.data, total: res.data.data.total })
    } catch (error) {
      console.error("useRelativeCommunities --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [code, page])
  return [data, loading]
}

export default useRelativeCommunities
