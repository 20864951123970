import { Trans } from "@lingui/macro"
import { Pie } from '@ant-design/plots'
import { useMemo, useState } from "react"
import { Spin } from "antd"
import useMemberStructure from "./hooks/useMemberStructure"
import { formatNum } from "utils"

const MemberStructure = ({ code }: { code: string }) => {
  const [time, setTime] = useState('week')
  const [data, loading] = useMemberStructure(time, code)
  const config: any = {
    appendPadding: 10,
    data,
    width: 365,
    height: 365,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'outer',
      content: ({ percent }: any) => `${formatNum(percent * 100, 2)}%`,
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }
  const graph = useMemo(() => {
    return (
      <Pie {...config} />
    )
    // eslint-disable-next-line
  }, [data])
  return (
    <div className="bg-con rounded px-4 py-4 relative">
      <div className="flex flex-col md:flex-row">
        <div className="text-lg font-medium">Member Structure</div>
        <div className="flex-1 flex md:justify-end text-xs mt-4 md:mt-0">
          <div onClick={() => setTime('week')} className={`${time === 'week' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-l py-1.5`}><Trans>7 Days</Trans></div>
          <div onClick={() => setTime('month')} className={`${time === 'month' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer py-1.5 border-l border-r border-ci border-opacity-20`}><Trans>30 Days</Trans></div>
          <div onClick={() => setTime('all')} className={`${time === 'all' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-r py-1.5`}><Trans>All</Trans></div>
        </div>
      </div>
      {loading && <div className="absolute left-1/2 top-1/2 z-40"><Spin /></div>}
      <div className="mt-10">{graph}</div>
    </div>
  )
}

export default MemberStructure
