import { Trans } from '@lingui/macro'
import { Button, Form, Input, DatePicker, Upload, message } from 'antd'
import arrow_r from 'assets/images/icon_arrow_r.svg'
import icon_upload from 'assets/images/icon_upload.svg'
import axios, { api_key, cur, secret_key } from 'config/axios'
import { sha256 } from 'js-sha256'
import { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { beforeUpload } from 'utils'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
// import ImgCrop from 'antd-img-crop'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'style/quill.less'
import UploadImg from 'components/UploadImg'
import { useUpdateEffect } from 'ahooks'

const { RangePicker } = DatePicker
// const { TextArea } = Input

const Setup = ({ setStep, setupData, setUpdate, trans_code, count, setCode, setCount }: any) => {
  const [form] = Form.useForm()
  const [status, setStatus] = useState(true)
  const signed = cookie.load('soad_signed')
  const account = cookie.load('soad_acc')
  const space_code = cookie.load('soad_space_code')
  const [loading, setLoading] = useState(false)
  const [loadImg, setLoadImg] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const [value, setValue] = useState('')

  const timestamp = parseInt(new Date().getTime() / 1000 + '')
  const sha = sha256(api_key + secret_key + timestamp)

  const imgReg = /<img.*?(?:>|\/>)/gi

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadImg(true)
      return
    }

    if (info.file.status === 'done') {
      setLoadImg(false)
      const arr = info.fileList
      if (arr[arr.length - 1].response.code === 0) setImageUrl(arr[arr.length - 1].response.data)
    }
  }

  const onFinish = async (fieldValues: any) => {
    if (fieldValues.description === '<p><br></p>') {
      message.error('Please enter your description!')
      return
    }

    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    const start_time = parseInt(fieldValues.time[0].valueOf() / 1000 + '')
    const end_time = parseInt(fieldValues.time[1].valueOf() / 1000 + '')
    delete fieldValues.time

    const submitObj = {
      step: 1,
      start_time,
      end_time,
      ...fieldValues,
    }
    if (imageUrl) submitObj.cover_image_url = imageUrl
    if (trans_code) submitObj.campaign_code = trans_code
    submitObj.is_private = status
    setLoading(true)
    const res = await axios.post('/api/admin/space/campaign', submitObj)
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setCode(res.data.data.campaign_code)
      setUpdate((state: any) => !state)
      setStep(2)
    } else {
      message.error(res.data.message)
    }
  }

  const handleQuillChange = (v: any) => {
    let a = v
    let deArray = a.match(imgReg) //使用正则表达式，拿到的是数组
    if (deArray !== null && deArray !== undefined) {
      for (const vm of deArray) {
        a = a.replace(vm, '') //放在循环中剔除img标签
      }
    }
    form.setFieldsValue({
      description: a,
    })
    setValue(a)
  }

  useEffect(() => {
    if (setupData && setupData !== 7) {
      if (setupData.cover_image_url) setImageUrl(setupData.cover_image_url)
      setStatus(setupData.is_private)
      form.setFieldsValue({
        title: setupData.title,
        description: setupData.description,
        time: [moment(setupData.start_time * 1000), moment(setupData.end_time * 1000)],
      })
    }
    if (localStorage.getItem('cacheStepForm')) {
      const formData = JSON.parse(localStorage.getItem('cacheStepForm') || '{}')
      setStatus(formData.status)
      form.setFieldsValue({
        title: formData.title,
        description: formData.description,
        time: [moment(formData.start_time), moment(formData.end_time)],
      })
    }
    // eslint-disable-next-line
  }, [setupData, form])

  // from data cache 记录初始化用于判断用户最后的编辑状态是否是更改过
  const handleChangeForm = (value?: any) => {
    if (count === 0) {
      localStorage.setItem('cacheStepFormInit', JSON.stringify({ ...form?.getFieldsValue(), status }))
    }
    localStorage.setItem('cacheStepForm', JSON.stringify({ ...form?.getFieldsValue(), status }))
    setCount(count + 1)
  }
  useUpdateEffect(() => {
    handleChangeForm()
  }, [status])
  return (
    <div className="bg-ncon border border-bord rounded-lg mb-10">
      <div className="px-5 py-2 border-b border-bord bg-ncon1 rounded-t-lg">
        <Trans>Set Up Campaign Basic Info</Trans>
      </div>
      <div className="px-5 py-6 setup">
        <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off" onValuesChange={handleChangeForm}>
          <Form.Item
            label="Title:"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter your title!',
              },
            ]}
          >
            <Input maxLength={64} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label={
              <div>
                <span className="text-st mr-1">*</span>Cover Image:
              </div>
            }
          >
            <div>
              <UploadImg aspect={2 / 1}>
                <Upload
                  name="file"
                  headers={{
                    APIKEY: api_key,
                    TIME: timestamp as any,
                    SIGN: sha,
                    signature: signed,
                    address: account + '',
                    'Space-Code': space_code,
                  }}
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={`${cur}/api/admin/user/upload`}
                  beforeUpload={(file) => beforeUpload(file, 10, 2, 1)}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <div className="relative img_wrap_setup">
                      <img className="max-w-24 max-h-12" src={imageUrl} alt="avatar" />
                      <div className="img_mask_setup flex items-center justify-center">
                        <img src={icon_upload} alt="" />
                      </div>
                    </div>
                  ) : (
                    <div>{loadImg ? <LoadingOutlined className="text-ci" /> : <img src={icon_upload} alt="" />}</div>
                  )}
                </Upload>
              </UploadImg>
              <div className="text-yellow-600">PNG, JPG 1000x500 Picture (Max 10mb)</div>
            </div>
          </Form.Item>
          <Form.Item
            label="Time Period（based on your computer setting）:"
            name="time"
            rules={[
              {
                required: true,
                message: 'Please enter your time period!',
              },
            ]}
          >
            <RangePicker
              className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black w-96"
              showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')] }}
            />
          </Form.Item>
          <Form.Item
            label="Description:"
            name="description"
            style={{ height: 240 }}
            rules={[
              {
                required: true,
                message: 'Please enter campaign description!',
              },
            ]}
          >
            <ReactQuill className="h-40 bg-nibg w-500" theme="snow" value={value} onChange={handleQuillChange} />
          </Form.Item>
          <div className="mb-6">
            <div>
              <Trans>Public Visit:</Trans>
            </div>
            <div className="flex space-x-5 mt-3">
              <div onClick={() => setStatus(false)} className={`cursor-pointer flex items-center px-3 py-1 rounded-md border ${!status ? 'border-ci ' : 'border-bord'}`}>
                <div className={`w-2.5 h-2.5 mr-1.5 rounded-full ${!status ? 'border-3 border-ci' : 'border border-bord'}`}></div>
                <Trans>Public</Trans>
              </div>
              <div onClick={() => setStatus(true)} className={`cursor-pointer flex items-center px-3 py-1 rounded-md border ${status ? 'border-ci ' : 'border-bord'}`}>
                <div className={`w-2.5 h-2.5 mr-1.5 rounded-full ${status ? 'border-3 border-ci' : 'border border-bord'}`}></div>
                <Trans>Private</Trans>
              </div>
            </div>
          </div>
          <div className="bg-ncon fixed bottom-0 right-0 w-full" style={{ zIndex: 10 }}>
            <div className="flex ml-64 justify-start  py-5 px-6">
              <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-4 flex items-center" type="primary">
                <Trans>Save</Trans>
                <img className="ml-1.5 h-4" src={arrow_r} alt="" />
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Setup
