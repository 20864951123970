import { Table, Progress, Tooltip } from 'antd'
import { Trans } from "@lingui/macro"
import { formatNum, titleCase } from "utils"
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'
import { TeamOutlined } from '@ant-design/icons'
import { useState } from 'react'
import useHotCommunities from './hooks/useHotCommunities'

const HotCommunities = () => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [{list, total}, loading] = useHotCommunities(page)
  const max = cookie.load('soad_max')

  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 50,
      render: (_cur: any, _record: any, index: number) => (<div>{(index + 1)+(page-1)*8}</div>)
    },
    {
      title: <div><Trans>Title</Trans></div>,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 220,
      render: (cur: any, record: any, index: any) => {
        return (
        <div className="flex items-center">
          {record.logo_url && <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />}
          {!record.logo_url && <TeamOutlined style={{fontSize: 18}} className='text-ci mr-1.5' />}
          <div className="truncate">
            <Tooltip placement="topLeft" title={cur}>
              {cur}
            </Tooltip>
          </div>
        </div>
      )}
    },
    {
      title: <div><Trans>Platform</Trans></div>,
      dataIndex: 'platform',
      key: 'platform',
      width: 100,
      render: (cur: any) => (<div>{titleCase(cur)}</div>)
    },
    {
      title: <div><Trans>Social Volume</Trans></div>,
      dataIndex: 'social_volume',
      key: 'social_volume',
      width: 120,
      render: (cur: any) => (
      <div className='flex items-center'>
        <span className='mr-2'>{formatNum(cur)}</span>
        <Progress strokeColor='#7754F8' showInfo={false} percent={max === 0 ? 0 : (cur / max * 100)} size="small" status="active" />
      </div>)
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }

  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded px-4 pt-4 pb-0">
        <div className="text-lg font-medium pl-3"><Trans>🔥 Trending Communities</Trans></div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
            rowKey={(record) => record.community_code}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 8,
              total: total > 40 ? 40 : total,
              // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  const arr = [{
                    community_code: record.community_code,
                    logo_url: record.logo_url,
                    name: record.name,
                  }]
                  cookie.save('soad_community', arr, { path: '/' })
                  history.push(`/community`)
                  window.scrollTo(0, 0)
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default HotCommunities
