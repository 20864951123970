import { useEffect, useState } from "react"
import axios from "config/axios"

const useActionList = (page: number, pageSize = 10, campaign_code: string, search_obj: any, update: boolean) => {
  const { address, task_id, status, start_time, end_time } = search_obj
  const [data, setData] = useState<any>({ list: [], tasks: [], total: 0 })
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/space/campaign/analyze/actions?page=${page}&pagesize=${pageSize}&campaign_code=${campaign_code}&address=${address}&task_id=${task_id}&status=${status}&start_time=${start_time}&end_time=${end_time}`)
      setLoading(false)
      // const image_list = []
      // for (const obj of res.data.data.data) {
      //   if (obj.detail.type === 'proof_of_work') {
      //     image_list.push({
      //       id: obj.id,
      //       status: obj.status,
      //       image_url: obj.detail.image_url,
      //     })
      //   }
      // }
      // for (const obj of res.data.data.data) {
      //   if (obj.detail.type === 'proof_of_work') {
      //     obj.image_list = image_list
      //   }
      // }
      setData({ list: res.data.data.data, tasks: res.data.data.campaign.tasks, total: res.data.data.total })
    } catch (error) {
      console.error("useActionList --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [page, pageSize, search_obj, update])
  return [data, loading]
}

export default useActionList
