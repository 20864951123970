import { Table, Tooltip } from 'antd'
import { Trans } from "@lingui/macro"
import useRelativeKOLs from './hooks/useRelativeKOLs'
import { useState } from 'react'
import { formatNum } from 'utils'
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'

const RelativeKOLs = ({ code }: { code: string }) => {
  const history = useHistory()
  const [time, setTime] = useState('week')
  const [page, setPage] = useState(1)
  const [{ list, total }, loading] = useRelativeKOLs(code, page, time)
  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 50,
      render: (_cur: any, _record: any, index: number) => (<div>{(index + 1)+(page-1)*8}</div>)
    },
    {
      title: <div><Trans>Username</Trans></div>,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200,
      render: (cur: any, record: any) => (
        <div className="flex items-center">
          <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />
          <div className="truncate">
            <Tooltip placement="topLeft" title={cur}>
              {cur}
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      title: <div><Trans>Followers</Trans></div>,
      dataIndex: 'followers_count',
      key: 'followers_count',
      width: 120,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
    {
      title: <div><Trans>Engagement</Trans></div>,
      dataIndex: 'engagement',
      key: 'engagement',
      width: 120,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded px-4 pt-4">
        <div className="flex flex-col md:flex-row">
          <div className="text-lg font-medium"><Trans>Relative KOLs</Trans></div>
          <div className="flex-1 flex md:justify-end text-xs mt-4 md:mt-0">
            <div onClick={() => setTime('week')} className={`${time === 'week' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-l py-1.5`}><Trans>7 Days</Trans></div>
            <div onClick={() => setTime('month')} className={`${time === 'month' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer py-1.5 border-l border-r border-ci border-opacity-20`}><Trans>30 Days</Trans></div>
            <div onClick={() => setTime('all')} className={`${time === 'all' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-r py-1.5`}><Trans>All</Trans></div>
          </div>
        </div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table className="" loading={loading} dataSource={list} columns={columns}
          rowKey={(record: any) => record.screen_name}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 8,
              total: total,
              // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  const arr = [{
                    screen_name: record.screen_name,
                    logo_url: record.logo_url,
                    name: record.name,
                  }]
                  cookie.save('soad_twitter', arr, { path: '/' })
                  history.push(`/twitter/KOL`)
                  window.scrollTo(0, 0)
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default RelativeKOLs
