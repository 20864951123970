import React, { FC, useEffect, useState } from 'react'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
// import { detect, fromStorage, fromNavigator } from '@lingui/detect-locale'

export const locales = ['en', 'zh-CN']
export const defaultLocale = 'en'

locales.map(locale => i18n.loadLocaleData(locale, { plurals: () => null }))

// const isLocaleValid = (locale: string) => locales.includes(locale)
const getInitialLocale = () => {
	// const detectedLocale = detect(fromStorage('lang'), fromNavigator(), () => defaultLocale)
	// detectedLocale && isLocaleValid(detectedLocale) ? detectedLocale : defaultLocale
	return defaultLocale
}

async function activate(locale: string) {
	const { messages } = await import(`@lingui/loader!./locales/${locale}.po`)
	i18n.load(locale, messages)
	i18n.activate(locale)
}

export const LanguageContext = React.createContext<{
	setLanguage: (_: string) => void
	language: string
}>({
	setLanguage: (_: string) => null,
	language: ''
})

const LanguageProvider: FC = ({ children }) => {
	const [language, setLanguage] = useState(getInitialLocale())
	const [init, setInit] = useState(false)

	const _setLanguage = (language: string): void => {
		activate(language).then(() => {
			localStorage.setItem('lang', language)
			setLanguage(language)
		})

	}

	useEffect(() => {
		const load = async () => {
			await activate(language)
			setInit(true)
		}

		load()
		// eslint-disable-next-line
	}, [])

	if (!init) return <></>

	return (
		<I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
			<LanguageContext.Provider value={{ setLanguage: _setLanguage, language }}>
				{children}
			</LanguageContext.Provider>
		</I18nProvider>
	)
}

export default LanguageProvider
