import { Trans } from "@lingui/macro"
import { Button, Form, Input, Modal, Table, Select, message, Upload } from "antd"
import { useState } from "react"
import { beforeUpload, isUrl, titleCase } from "utils"
import edit from 'assets/images/icon_edit.svg'
import search from 'assets/images/icon_search.svg'
import icon_up from 'assets/images/icon_up.svg'
import icon_upload from 'assets/images/icon_upload.svg'
import icon_right from 'assets/images/icon_right.svg'
import add_logo from 'assets/images/_icon_add.svg'
import add from 'assets/images/_icon_add.svg'
import { MinusCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import useSpaceToken from "./hooks/useSpaceToken"
import debounce from 'lodash/debounce'
import { mediaImages } from "config/media"
import { blockchainName, blockchainScan, networkNameIcon } from "config/blockchain"
import useCategories from "./hooks/useCategories"
import cookie from 'react-cookies'
import axios, { api_key, cur, secret_key } from "config/axios"
import { sha256 } from "js-sha256"

const { TextArea } = Input
const { Option } = Select

const Tokens = ({ isAdmin }: any) => {
  const [page, setPage] = useState(1)
  const [operation, setOperation] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [record, setRecord] = useState<any>('')
  const [update, setUpdate] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [title, setTitle] = useState('Add Token')
  const categories = useCategories()
  const [{ list, total }, loading] = useSpaceToken(page, 10, isAdmin, searchText, update)

  const [loadImg, setLoadImg] = useState(false)
  const [imageUrl, setImageUrl] = useState()

  const signed = cookie.load('soad_signed')
  const account = cookie.load('soad_acc')
  const space_code = cookie.load('soad_space_code')
  const timestamp = parseInt(new Date().getTime() / 1000 + '')
  const sha = sha256(api_key + secret_key + timestamp)

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadImg(true)
      return
    }

    if (info.file.status === 'done') {
      setLoadImg(false)
      const arr = info.fileList
      if (arr[arr.length - 1].response.code === 0) setImageUrl(arr[arr.length - 1].response.data)
    }
  }

  const columns: any = [
    {
      title: <div className="pl-2"><Trans>Token</Trans></div>,
      dataIndex: 'symbol',
      key: 'symbol',
      width: 150,
      render: (cur: any, record: any) => (
        <div className="flex items-center space-x-2 pl-2">
          <img className="h-5 rounded-full" src={record.logo_url} alt="" />
          <div>{cur}</div>
        </div>
      )
    },
    {
      title: <div><Trans>Owner</Trans></div>,
      dataIndex: 'space_name',
      key: 'space_name',
      width: 180,
      ellipsis: true,
    },
    {
      title: <div><Trans>Chains</Trans></div>,
      dataIndex: 'blockchains',
      key: 'blockchains',
      width: 140,
      render: (cur: any) => (
        <div className="flex space-x-2">
          {cur.map((key: string, index: number) => (
            <img key={index} className="h-5 rounded-full" src={networkNameIcon[key]} alt="" />
          ))}
        </div>
      )
    },
    {
      title: <div><Trans>Links</Trans></div>,
      dataIndex: 'urls',
      key: 'urls',
      width: 140,
      render: (cur: any) => (
        <div className="flex">
          {Object.keys(cur).map((key: string, index: number) => (
            <>{cur[key] && <a className="mr-1.5" key={index} href={cur[key]} target='_blank' rel="noreferrer"><img className="h-4" src={mediaImages[key]} alt="" /></a>}</>
          ))}
        </div>
      )
    },
    {
      title: <div><Trans>Operations</Trans></div>,
      dataIndex: '',
      key: 'operations',
      width: 100,
      render: (_: any, record: any) => (
        <div>
          <Button onClick={() => {
            setRecord(record)
            setImageUrl(record.logo_url)
            setTitle('Edit Token')
            setOperation(true)
          }} className="px-3.5 h-7 rounded-full flex items-center" type='primary'>
            <Trans>Edit</Trans>
            <img className="ml-0.5" src={edit} alt="" />
          </Button>
        </div>
      )
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    const chains = fieldValues.token_addresses.map((field: any) => field.blockchain)
    const new_chains = new Set(chains)
    if ([...new_chains].length !== chains.length) {
      message.error('Blockchain is repeated!')
      return
    }

    if (!Object.keys(fieldValues).length) return
    const arg = {
      ...fieldValues,
      token_addresses: JSON.stringify(fieldValues.token_addresses)
    }
    if (record.project_code) arg.code = record.project_code
    if (imageUrl) arg.logo_url = imageUrl
    setEditLoading(true)
    const res = await axios.post('/api/admin/project/token', arg)
    setEditLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setOperation(false)
      setUpdate((state) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const getSearch = debounce((value: string) => {
    setSearchText(value)
  }, 500)

  return (
    <div>
      <div className="bg-ncon rounded-lg border border-bord">
        <div className="flex items-center px-6">
          <div className="flex-1 py-4 flex items-center font-semibold space-x-4">
            <div><Trans>Tokens</Trans></div>
            {isAdmin && <div>
              <Button onClick={() => {
                setRecord('')
                setImageUrl(undefined)
                setTitle('Add Token')
                setOperation(true)
              }} className="px-3.5 h-7 rounded-full flex items-center" type='primary'>
                <div><Trans>Add</Trans></div>
                <img className="ml-0.5" src={add} alt="" />
              </Button>
            </div>}
          </div>
          <div className="relative">
            <Input
              onChange={(e: any) => getSearch(e.target.value)} placeholder="Search Token" className="rounded-full w-80 pl-11 text-sm h-9 bg-transparent border border-bord hover:border-ci focus:border-ci" />
            <div className="absolute h-full flex items-center top-0 left-4"><img src={search} alt="" /></div>
          </div>
        </div>
        <div className="py-1 overflow-x-scroll">
          <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
            rowKey={(record) => record.project_code + record.space_code}
            rowClassName={(_, index) => {
              let className = 'nrodd'
              if (index % 2 === 0) className = 'nreven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 10,
              total: total,
              // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender: record => (
                <div className="pl-20 text-white text-opacity-75">
                  <div className="flex items-center space-x-3 border-b border-bord pb-4">
                    <div><Trans>Name:</Trans></div>
                    <div>{record.name}</div>
                  </div>
                  <div className="py-4 border-b border-bord">
                    <div><Trans>Contract Address:</Trans></div>
                    {record.token_addresses.map((chain: any, index: number) => (
                      <>{blockchainName[chain.blockchain] && <div key={index} className="flex items-center mt-3 space-x-2">
                        <img className="h-5 rounded-full" src={networkNameIcon[chain.blockchain]} alt="" />
                        <div className="w-20">{titleCase(blockchainName[chain.blockchain])}:</div>
                        <a className="text-white text-opacity-75 hover:text-opacity-90 underline" href={`${blockchainScan[chain.blockchain]}/address/${chain.address}`} target='_blank' rel="noreferrer">{chain.address}</a>
                      </div>}</>
                    ))}
                  </div>
                  <div className="pt-4">
                    <div><Trans>Links:</Trans></div>
                    {Object.keys(record.urls).map((url: any, index: number) => (
                      <>{record.urls[url] && <div key={index} className="flex items-center mt-3 space-x-2">
                        <img className="h-5 rounded-full" src={mediaImages[url]} alt="" />
                        <div className="w-20">{titleCase(url)}:</div>
                        <a className="text-aco text-opacity-75 hover:text-opacity-90 underline" href={record.urls[url]} target='_blank' rel="noreferrer">{record.urls[url]}</a>
                      </div>}</>
                    ))}
                  </div>
                </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <img className="h-5" src={icon_up} onClick={e => onExpand(record, e)} alt='' />
                ) : (
                  <img className="h-5" src={icon_right} onClick={e => onExpand(record, e)} alt='' />
                )
            }}
          />
        </div>
      </div>
      <Modal destroyOnClose={true} title={title} width={836} visible={operation} onCancel={() => {
        setOperation(false)
      }} footer={null} centered>
        <div className="space-y-6">
          <div className="flex items-center space-x-2 token">
            <div className="text-center">
              <Upload
                name="file"
                headers={{
                  APIKEY: api_key,
                  TIME: timestamp as any,
                  SIGN: sha,
                  signature: signed,
                  address: account + '',
                  "Space-Code": space_code
                }}
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${cur}/api/admin/user/upload`}
                beforeUpload={(file) => beforeUpload(file, 5, 1, 1)}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <div className="relative img_wrap">
                    <img
                      className="w-full rounded-full"
                      src={imageUrl}
                      alt="avatar"
                    />
                    <div className="img_mask flex items-center justify-center">
                      <img src={icon_upload} alt="" />
                    </div>
                  </div>
                ) : (
                  <div>
                    {loadImg ? <LoadingOutlined className="text-ci" /> : <img src={icon_upload} alt='' />}
                  </div>
                )}
              </Upload>
            </div>
            <div className="text-yellow-600">PNG, JPG 1:1 logo (Max 5mb)</div>
          </div>
          <Form
            name="basic"
            layout="vertical"
            initialValues={!record ? { token_addresses: [{}] } : {
              symbol: record.symbol,
              name: record.name,
              introductions: record.introductions,
              categories: record.categories,
              ...record.urls,
              token_addresses: record.token_addresses.length ? record.token_addresses : [{}]
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="grid grid-cols-2 gap-x-6">
              <Form.Item
                label="Symbol:"
                name="symbol"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your symbol!',
                  },
                ]}
              >
                <Input disabled={title === 'Add Token' ? false : true} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
              </Form.Item>
              <Form.Item
                label="Name:"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name!',
                  },
                ]}
              >
                <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
              </Form.Item>
            </div>
            {!record.name && <div className="grid grid-cols-2 gap-x-6">
              <Form.Item
                label="Coingecko ID :"
                name="coingecko_id"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter your Coingecko ID!',
              //   },
              // ]}
              >
                <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
              </Form.Item>
            </div>}
            <div className="mb-6"><Trans>Contract address:</Trans></div>
            <Form.List name="token_addresses">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index: number) => (
                    <div key={key}>
                      <div className="flex items-center gap-x-6 py-0">
                        <Form.Item
                          className="w-80"
                          {...restField}
                          label='Blockchain:'
                          name={[index, 'blockchain']}
                          rules={[
                            {
                              required: true,
                              message: 'blockchain is required!',
                            },
                          ]}
                        >
                          <Select
                            showArrow={true}
                            dropdownClassName="border border-bord"
                          >
                            {
                              Object.entries(blockchainName).map(([sname, name]: any, index: number) => (
                                <Option value={sname} key={index}>{titleCase(name)}</Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="flex-1"
                          {...restField}
                          label='Address:'
                          name={[index, 'address']}
                          rules={[
                            // {
                            //   validator: (_, value) => {
                            //     if (value && !isAddress(value)) {
                            //       return Promise.reject('Please enter correct address!')
                            //     } else {
                            //       return Promise.resolve()
                            //     }
                            //   }
                            // }
                          ]}
                        >
                          <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </div>
                    </div>
                  ))}
                  <Form.Item className="mt-2">
                    <Button onClick={() => add()} className="px-3.5 h-7 rounded-full flex items-center" type='primary'>
                      <div><Trans>Add</Trans></div>
                      <img className="ml-0.5" src={add_logo} alt="" />
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="mb-4 mt-6"><Trans>Links:</Trans></div>
            <div className="grid grid-cols-2 gap-x-6">
              <Form.Item
                label=""
                name="twitter"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['twitter']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
              <Form.Item
                label=""
                name="telegram"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['telegram']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
              <Form.Item
                label=""
                name="discord"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['discord']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
              <Form.Item
                label=""
                name="homepage"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['homepage']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
              <Form.Item
                label=""
                name="github"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['github']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
              <Form.Item
                label=""
                name="medium"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['medium']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
              <Form.Item
                label=""
                name="opensea"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['opensea']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
              <Form.Item
                label=""
                name="nftscan"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isUrl(value)) {
                        return Promise.reject('Please enter the correct url format!')
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input prefix={<img src={mediaImages['nftscan']} className='w-5 h-5' alt='' />} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
              </Form.Item>
            </div>
            <Form.Item
              label="Description"
              name="introductions"
              rules={[
                {
                  required: true,
                  message: 'Please enter your description!',
                },
              ]}
            >
              <TextArea placeholder="" className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' rows={4} />
            </Form.Item>
            <div className="grid grid-cols-2 gap-x-6">
              <Form.Item
                label="Categories"
                name="categories"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your categories!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select a tag"
                  dropdownClassName="border border-bord"
                  notFoundContent={<div className="text-center text-white">No Data</div>}
                >
                  {
                    categories.map((categora: any) => (
                      <Option key={categora.categora_id} value={categora.categora_id}>{categora.categora_name}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </div>
            <div className="flex justify-center"><Button loading={editLoading} htmlType="submit" size='large' className='px-20 mt-2 md:mt-8 rounded' type='primary'><Trans>Save</Trans></Button></div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default Tokens
