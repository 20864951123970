import React, { useMemo, useState } from 'react'
import { Column } from '@ant-design/charts'
import { Trans } from '@lingui/macro'
import { Spin } from 'antd'
import { useRequest } from 'ahooks'
import axios from 'axios'
import moment from 'moment'
const DailySnapshotColumn: React.FC = () => {
  const [time, setTime] = useState('week')
  const { data, loading } = useRequest(() => axios.get(`/api/admin/stats/gemstone/list?interval=${time}`), {
    refreshDeps: [time],
  })

  const oldData = data?.data?.data
  let newData: { type: any; value: any }[] = []
  if (oldData?.length) {
    for (let x = 0; x < oldData?.length; x++) {
      const burned = {
        type: 'Burned',
        value: oldData[x].total_burned,
        date: moment(oldData[x].date * 1000).format('YYYY-MM-DD'),
      }
      const issued = {
        type: 'Issued',
        value: oldData[x].total_issued,
        date: moment(oldData[x].date * 1000).format('YYYY-MM-DD'),
      }
      newData.push({ ...burned }, { ...issued })
    }
  }
  const config: any = {
    data: [...newData] || [],
    isGroup: true,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    yAxis: {
      label: {
        autoRotate: false,
      },
      grid: {
        line: {
          style: {
            opacity: 0.1,
          },
        },
      },
    },
    /** 设置颜色 */
    color: ['#1ca9e6', '#f88c24'],

    /** 设置间距 */
    marginRatio: 0.1,
    label: {
      // 可手动配置 label 数据标签位置
      position: 'top',
      // 'top', 'middle', 'bottom'
      style: {
        fill: '#ffffff',
        opacity: 0.6,
      },
      // 可配置附加的布局方法
    },
  }
  const graph = useMemo(() => {
    return <Column {...config} />
    // eslint-disable-next-line
  }, [data])

  return (
    <div className="bg-con rounded px-4 py-4 relative">
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 flex md:justify-end text-xs mt-4 md:mt-0">
          <div
            onClick={() => setTime('week')}
            className={`${time === 'week' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-l py-1.5`}
          >
            <Trans>7 Days</Trans>
          </div>
          <div
            onClick={() => setTime('month')}
            className={`${
              time === 'month' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'
            } w-16 flex items-center justify-center cursor-pointer py-1.5 border-l border-r border-ci border-opacity-20`}
          >
            <Trans>30 Days</Trans>
          </div>
          <div
            onClick={() => setTime('quarter')}
            className={`${time === 'quarter' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-r py-1.5`}
          >
            <Trans>90 Days</Trans>
          </div>
        </div>
      </div>
      {loading && (
        <div className="absolute left-1/2 top-1/2 z-40">
          <Spin />
        </div>
      )}
      <div className="mt-14">{graph}</div>
    </div>
  )
}

export default DailySnapshotColumn
