import { Trans } from "@lingui/macro"
import { DualAxes } from '@ant-design/plots'
import useLines from "./hooks/useLines"
import { useMemo, useState } from "react"
import { Spin } from "antd"
import { formatNum, formatTooltip } from "utils"

const Communities = () => {
  const [time, setTime] = useState('week')
  const [data, loading] = useLines(time)
  const valueArr = [], countArr = []
  for (const obj of data.communities) {
    valueArr.push(obj.value)
  }
  for (const obj of data.members) {
    countArr.push(obj.count)
  }
  const config: any = {
    data: [data.communities, data.members],
    xField: 'date',
    yField: ['value', 'count'],
    xAxis: {
      line: {
        style: {
          stroke: '#484646',
        }
      }
    },
    yAxis: {
      'value': {
        label: {
          formatter: (y: any) => formatNum(y)
        },
        grid: {
          line: {
            style: {
              stroke: '#484646',
              lineDash: [4, 5],
            }
          }
        },
        max: Math.min(...valueArr) === Math.max(...valueArr) ? valueArr[0] * 1.4 : Math.max(...valueArr),
        min: Math.min(...valueArr) === Math.max(...valueArr) ? 0 : Math.min(...valueArr),
      },
      'count': {
        label: {
          formatter: (y: any) => formatNum(y)
        },
        max: Math.min(...countArr) === Math.max(...countArr) ? countArr[0] * 1.2 : Math.max(...countArr),
        min: Math.min(...countArr) === Math.max(...countArr) ? 0 : Math.min(...countArr),
      },
    },
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'type',
      },
      {
        geometry: 'line',
        seriesField: 'type',
      },
    ],
    tooltip: {
      title: 'fullTime',
      formatter: (item: any) => {
        return { name: item.type, value: formatTooltip(item.value) || formatTooltip(item.count) }
      }
    }
  }
  const graph = useMemo(() => {
    return (
      <DualAxes {...config} />
    )
    // eslint-disable-next-line
  }, [data])
  return (
    <div className="bg-con rounded px-4 py-4 relative">
      <div className="flex flex-col md:flex-row">
        <div className="text-lg font-medium"><Trans>Crypto Communities & Members</Trans></div>
        <div className="flex-1 flex md:justify-end text-xs mt-4 md:mt-0">
          <div onClick={() => setTime('week')} className={`${time === 'week' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-l py-1.5`}><Trans>7 Days</Trans></div>
          <div onClick={() => setTime('month')} className={`${time === 'month' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer py-1.5 border-l border-r border-ci border-opacity-20`}><Trans>30 Days</Trans></div>
          <div onClick={() => setTime('all')} className={`${time === 'all' ? 'bg-tbg text-white' : 'bg-tbg bg-opacity-20 text-gray-400'} w-16 flex items-center justify-center cursor-pointer rounded-r py-1.5`}><Trans>All</Trans></div>
        </div>
      </div>
      {loading && <div className="absolute left-1/2 top-1/2 z-40"><Spin /></div>}
      <div className="mt-14">{graph}</div>
    </div>
  )
}

export default Communities
