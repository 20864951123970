import { MinusCircleOutlined, DownOutlined } from '@ant-design/icons'
import { Form, Input, Select, Collapse } from 'antd'
import rewardlogo from 'assets/images/rewardlogo.svg'
import { isNumber2 } from 'utils'
import { useEffect } from 'react'

const { Option } = Select
const { Panel } = Collapse
const RewardWhiteList = ({ reward, index, setRewardsList }: any) => {
  const [form] = Form.useForm()

  const deleteFun = () => {
    setRewardsList((state: any) => {
      const list = state
      const index = list.indexOf(list.find((li: any) => li.uuid === reward.uuid))
      list.splice(index, 1)
      return [...list]
    })
  }

  const modeSelect = (value: any) => {
    if (Number(value) === 3) {
      form.setFieldsValue({
        shares: -1,
      })
    } else {
      form.setFieldsValue({
        shares: '',
      })
    }
  }

  useEffect(() => {
    const formObj = reward.name
      ? {
          ...reward,
        }
      : {
          type: 'white_list',
        }
    form.setFieldsValue(formObj)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="bg-resbg px-4 border border-bord rounded-md">
      <div className="py-3 border-b border-bord">
        <div className="flex items-center">
          <img src={rewardlogo} alt="" />
          <div className="text-valid ml-2">Reward-{index + 1}</div>
          <div className="ml-6 px-4 py-1 text-xs rounded-full bg-whitelist bg-opacity-20 text-whitelist">#Whitelist</div>
          <div className="flex-1 flex justify-end -mr-12">
            <MinusCircleOutlined onClick={deleteFun} className="text-base cursor-pointer" />
          </div>
        </div>
      </div>
      <div className="py-4">
        <Form name={'form' + (index + 1)} form={form} layout="vertical" autoComplete="off">
          <div className="grid grid-cols-3 gap-4">
            <Form.Item className="col-span-1 hidden" label="Reward Type:" name="type">
              <Select disabled>
                <Option value="nft">NFT</Option>
                <Option value="token">Token</Option>
                <Option value="white_list">White List</Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="col-span-3"
              label="Reward Name:"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Reward is required!',
                },
              ]}
            >
              <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <Form.Item
              label="Blockchain type:"
              name="chain_type"
              rules={[
                {
                  required: true,
                  message: 'Blockchain is required!',
                },
              ]}
            >
              <Select placeholder="Select chain type" showArrow={true} dropdownClassName="border border-bord">
                <Option value="evm">EVM</Option>
                <Option value="aptos">Aptos</Option>
                <Option value="ton">TON</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Mode:"
              name="mode"
              rules={[
                {
                  required: true,
                  message: 'Mode is required!',
                },
              ]}
            >
              <Select placeholder="Select a mode" showArrow={true} dropdownClassName="border border-bord" onSelect={modeSelect}>
                <Option value={1}>First come first served</Option>
                <Option value={2}>Manually Generated</Option>
                <Option value={3}>Unlimited</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Number of winners:"
              name="shares"
              rules={[
                {
                  required: true,
                  message: 'Number of winners is required!',
                },
                {
                  validator: (_, value) => {
                    if (value && !isNumber2(value)) {
                      return Promise.reject('Number of winners can only be numbers!')
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input placeholder="1000" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
            {/* <Form.Item
              label='Claim Button Text:'
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Claim Button Text is required!',
                },
              ]}
            >
              <Input placeholder="Claim OAT NFT" className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
            </Form.Item> */}
          </div>
          <div>
            <Form.Item
              label="Claim Link:"
              name="link"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Claim Link is required!',
              //   },
              // ]}
            >
              <Input placeholder="https://galxe.com/port3network/campaign/GCBs5UtrYX" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
          </div>
          <Collapse defaultActiveKey={['']} className="rounded-md" onChange={(key: string | string[]) => {}}>
            <Panel header="Sync Type" key="synctype" showArrow={false} extra={<DownOutlined />}>
              <div className="grid grid-cols-3 gap-4">
                <Form.Item label="Sync Type:" name="sync_type">
                  <Select allowClear>
                    <Option value="galxe">Galxe</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Credential ID:" name="credential_id">
                  <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                </Form.Item>
                <Form.Item label="Access Token:" name="access_token">
                  <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                </Form.Item>
                <Form.Item className="hidden" label="id:" name="id">
                  <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
                </Form.Item>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  )
}

export default RewardWhiteList
