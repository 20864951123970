import { Trans } from '@lingui/macro'
import { Button, Dropdown, message, Switch } from 'antd'
import add from 'assets/images/_icon_add.svg'
import arrow_r from 'assets/images/icon_arrow_r.svg'
import FollowTwitter from './MediaForm/FollowTwitter'
import { useEffect, useMemo, useState } from 'react'
import { filterTrim, getUuiD } from 'utils'
import RetweetTwitter from './MediaForm/RetweetTwitter'
import LikeTwitter from './MediaForm/LikeTwitter'
import JoinTelegram from './MediaForm/JoinTelegram'
import JoinDiscord from './MediaForm/JoinDiscord'
import AMATelegram from './MediaForm/AMATelegram'
import AMADiscord from './MediaForm/AMADiscord'
import VisitWebsite from './MediaForm/VisitWebsite'
import InviteUser from './MediaForm/InviteUser'
import HoldingToken from './MediaForm/HoldingToken'
import HoldingNft from './MediaForm/HoldingNft'
import { mediaImages } from 'config/media'
import DownloadApp from './MediaForm/DownloadApp'
import AnswerQuestion from './MediaForm/AnswerQuestion'
import ConnectWallet from './MediaForm/ConnectWallet'
import BindAccount from './MediaForm/BindAccount'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import axios from 'axios'
import InteractContract from './MediaForm/InteractContract'
import InteractProtocol from './MediaForm/InteractProtocol'
import ChannelCode from './MediaForm/ChannelCode'
import CompLikeVotes from './MediaForm/CompLikeVotes'
import ProofOfWork from './MediaForm/ProofOfWork'
import AnswerQuestion2 from './MediaForm/AnswerQuestion2'
import MintOkxCupNft from './MediaForm/MintOkxCupNft'
import InviteUser2 from './MediaForm/InviteUser2'
import Groups from './Group'
import QuoteTwitter from './MediaForm/QuoteTwitter'
import RetweetAndLikeTwitter from './MediaForm/RetweetAndLike'
import ReplyTwitter from './MediaForm/ReplyTwitter'
import JoinChannel from './MediaForm/JoinChannel'
const Tasks = ({ setStep, setUpdate, tasks, trans_code, groups, group_titles }: any) => {
  const [tab, setTab] = useState('offchain')
  const [tasks_arr, setTasks_arr] = useState<any>([])
  const [tasksGroupIds, setTaskIds] = useState<any>([])
  const [tgBotList, setTgBotList] = useState<any>([])
  const [discordBotList, setDiscordBotList] = useState<any>([])
  const [checkGroup, setCheckGroup] = useState(false)
  const [groupTitles, setGroupTitles] = useState<any>([])
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? '#7754F8' : 'rgba(0, 19, 33, 0.2)',
    ...draggableStyle,
  })

  for (const [index, task] of tasks_arr.entries()) {
    task.uuid = getUuiD(index)
  }
  const mediaEle = useMemo(() => {
    return tasks_arr.map((task: any, index: number) => {
      let ele: any
      if (task.platform === 'twitter') {
        if (task.type === 'follow') {
          // isOpen={index === tasks_arr.length - 1}
          ele = <FollowTwitter tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else if (task.type === 'retweet') {
          ele = <RetweetTwitter tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else if (task.type === 'retweet_and_like') {
          ele = <RetweetAndLikeTwitter tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else if (task.type === 'quoted') {
          ele = <QuoteTwitter tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else if (task.type === 'reply') {
          ele = <ReplyTwitter tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else {
          ele = <LikeTwitter tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        }
      } else if (task.platform === 'telegram') {
        if (task.type === 'join') {
          ele = <JoinTelegram tasks_arr={tasks_arr} botList={tgBotList} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else if (task.type === 'join_channel') {
          ele = <JoinChannel tasks_arr={tasks_arr} botList={tgBotList} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else {
          ele = <AMATelegram tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        }
      } else if (task.platform === 'discord') {
        if (task.type === 'join') {
          ele = <JoinDiscord tasks_arr={tasks_arr} botList={discordBotList} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        } else {
          ele = <AMADiscord tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
        }
      } else if (task.type === 'invite') {
        ele = <InviteUser tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'open_refer') {
        ele = <InviteUser2 tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'holding_token') {
        ele = <HoldingToken tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'holding_nft') {
        ele = <HoldingNft tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'visit') {
        ele = <VisitWebsite tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'download') {
        ele = <DownloadApp tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'answer_question') {
        ele = <AnswerQuestion tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'channel') {
        ele = <ChannelCode tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'connect_wallet') {
        ele = <ConnectWallet tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'bind_web2_id') {
        ele = <BindAccount tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'onchain_interact') {
        ele = <InteractProtocol tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'holding_comp_votes') {
        ele = <CompLikeVotes tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'proof_of_work') {
        ele = <ProofOfWork tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'quiz' || task.type === 'vote' || task.type === 'open_question') {
        ele = <AnswerQuestion2 tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else if (task.type === 'mint_okx_cup_nft') {
        ele = <MintOkxCupNft tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      } else {
        ele = <InteractContract tasks_arr={tasks_arr} key={task.uuid} setTasks_arr={setTasks_arr} task={task} trans_code={trans_code} />
      }
      return (
        <Draggable key={task.uuid} draggableId={task.uuid} index={index}>
          {(provided: any, snapshot: any) => (
            <div
              className="w-full rounded-md"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              {ele}
            </div>
          )}
        </Draggable>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks_arr, tgBotList, trans_code])
  const reorder = (list: [], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = async (result: any) => {
    if (!result.destination) return
    const source_task = tasks_arr[result.source.index]
    const dragArr = reorder(tasks_arr, result.source.index, result.destination.index)
    setTasks_arr([...dragArr])
    if (!source_task.id) return
    const task_ids = dragArr.filter((task: any) => task.id).map((task: any) => task.id)
    await axios.post('/api/admin/space/campaign/sort', {
      campaign_code: trans_code,
      task_ids,
    })
  }

  const getRotList = async () => {
    const dRes = await axios.get(`/api/admin/comm/bot/list?platform=discord`)
    if (dRes.status === 200 && dRes.data.code === 0) {
      setDiscordBotList(dRes.data.data)
    }

    const tRes = await axios.get(`/api/admin/comm/bot/list?platform=telegram`)
    if (tRes.status === 200 && tRes.data.code === 0) {
      setTgBotList(tRes.data.data)
    }
  }

  const onChangeSwitch = async (checked: boolean) => {
    if (!tasks_arr.length) return message.info('Please add a task')
    let order = null
    if (checked) {
      order = 'open'
    } else order = 'off'
    let params = {
      campaign_code: trans_code,
      status: order,
    }
    // if (!checked) {
    //   if (!filterTrim(tasksGroupIds[0]).length) return setCheckGroup(checked)

    //   confirm({
    //     title: 'Do you want to close or empty it?',
    //     icon: <ExclamationCircleOutlined />,
    //     content: '',
    //     okText: 'close',
    //     cancelText: 'empty',
    //     onOk: async () => {
    //       await axios.post('/api/admin/space/campaign/task/group/status', { ...params })
    //       setCheckGroup(checked)
    //     },
    //     onCancel: async () => {
    //       let paramsEmpty = {
    //         campaign_code: trans_code,
    //         status: 'remove',
    //       }
    //       const { data } = await axios.post('/api/admin/space/campaign/task/group/status', { ...paramsEmpty })
    //       if (+data.code === 0) {
    //         message.success('Empty success')
    //         setCheckGroup(checked)
    //         setUpdate((preUpdate: boolean) => !preUpdate)
    //       } else {
    //         message.error('Empty error')
    //         setCheckGroup(true)
    //       }
    //     },
    //   })
    // } else {
    //   const { data } = await axios.post('/api/admin/space/campaign/task/group/status', { ...params })
    //   if (checked) setTaskIds(data.data)
    //   setCheckGroup(checked)
    // }
    const { data } = await axios.post('/api/admin/space/campaign/task/group/status', { ...params })
    if (checked) {
      setTaskIds([...data.data])
    }
    setCheckGroup(checked)
  }
  const reqCampaign = async () => {
    const params = tasksGroupIds.map((item: any) => filterTrim(item))
    await axios.post('/api/admin/space/campaign', {
      step: 4,
      campaign_code: trans_code,
      groups: [...params],
      group_titles: [...groupTitles],
    })
    setUpdate((update: boolean) => !update)
  }
  useEffect(() => {
    // tasks = []
    setUpdate((state: any) => !state)
    getRotList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setTasks_arr(tasks)
    // eslint-disable-next-line
  }, [tasks])

  useEffect(() => {
    setCheckGroup(groups?.length)
  }, [groups])
  useEffect(() => {
    setGroupTitles([...group_titles])
  }, [group_titles])
  return (
    <div className="bg-ncon border border-bord rounded-lg mb-10" style={{ minWidth: checkGroup ? 1100 : 'auto' }}>
      <div className="flex justify-between h-14 px-5 py-2 border-b border-bord bg-ncon1 rounded-t-lg items-center ">
        <div className="flex items-center space-x-4">
          <div className="font-semibold">
            <Trans>Campaign Tasks</Trans>
          </div>
          <div className="text-xs text-white text-opacity-70">
            <Trans>Users need to finish following task to go Reward</Trans>
          </div>
        </div>
        <div className={`flex justify-end items-center `}>
          <Switch defaultChecked={groups?.length} checked={checkGroup} onChange={onChangeSwitch} />
          <span className="ml-3">Create Task Group</span>
        </div>
      </div>
      <div className="px-5 py-6">
        <div className="flex" style={{ minWidth: 1280 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided: any) => (
                <div className={`space-y-4 transition-all delay-300 w-fit`} {...provided.droppableProps} ref={provided.innerRef}>
                  {mediaEle}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {checkGroup ? (
            <Groups groupTitles={groupTitles} setGroupTitles={setGroupTitles} groups={groups} tasksGroupIds={tasksGroupIds} setTaskIds={setTaskIds} trans_code={trans_code} tasks_arr={tasks_arr} />
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1 flex space-x-8 font-medium mt-10 pb-4 border-b border-bord">
          <div onClick={() => setTab('offchain')} className={`cursor-pointer ${tab === 'offchain' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}>
            <Trans>Off-chain</Trans>
          </div>
          <div onClick={() => setTab('onchain')} className={`cursor-pointer ${tab === 'onchain' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}>
            <Trans>On-chain</Trans>
          </div>
          <div onClick={() => setTab('customized')} className={`cursor-pointer ${tab === 'customized' ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}>
            <Trans>Customized</Trans>
          </div>
        </div>
        <div className="flex items-center space-x-2 mt-4">
          <img className="h-3.5" src={add} alt="" />
          <div>
            <Trans>Add Task to Campaign</Trans>
          </div>
        </div>
        {tab === 'offchain' && (
          <div className="w-7/12 mt-5 space-y-5">
            <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3">A</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'quiz',
                        platform: 'all',
                        select: 'single',
                        votes: '1',
                        question: '',
                        title: '',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-abg rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['answer_question']} alt="" />
                  <div>Answer Question</div>
                </div>
              </div>
            </div>
            {/* Bind Web2 Account */}
            {/* <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3">B</div>
              <div className="flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'bind_web2_id',
                        platform: 'media',
                        media: [],
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-accbg rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['bind_account']} alt="" />
                  <div>Bind Web2 Account</div>
                </div>
              </div>
            </div> */}
            <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3">C</div>
              <div className="flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'channel',
                        platform: 'channel',
                        title: '',
                        tip: '',
                        question: '',
                        code: [],
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-yellow-600 rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['channel']} alt="" />
                  <div>Channel Code</div>
                </div>
              </div>
            </div>
            <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3">D</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <div className="bg-ncon1 rounded-xl border border-bord divide-y divide-bord cursor-pointer">
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'join',
                              platform: 'discord',
                              bot: '',
                              room_id: '',
                              url: '',
                              votes: '1',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Join on Discord</Trans>
                      </div>
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'text_ama',
                              platform: 'discord',
                              room_id: '',
                              url: '',
                              votes: '1',
                              title: '',
                              start_time: '',
                              end_time: '',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>AMA on Discord</Trans>
                      </div>
                    </div>
                  }
                >
                  <div className="bg-dis rounded-md px-4 py-1 cursor-pointer flex">
                    <img className="mr-2" src={mediaImages['discord']} alt="" />
                    <div>Discord</div>
                  </div>
                </Dropdown>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'download',
                        platform: 'all',
                        url: '',
                        votes: '1',
                        title: '',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-dis rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['download_app']} alt="" />
                  <div>Download App</div>
                </div>
              </div>
            </div>
            <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3 pl-0.5">I</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'open_refer',
                        platform: 'all',
                        votes: '1',
                        invites_per_user: '1',
                        max_invites_per_user: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-green-800 rounded-md px-4 py-1 cursor-pointer flex"
                >
                  <img className="mr-2" src={mediaImages['icon_invite']} alt="" />
                  <div>Invite Friend</div>
                </div>
              </div>
            </div>
            <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3 pl-0.5">P</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'proof_of_work',
                        platform: 'all',
                        votes: '1',
                        title: '',
                        url: '',
                        description: '',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-purple-600 rounded-md px-4 py-1 cursor-pointer flex"
                >
                  <img className="mr-2" src={mediaImages['proof']} alt="" />
                  <div>Proof of Work</div>
                </div>
              </div>
            </div>
            <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3">T</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <div className="bg-ncon1 rounded-xl border border-bord divide-y divide-bord cursor-pointer">
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'follow',
                              platform: 'twitter',
                              screen_name: '',
                              votes: '1',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Follow on Twitter</Trans>
                      </div>
                      {/* <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'retweet',
                              platform: 'twitter',
                              url: '',
                              votes: '1',
                              title: '',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Retweet on Twitter</Trans>
                      </div> */}
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'retweet_and_like',
                              platform: 'twitter',
                              url: '',
                              task_types: [],
                              votes: '1',
                              title: '',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Retweet & Like on Twitter</Trans>
                      </div>
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'quoted',
                              platform: 'twitter',
                              url: '',
                              content: '',
                              votes: '1',
                              title: '',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Quote on Twitter</Trans>
                      </div>
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'reply',
                              platform: 'twitter',
                              url: '',
                              content: '',
                              votes: '1',
                              title: '',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Reply on Twitter</Trans>
                      </div>
                      {/* <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'like',
                              platform: 'twitter',
                              url: '',
                              votes: '1',
                              title: '',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Like on Twitter</Trans>
                      </div> */}
                    </div>
                  }
                >
                  <div className="bg-twi rounded-md px-4 py-1 cursor-pointer flex">
                    <img className="mr-2" src={mediaImages['twitter']} alt="" />
                    <div>Twitter</div>
                  </div>
                </Dropdown>
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <div className="bg-ncon1 rounded-xl border border-bord divide-y divide-bord cursor-pointer">
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'join',
                              platform: 'telegram',
                              bot: '',
                              room_id: '',
                              url: '',
                              votes: '1',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Join on Telegram</Trans>
                      </div>
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'text_ama',
                              platform: 'telegram',
                              room_id: '',
                              url: '',
                              votes: '1',
                              title: '',
                              start_time: '',
                              end_time: '',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>AMA on Telegram</Trans>
                      </div>
                      <div
                        onClick={() => {
                          setTasks_arr((state: any) => [
                            ...state,
                            {
                              type: 'join_channel',
                              platform: 'telegram',
                              bot: '',
                              room_id: '',
                              url: '',
                              votes: '1',
                              mandatory: true,
                            },
                          ])
                        }}
                        className="px-3 py-1.5 hover:text-ci"
                      >
                        <Trans>Join channel on Telegram</Trans>
                      </div>
                    </div>
                  }
                >
                  <div className="bg-twi rounded-md px-4 py-1 cursor-pointer flex">
                    <img className="mr-2" src={mediaImages['telegram']} alt="" />
                    <div>Telegram</div>
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className="flex space-x-7">
              <div className="pt-5p text-white text-opacity-60 w-3">V</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'visit',
                        platform: 'website',
                        url: '',
                        votes: '1',
                        title: '',
                        action: 'Visit',
                        countdown: '0',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-green-600 rounded-md px-4 py-1 cursor-pointer flex"
                >
                  <img className="mr-2" src={mediaImages['homepage']} alt="" />
                  <div>Visit Website</div>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 'onchain' && (
          <div className="w-7/12 mt-5 space-y-5">
            <div className="flex flex-wrap gap-3 mb-8 pl-28">
              <div
                onClick={() => {
                  setTasks_arr((state: any) => [
                    ...state,
                    {
                      type: 'holding_token',
                      platform: 'web3',
                      votes: '1',
                      symbol: '',
                      blockchain: '',
                      contract_address: '',
                      threshold_balance: '1',
                      mandatory: true,
                    },
                  ])
                }}
                className="bg-ci bg-opacity-20 rounded-md px-4 py-1 cursor-pointer flex"
              >
                <img className="mr-2" src={mediaImages['icon_token']} alt="" />
                <div>Holding Token</div>
              </div>
              <div
                onClick={() => {
                  setTasks_arr((state: any) => [
                    ...state,
                    {
                      type: 'holding_nft',
                      platform: 'web3',
                      votes: '1',
                      symbol: '',
                      blockchain: '',
                      contract_address: '',
                      threshold_balance: '1',
                      mandatory: true,
                    },
                  ])
                }}
                className="bg-ci bg-opacity-20 rounded-md px-4 py-1 cursor-pointer flex"
              >
                <img className="mr-2" src={mediaImages['icon_nft']} alt="" />
                <div>Holding NFT</div>
              </div>
              <div
                onClick={() => {
                  setTasks_arr((state: any) => [
                    ...state,
                    {
                      type: 'connect_wallet',
                      platform: 'wallet',
                      votes: '1',
                      mandatory: true,
                    },
                  ])
                }}
                className="bg-ci bg-opacity-20 rounded-md px-4 py-1 cursor-pointer flex w-fit"
              >
                <img className="mr-2" src={mediaImages['connect_wallet']} alt="" />
                <div>Connect Wallet</div>
              </div>
              <div
                onClick={() => {
                  setTasks_arr((state: any) => [
                    ...state,
                    {
                      type: 'onchain_common_interact',
                      platform: 'Contract',
                      blockchain: '',
                      contract_address: '',
                      start_time: '',
                      end_time: '',
                      url: '',
                      title: '',
                      votes: '1',
                      mandatory: true,
                    },
                  ])
                }}
                className="bg-ci bg-opacity-20 rounded-md px-4 py-1 cursor-pointer flex"
              >
                <img className="mr-2" src={mediaImages['com']} alt="" />
                <div>Interact with Contract</div>
              </div>
            </div>
            <div className="flex">
              <div className="pt-5p text-white text-opacity-60 w-28">BNB Chain</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'PancakeSwap',
                        protocol_code: 'PancakeSwap',
                        blockchain: 'bsc',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['PancakeSwap']} alt="" />
                  <div>Pancake</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'Alpaca',
                        protocol_code: 'Alpaca',
                        blockchain: 'bsc',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['Alpaca']} alt="" />
                  <div>Alpaca</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'Galxe',
                        protocol_code: 'Galxe',
                        blockchain: 'bsc',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['Galxe']} alt="" />
                  <div>Project Galaxy</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'Venus Protocol',
                        protocol_code: 'VenusProtocol',
                        blockchain: 'bsc',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['Venus Protocol']} alt="" />
                  <div>Venus Protocol</div>
                </div>
                {/* <div onClick={() => {
                setTasks_arr((state: any) => [...state, {
                  type: 'pancake',
                  platform: 'media',
                  media: [],
                  votes: '1',
                  mandatory: true,
                }])
              }} className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"><img className="mr-2" src={mediaImages['bsc']} alt="" /><div>WAM</div></div>
              <div onClick={() => {
                setTasks_arr((state: any) => [...state, {
                  type: 'pancake',
                  platform: 'media',
                  media: [],
                  votes: '1',
                  mandatory: true,
                }])
              }} className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"><img className="mr-2" src={mediaImages['bsc']} alt="" /><div>Tranchess</div></div>
              <div onClick={() => {
                setTasks_arr((state: any) => [...state, {
                  type: 'pancake',
                  platform: 'media',
                  media: [],
                  votes: '1',
                  mandatory: true,
                }])
              }} className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"><img className="mr-2" src={mediaImages['bsc']} alt="" /><div>X World Games</div></div>
              <div onClick={() => {
                setTasks_arr((state: any) => [...state, {
                  type: 'pancake',
                  platform: 'media',
                  media: [],
                  votes: '1',
                  mandatory: true,
                }])
              }} className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"><img className="mr-2" src={mediaImages['bsc']} alt="" /><div>Travian</div></div>
              <div onClick={() => {
                setTasks_arr((state: any) => [...state, {
                  type: 'pancake',
                  platform: 'media',
                  media: [],
                  votes: '1',
                  mandatory: true,
                }])
              }} className="bg-bscbg rounded-md px-4 py-1 cursor-pointer flex w-fit"><img className="mr-2" src={mediaImages['bsc']} alt="" /><div>Fota</div></div> */}
              </div>
            </div>
            <div className="flex">
              <div className="pt-5p text-white text-opacity-60 w-28">Aptos Mainnet</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'LiquidSwap',
                        protocol_code: 'LiquidSwap',
                        blockchain: 'aptos',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-aptchain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['LiquidSwap']} alt="" />
                  <div>LiquidSwap</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'AptID',
                        protocol_code: 'AptID',
                        blockchain: 'aptos',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-aptchain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['AptID']} alt="" />
                  <div>AptID</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'Topaz',
                        protocol_code: 'Topaz',
                        blockchain: 'aptos',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-aptchain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['Topaz']} alt="" />
                  <div>Topaz</div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="pt-5p text-white text-opacity-60 w-28">Aptos Testnet</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'LiquidSwap',
                        protocol_code: 'LiquidSwap',
                        blockchain: 'aptostest',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-aptchain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['LiquidSwap']} alt="" />
                  <div>LiquidSwap</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'AptID',
                        protocol_code: 'AptID',
                        blockchain: 'aptostest',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-aptchain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['AptID']} alt="" />
                  <div>AptID</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'Topaz',
                        protocol_code: 'Topaz',
                        blockchain: 'aptostest',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-aptchain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['Topaz']} alt="" />
                  <div>Topaz</div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="pt-5p text-white text-opacity-60 w-28">Boba Network</div>
              <div className="flex-1 flex flex-wrap gap-3">
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'OolongSwap',
                        protocol_code: 'OolongSwap',
                        blockchain: 'boba',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-bobachain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['OolongSwap']} alt="" />
                  <div>OolongSwap</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'Gin Finance',
                        protocol_code: 'GinFinance',
                        blockchain: 'boba',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-bobachain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['Gin Finance']} alt="" />
                  <div>Gin Finance</div>
                </div>
                <div
                  onClick={() => {
                    setTasks_arr((state: any) => [
                      ...state,
                      {
                        type: 'onchain_interact',
                        platform: 'Bodh Finance',
                        protocol_code: 'BodhFinance',
                        blockchain: 'boba',
                        contract_address: '',
                        start_time: '',
                        end_time: '',
                        url: '',
                        title: '',
                        votes: '1',
                        mandatory: true,
                      },
                    ])
                  }}
                  className="bg-bobachain rounded-md px-4 py-1 cursor-pointer flex w-fit"
                >
                  <img className="mr-2" src={mediaImages['Bodh Finance']} alt="" />
                  <div>Bodh Finance</div>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 'customized' && (
          <div className="w-7/12 mt-5 space-y-5">
            <div className="flex flex-wrap gap-3 mb-8 pl-28">
              <div
                onClick={() => {
                  setTasks_arr((state: any) => [
                    ...state,
                    {
                      type: 'holding_comp_votes',
                      platform: 'web3',
                      votes: '1',
                      symbol: '',
                      blockchain: '',
                      contract_address: '',
                      threshold_votes: '1',
                      decimal: '18',
                      mandatory: true,
                    },
                  ])
                }}
                className="bg-ci bg-opacity-20 rounded-md px-4 py-1 cursor-pointer flex"
              >
                <img className="mr-2" src={mediaImages['icon_token']} alt="" />
                <div>Comp like votes</div>
              </div>
              <div
                onClick={() => {
                  setTasks_arr((state: any) => [
                    ...state,
                    {
                      type: 'mint_okx_cup_nft',
                      platform: 'web3',
                      votes: '1',
                      symbol: '',
                      blockchain: '',
                      contract_address: '',
                      threshold_votes: '1',
                      decimal: '18',
                      mandatory: true,
                    },
                  ])
                }}
                className="bg-ci bg-opacity-20 rounded-md px-4 py-1 cursor-pointer flex"
              >
                <img className="mr-2" src={mediaImages['icon_nft']} alt="" />
                <div>Mint OKX Cup NFTs</div>
              </div>
            </div>
          </div>
        )}
        <div className="bg-ncon fixed bottom-0 right-0 w-full" style={{ zIndex: 10 }}>
          <div className="flex ml-64 justify-start  py-5 px-6">
            <Button
              onClick={() => {
                checkGroup && reqCampaign()
                setStep(3)
              }}
              className="rounded px-4 flex items-center"
              type="primary"
            >
              <Trans>Save</Trans>
              <img className="ml-1.5 h-4" src={arrow_r} alt="" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tasks
