import { useEffect, useState } from "react"
import axios from "config/axios"
import { formatTime } from "utils"

const useActivePrice = (date: string, code: string) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const ress: any = await Promise.all(
        [
          axios.get(`/api/admin/project/chart?code=${code}&date=${date}&type=active_index`),
          axios.get(`/api/admin/project/chart?code=${code}&date=${date}&type=spam`),
        ])
      for (const [index, res] of ress.entries()) {
        for (const obj of res.data.data) {
          const time = formatTime(date, obj.date)
          const fullTime = formatTime(date, obj.date, true)
          obj.time = time
          obj.fullTime = fullTime
          const type = index === 0 ? 'Social Volume': 'Spam Volume'
          obj.type = type
        }
      }
      setLoading(false)
      setData([...ress[0].data.data, ...ress[1].data.data])
    } catch (error) {
      console.error("useActivePrice --> " + error)
    }
  }
  useEffect(() => {
    if (code) fetchData()
    // eslint-disable-next-line
  }, [date, code])
  return [data, loading]
}

export default useActivePrice
