import { configureStore } from '@reduxjs/toolkit'
import spaceReducer from './space'

const store = configureStore({
	reducer: {
		space: spaceReducer
	},
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
