import { Trans } from '@lingui/macro'
import { Button, Form, Input, Table, Select, DatePicker, Tooltip, message, Image, Modal, Checkbox, Row, Col, Radio } from 'antd'
import { useState } from 'react'
import arrow from 'assets/images/icon_arrow_right.svg'
import spclose from 'assets/images/spclose.svg'
import { SearchOutlined, ExportOutlined, CaretDownOutlined } from '@ant-design/icons'
import { mediaImages } from 'config/media'
import useActionList from './hooks/useActionList'
import { downloadFile, getUuiD, shortenAddress, titleCase } from 'utils'
import { wrapBlockchain } from 'config/blockchain'
import moment from 'moment'
import axios from 'axios'
import { isEmpty, debounce } from 'lodash'
const { Option } = Select

export const wrapTitle = (task: any) => {
  if (task.platform === 'twitter') {
    if (task.type === 'follow') {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['twitter']} alt="" />
          <div>Follow @{task.screen_name} on Twitter</div>
        </div>
      )
    } else if (task.type === 'retweet') {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['twitter']} alt="" />
          <div>Retweet {task.title} on Twitter</div>
        </div>
      )
    } else if (task.type === 'quoted') {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['twitter']} alt="" />
          <div>Quote {task.title} on Twitter</div>
        </div>
      )
    } else if (task.type === 'reply') {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['twitter']} alt="" />
          <div>Reply {task.title} on Twitter</div>
        </div>
      )
    } else if (task.type === 'retweet_and_like') {
      let action = ''
      if (!task.task_types.length || task.task_types.length === 2) {
        action = 'Retweet & Like'
      } else if (task.task_types.length === 1) {
        action = titleCase(task.task_types[0])
      }
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['twitter']} alt="" />
          <div>
            {action} {task.title} on Twitter
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['twitter']} alt="" />
          <div>Like {task.title} on Twitter</div>
        </div>
      )
    }
  } else if (task.platform === 'telegram') {
    if (task.type === 'join') {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['telegram']} alt="" />
          <div>Join {task.title} on Telegram</div>
        </div>
      )
    } else {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['telegram']} alt="" />
          <div>Join {task.title} Text AMA on Telegram</div>
        </div>
      )
    }
  } else if (task.platform === 'discord') {
    if (task.type === 'join') {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['discord']} alt="" />
          <div>Join {task.title} on Discord</div>
        </div>
      )
    } else {
      return (
        <div className="flex items-center space-x-1">
          <img src={mediaImages['discord']} alt="" />
          <div>Join {task.title} Text AMA on Discord</div>
        </div>
      )
    }
  } else if (task.type === 'invite' || task.type === 'open_refer') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['icon_invite']} alt="" />
        <div>
          Invite {task.invites_per_user} {task.invites_per_user < 2 ? 'Friend' : 'Friends'}
        </div>
      </div>
    )
  } else if (task.type === 'holding_token') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['icon_token']} alt="" />
        <div>
          Holding {task.threshold_balance} {task.symbol} on {wrapBlockchain[task.blockchain]}
        </div>
      </div>
    )
  } else if (task.type === 'holding_nft') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['icon_nft']} alt="" />
        <div>
          Holding {task.threshold_balance} {task.symbol} on {wrapBlockchain[task.blockchain]}
        </div>
      </div>
    )
  } else if (task.type === 'visit') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['homepage']} alt="" />
        <div>
          {task.action ? titleCase(task.action) : 'Visit'} {task.title} Website
        </div>
      </div>
    )
  } else if (task.type === 'download') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['download_app']} alt="" />
        <div>Download {task.title} App</div>
      </div>
    )
  } else if (task.type === 'answer_question') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['answer_question']} alt="" />
        <div>Answer {task.title} Question</div>
      </div>
    )
  } else if (task.type === 'channel') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['channel']} alt="" />
        <div>Answer {task.title} Channel Code</div>
      </div>
    )
  } else if (task.type === 'connect_wallet') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['connect_wallet']} alt="" />
        <div>Connect Your Wallet</div>
      </div>
    )
  } else if (task.type === 'bind_web2_id') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['bind_account']} alt="" />
        <div>Bind {task.media.map((me: any) => titleCase(me)).join('/')} Account</div>
      </div>
    )
  } else if (task.type === 'onchain_interact') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages[task.platform]} alt="" />
        <div>
          {titleCase(task.title)} on {task.platform}
        </div>
      </div>
    )
  } else if (task.type === 'holding_comp_votes') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['icon_token']} alt="" />
        <div>
          Holding {task.threshold_votes} {task.symbol} votes on {wrapBlockchain[task.blockchain]}
        </div>
      </div>
    )
  } else if (task.type === 'proof_of_work') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['proof']} alt="" />
        <div>Proof of Work for {task.title}</div>
      </div>
    )
  } else if (task.type === 'open_question') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['answer_question']} alt="" />
        <div>OpenQuestion {task.title}</div>
      </div>
    )
  } else if (task.type === 'vote' || task.type === 'quiz') {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['answer_question']} alt="" />
        <div className="capitalize">
          {task.type} {task.title}
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex items-center space-x-1">
        <img src={mediaImages['com']} alt="" />
        <div>{titleCase(task.title)} on Chain</div>
      </div>
    )
  }
}

const Actions = ({ campaign_code, setMenu }: any) => {
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const [update, setUpdate] = useState(false)
  const [loadExport, setLoadExport] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search_obj, setSearch_obj] = useState<any>({
    address: '',
    task_id: 'all',
    status: 'all',
    start_time: '',
    end_time: '',
  })
  const [{ list, tasks, total }, loading] = useActionList(page, 10, campaign_code, search_obj, update)
  const [currentImage, setCurrentImage] = useState()
  const [currentId, setCurrentId] = useState()
  const [previewModal, setPreviewModal] = useState(false)
  const [answerModal, setAnswerModal] = useState(false)
  const [record, setRecord] = useState<any>({})
  const [checkes, setCheckes] = useState<any>([])
  const [valueRadio, setValueRadio] = useState('')
  const columns: any = [
    {
      title: (
        <div>
          <Trans>User</Trans>
        </div>
      ),
      dataIndex: 'address',
      key: 'address',
      width: 120,
      elippses: true,
      render: (cur: string) => {
        return (
          <Tooltip overlay={cur}>
            <span>{shortenAddress(cur)}</span>
          </Tooltip>
        )
      },
    },
    {
      title: (
        <div>
          <Trans>Action</Trans>
        </div>
      ),
      dataIndex: 'action',
      key: 'action',
      width: 440,
      render: (_: any, record: any) => {
        return <div>{wrapTitle(record.detail)}</div>
      },
    },
    {
      title: (
        <div>
          <Trans>Detail</Trans>
        </div>
      ),
      dataIndex: 'detail',
      key: 'detail',
      width: 180,
      elippses: true,
      render: (cur: any, record: any) => {
        const ele =
          cur.type === 'proof_of_work' ? (
            <div>
              <Image
                onClick={() => {
                  setCurrentId(record.id)
                  setCurrentImage(cur.image_url)
                  setPreviewModal(true)
                }}
                preview={{ visible: false }}
                width={60}
                height={'auto'}
                src={cur.image_url}
              />
            </div>
          ) : (
            <Tooltip overlay={cur.answer}>
              <div className="truncate w-40">{cur.answer}</div>
            </Tooltip>
          )
        return ele
      },
    },
    {
      title: (
        <div>
          <Trans>Action Time</Trans>
        </div>
      ),
      dataIndex: 'action_time',
      key: 'action_time',
      width: 180,
      render: (cur: any) => {
        return <div>{moment(cur * 1000).format('YYYY-MM-DD HH:mm')}</div>
      },
    },
    {
      title: (
        <div>
          <Trans>Status</Trans>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (cur: any, record: any, index: number) => {
        return (
          <div className="rmbor">
            <Select
              key={getUuiD(index)}
              onClick={(e: any) => e.stopPropagation()}
              onSelect={(value: any) => onSelectStatus(value, record.id)}
              suffixIcon={<CaretDownOutlined className={`${cur !== 'wait_check' ? (cur === 'completed' ? 'text-valid' : 'text-red-500') : 'text-pend'}`} />}
              dropdownMatchSelectWidth={90}
              className={`${
                cur !== 'wait_check' ? (cur === 'completed' ? 'bg-valid bg-opacity-20 text-valid' : 'bg-red-500 bg-opacity-20 text-red-500') : 'bg-pend bg-opacity-20 text-pend'
              } rounded-full text-xs`}
              defaultValue={cur}
            >
              <Option className="text-xs flex items-center" value="wait_check">
                <Trans>Checking</Trans>
              </Option>
              <Option className="text-xs flex items-center" value="invalid">
                <Trans>Invalid</Trans>
              </Option>
              <Option className="text-xs flex items-center" value="completed">
                <Trans>Completed</Trans>
              </Option>
            </Select>
          </div>
        )
      },
    },
  ]

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }

  const onFinish = (fieldValues: any) => {
    const { address, task_id, status, start_time, end_time } = form.getFieldsValue()
    setPage(1)
    setSearch_obj({
      address: address ? address : '',
      task_id: task_id ? task_id : '',
      status: status ? status : '',
      start_time: start_time ? parseInt(start_time.valueOf() / 1000 + '') : '',
      end_time: end_time ? parseInt(end_time.valueOf() / 1000 + '') : '',
    })
  }
  // 校验是否是前后时间都选中才发起请求
  const pickerReg = () => {
    const { start_time, end_time } = form.getFieldsValue()
    !isEmpty(start_time) && !isEmpty(end_time) && onFinish(form.getFieldsValue())
  }
  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 80,
    onChange: onSelectChange,
  }
  const onSelectStatus = async (value: any, id: any, info?: any) => {
    const res = await axios.post('/api/admin/space/campaign/analyze/actions', {
      campaign_code,
      status: value,
      action_ids: [id],
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
      setPreviewModal(false)
      setAnswerModal(false)
    } else {
      message.error(res.data.message)
    }
  }

  const onSelectStatusPage = async (value: string) => {
    if (!selectedRowKeys.length) return
    const res = await axios.post('/api/admin/space/campaign/analyze/actions', {
      campaign_code,
      status: value,
      action_ids: selectedRowKeys,
    })
    if (res.data.code === 0) {
      setUpdate((state: any) => !state)
      message.success('Success!')
    } else {
      message.error(res.data.message)
    }
  }

  const exportFun = async () => {
    const { address, task_id, status, start_time, end_time } = search_obj
    const url = `/api/admin/space/campaign/analyze/actions?page=${1}&pagesize=${10}&campaign_code=${campaign_code}&address=${address}&task_id=${task_id}&status=${status}&start_time=${start_time}&end_time=${end_time}&is_export=${true}`
    setLoadExport(true)
    const res = await axios({
      url,
      method: 'get',
      responseType: 'blob',
    })
    setLoadExport(false)
    downloadFile(res.data, `${campaign_code}_campaign_actions`)
  }

  // 点击行事件，model
  const handleClickTr = (record: any) => {
    setRecord({ ...record })
    setAnswerModal(true)
  }
  // 弹出的model
  const model = () => {
    let answers = ''
    // 判断record和tasks是否都满足条件
    if (isEmpty(record) || !tasks || !tasks.length || !['vote', 'quiz', 'open_question'].includes(record.detail.type)) return
    let task_id = record.task_id
    let task = tasks.find((element: any) => task_id === element.id)
    // 将answers中的答案转成字符串
    if (record?.detail?.answers && record.detail.answers instanceof Array) {
      answers = record.detail.answers.join(',')
    } else answers = record?.detail?.answers || ''

    const onChangeRedio = (e: any) => {
      setValueRadio(e.target.value)
    }

    const onChangeCheck = (value: any) => {
      setCheckes([...value])
    }

    //发布答案
    const handlePulish = async () => {
      if (task.select === 'single' && !valueRadio) return
      if (task.select === 'multi' && !checkes.length) return
      let answers: any = []
      if (task.select === 'single') {
        answers[0] = valueRadio
      } else answers = checkes
      let params: any = {
        task_code: task.id,
        campaign_code,
        answers,
      }
      const result = await axios.post('/api/admin/space/campaign/analyze/answer', params)
      setCheckes([])
      setValueRadio('')

      if (+result.data.code === 0) {
        message.success('succees!')
        setAnswerModal(false)
      } else {
        message.error(result.data.message || 'Publishing failed')
      }
    }
    const goReport = () => {
      setAnswerModal(false)
      setMenu(2)
    }
    let ele = <></>

    // 发布答案 quiz
    let ele1 = (
      <Modal
        width={450}
        title={task?.question}
        visible={answerModal}
        onCancel={() => {
          setAnswerModal(false)
          setCheckes([])
        }}
        footer={null}
        destroyOnClose
      >
        <div className="p-2 flex flex-col">
          <div>
            {task.select && task.select === 'single' && (
              <Radio.Group style={{ width: '100%' }} onChange={onChangeRedio} value={valueRadio}>
                {task.options &&
                  task.options?.length &&
                  task.options.map((item: any, index: number) => (
                    <div key={index} className={`h-12 mt-4  flex items-center px-2 rounded ${valueRadio === item ? 'bg-bline' : ''}`}>
                      <Radio style={{ width: '100%' }} value={item}>
                        {item}
                      </Radio>
                    </div>
                  ))}
              </Radio.Group>
            )}
            {task.select && task.select === 'multi' && (
              <Checkbox.Group style={{ width: '100%' }} onChange={onChangeCheck}>
                {task.options &&
                  task.options.map((item: any, index: number) => (
                    <Row className="mb-6" key={index}>
                      <div className={`flex items-center rounded-lg h-14 border w-full px-6 bg-  ${checkes.includes(item) ? 'border-btbor' : 'border-mline'} `}>
                        <Checkbox style={{ width: '100%' }} value={item}>
                          {item}
                        </Checkbox>
                      </div>
                    </Row>
                  ))}
              </Checkbox.Group>
            )}
          </div>
          <div className="mt-12">
            <Button block className="w-full rounded-lg h-11 px-4" type="primary" onClick={handlePulish}>
              Publish Answer
            </Button>
          </div>
        </div>
      </Modal>
    )
    // open_question
    let ele2 = (
      <Modal
        width={450}
        title="Task Detail"
        visible={answerModal}
        onCancel={() => {
          setAnswerModal(false)
        }}
        footer={null}
        destroyOnClose
      >
        <div className="p-2 flex flex-col">
          <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Row>
              <Col span={24}>
                <Form.Item name="title">
                  <h3 className="text-fff60 mb-5 font-normal">Questions (Open Question)</h3>
                  <Input.TextArea autoSize placeholder="" disabled value={record?.detail?.question || ''} className="bg-tbg/20 border-none hover:border-ci focus:border-ci rounded-lg " />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-8">
              <Col span={24}>
                <Form.Item name="title">
                  <h3 className="text-fff60 mb-5 font-normal">Answer </h3>
                  <Input.TextArea autoSize disabled value={record?.detail?.answer || ''} className="bg-tbg/20 border-none hover:border-ci focus:border-ci rounded-lg " />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <div className="flex">
                <Button block className="w-full rounded-lg font-medium px-4 h-11 mr-12 bg-tab/40 border-none hover:text-white focus:text-white" onClick={() => onSelectStatus('invalid', record.id)}>
                  Mark as invalid
                </Button>
                <Button block className="w-full rounded-lg font-medium px-4 h-11" type="primary" onClick={() => onSelectStatus('completed', record.id)}>
                  Mark as completed
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    )
    // quiz + vote
    let ele3 = (
      <Modal
        width={450}
        title="Task Detail"
        visible={answerModal}
        onCancel={() => {
          setAnswerModal(false)
        }}
        footer={null}
        destroyOnClose
      >
        <div className="p-2 flex flex-col">
          <Form name="basic" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Row>
              <Col span={24}>
                <Form.Item name="title">
                  <h3 className="text-fff60 mb-5 font-normal capitalize">Questions ({task.type})</h3>
                  <Input.TextArea autoSize placeholder="" disabled value={record?.detail?.question} className="bg-tbg/20 border-none hover:border-ci focus:border-ci rounded-lg" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col span={24}>
                <Form.Item name="title">
                  <h3 className="text-fff60 mb-5 font-normal">Answer </h3>
                  <Input.TextArea autoSize disabled value={answers} className="bg-tbg/20 border-none hover:border-ci focus:border-ci rounded-lg" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <h3 className="mb-5 font-normal">This is {formatStatus(record.status)} answer</h3>
              <Button block className="w-full rounded-lg font-medium px-4 h-11" type="primary" onClick={goReport}>
                See Reports
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    )
    // 判断任务是否存在
    if (!isEmpty(task)) {
      switch (record.detail.type) {
        case 'vote':
          ele = ele3
          break
        case 'quiz':
          //判断是否发布答案
          if (task?.answers?.length) {
            //发布过答案
            ele = ele3
          } else ele = ele1 //未发布过答案
          break
        case 'open_question':
          ele = ele2
      }
    }
    //通过用户信息判断 判断弹出框
    return ele
  }

  const formatStatus = (value: string) => {
    switch (value) {
      case 'completed':
        return (
          <>
            <span style={{ color: '#21d08f' }}>valid</span>
          </>
        )
      case 'wait_check':
        return <>checking</>
      case 'invalid':
        return (
          <>
            <span style={{ color: '#ef4444' }}>invalid</span>
          </>
        )
    }
  }
  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto ">
        <div className="bg-ncon rounded-lg border border-bord ">
          <div className="border-b border-bord ">
            <div className="flex items-center px-6 pt-7 pb-1 ">
              <Form name="basic" layout="vertical" form={form} onFinish={onFinish} autoComplete="off" className="w-full">
                <div className="flex space-x-4 justify-between">
                  <div className="flex space-x-4">
                    <Form.Item name="task_id" initialValue="all">
                      <Select className="w-200" onChange={onFinish}>
                        <Option value="all">
                          <Trans>All</Trans>
                        </Option>
                        {tasks.map((task: any) => (
                          <Option key={task.id} value={task.id}>
                            {wrapTitle(task)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="status" initialValue="all">
                      <Select className="w-200" onChange={onFinish}>
                        <Option value="all">
                          <Trans>All</Trans>
                        </Option>
                        <Option value="wait_check">
                          <Trans>Checking</Trans>
                        </Option>
                        <Option value="invalid">
                          <Trans>Invalid</Trans>
                        </Option>
                        <Option value="completed">
                          <Trans>Completed</Trans>
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item name="address">
                    <Input
                      placeholder="Address"
                      prefix={<SearchOutlined />}
                      allowClear
                      className="bg-nibg border-bord hover:border-ci focus:border-ci  w-400 rounded-full"
                      onChange={debounce(onFinish, 500)}
                    />
                  </Form.Item>
                </div>
                <div className="flex  justify-between">
                  <div className="flex space-x-4">
                    {/* <Form.Item
                      name="status"
                      initialValue='all'
                    >
                      <Select className="w-200" onChange={onFinish}>
                        <Option value='all'><Trans>All</Trans></Option>
                        <Option value='wait_check'><Trans>Checking</Trans></Option>
                        <Option value='invalid'><Trans>Invalid</Trans></Option>
                        <Option value='completed'><Trans>Completed</Trans></Option>
                      </Select>
                    </Form.Item> */}
                    <Form.Item name="start_time">
                      <DatePicker
                        allowClear
                        className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black"
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        onChange={pickerReg}
                      />
                    </Form.Item>
                    <div className="pt-1.5">
                      <img src={arrow} alt="" />
                    </div>
                    <Form.Item name="end_time">
                      <DatePicker
                        allowClear
                        className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md text-black"
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        onChange={pickerReg}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex space-x-4">
                    <Button htmlType="submit" className="rounded-lg" type="primary">
                      <Trans>Search</Trans> <SearchOutlined />
                    </Button>
                    <Button loading={loadExport} onClick={exportFun} className="rounded-lg" type="primary">
                      <Trans>Export</Trans> <ExportOutlined />
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div className="py-1 overflow-x-scroll">
            <Table
              className=""
              loading={loading as boolean}
              dataSource={list}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              rowClassName={(_, index) => {
                let className = 'nrodd'
                if (index % 2 === 0) className = 'nreven'
                return className
              }}
              onRow={(record) => {
                return {
                  onClick: () => handleClickTr(record), // 点击行
                }
              }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                size: 'small',
                current: page,
                pageSize: 10,
                total: total,
              }}
              onChange={handleTableChange}
            />
          </div>
          <div className="bg-ncon fixed bottom-0 right-0 w-full" style={{ zIndex: 10 }}>
            {/* <div className="flex space-x-4 ">
              <Select onSelect={onSelectStatusPage} placeholder="Mark selected as" className="w-60">
                <Option value='wait_check'><Trans>Mark selected as Checking</Trans></Option>
                <Option value='invalid'><Trans>Mark selected as Invalid</Trans></Option>
                <Option value='completed'><Trans>Mark selected as Completed</Trans></Option>
              </Select>
            </div> */}
            {/* 全选操作，用户批量修改状态 */}
            {!!selectedRowKeys.length && (
              <div className="flex justify-end  py-5 px-6">
                <Button className="rounded-lg font-medium px-4 h-11 ml-8 bg-tab/40 border-none hover:bg-tbg focus:bg-tbg" onClick={() => onSelectStatusPage('wait_check')} type="primary">
                  Mark selected as Checking
                </Button>
                <Button className="rounded-lg font-medium px-4 h-11 ml-8 bg-tab/40 border-none hover:bg-tbg focus:bg-tbg" onClick={() => onSelectStatusPage('invalid')} type="primary">
                  Mark selected as Invalid
                </Button>
                <Button className="rounded-lg font-medium px-4 h-11 ml-8 bg-tab/40 border-none hover:bg-tbg focus:bg-tbg" onClick={() => onSelectStatusPage('completed')} type="primary">
                  Mark selected as Completed
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="action text-center"
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
        destroyOnClose={true}
        width={'65%'}
        title={null}
        visible={previewModal}
        onCancel={() => {
          setPreviewModal(false)
        }}
        closable={false}
        footer={null}
        centered={true}
        bodyStyle={{ padding: 0 }}
      >
        <div className="relative">
          <div onClick={() => setPreviewModal(false)} className="absolute -top-4 -right-4 cursor-pointer">
            <img src={spclose} alt="" />
          </div>
          <div className="flex justify-center">
            <div className="flex items-center justify-center w-fit relative">
              <img style={{ maxWidth: '100%' }} src={currentImage} alt="" />
            </div>
          </div>
          <div className="flex justify-center space-x-8 mt-4 z-50">
            <Button onClick={() => onSelectStatus('invalid', currentId)} size="large" className="mt-6 rounded w-60 bg-sendbg hover:bg-ci hover:bg-opacity-40 border-none" type="primary">
              <Trans>Mark as Invalid</Trans>
            </Button>
            <Button onClick={() => onSelectStatus('completed', currentId)} size="large" className="mt-6 rounded w-60" type="primary">
              <Trans>Mark as Completed</Trans>
            </Button>
          </div>
        </div>
      </Modal>
      {model()}
    </div>
  )
}

export default Actions
