import { Trans } from '@lingui/macro'
import { Button, Form, Input, message, Modal, notification, Select, Spin, Tooltip } from 'antd'
import axios from 'axios'
import arrow_left from 'assets/images/icon_arrow_left.svg'
// import arrow_right from 'assets/images/icon_arrow_right.svg'
// import { PaperClipOutlined } from '@ant-design/icons'
import add from 'assets/images/_icon_add.svg'
import note from 'assets/images/icon_note.svg'
import uploaded from 'assets/images/uploaded.svg'
import upexcel from 'assets/images/upexcel.svg'
import downexcel from 'assets/images/downexcel.svg'
import exam from 'assets/excel/example.xlsx'
import { SearchOutlined } from '@ant-design/icons'
import { blockchain, blockchainScan, giveawayContract, wrapBlockchain } from 'config/blockchain'
import { useEffect, useRef, useState } from 'react'
import { formatBigNumber, formatNum, getContract, getRandomNum, isAddress, isChainCoin, isPositiveNumber, subsByNums, titleCase } from 'utils'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import useGiveawayDetail from './hooks/useGiveawayDetail'
import * as XLSX from 'xlsx'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/dracula.css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import 'codemirror/mode/xml/xml'
import 'codemirror/addon/display/placeholder.js'
import { Controlled as ControlledEditorComponent } from 'react-codemirror2'
import { useWeb3React } from '@web3-react/core'
import useTokenBalance from 'hooks/useTokenBalance'
import { airdrop_contract_address_config } from 'config/Address'
import useAllowance from 'hooks/useAllowance'
import useTokenApprove from 'hooks/useTokenApprove'
import BigNumber from 'bignumber.js'
import { ExternalLink as LinkIcon } from 'react-feather'
import { CloseOutlined } from '@ant-design/icons'
import useOperate from './hooks/useOperate'
import airdrop_abi from 'config/abis/LuckyMoney.json'
import { utils } from 'ethers'
import SentHistory from './SentHistory'
import proof_tip from 'assets/images/proof_tip.svg'
import 'style/codemirror.less'

const { Option } = Select

const addressToSubmit = (addresses: string) => {
  if (!addresses) return []
  let arr = addresses.split('\n')
  const submitArr = []
  for (const str of arr) {
    const perArr = str.split(',')
    if (perArr[0]) {
      submitArr.push({
        address: perArr[0],
        amount: perArr[1] ? perArr[1] : '0',
      })
    }
  }
  return submitArr
}

const submitToAddress = (addresses: { address: string; amount: string }[]) => {
  let str = ''
  for (const obj of addresses) {
    str += `${obj.address}${Number(obj.amount) === 0 ? '' : ',' + obj.amount}\n`
  }
  return str
}

const Detail = () => {
  const { account, chainId, library } = useWeb3React()
  const { state }: { state: any } = useLocation()
  const query = useQuery()
  const idv = query.get('id')
  const [id, setId] = useState(idv)
  const history = useHistory()
  const [update, setUpdate] = useState(false)
  const [update_balance, setUpdate_balance] = useState(false)
  const [loading, setLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [token_code, setToken_code] = useState('')
  const [symbol, setSymbol] = useState('')
  const [title, setTitle] = useState('')
  const [token_address, setToken_address] = useState('')
  const [chain, setChain] = useState('')
  const [campaigns, setCampaigns] = useState<any>([])
  const [before_campaign_code, setBefore_Campaign_code] = useState('')
  const [campaign_code, setCampaign_code] = useState('')
  const [prize_code, setPrize_code] = useState('')
  const [prizes, setPrizes] = useState([])
  const [addresses, setAddresses] = useState('')
  const [sendAmount, setSendAmount] = useState()
  // const [rule, setRule] = useState<any>()
  const [excel_data, setExcel_data] = useState<any>({
    i: 0,
    str: '',
  })
  const [winnerModal, setWinnerModal] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  // const [avgModal, setAvgModal] = useState(false)
  // const [randomModal, setRandomModal] = useState(false)
  const [sendModal, setSendModal] = useState(false)
  const [winnerLoading, setWinnerLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  // const [randomTotal, setRandomTotal] = useState(0)
  const [total_amount_fee, setTotal_amount_fee] = useState('0')
  const [fee, setFee] = useState('0')
  const [total_amount, setTotal_amount] = useState('0')
  const [form] = Form.useForm()
  const [form_winners] = Form.useForm()
  const csvRef = useRef<any>(null)
  const [airdrop_contract_address, setAirdrop_contract_address] = useState('')

  const [details] = useGiveawayDetail(id, update)
  const { giveaway, wait_datas, send_datas } = details
  const [walletBalance, decimals] = useTokenBalance(token_address, update_balance)
  const allowance = useAllowance(token_address, airdrop_contract_address, update_balance)
  const isApproved = allowance !== 0 && new BigNumber(allowance).gte(walletBalance)
  const approve = useTokenApprove(token_address, airdrop_contract_address)
  const { sendToken } = useOperate(airdrop_contract_address)

  const totalAmountFeeFun = async () => {
    try {
      const obj_arr = addressToSubmit(addresses).slice(0, 200)
      const amount_arr: any = []
      for (const obj of obj_arr) {
        amount_arr.push(obj.amount)
      }
      const total_amount = amount_arr.reduce((pre: any, cur: any) => new BigNumber(pre).plus(cur)).toString()
      const airdropContract = getContract(airdrop_contract_address, airdrop_abi, library, account as string)
      const value = await airdropContract?.feeOf(utils.parseUnits(total_amount, decimals))
      const fee = formatBigNumber(value, decimals as number)
      setTotal_amount_fee(new BigNumber(total_amount).plus(fee).toString())
      setFee(fee)
      setTotal_amount(total_amount)
    } catch (error) {}
  }

  const openNotificationWithIcon = (type: 'success' | 'error', tx: string, title: string) => {
    notification[type]({
      message: title,
      description: (
        <a className="text-blu hover:underline" href={`${blockchainScan[chain]}/tx/${tx}`} rel="noreferrer" target="_blank">
          View on explorer
          <LinkIcon className="inline-block mb-1" size={16} />
        </a>
      ),
      closeIcon: <CloseOutlined style={{ color: 'white' }} />,
    })
  }

  const titleChange = (e: any) => {
    const { value } = e.target
    setTitle(value)
  }

  const onWinnerFinish = async (fieldValues: any) => {
    if (!fieldValues.prize_code) {
      return
    }
    setWinnerLoading(true)
    const response = await axios.get(`/api/admin/space/campaign/winner/all?campaign_code=${fieldValues.campaign_code}&prize_code=${fieldValues.prize_code}`)
    setWinnerLoading(false)
    if (response.data.code === 0) {
      let str = ''
      let i = 0
      for (const arr of response.data.data) {
        str += arr + '\n'
        i++
      }
      setAddresses((state: string) => state + str)
      message.success(`${i} valid records were imported`)
      setWinnerModal(false)
    } else {
      message.error(response.data.message)
    }
  }

  let timeout: any
  let currentValue: any

  const fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      setLoading(true)
      const res = await axios.get(`/api/admin/space/token/search?type=all&blockchain=${chain}&symbol=${value}`)
      setLoading(false)
      if (currentValue === value) {
        callback(res.data.data)
      }
    }

    timeout = setTimeout(fake, 300)
  }
  const handleSearch = (value: string) => {
    if (value) {
      fetch(value, (data: any) => setData(data))
    } else {
      setData([])
    }
  }
  const handleChange = async (value: any, obj: any) => {
    if (!value) {
      setSymbol('')
      setToken_address('')
      setToken_code('')
    } else {
      const symbol = obj.key.split('-')[0]
      setSymbol(symbol)
      const token_address = obj.key.split('-')[2]
      setToken_address(token_address)
      setToken_code(value)
    }
  }

  const getTokenAddress = (arr: { address: string; blockchain: string }[], network?: string) => {
    let address = ''
    for (const obj of arr) {
      if (network) {
        if (obj.blockchain === network) {
          address = obj.address
        }
      } else {
        if (obj.blockchain === chain) {
          address = obj.address
        }
      }
    }
    return address
  }

  const options = data.map((d: any) => {
    return (
      <Option className="border-b border-bord" key={d.symbol + '-' + d.project_code + '-' + getTokenAddress(d.tokens)} value={d.project_code}>
        <div className="flex items-center">
          <img className="w-4 mr-1.5 rounded-full" src={d.logo_url} alt="" />
          <span>
            {d.symbol}({d.name})
          </span>
        </div>
      </Option>
    )
  })

  const fetchCampaigns = async () => {
    const res = await axios.get('/api/admin/space/campaign/all')
    setCampaigns(res.data.data)
    if (giveaway?.prize_code) {
      for (const obj of res.data.data) {
        if (obj.campaign_code === giveaway?.campaign_code) {
          setPrizes(obj.prizes)
          return
        }
      }
    }
  }

  const campaignSelect = (campaign_code: string) => {
    setBefore_Campaign_code(campaign_code)
    setPrize_code('')
    form_winners.setFieldsValue({
      prize_code: '',
    })
    for (const obj of campaigns) {
      if (obj.campaign_code === campaign_code) {
        setPrizes(obj.prizes)
        return
      }
    }
  }

  const prizeSelect = (value: string) => {
    setCampaign_code(before_campaign_code)
    setPrize_code(value)
  }

  const blockchainSelect = async (chain: string) => {
    setChain(chain)
    setAirdrop_contract_address(airdrop_contract_address_config[chain])
    if (state) {
      const token_address = getTokenAddress(state.tokens, chain)
      setToken_address(token_address)
    }
  }

  const handleClick = () => {
    csvRef.current.click()
  }

  const _upChange = (e: any) => {
    let file = e.target.files[0]
    setFileName(file.name)
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = function (event: any) {
      try {
        let result = event.target.result
        let xlsxdata = XLSX.read(result, { type: 'binary' })
        for (let n in xlsxdata.Sheets) {
          const col: any = XLSX.utils.sheet_to_json(xlsxdata.Sheets[n], { header: 1, defval: '', blankrows: true })
          let str = ''
          let i = 0
          for (const arr of col) {
            if (isAddress(arr[0])) {
              if (arr[1]) {
                str += arr.toString() + '\n'
              } else {
                str += arr[0] + '\n'
              }
              i++
            }
          }
          setExcel_data({
            i,
            str,
          })
          break
        }
      } catch (err) {}
    }
  }

  const codeBeforeChange = (_editor: any, _data: any, value: any) => {
    setAddresses(value)
  }

  const excelImport = async () => {
    setAddresses((state: string) => state + excel_data.str)
    setUploadModal(false)
    message.success(`${excel_data.i} valid records were imported`)
  }

  const saveFun = async () => {
    try {
      await form.validateFields()
      setSaveLoading(true)
      const arg: any = {
        title: title,
        token_code,
        blockchain: chain,
        campaign_code,
        prize_code,
        is_draft: true,
        addresses: addressToSubmit(addresses),
      }
      if (id) arg.id = id
      const res = await axios.post('/api/admin/giveaway/token', arg)
      setSaveLoading(false)
      if (res.data.code === 0) {
        message.success('success!')
        history.push('/rewards', { token_code, tab: 'token' })
        // setUpdate((state: any) => !state)
      } else {
        message.error(res.data.message)
      }
    } catch (error) {}
  }

  const sendFun = () => {
    if (!token_code) {
      message.error('Please Select your token!')
      return
    }
    if (blockchain[chainId as number] !== chain) {
      message.error(`Please switch to ${wrapBlockchain[chain]} network to be continue.`)
      return
    }
    let isValid = true
    let error_message = ''
    const address_arr = addressToSubmit(addresses)
    const check_arr: any = []
    for (const obj of address_arr) {
      if (!isAddress(obj.address)) {
        isValid = false
        error_message = 'Wrong address format detected!'
        break
      }
      if (Number(obj.amount) === 0 || isNaN(Number(obj.amount))) {
        isValid = false
        error_message = 'Wrong amount detected!'
        break
      }
      if (obj.amount.split('.')[1]?.length > decimals) {
        isValid = false
        error_message = 'Amount exceeds Token precision!'
        break
      }
      if (check_arr.includes(obj.address.toLowerCase())) {
        isValid = false
        error_message = 'Duplicate addresses detected!'
        break
      }
      check_arr.push(obj.address.toLowerCase())
    }
    if (!addresses || !address_arr.length) {
      message.error('Please fill in the list of receiving addresses!')
      return
    }
    if (!isValid) {
      message.error(error_message)
      return
    }
    totalAmountFeeFun()
    setSendModal(true)
  }

  const rulesSelect = async (value: string) => {
    // setRule(value)
    if (!sendAmount) {
      message.error('Please enter your send!')
    }
    if (shares === 0) {
      message.error('Please fill in the list of receiving addresses!')
      return
    }
    if (value === 'avg') {
      avgClick()
      // setAvgModal(true)
      // setAvgEach('0')
    } else {
      randomClick()
      // setRandomModal(true)
      // setRandomTotal(0)
    }
  }

  const avgTotalChange = (e: any) => {
    const { value } = e.target
    setSendAmount(value)
  }

  // useEffect(() => {
  //   if (avgEach && shares !== 0 && rule) {
  //     if (rule === 'avg') {
  //       avgClick()
  //     } else {
  //       randomClick()
  //     }
  //   }
  // }, [avgEach])

  const avgClick = () => {
    if (!sendAmount) return
    const avgEach = subsByNums(new BigNumber(sendAmount).div(shares).toFixed(), Number(decimals))
    const address_arr = addressToSubmit(addresses)
    for (const obj of address_arr) {
      obj.amount = avgEach
    }
    const new_address = submitToAddress(address_arr)
    // setAvgModal(false)
    setAddresses(new_address)
  }

  // const randomTotalChange = (e: any) => {
  //   const { value } = e.target
  //   if (!value || !isPositiveNumber(value)) {
  //     setRandomTotal(0)
  //     return
  //   }
  //   setRandomTotal(value)
  // }
  const randomClick = () => {
    if (!sendAmount) return
    const random_arr = getRandomNum(shares, sendAmount, Number(decimals))
    const address_arr = addressToSubmit(addresses)
    for (const [index, obj] of address_arr.entries()) {
      obj.amount = random_arr[index]
    }
    const new_address = submitToAddress(address_arr)
    // setRandomModal(false)
    setAddresses(new_address)
  }

  const approveFun = async () => {
    setApproveLoading(true)
    const tx = await approve()
    if (tx) {
      const receipt = await library.waitForTransaction(tx.hash)
      if (receipt?.status === 1) {
        setApproveLoading(false)
        setUpdate_balance((state: any) => !state)
        openNotificationWithIcon('success', tx.hash, 'Approve ' + symbol)
      } else {
        message.error('Transaction mint is failed')
      }
    } else {
      setApproveLoading(false)
    }
  }

  const sendTokenFun = async () => {
    const obj_arr = addressToSubmit(addresses).slice(0, 200)
    const address_arr: any = []
    const amount_arr: any = []
    for (const obj of obj_arr) {
      address_arr.push(obj.address)
      amount_arr.push(obj.amount)
    }
    setSendLoading(true)
    const hash = await sendToken(token_address, address_arr, amount_arr, total_amount_fee, decimals)
    if (hash) {
      const arg: any = {
        title: title,
        token_code,
        blockchain: chain,
        campaign_code,
        prize_code,
        is_draft: false,
        addresses: addressToSubmit(addresses),
      }
      if (id) arg.id = id
      const resp = await axios.post('/api/admin/giveaway/token', arg)
      if (resp.data.code === 0) {
        const res = await axios.post(`/api/admin/giveaway/token/${resp.data.data.id}/send`, {
          txid: hash,
          addresses: address_arr,
        })
        if (res.data.code === 0) {
          setUpdate((state: any) => !state)
          setId(resp.data.data.id)
        } else {
          message.error(res.data.message)
        }
      }
      const receipt = await library.waitForTransaction(hash)
      if (receipt?.status === 1) {
        setSendLoading(false)
        openNotificationWithIcon('success', hash, 'Send ' + symbol)
        setSendModal(false)
        setUpdate_balance((state: any) => !state)
      } else {
        message.error('Transaction mint is failed')
      }
    } else {
      setSendLoading(false)
    }
  }

  useEffect(() => {
    if (giveaway?.token_code) {
      setData([
        {
          project_code: giveaway?.token_code,
          logo_url: giveaway?.token_logo,
          name: giveaway?.token_name,
          symbol: giveaway?.token_symbol,
          tokens: [
            {
              address: giveaway?.token_address,
              blockchain: giveaway?.blockchain,
            },
          ],
        },
      ])
    }
    if (state) {
      setData([
        {
          project_code: state.token_code,
          logo_url: state.token_logo,
          name: state.token_name,
          symbol: state.token_symbol,
          tokens: state.tokens,
        },
      ])
    }
    setTitle(giveaway?.title)
    setCampaign_code(giveaway?.campaign_code)
    setPrize_code(giveaway?.prize_code)
    setToken_code(giveaway?.token_code)
    setSymbol(giveaway?.token_symbol)
    if (state) {
      setToken_code(state.token_code)
      setSymbol(state.token_symbol)
    }
    setToken_address(giveaway?.token_address)
    setChain(giveaway?.blockchain)
    setAirdrop_contract_address(airdrop_contract_address_config[giveaway?.blockchain])
    setAddresses(submitToAddress(wait_datas))
    form.setFieldsValue({
      title: giveaway?.title,
      blockchain: giveaway?.blockchain,
    })
  }, [form, giveaway, wait_datas, state])

  const shares = addresses.split('\n').filter((address: string) => !!address).length

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl space-y-6">
        <div className="flex space-x-3">
          <img
            className="cursor-pointer"
            onClick={() => {
              history.push('/rewards', { token_code, tab: 'token' })
            }}
            src={arrow_left}
            alt=""
          />
          <div className="text-base font-medium">
            <Trans>Add Token Giveaways</Trans>
          </div>
        </div>
        <div className="bg-ncon rounded-lg px-5 py-6 border border-bord">
          <Form name="basic" form={form} layout="vertical" className="w-3/5" onFinish={sendFun} autoComplete="off">
            <div>
              <div className="flex items-center space-x-3 mb-6">
                <div className="w-6 h-6 bg-ci rounded-full flex items-center justify-center">1</div>
                <div className="text-base">Token Giveaway setting</div>
              </div>
              <Form.Item
                label="Name:"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name!',
                  },
                ]}
              >
                <Input onChange={titleChange} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-x-8">
              <Form.Item
                label="Blockchain:"
                name="blockchain"
                rules={[
                  {
                    required: true,
                    message: 'Please Select your blockchain!',
                  },
                ]}
              >
                <Select disabled={!!giveaway?.send_num} onSelect={blockchainSelect} showArrow={true} dropdownClassName="border border-bord">
                  {Object.entries(giveawayContract).map(([sname, name]: any, index: number) => (
                    <Option value={sname} key={index}>
                      {titleCase(name)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={<div className="star_req">Token:</div>}>
                <div className="relative">
                  <Select
                    disabled={!!giveaway?.send_num}
                    allowClear={true}
                    placeholder="Search Token"
                    showSearch
                    value={token_code}
                    className="w-full"
                    dropdownClassName="border border-bord"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    notFoundContent={loading ? <Spin size="small" /> : <div className="text-white">No results found</div>}
                  >
                    {options}
                  </Select>
                  <div className="absolute h-full flex items-center bottom-0.5 right-0 pr-6">
                    <SearchOutlined className="text-xl" />
                  </div>
                </div>
              </Form.Item>
            </div>
            <div>
              <div className="flex items-center space-x-3 mb-6 mt-4">
                <div className="w-6 h-6 bg-ci rounded-full flex items-center justify-center">2</div>
                <div className="text-base">Address Settings</div>
              </div>
              <Form.Item
                label={<div className="">Send:</div>}
                name="send"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !isPositiveNumber(value)) {
                        return Promise.reject(' Please enter positive number!')
                      } else {
                        return Promise.resolve()
                      }
                    },
                  },
                ]}
              >
                <div className="flex">
                  <Input onChange={avgTotalChange} suffix={<div className="text-white text-opacity-50">{symbol}</div>} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md" />
                  {/* <div className="flex-1 border border-bord h-8 bg-nibg rounded-md flex items-center px-2">
                    <span className="flex-1">{giveaway?.total_amount}</span>
                    <span className="text-white text-opacity-50"><Trans>{symbol}</Trans></span>
                  </div> */}
                  {/* <img className="px-2" src={arrow_right} alt="" />
                  <div className="flex-1 border border-bord h-8 bg-nibg rounded-md flex items-center px-2">
                    <span className="flex-1">{giveaway?.send_num}</span>
                    <span className="text-white text-opacity-50"><Trans>Address</Trans></span>
                  </div> */}
                </div>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label={
                  <div className="flex">
                    To Addresses from
                    <Tooltip overlay={<div>You can skip this step and come back to distribute rewards after your campaign is finished.</div>}>
                      <img src={proof_tip} style={{ marginRight: 4, marginLeft: 4 }} alt="" />
                    </Tooltip>
                    :
                  </div>
                }
              >
                <div className="flex space-x-8">
                  <div
                    onClick={() => {
                      fetchCampaigns()
                      setWinnerModal(true)
                    }}
                    className="flex-1 bg-sendbg px-6 py-2 rounded-md cursor-pointer hover:bg-ci hover:bg-opacity-40 flex items-center justify-center space-x-2"
                  >
                    <span className="">Campaigns</span>
                    <img src={add} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setUploadModal(true)
                      setFileName('')
                    }}
                    className="flex-1 bg-sendbg px-6 py-2 rounded-md cursor-pointer hover:bg-ci hover:bg-opacity-40 flex items-center justify-center space-x-2"
                  >
                    <span className="">Local File</span>
                    <img src={add} alt="" />
                  </div>
                </div>
              </Form.Item>
            </div>
            <div className="">
              <div className="flex items-center space-x-3 mb-6 mt-10">
                <div className="w-6 h-6 bg-ci rounded-full flex items-center justify-center">3</div>
                <div className="text-base">Send Tokens</div>
              </div>
              {/* <div className="flex space-x-6 mt-10 mb-4">
                <div onClick={() => {
                  setAvgModal(true)
                  setAvgEach('0')
                }} className="bg-sendbg px-6 py-1.5 rounded-md cursor-pointer hover:bg-ci hover:bg-opacity-40"><Trans>Average Distribution</Trans></div>
                <div onClick={() => {
                  setRandomModal(true)
                  setRandomTotal(0)
                }} className="bg-sendbg px-6 py-1.5 rounded-md cursor-pointer hover:bg-ci hover:bg-opacity-40"><Trans>Random Distribution</Trans></div>
              </div> */}
              <ControlledEditorComponent
                onBeforeChange={codeBeforeChange}
                value={addresses}
                className="code-mirror-wrapper"
                options={{
                  lineWrapping: true,
                  lint: true,
                  mode: 'xml',
                  lineNumbers: true,
                  theme: 'dracula',
                  placeholder: '0x1c7b64EF2bB834BeE839B390edBebB4B5B900860,1',
                }}
              />
            </div>
            <div className="mt-8">
              <div className="mb-2">
                <Trans>Distribution rules:</Trans>
              </div>
              <Select className="w-full" showArrow={true} onSelect={rulesSelect} dropdownClassName="border border-bord">
                <Option value="avg">Average Distribution</Option>
                <Option value="random">Random Distribution</Option>
              </Select>
            </div>
            <div className="flex space-x-6">
              <Button onClick={saveFun} size="large" loading={saveLoading} className="mt-6 rounded w-full bg-sendbg hover:bg-ci hover:bg-opacity-40 border-none" type="primary">
                <Trans>{!!giveaway?.send_num ? 'Save' : 'Save as draft'}</Trans>
              </Button>
              <Button htmlType="submit" size="large" className="mt-6 rounded w-full" type="primary">
                <Trans>Send</Trans>
              </Button>
            </div>
          </Form>
        </div>

        {/* history */}
        {!!send_datas.length && <SentHistory send_datas={send_datas} symbol={symbol} chain={chain} />}
      </div>

      <Modal
        destroyOnClose={true}
        title={`Upload Files`}
        width={418}
        visible={uploadModal}
        onCancel={() => {
          setUploadModal(false)
          setFileName('')
        }}
        footer={null}
        centered
      >
        <div className="pb-1">
          <div className="flex items-center space-x-1 text-white text-opacity-60">
            <img className="opacity-60" src={note} alt="" />
            <div>
              <Trans>Support Excel/CSV</Trans>
            </div>
          </div>
          <div onClick={handleClick} className="bg-nibg cursor-pointer border border-bord rounded-md flex items-center justify-center h-16 mt-4">
            <img src={upexcel} alt="" />
          </div>
          {fileName && (
            <div className="flex space-x-2 mt-2">
              <img src={uploaded} alt="" />
              <span className="text-valid">{fileName}</span>
            </div>
          )}
          <div className="flex space-x-3 mt-6">
            <span>
              <Trans>Download Template</Trans>
            </span>
            <a href={exam} download="example.xlsx">
              <img className="cursor-pointer" src={downexcel} alt="" />
            </a>
          </div>
          <input id="upfile" type="file" className="hidden" accept=".csv,.xls,.xlsx" onChange={_upChange} ref={csvRef} />
          <Button disabled={!fileName} onClick={excelImport} size="large" className="w-full mt-4 md:mt-6 rounded" type="primary">
            <Trans>Import</Trans>
          </Button>
        </div>
      </Modal>

      <Modal
        destroyOnClose={true}
        title={`Giveaway for Campaign Winners`}
        width={418}
        visible={winnerModal}
        onCancel={() => {
          setWinnerModal(false)
        }}
        footer={null}
        centered
      >
        <div className="space-y-6 pb-1">
          <Form
            name="basic"
            layout="vertical"
            form={form_winners}
            initialValues={{
              campaign_code,
              prize_code,
            }}
            onFinish={onWinnerFinish}
            autoComplete="off"
          >
            <Form.Item label="Campaign:" name="campaign_code">
              <Select disabled={!!giveaway?.send_num && !!campaign_code} showArrow={true} onSelect={campaignSelect} dropdownClassName="border border-bord">
                {campaigns.map(({ campaign_code, title }: any, index: number) => (
                  <Option value={campaign_code} key={index}>
                    {title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Prize:" name="prize_code">
              <Select disabled={!!giveaway?.send_num && !!prize_code} showArrow={true} onSelect={prizeSelect} dropdownClassName="border border-bord">
                {prizes.map(({ id, name }: any, index: number) => (
                  <Option value={id} key={index}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button htmlType="submit" loading={winnerLoading} size="large" className="w-full mt-2 md:mt-2 rounded" type="primary">
              <Trans>Import</Trans>
            </Button>
          </Form>
        </div>
      </Modal>

      {/* <Modal destroyOnClose={true} title={`Average Distribution`} width={418} visible={avgModal} onCancel={() => {
        setAvgModal(false)
      }} footer={null} centered>
        <div className="space-y-6 pb-1">
          <Form
            name="basic"
            autoComplete="off"
          >
            <Form.Item
              label="Total Amount"
              name="total_amount"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !isPositiveNumber(value)) {
                      return Promise.reject('Total Amout must be positive number!')
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <Input suffix={<div className="text-white text-opacity-60">{symbol}</div>} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>
            <div className="flex">
              <div className="w-28">Shares:</div>
              <div className="flex-1 ml-2.5 text-white text-opacity-60">{shares}</div>
            </div>
            <div className="flex mt-6">
              <div className="w-28">Each Shares:</div>
              <div className="flex-1 ml-2.5 text-white text-opacity-60">{avgEach}</div>
            </div>
            <Button disabled={!avgEach} onClick={avgClick} size='large' className='w-full mt-8 rounded' type='primary'><Trans>Comfirm</Trans></Button>
          </Form>
        </div>
      </Modal> */}

      {/* <Modal destroyOnClose={true} title={`Random Distribution`} width={418} visible={randomModal} onCancel={() => {
        setRandomModal(false)
      }} footer={null} centered>
        <div className="space-y-6 pb-1">
          <Form
            name="basic"
            autoComplete="off"
          >
            <Form.Item
              label="Total Amount"
              name="total_amount"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !isPositiveNumber(value)) {
                      return Promise.reject('Total Amout must be positive number!')
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <Input onChange={randomTotalChange} suffix={<div className="text-white text-opacity-60">{symbol}</div>} className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md' />
            </Form.Item>
            <div className="flex">
              <div className="w-28">Shares:</div>
              <div className="flex-1 ml-2.5 text-white text-opacity-60">{shares}</div>
            </div>
            <Button disabled={!randomTotal} onClick={randomClick} size='large' className='w-full mt-8 rounded' type='primary'><Trans>Comfirm</Trans></Button>
          </Form>
        </div>
      </Modal> */}

      <Modal
        destroyOnClose={true}
        title={`Send Giveaway`}
        width={418}
        visible={sendModal}
        onCancel={() => {
          setSendModal(false)
        }}
        footer={null}
        centered
      >
        <div className="space-y-6 pb-1">
          <div className="flex">
            <div className="">Total Amount:</div>
            <div className="flex-1 flex justify-end">
              {formatNum(total_amount, 6, true)}
              <span className="ml-2">{symbol}</span>
            </div>
          </div>
          <div className="flex">
            <div className="">Wallet Balance:</div>
            <div className="flex-1 flex justify-end">
              {formatNum(walletBalance, 6, true)}
              <span className="ml-2">{symbol}</span>
            </div>
          </div>
          <div className="flex">
            <div className="">Shares:</div>
            <div className="flex-1 flex justify-end">{shares}</div>
          </div>
          <div className="flex">
            <div className="">Fees:</div>
            <div className="flex-1 flex justify-end">{fee}</div>
          </div>
          {!isApproved && !isChainCoin(token_address) && (
            <div>
              <Button loading={approveLoading} onClick={approveFun} className="w-full rounded mt-8" size="large" type="primary">
                <Trans>Approve</Trans>
              </Button>
            </div>
          )}
          {(isApproved || isChainCoin(token_address)) && (
            <Button disabled={new BigNumber(total_amount_fee).gt(walletBalance)} loading={sendLoading} onClick={sendTokenFun} size="large" className="w-full mt-8 rounded" type="primary">
              <Trans>Send</Trans>
            </Button>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default Detail
