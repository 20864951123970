import { blockchainName, wrapBlockchain } from 'config/blockchain'
import { mediaImages } from 'config/media'
import React from 'react'
import { titleCase } from 'utils'

const TaskTitle: React.FC<{ task: any }> = ({ task }) => {
  const titleEle = () => {
    if (task.platform === 'twitter') {
      if (task.type === 'follow') {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>{titleCase(task.type)}</div>
              <div className="w-60 truncate mx-1">
                {`${task.screen_name ? '@' : ''}${task.screen_name}`} on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      } else if (task.type === 'retweet') {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>{titleCase(task.type)}</div>
              <div className="w-60 truncate mx-1">
                {task.title} &nbsp; on {titleCase(task.platform)}{' '}
              </div>
            </div>
          </div>
        )
      } else if (task.type === 'retweet_and_like') {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div className="w-60 truncate mx-1">
                {!task.task_types.length || task.task_types.length === 2 ? 'Retweet & Like' : titleCase(task.task_types[0])}
                &nbsp;
                {task.title} on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      } else if (task.type === 'quoted') {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>Quote</div>
              <div className="w-60 truncate mx-1">
                {task.title} on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      } else if (task.type === 'reply') {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div className="w-60 truncate mx-1">
                <div>Reply</div>
                <div className="max-w-xs truncate mx-1">
                  {task.title} on {titleCase(task.platform)}
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>{titleCase(task.type)}</div>
              <div className="w-60 truncate mx-1">
                {task.title} on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      }
    } else if (task.platform === 'telegram') {
      if (task.type === 'join') {
        return (
          <div className=" flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>{titleCase(task.type)}</div>
              <div className="w-60 truncate mx-1">
                {task.name} on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      } else if (task.type === 'join_channel') {
        return (
          <div className=" flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div className="w-60 truncate mx-1">
                Join {task.name} Channel on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className=" flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>Join</div>
              <div className="w-60 truncate mx-1">
                {task.title} Text AMA on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      }
    } else if (task.platform === 'discord') {
      if (task.type === 'join') {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>{titleCase(task.type)}</div>
              <div className="w-60 truncate mx-1">
                {task.name} on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="flex">
            <img className="mr-2" src={mediaImages[task.platform]} alt="" />
            <div className="flex">
              <div>Join</div>
              <div className="w-60 truncate mx-1">
                {task.title} Text AMA on {titleCase(task.platform)}
              </div>
            </div>
          </div>
        )
      }
    } else if (task.type === 'invite' || task.type === 'open_refer') {
      return (
        <div className=" flex">
          <img className="mr-2" src={mediaImages['icon_invite']} alt="" />
          <div className="flex">
            <div>{titleCase(task.type)}</div>
            <div className="w-60 truncate mx-1">
              {task.invites_per_user} {task.invites_per_user < 2 ? 'Friend' : 'Friends'}
            </div>
          </div>
        </div>
      )
    } else if (task.type === 'holding_token') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages['icon_token']} alt="" />
          <div className="flex">
            <div className="w-60 truncate mx-1">
              Holding {task.threshold_balance} {task.symbol} {task.symbol ? '' : 'Token'} {task.symbol ? 'on' : ''}
              {wrapBlockchain[task.blockchain]}
            </div>
          </div>
        </div>
      )
    } else if (task.type === 'holding_nft') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages['icon_nft']} alt="" />
          <div className="flex">
            <div className="w-60 truncate mx-1">
              Holding {task.threshold_balance} {task.symbol} {task.symbol ? '' : 'NFT'} {task.symbol ? 'on' : ''} {wrapBlockchain[task.blockchain]}
            </div>
          </div>
        </div>
      )
    } else if (task.type === 'visit') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages['homepage']} alt="" />
          <div className="flex">
            <div>{titleCase(task.action ? task.action : 'visit')}</div>
            <div className="w-60 truncate mx-1">
              {task.title} {titleCase(task.platform)}
            </div>
          </div>
        </div>
      )
    } else if (task.type === 'download') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages['download_app']} alt="" />
          <div className="flex">
            <div>Download</div>
            <div className="w-60 truncate mx-1">{task.title} App</div>
          </div>
        </div>
      )
    } else if (task.type === 'answer_question') {
      return (
        <div className=" flex">
          <img className="mr-2" src={mediaImages['answer_question']} alt="" />
          <div className="flex">
            <div>Answer</div>
            <div className="w-60 truncate mx-1">{task.title} Question</div>
          </div>
        </div>
      )
    } else if (task.type === 'channel') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages['channel']} alt="" />
          <div className="flex">
            <div>Answer</div>
            <div className="w-60 truncate mx-1">{task.title} Channel Code</div>
          </div>
        </div>
      )
    } else if (task.type === 'connect_wallet') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages['connect_wallet']} alt="" />
          <div className="flex">
            <div>Connect Your Wallet</div>
          </div>
        </div>
      )
    } else if (task.type === 'bind_web2_id') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages['bind_account']} alt="" />

          <div className="flex">
            <div>Bind </div>
            <div className="w-60 truncate mx-1">{task.media.map((me: any) => titleCase(me)).join('/')} Account</div>
          </div>
        </div>
      )
    } else if (task.type === 'onchain_interact') {
      return (
        <div className="flex">
          <img className="mr-2" src={mediaImages[task.platform]} alt="" />
          <div className="flex">
            <div className="w-60 truncate mx-1">
              {titleCase(task.title)} on {task.platform} {` - ${blockchainName[task.blockchain]}`}
            </div>
          </div>
        </div>
      )
    } else if (task.type === 'holding_comp_votes') {
      return (
        <div className="w-500 flex">
          <img className="mr-2" src={mediaImages['icon_token']} alt="" />

          <div className="flex">
            <div className="w-60 truncate mx-1">
              Holding {task.threshold_votes} {task.symbol} {task.symbol ? '' : 'Token'} votes {task.symbol ? 'on' : ''} {wrapBlockchain[task.blockchain]}{' '}
            </div>
          </div>
        </div>
      )
    } else if (task.type === 'proof_of_work') {
      return (
        <div className="w-500 flex">
          <img className="mr-2" src={mediaImages['proof']} alt="" />
          <div className="flex items-center">
            <div>Proof of Work for</div>
            <div className="w-60 truncate mx-1">{task.title}</div>
          </div>
        </div>
      )
    } else if (task.type === 'quiz' || task.type === 'vote' || task.type === 'open_question') {
      return (
        <div className="w-500 flex">
          <img className="mr-2" src={mediaImages['answer_question']} alt="" />
          <div className="flex">
            <div className="capitalize">{task.type === 'open_question' ? 'OpenQuestion' : task.type}</div>
            <div className="w-60 truncate mx-1">{task.title}</div>
          </div>
        </div>
      )
    } else if (task.type === 'mint_okx_cup_nft') {
      return (
        <div className="w-500 flex">
          <img className="mr-2" src={mediaImages['icon_nft']} alt="" />
          <div className="flex">
            <div>
              Mint {task.threshold_votes} {task.symbol}
            </div>
            <div className="w-60 truncate mx-1">on {wrapBlockchain[task.blockchain]}</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="w-500 flex">
          <img className="mr-2" src={mediaImages['com']} alt="" />
          <div className="flex">
            <div className="w-60 truncate mx-1">{titleCase(task.title || '')} on Chain</div>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="flex">
      <div className="flex">
        <div className="w-52">{titleEle()}</div>
      </div>
    </div>
  )
}

export default TaskTitle
