import { useRequest } from 'ahooks'
import { Table, Typography } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { shortenSystemAPIKey } from 'utils'
import './index.less'
const { Paragraph } = Typography
// const IdentityConfig = {
//   resident: {
//     name: 'RESIDENT',
//   },
//   space_traveler: {
//     name: 'SPACE TRAVELER',
//   },
//   knight: {
//     name: 'KNIGHT',
//   },
//   skywalker: {
//     name: 'SKYWALKER',
//   },
//   grand_master: {
//     name: 'GRAND MASTER',
//   },
// }
const RankList = () => {
  const [page, setPage] = useState({ page: 1, pagesize: 20 })
  const { data, loading } = useRequest(() => axios.get(`/api/admin/stats/gemstone/rank?page=${page.page}&pagesize=${page.pagesize}`), {
    refreshDeps: [page.page],
  })
  const columns: any = [
    {
      title: (
        <div>
          <Trans>Rank</Trans>
        </div>
      ),
      dataIndex: 'rank',
      key: 'rank',
      width: 30,
    },
    {
      title: (
        <div>
          <Trans>Address</Trans>
        </div>
      ),
      dataIndex: 'user_address',
      key: 'user_address',
      render: (cur: string) => <div className=" w-52">{cur ? <Paragraph copyable={{ text: cur }}>{shortenSystemAPIKey(cur || '', 6)}</Paragraph> : '-'}</div>,
    },
    {
      title: (
        <div>
          <Trans>Score</Trans>
        </div>
      ),
      dataIndex: 'score',
      key: 'score',
      width: 100,
    },
    {
      title: (
        <div>
          <Trans>Score Daily</Trans>
        </div>
      ),
      dataIndex: 'score_daily',
      key: 'score_daily',
      render: (cur: string) => <div className="w-20">{cur}</div>,
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage({ ...page, page: pagination.current })
  }
  return (
    <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
      <Table
        className="tab-container"
        loading={loading as boolean}
        dataSource={data?.data.data.data}
        columns={columns}
        rowKey={(record) => record.campaign_code}
        rowClassName={(_, index) => {
          let className = 'nrodd'
          if (index % 2 === 0) className = 'nreven'
          return className
        }}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          size: 'small',
          current: page.page,
          pageSize: page.pagesize,
          total: data?.data.data.total,
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}

export default RankList
