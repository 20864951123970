import { useWeb3React } from "@web3-react/core"
import { useMemo } from "react"
import { getContract } from "utils"
import erc20_abi from 'config/abis/erc20.json'
import airdrop_abi from 'config/abis/LuckyMoney.json'

export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true) {
	const { library, account } = useWeb3React()
	return useMemo(() => {
		if (!address || !ABI || !library) return null
		try {
			return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
		} catch (error) {
			console.error('Failed to get contract', error)
			return null
		}
	}, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
	return useContract(tokenAddress, erc20_abi, withSignerIfPossible)
}

export function useAirdropContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
	return useContract(tokenAddress, airdrop_abi, withSignerIfPossible)
}
