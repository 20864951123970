import { Trans } from "@lingui/macro"
import { Button, Form, Input, message, Popconfirm } from "antd"
import close from 'assets/images/_icon_close.svg'
import axios from "config/axios"
import { useEffect, useState } from "react"
import { isAddress } from "utils"
import cookie from 'react-cookies'

const AddAddress = ({ setUpdate, addresses }: any) => {
  const [addressList, setAddressList] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const acc = cookie.load('soad_acc')

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    setLoading(true)
    const res = await axios.post('/api/admin/space/profile', {
      ...fieldValues,
      type: 'add_address',
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  const confirmFun = async (address: string) => {
    const res = await axios.post('/api/admin/space/profile', {
      type: 'remove_address',
      address
    })
    if (res.data.code === 0) {
      message.success('succees!')
      setUpdate((state: any) => !state)
    } else {
      message.error(res.data.message)
    }
  }

  useEffect(() => {
    setAddressList(addresses)
  }, [addresses])

  return (
    <div>
      <div><Trans>Admin Address:</Trans></div>
      <div className="mt-4 w-fit relative">
        <Form
          name="basic"
          layout="horizontal"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label=""
            name="address"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isAddress(value)) {
                    return Promise.reject('Incorrect address format!')
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
          >
            <Input className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96' />
          </Form.Item>
          <Button loading={loading} htmlType="submit" className='rounded px-8 absolute top-0 -right-32' type='primary'><Trans>Save</Trans></Button>
        </Form>
      </div>
      <>
        {
          addressList?.map((address: string) => (
            <div key={address} className="bg-nibg border border-bord w-96 rounded-md px-2 py-1.5 text-xs flex items-center">
              <div className="flex-1">{address}</div>
              {acc !== address &&
                <Popconfirm onConfirm={() => confirmFun(address)} title="Are you sure delete this address?" okText="Yes" cancelText="No">
                  <img className="ml-2 cursor-pointer h-4.5" src={close} alt="" />
                </Popconfirm>}
            </div>
          ))
        }
      </>
    </div>
  )
}

export default AddAddress