import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { formatNum } from 'utils'
import RanksColumn from './RanksColumn'
import RankList from './RankList'
import DailySnapshotColumn from './DailySnapshotColumn'
import axios from 'axios'
import { useAsyncEffect } from 'ahooks'
import DailySnapshotLine from './DailySnapshotLine'
export type TypeGemStone = {
  daily_burned: number
  daily_issued: number
  rank_distributed: any[]
  total_balance: number
  total_burned: number
  total_issued: number
}
const GemStone: React.FC = () => {
  const [data, setDate] = useState<TypeGemStone>({} as TypeGemStone)
  useAsyncEffect(async () => {
    const result = await axios.get('/api/admin/stats/gemstone/total')
    const newDistributed = [
      {
        count: 0,
        resident: 'Resident',
        identity: 'Resident',
      },
      {
        count: 0,
        space_traveler: 'Space Traveler',
        identity: 'Space Traveler',
      },
      {
        count: 0,
        knight: 'Knight',
        identity: 'Knight',
      },
      {
        count: 0,
        skywalker: 'Skywalker',
        identity: 'Skywalker',
      },
      {
        count: 0,
        grand_master: 'Grand Master',
        identity: 'Grand Master',
      },
    ]
    if (result?.data?.data?.rank_distributed) {
      for (let x = 0; x < 5; x++) {
        const keys = Object.keys(newDistributed[x])
        result?.data?.data?.rank_distributed.forEach((element: any) => {
          if (keys.includes(element.identity)) {
            newDistributed[x].count = element.count
          }
        })
      }
    }
    setDate({ ...result.data.data, rank_distributed: newDistributed })
  }, [])
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="grid grid-cols-4 gap-5">
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium">
            <Trans>Total Issued</Trans>
          </div>
          <div className="text-2xl font-bold">{formatNum(data?.total_issued || 0)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium">
            <Trans>Total Burned</Trans>
          </div>
          <div className="text-2xl font-bold">{formatNum(data?.total_burned || 0)}</div>
        </div>
        <div className="bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium">
            <Trans>Total Balance</Trans>
          </div>
          <div className="text-2xl font-bold">{formatNum(data?.total_balance || 0)}</div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-5 mt-10">
        <div className="text-2xl font-bold bg-con rounded px-4 py-3 space-y-3 flex flex-col justify-between">
          <div className="font-medium">
            <Trans>Ranks Statistics</Trans>
          </div>
          <RanksColumn data={data?.rank_distributed || []} />
        </div>
        <div className="text-2xl font-bold bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium">
            <Trans>🔥 Rank List </Trans>
          </div>
          <RankList />
        </div>
      </div>
      <div className="mt-10">
        <div className="flex flex-col justify-between text-2xl font-bold bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium">
            <Trans>Daily Snapshot (Every day) </Trans>
          </div>
          <DailySnapshotLine />
        </div>
      </div>
      <div className="mt-10">
        <div className="flex flex-col justify-between text-2xl font-bold bg-con rounded px-4 py-3 space-y-3">
          <div className="font-medium">
            <Trans>Daily Snapshot (Total) </Trans>
          </div>
          <DailySnapshotColumn />
        </div>
      </div>
    </div>
  )
}

export default GemStone
