import { Trans } from '@lingui/macro'
import { Button, DatePicker, Form, Input, message, Select, Tooltip } from 'antd'
import inhitution from 'assets/images/icon_inhitution.svg'
import projcit from 'assets/images/icon_projcit.svg'
import manager from 'assets/images/icon_manager.svg'
import note from 'assets/images/icon_note.svg'
import arrow_left from 'assets/images/icon_arrow_left.svg'
import Footer from 'components/Footer'
import { useState } from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import useSpaceTypes from 'pages/Space/ProjectSpaces/hooks/useSpaceTypes'
import { isCode } from 'utils'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const { Option } = Select
const { RangePicker } = DatePicker

const Login = ({ setData, setUpdateSpaceTree }: any) => {
  const history = useHistory()
  const [createSpace, setCreateSpace] = useState(false)
  const [loading, setLoading] = useState(false)
  const acc = cookie.load('soad_acc')
  const isSigned = cookie.load('soad_signed')
  const space_types = useSpaceTypes(createSpace)

  const onFinish = async (fieldsValue: any) => {
    const start_time = parseInt(fieldsValue.time[0].valueOf() / 1000 + '')
    const end_time = parseInt(fieldsValue.time[1].valueOf() / 1000 + '')
    delete fieldsValue.time
    const arg = {
      ...fieldsValue,
      start_time,
      end_time,
    }
    setLoading(true)
    const res = await axios.post('/api/admin/space/create', arg)
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
      await setData(undefined)
      cookie.save('soad_role', 1, { path: '/' })
      setUpdateSpaceTree((state: any) => !state)
      history.push('/space-profile')
    } else {
      message.error(res.data.message)
    }
  }

  return (
    <div>
      <div className="px-3 pt-8 md:pt-16 pb-40">
        {!createSpace && (
          <div className="container mx-auto max-w-4xl">
            <div className="text-center text-2xl px-4 md:px-0 font-bold">
              <Trans>SoGraph Helps Project Connect with Massive Web3 Users</Trans>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10 md:mt-20 px-4 md:px-0">
              <div className="bg-locon rounded-xl py-5 border border-lobor hover:bg-loconh relative pb-30">
                <div className="text-center text-lg">
                  <Trans>For VCs</Trans>
                </div>
                <div className="flex justify-center my-3">
                  <img src={inhitution} alt="" />
                </div>
                <div className="text-center">
                  <Trans>Portfolio Statistics</Trans>
                </div>
                <div className="text-center mt-2">
                  <Trans>Project Insights</Trans>
                </div>
                <div className="text-center mt-2">
                  <Trans>Market Trends</Trans>
                </div>
                <div className="flex justify-center w-full mt-10 md:mt-0 md:absolute md:bottom-6">
                  <Tooltip overlay={isSigned ? '' : 'Please connect your wallet and sign to login.'}>
                    <Button
                      onClick={() => {
                        if (!isSigned) return
                        setCreateSpace(true)
                      }}
                      className="rounded-full px-4"
                      type="primary"
                    >
                      <Trans>Create My Space (Free)</Trans>
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div className="bg-locon rounded-xl py-5 border border-lobor hover:bg-loconh relative">
                <div className="text-center text-lg">
                  <Trans>For Projects</Trans>
                </div>
                <div className="flex justify-center my-3">
                  <img src={projcit} alt="" />
                </div>
                <div className="text-center">
                  <Trans>Web3 Quest Solution</Trans>
                </div>
                <div className="text-center mt-2">
                  <Trans>Project Insights</Trans>
                </div>
                <div className="text-center mt-2">
                  <Trans>Community Statistics</Trans>
                </div>
                <div className="text-center mt-2">
                  <Trans>Social Sentiment</Trans>
                </div>
                <div className="flex justify-center w-full mt-10 md:mt-0 md:absolute md:bottom-6">
                  <Tooltip overlay={isSigned ? '' : 'Please connect your wallet and sign to login.'}>
                    <Button
                      onClick={() => {
                        if (!isSigned) return
                        setCreateSpace(true)
                      }}
                      className="rounded-full px-4"
                      type="primary"
                    >
                      <Trans>Create My Space (Free)</Trans>
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div className="bg-locon rounded-xl py-5 border border-lobor hover:bg-loconh relative">
                <div className="text-center text-lg">
                  <Trans>For Communities</Trans>
                </div>
                <div className="flex justify-center my-3">
                  <img src={manager} alt="" />
                </div>
                <div className="text-center">
                  <Trans>Community Insights</Trans>
                </div>
                <div className="text-center mt-2">
                  <Trans>DAO Tool Supports</Trans>
                </div>
                <div className="text-center mt-2">
                  <Trans>Member Structure</Trans>
                </div>
                <div className="flex justify-center w-full mt-10 md:mt-0 md:absolute md:bottom-6">
                  <Tooltip overlay={isSigned ? '' : 'Please connect your wallet and sign to login.'}>
                    <Button
                      onClick={() => {
                        if (!isSigned) return
                        setCreateSpace(true)
                      }}
                      className="rounded-full px-4"
                      type="primary"
                    >
                      <Trans>Create My Space (Free)</Trans>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        )}
        {createSpace && (
          <div className="w-full flex justify-center">
            <div className="w-400">
              <div onClick={() => setCreateSpace(false)} className="mb-4 cursor-pointer">
                <img src={arrow_left} alt="" />
              </div>
              <Form
                name="basic"
                initialValues={{
                  address: acc,
                  time: [moment(moment().format('YYYY-MM-DD 00:00:00')), moment(moment().add(1, 'y').format('YYYY-MM-DD 00:00:00'))],
                }}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Type:"
                  name="space_type"
                  rules={[
                    {
                      required: true,
                      message: 'Please complete your SoQuest Space infomation!',
                    },
                  ]}
                >
                  <Select
                    className="bg-ncon"
                    placeholder="Please categorize your SoQuest Space type"
                    dropdownClassName="border border-bord"
                    notFoundContent={<div className="text-center text-white">No Data</div>}
                  >
                    {space_types.map((space_type: any) => (
                      <Option key={space_type.key} value={space_type.key}>
                        {space_types.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Name:"
                  name="space_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your name!',
                    },
                  ]}
                >
                  <Input placeholder="Builder DAO" className="bg-ncon border-bord hover:border-ci focus:border-ci rounded-md" />
                </Form.Item>
                <Form.Item
                  label={
                    <div className="flex items-center space-x-2">
                      <span>Code:</span>
                      <Tooltip overlay="Unique code for your space url">
                        <img className="cursor-pointer opacity-80" src={note} alt="" />
                      </Tooltip>
                    </div>
                  }
                  name="space_code"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your space code!',
                    },
                    {
                      validator: (_, value) => {
                        if (value && !isCode(value)) {
                          return Promise.reject('Please fill in the Code with Numbers or Letters ONLY!')
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="builder_dao" className="bg-ncon border-bord hover:border-ci focus:border-ci rounded-md" />
                </Form.Item>
                <Form.Item
                  label="Admin Telegram ID:"
                  name="telegram_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Telegram ID!',
                    },
                  ]}
                >
                  <Input className="bg-ncon border-bord hover:border-ci focus:border-ci rounded-md" />
                </Form.Item>
                <Form.Item label="Valid Period:" name="time">
                  <RangePicker
                    disabled
                    className="bg-ncon border-bord hover:border-ci focus:border-ci rounded-md text-black"
                    showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')] }}
                  />
                </Form.Item>
                <Form.Item label="Owner Address:" name="address">
                  <Input disabled className="bg-ncon border-bord hover:border-ci focus:border-ci rounded-md" />
                </Form.Item>

                <Button loading={loading} htmlType="submit" size="large" className="w-full mt-2 md:mt-2 rounded" type="primary">
                  <Trans>Create</Trans>
                </Button>
              </Form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Login
