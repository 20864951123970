import { Select, Spin, Tooltip } from "antd"
import axios from "config/axios"
import { useEffect, useRef, useState } from "react"
import { SearchOutlined, TeamOutlined } from '@ant-design/icons'
import { Trans } from "@lingui/macro"
import useCommunityDetail from "./hooks/useCommunityDetail"
import { formatNum } from "utils"
import { ReactComponent as Tele } from 'assets/images/icon_telegram.svg'
import { ReactComponent as Disc } from 'assets/images/icon_discord.svg'
import ActiveIndex from "./ActiveIndex"
import MemberStructure from "./MemberStructure"
import ActiveMember from "./ActiveMember"
import ProjectMention from "./ProjectMention"
import MemberGrowth from "./MemberGrowth"
import cookie from 'react-cookies'
import useHotCommunities from "pages/Home/hooks/useHotCommunities"

const { Option } = Select

const Communities = () => {
  const [{ list }, load] = useHotCommunities(1, 40)
  const hotOptions = list
  const co = cookie.load('soad_community') || []
  const [data, setData] = useState([])
  const [value, setValue] = useState(co[0]?.community_code)
  const [loading, setLoading] = useState(false)
  const [detail] = useCommunityDetail(value)
  const [isEllipsis, setIsEllipsis] = useState(false)
  const [status, setStatus] = useState(false)
  const divEl = useRef<any>(null)

  useEffect(() => {
    const div = divEl?.current
    setIsEllipsis(div?.scrollHeight > div?.clientHeight)
  }, [value, detail])

  useEffect(() => {
    if (detail.community_id) {
      if (!status) {
        setData(co)
        setValue(co[0]?.community_code)
      }
    }
    // eslint-disable-next-line
  }, [detail.community_id])

  useEffect(() => {
    if (!co.length || (co.length && !detail.community_id)) setData(hotOptions)
    // eslint-disable-next-line
  }, [hotOptions, detail.community_id])

  let timeout: any
  let currentValue: any

  const fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      setLoading(true)
      const res = await axios.get(`/api/admin/community/search?name=${value}`)
      setLoading(false)
      if (currentValue === value) {
        callback(res.data.data)
      }
    }
    timeout = setTimeout(fake, 300)
  }
  const handleSearch = (value: string) => {
    setStatus(true)
    if (value) {
      fetch(value, (data: any) => setData(data))
    } else {
      setData(hotOptions)
    }
  }
  const handleChange = (value: any, opt: any) => {
    const arr = [{
      community_code: opt.value,
      logo_url: opt.label.logo,
      name: opt.label.name,
    }]
    cookie.save('soad_community', arr, { path: '/' })
    setValue(value)
  }
  const options = data.map((d: any) => {
    return (
      <Option
        key={d.community_code}
        label={{ logo: d.logo_url, name: d.name }}
        value={d.community_code}
      >
        <div className='flex items-center'>
          {d.logo_url && <img className='w-4 mr-1.5 rounded-full' src={d.logo_url} alt="" />}
          {!d.logo_url && <TeamOutlined className="text-ci mr-1.5" />}
          <span>{d.name}</span>
        </div>
      </Option>
    )
  })

  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className={`flex-1 relative flex justify-center ${value ? 'mt-6' : 'mt-12'}`}>
        <Select
          placeholder='Search Communities'
          showSearch
          value={(detail.community_id && data.length) ? value : undefined}
          size='large'
          className='w-1/2 ant-select-open'
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={loading || load ? <Spin size="small" /> : <div className='text-white'>No results found</div>}
        >
          {options}
        </Select>
        <div className='absolute right-1/4 bottom-1.5 pr-4'><SearchOutlined className='text-ci text-xl' /></div>
      </div>
      {value && detail.community_id && <div className="flex justify-center">
        <div className="bg-con rounded px-4 py-4 mt-10 w-2/3">
          <div className="flex space-x-12 items-center">
            <div className="flex-1 flex items-center space-x-3 pr-2">
              <div className="w-12">
                {detail.logo_url && <img className="w-12 rounded-full" src={detail.logo_url} alt="" />}
                {!detail.logo_url && <TeamOutlined className='text-ci text-4xl' />}
              </div>
              <div className="space-y-2 flex-1">
                <div className="flex items-center space-x-4">
                  <div className="text-medium">{detail.name}</div>
                  <a href={detail.link_url} target='_blank' rel="noreferrer">
                    {detail.platform === 'telegram' && <Tele className="h-4.5" fill={'#7754F8'} />}
                    {detail.platform === 'discord' && <Disc className="h-4.5" fill={'#7754F8'} />}
                  </a>
                </div>
                {!!isEllipsis && <Tooltip placement="topLeft" overlayStyle={{ minWidth: 520 }} title={detail.introduction}>
                  <div ref={divEl} className="elip">{detail.introduction}</div>
                </Tooltip>}
                {!isEllipsis && <div ref={divEl} className="elip">{detail.introduction}</div>}
              </div>
            </div>
            <div className="flex space-x-8">
              <div className="space-y-3">
                <div className="text-medium"><Trans>Members</Trans></div>
                <div className="text-xl text-bold">{formatNum(detail.members)}</div>
              </div>
              <div className="space-y-3">
                <div className="text-medium"><Trans>Active</Trans></div>
                <div className="text-xl text-bold">{formatNum(detail.daily_active_member_count)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {value && detail.community_id && <div className="space-y-8 mt-10">
        <div className="grid grid-cols-2 gap-4">
          <ActiveIndex code={value} />
          <MemberGrowth code={value} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <MemberStructure code={value} />
          <ProjectMention code={value} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <ActiveMember code={value} />
        </div>
      </div>}
    </div>
  )
}

export default Communities
