import { Table } from 'antd'
import { Trans } from "@lingui/macro"
import { formatNum } from "utils"
import { DollarOutlined } from '@ant-design/icons'
import { useState } from 'react'
import useTrendingProject from './hooks/useTrendingProject'

const TrendingProjects = () => {
  const [page, setPage] = useState(1)
  const [{ list, total }, loading] = useTrendingProject(page)
  const columns: any = [
    {
      title: '#',
      dataIndex: '',
      key: 'key',
      width: 100,
      render: (_cur: any, _record: any, index: number) => (<div>{(index + 1)+(page-1)*8}</div>)
    },
    {
      title: <div><Trans>Symbol</Trans></div>,
      dataIndex: 'symbol',
      key: 'symbol',
      ellipsis: true,
      width: 180,
      render: (cur: any, record: any) => (
        <div className="flex items-center">
          {record.logo_url && <img className="h-4.5 mr-1.5 rounded-full" src={record.logo_url} alt="" />}
          {!record.logo_url && <DollarOutlined style={{fontSize: 18}} className='mr-1.5 text-ci' />}
          <div className="truncate">
            {/* <Tooltip placement="topLeft" title={cur}> */}
              {cur}
            {/* </Tooltip> */}
          </div>
        </div>
      )
    },
    {
      title: <div><Trans>Engagement(24H)</Trans></div>,
      dataIndex: 'value',
      key: 'value',
      width: 120,
      render: (cur: any) => (<div>{formatNum(cur)}</div>)
    },
  ]
  const handleTableChange = (pagination: any) => {
    setPage(pagination.current)
  }
  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className="bg-con rounded pt-4">
        <div className="text-xl font-medium pl-3"><Trans>Trending Projects</Trans></div>
        <div className="mt-4 md:mt-4 py-1 overflow-x-scroll">
          <Table className="" loading={loading as boolean} dataSource={list} columns={columns}
            rowKey={(record) => record.symbol}
            rowClassName={(_, index) => {
              let className = 'rodd'
              if (index % 2 === 0) className = 'reven'
              return className
            }}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              size: 'small',
              current: page,
              pageSize: 8,
              total: total > 40 ? 40 : total,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  )
}

export default TrendingProjects
