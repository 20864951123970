import { MinusCircleOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Select, Spin } from 'antd'
import rewardlogo from 'assets/images/rewardlogo.svg'
import { isNumber2 } from 'utils'
import axios from 'axios'
import { useEffect, useState } from 'react'
import useTokenGiveawayList from './hooks/useTokenGiveawayList'
import { Trans } from '@lingui/macro'
import { blockchainName } from 'config/blockchain'

const { Option } = Select

const RewardToken = ({ reward, index, setRewardsList }: any) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [value, setValue] = useState('')
  const [displaySymbol, setDisplaySymbol] = useState(false)
  const [update, setUpdate] = useState(false)
  const [giveawayList, load] = useTokenGiveawayList(update)

  const deleteFun = () => {
    setRewardsList((state: any) => {
      const list = state
      const index = list.indexOf(list.find((li: any) => li.uuid === reward.uuid))
      list.splice(index, 1)
      return [...list]
    })
  }

  let timeout: any
  let currentValue: any

  const fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      setLoading(true)
      const res = await axios.get(`/api/admin/space/token/search?type=all&symbol=${value}`)
      setLoading(false)
      if (currentValue === value) {
        callback(res.data.data)
      }
    }

    timeout = setTimeout(fake, 300)
  }
  const handleSearch = (value: string) => {
    if (value) {
      fetch(value, (data: any) => setData(data))
    } else {
      setData([])
    }
  }
  const handleChange = (value: any) => {
    setValue(value)
    form.setFieldsValue({
      project: value,
    })
  }
  const options = data.map((d: any) => {
    return (
      <Option className="border-b border-bord" key={d.project_code} value={d.project_code}>
        <div className="flex items-center">
          <img className="w-4 mr-1.5 rounded-full" src={d.logo_url} alt="" />
          <span>
            {d.symbol}({d.name})
          </span>
        </div>
      </Option>
    )
  })

  const createTokenGiveaway = () => {
    window.open(`${window.location.origin}/giveaway/detail`, '_blank')
  }

  const sourceSelect = (_: string, { key }: any) => {
    const arr = key.split('~')
    const project_code = arr[1]
    const name = arr[2]
    const logo_url = arr[3]
    const symbol = arr[4]
    const chain = arr[5]
    setData([
      {
        project_code,
        logo_url,
        name,
        symbol,
      },
    ])
    setValue(project_code)
    setDisplaySymbol(true)
    form.setFieldsValue({
      project: project_code,
      blockchain: blockchainName[chain],
    })
  }

  const modeSelect = (value: any) => {
    if (Number(value) === 3) {
      form.setFieldsValue({
        shares: -1,
      })
    } else {
      form.setFieldsValue({
        shares: '',
      })
    }
  }

  useEffect(() => {
    if (reward.project) {
      setData([
        {
          project_code: reward.project,
          logo_url: reward.project_image,
          name: reward.project_name,
          symbol: reward.project_symbol,
        },
      ])
      setValue(reward.project)
      setDisplaySymbol(true)
    }
    const formObj = reward.name
      ? {
          ...reward,
        }
      : {
          type: 'token',
        }
    form.setFieldsValue(formObj)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="bg-resbg px-4 border border-bord rounded-md">
      <div className="py-3 border-b border-bord">
        <div className="flex items-center">
          <img src={rewardlogo} alt="" />
          <div className="text-valid ml-2">Reward-{index + 1}</div>
          <div className="ml-6 px-4 py-1 text-xs rounded-full bg-yellow-500 bg-opacity-20 text-yellow-500">#Token</div>
          <div className="flex-1 flex justify-end -mr-12">
            <MinusCircleOutlined onClick={deleteFun} className="text-base cursor-pointer" />
          </div>
        </div>
      </div>
      <div className="py-4">
        <Form name={'form' + (index + 1)} form={form} layout="vertical" autoComplete="off">
          <div className="grid grid-cols-3 gap-4">
            <Form.Item className="col-span-1 hidden" label="Reward Type:" name="type">
              <Select disabled>
                <Option value="nft">NFT</Option>
                <Option value="token">Token</Option>
                <Option value="white_list">White List</Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="col-span-3"
              label="Reward Name:"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Reward is required!',
                },
              ]}
            >
              <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <Form.Item
              className="col-span-2"
              label="Source:"
              name="giveaway"
              rules={[
                {
                  required: true,
                  message: 'Please select a source!',
                },
              ]}
            >
              <Select placeholder="Select or create new" onSelect={sourceSelect} notFoundContent={<div className="text-white text-center">No Data</div>}>
                {giveawayList.map(({ code, title, project, project_image, project_name, project_symbol, blockchain }: any) => (
                  <Option key={code + '~' + project + '~' + project_name + '~' + project_image + '~' + project_symbol + '~' + blockchain} value={code}>
                    {title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="col-span-1 flex items-center space-x-4 pt-1">
              <div className="flex-1">
                <div className="w-fit" onClick={() => setUpdate((state: boolean) => !state)}>
                  <SyncOutlined spin={load} className="cursor-pointer text-white text-opacity-70 hover:text-opacity-100" />
                </div>
              </div>
              <div onClick={createTokenGiveaway} className="space-x-1 cursor-pointer text-white text-opacity-70 hover:text-opacity-100">
                <span>
                  <Trans>Add Token Reward</Trans>
                </span>
                <PlusOutlined />
              </div>
            </div>
          </div>
          {displaySymbol && (
            <div className="grid grid-cols-3 gap-4">
              <Form.Item className="col-span-1" label="Blockchain:" name="blockchain">
                <Input disabled className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
              </Form.Item>
              <Form.Item className="col-span-2" label="Token" name="project">
                <div className="relative">
                  <Select
                    disabled
                    placeholder="Search Token"
                    showSearch
                    value={value}
                    className="w-full"
                    dropdownClassName="border border-bord"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    notFoundContent={loading ? <Spin size="small" /> : <div className="text-white">No results found</div>}
                  >
                    {options}
                  </Select>
                  {/* <div className='absolute h-full flex items-center bottom-0.5 right-0 pr-6'><SearchOutlined className='text-xl' /></div> */}
                </div>
              </Form.Item>
            </div>
          )}
          <div className="grid grid-cols-3 gap-4">
            <Form.Item
              label="Blockchain type:"
              name="chain_type"
              rules={[
                {
                  required: true,
                  message: 'Blockchain is required!',
                },
              ]}
            >
              <Select placeholder="Select chain type" showArrow={true} dropdownClassName="border border-bord">
                <Option value="evm">EVM</Option>
                <Option value="aptos">Aptos</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Mode:"
              name="mode"
              rules={[
                {
                  required: true,
                  message: 'Mode is required!',
                },
              ]}
            >
              <Select placeholder="Select a mode" showArrow={true} dropdownClassName="border border-bord" onSelect={modeSelect}>
                <Option value={1}>First come first served</Option>
                <Option value={2}>Manually Generated</Option>
                <Option value={3}>Unlimited</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Number of winners:"
              name="shares"
              rules={[
                {
                  required: true,
                  message: 'Number of winners is required!',
                },
                {
                  validator: (_, value) => {
                    if (value && !isNumber2(value)) {
                      return Promise.reject('Number of winners can only be numbers!')
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input placeholder="1000" className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
            {/* <Form.Item
              label='Claim Button Text:'
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Claim Button Text is required!',
                },
              ]}
            >
              <Input placeholder="Claim OAT NFT" className='bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2' />
            </Form.Item> */}
            <Form.Item className="hidden" label="id:" name="id">
              <Input className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md pl-2" />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default RewardToken
