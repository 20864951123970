import { useEffect, useState } from "react"
import axios from "config/axios"

const useProtocolList = (page: number, pageSize = 10, search_obj: any, update: boolean) => {
  const { protocol_code, blockchain } = search_obj
  const [data, setData] = useState<any>({ list: [], total: 0 })
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/api/admin/project/protocol?page=${page}&pagesize=${pageSize}&protocol_code=${protocol_code}&blockchain=${blockchain}`)
      setLoading(false)
      setData({ list: res.data.data.data, total: res.data.data.total })
    } catch (error) {
      console.error("useProtocolList --> " + error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [page, pageSize, search_obj, update])
  return [data, loading]
}

export default useProtocolList
