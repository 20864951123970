import { useEffect, useState } from "react"
import axios from "config/axios"
import cookie from 'react-cookies'

const useSpaceTypes = (update?: boolean) => {
  const [data, setData] = useState<any>([])
  const isSigned = cookie.load('soad_signed')
  const fetchData = async () => {
    try {
      const res = await axios.get(`/api/admin/space/types`)
      const info = res.data.data
      if (res.data.code === 0) setData(info)
    } catch (error) {
      console.error("useSpaceTypes --> " + error)
    }
  }
  useEffect(() => {
    if (isSigned) fetchData()
    // eslint-disable-next-line
  }, [update])
  return data
}

export default useSpaceTypes
