import { Trans } from "@lingui/macro"
import { useState } from "react"
import TwitterAPIKey from "./TwitterAPIKey"

const SpaceToken = () => {

  const [tab, setTab] = useState(1)

  return (
    <div className="px-3 md:px-0">
      <div className="container mx-auto max-w-6.5xl">
        <div className="flex-1 flex items-center space-x-6 font-semibold mb-8">
          <div onClick={() => setTab(1)} className={`cursor-pointer ${tab === 1 ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}><Trans>Twitter API Key</Trans></div>
          {/* <div onClick={() => setTab(2)} className={`cursor-pointer ${tab === 2 ? 'text-white' : 'text-white text-opacity-60'} hover:text-white`}><Trans>Covalent API Key</Trans></div> */}
        </div>
        {tab === 1 && <TwitterAPIKey />}
      </div>
    </div>
  )
}

export default SpaceToken