import { useEffect } from 'react'
import axios from 'axios'
import { useWeb3React } from '@web3-react/core'
import { blockchain } from 'config/blockchain'
import cookie from 'react-cookies'
import { sha256 } from 'js-sha256'
import { LOGIN_KEY } from 'config/common'

const useUserReport = (login: boolean, setSigned: any) => {
  const { chainId } = useWeb3React()
  const account = cookie.load('soad_acc')
  const co = cookie.load('soad_signature')
  const signStamp = cookie.load('soad_signStamp')
  const signed = cookie.load('soad_signed')
  const chain = blockchain[Number(chainId)]
  const fetchData = async () => {
    try {
      const credential = localStorage.getItem('credential')
      const token = sha256(account + co + LOGIN_KEY)
      const signed = await axios.post('/api/admin/user/login', {
        // eslint-disable-next-line
        msg: 'Welcome to SoGraph.xyz, Please sign this message to login.\n\n' + 'Timestamp: ' + signStamp,
        address: account,
        signature: co,
        credential: credential ? credential : '',
        blockchain: chain,
        signed_at: signStamp,
        token,
      })
      const backs = signed.data.data
      const code = signed.data.code
      if (code === 0) {
        localStorage.setItem('soad_userId', backs.user_code)
        localStorage.setItem('soad_credential', backs.credential)
        cookie.save('soad_signed', backs.signature, { path: '/' })
        cookie.save('soad_role', backs.role, { path: '/' })
        setSigned(backs.signature)
      }
      // if (code === 0) {
      //   if (!localStorage.getItem('userId')) {
      //     const res = await axios.post('/api/admin/user/report', {
      //       blockchain: chain,
      //       address: account,
      //     }, {
      //       headers: {
      //         signature: backs,
      //         address: account + ''
      //       }
      //     })
      //     localStorage.setItem('userId', res.data.data)
      //   } else {
      //     const check = await axios.get(`/api/admin/user/address/check?blockchain=${chain}&address=${account}`, {
      //       headers: {
      //         signature: backs,
      //         address: account + ''
      //       }
      //     })
      //     const isChecked = check.data.data
      //     if (isChecked) localStorage.setItem('userId', isChecked)
      //     if (!isChecked) {
      //       const res = await axios.post('/api/admin/user/report', {
      //         blockchain: chain,
      //         address: account,
      //       }, {
      //         headers: {
      //           signature: backs,
      //           address: account + ''
      //         }
      //       })
      //       localStorage.setItem('userId', res.data.data)
      //     }
      //   }
      // }
    } catch (error) {
      console.error('useUserReport --> ' + error)
    }
  }
  useEffect(() => {
    if (co && !signed) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [login])
}

export default useUserReport
