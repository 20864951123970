import { useEffect, useState } from 'react'
import axios from 'config/axios'
import { useSelector } from 'react-redux'
import { RootState } from 'state'

const useSetupData = (campaign_code: string | undefined, update: boolean) => {
  const [data, setData] = useState<any>()
  const space_update = useSelector((state: RootState) => state.space.space_code)
  const fetchData = async () => {
    try {
      const res = await axios.get(`/api/admin/space/campaign/detail?campaign_code=${campaign_code}`)
      const info = res.data.code === 7 ? 7 : res.data.data
      setData(info)
    } catch (error) {
      console.error('useSetupData --> ' + error)
    }
  }
  useEffect(() => {
    if (campaign_code) fetchData()
    // eslint-disable-next-line
  }, [update, space_update])
  return data
}

export default useSetupData
