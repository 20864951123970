import { Select, Spin, Tooltip } from 'antd'
import axios from 'config/axios'
import { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { Trans } from '@lingui/macro'
import useProjectDetail from './hooks/useProjectDetail'
import SocialVolume from './SocialVolume'
import Communities from './Communities'
import Attitude from './Attitude'
import RelativeCommunities from './RelativeCommunities'
import RelativeKOLs from './RelativeKOLs'
import { formatNum, formatTimeAgo, titleCase } from 'utils'
import { SearchOutlined, TeamOutlined } from '@ant-design/icons'
import { ReactComponent as Tele } from 'assets/images/icon_telegram.svg'
import { ReactComponent as Disc } from 'assets/images/icon_discord.svg'
import { ReactComponent as Twit } from 'assets/images/icon_twitter.svg'
import ci_arrow from 'assets/images/ci_arrow.png'
import cookie from 'react-cookies'
import Sentiment from './Sentiment'
import useStatistics from './hooks/useStatistics'
import { CaretUpOutlined, CaretDownOutlined, FieldTimeOutlined } from '@ant-design/icons'
// import TwitterFollowers from './TwitterFollowers'
import CommunityMembers from './CommunityMembers'
import MemberStructure from './MemberStructure'
import useHotProjects from 'pages/Home/hooks/useHotProjects'
import useFlow from './hooks/useFlow'

const { TabPane } = Tabs
const { Option } = Select

const Projects = () => {
  const [{ list }, load] = useHotProjects(1, 40)
  const hotOptions = list
  const co = cookie.load('soad_project') || []
  const [data, setData] = useState([])
  const [value, setValue] = useState(co[0]?.project_code)
  const [loading, setLoading] = useState(false)
  const [detail] = useProjectDetail(value)
  const statistics = useStatistics(value)
  const [{ chat_flows }, loadFlow] = useFlow(value)
  const differ = statistics.price - statistics.yday_price
  const isUp = differ >= 0 ? true : false
  const [isEllipsis, setIsEllipsis] = useState(false)
  const [status, setStatus] = useState(false)
  const divEl = useRef<any>(null)
  useEffect(() => {
    const div = divEl?.current
    setIsEllipsis(div?.scrollHeight > div?.clientHeight)
  }, [value, detail])

  useEffect(() => {
    if (detail.symbol) {
      if (!status) {
        setData(co)
        setValue(co[0]?.project_code)
      }
    }
    // eslint-disable-next-line
  }, [detail.symbol])

  useEffect(() => {
    if (!co.length || (co.length && !detail.symbol)) setData(hotOptions)
    // eslint-disable-next-line
  }, [hotOptions, detail.symbol])

  let timeout: any
  let currentValue: any

  const fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      setLoading(true)
      const res = await axios.get(`/api/admin/project/search?symbol=${value}`)
      setLoading(false)
      if (currentValue === value) {
        callback(res.data.data)
      }
    }

    timeout = setTimeout(fake, 300)
  }
  const handleSearch = (value: string) => {
    setStatus(true)
    if (value) {
      fetch(value, (data: any) => setData(data))
    } else {
      setData(hotOptions)
    }
  }
  const handleChange = (value: any, opt: any) => {
    const arr = [{
      project_code: opt.value,
      logo_url: opt.label.logo,
      name: opt.label.name,
      symbol: opt.label.symbol,
    }]
    cookie.save('soad_project', arr, { path: '/' })
    setValue(value)
  }
  const options = data.map((d: any) => {
    return (
      <Option
        key={d.project_code}
        value={d.project_code}
        label={{ logo: d.logo_url, name: d.name, symbol: d.symbol }}
      >
        <div className='flex items-center'>
          <img className='w-4 mr-1.5 rounded-full' src={d.logo_url} alt="" />
          <span>{d.symbol}({d.name})</span>
        </div>
      </Option>
    )
  })

  return (
    <div className="container mx-auto max-w-6.5xl">
      <div className={`flex-1 relative flex justify-center ${value ? 'mt-6' : 'mt-12'}`}>
        <Select
          placeholder='Search Project Name or Symbol'
          showSearch
          value={(detail.symbol && data.length) ? value : undefined}
          size='large'
          className='w-1/2 ant-select-open'
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={loading || load ? <Spin size="small" /> : <div className='text-white'>No results found</div>}
        >
          {options}
        </Select>
        <div className='absolute right-1/4 bottom-1.5 pr-4'><SearchOutlined className='text-ci text-xl' /></div>
      </div>
      {value && detail.symbol && <div className="flex justify-center">
        <div className="bg-con rounded px-4 py-4 mt-10 w-2/3">
          <div className="flex space-x-12 items-center">
            <div className="flex-1 flex items-center space-x-3">
              <div className='w-12'>
                {detail.logo_url && <img className="w-12 rounded-full" src={detail.logo_url} alt="" />}
                {!detail.logo_url && <TeamOutlined className='text-ci text-4xl' />}
              </div>
              <div className="flex-1 space-y-2">
                <div className="flex items-center space-x-4">
                  <div className="text-medium">{detail.name}</div>
                  {detail.official_community_urls.telegram && <a href={detail.official_community_urls.telegram} target='_blank' rel="noreferrer">
                    <Tele className="h-4.5" fill={'#7754F8'} />
                  </a>}
                  {detail.official_community_urls.discord && <a href={detail.official_community_urls.discord} target='_blank' rel="noreferrer">
                    <Disc className="h-4.5" fill={'#7754F8'} />
                  </a>}
                  {detail.official_community_urls.twitter && <a href={detail.official_community_urls.twitter} target='_blank' rel="noreferrer">
                    <Twit className="h-4.5" fill={'#7754F8'} />
                  </a>}
                  {detail?.website_urls?.slice(0, 3).map((link: any, index: number) => (
                    <a key={index} href={link} target='_blank' rel="noreferrer"><div className='bg-ci bg-opacity-20 rounded px-2 flex items-center'>
                      <span>web</span>
                      <img className='h-4.5 ml-1' src={ci_arrow} alt="" />
                    </div></a>
                  ))}
                </div>
                {!!isEllipsis && <Tooltip placement="topLeft" overlayStyle={{ minWidth: 460 }} title={detail.description}>
                  <div ref={divEl} className='elip'>{detail.description}</div>
                </Tooltip>}
                {!isEllipsis && <div ref={divEl} className='elip'>{detail.description}</div>}
              </div>
            </div>
            <div className="flex space-x-6 w-fit">
              <div className="space-y-3">
                <div className="text-medium"><Trans>Price</Trans></div>
                <div className="text-lg text-bold">${formatNum(detail.price, 2, true)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {value && detail.symbol && <div className='mt-12'>
        <Tabs defaultActiveKey="1">
          <TabPane tab={<div className='text-lg'><Trans>Overview</Trans></div>} key="1">
            <div className='space-y-8 mt-6'>
              <div className="grid grid-cols-4 gap-5">
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Appeared Communities</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.covered_communities)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Communities Members</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.communities_members)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Social Coverage</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.social_coverage)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Twitter Followers</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.twitter_followers)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>KOLs</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.kols)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Social Volume (24H)</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.social_volume)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Spam Volume (24H)</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.spam_volume)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Price</Trans></div>
                  <div className="text-2xl font-bold flex items-center">
                    ${formatNum(statistics.price, 2, true)}
                    <div className='ml-2 text-lg'>
                      {isUp && <CaretUpOutlined className={`mr-0.5 text-xs ${isUp ? 'text-green-500' : 'text-red-500'}`} />}
                      {!isUp && <CaretDownOutlined className={`mr-0.5 text-xs ${isUp ? 'text-green-500' : 'text-red-500'}`} />}
                      <span className={`${isUp ? 'text-green-500' : 'text-red-500'}`}>{formatNum(Math.abs(statistics.price ? (differ / statistics.price * 100) : 0))}%</span>
                    </div>
                  </div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Market Cap</Trans></div>
                  <div className="text-2xl font-bold">${formatNum(statistics.market_cap)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Trading Volume (24H)</Trans></div>
                  <div className="text-2xl font-bold">${formatNum(statistics.daily_volumn)}</div>
                </div>
                <div className="bg-con rounded px-4 py-3 space-y-3">
                  <div className="font-medium"><Trans>Sentiment (24H)</Trans></div>
                  <div className="text-2xl font-bold">{formatNum(statistics.sentiment)}</div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <SocialVolume code={value} />
                <Sentiment code={value} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Communities code={value} />
                <Attitude code={value} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                {/* <TwitterFollowers code={value} /> */}
                <CommunityMembers code={value} />
                <MemberStructure code={value} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <RelativeKOLs code={value} />
                <RelativeCommunities code={value} />
              </div>
            </div>
          </TabPane>
          <TabPane tab={<div className='text-lg'><Trans>News & Social Flow</Trans></div>} key="2">
            <div className='mt-8 h-600 overflow-y-scroll border border-ci border-opacity-20 rounded-lg px-5 py-5'>
              <div className='text-lg text-bold mb-4'><Trans>Social Flow:</Trans></div>
              {loadFlow && <div className="flex justify-center"><Spin /></div>}
              <ul className='timeUl'>
                {
                  chat_flows?.map((flow: any, index: any) => (
                    <li key={index} className="relative mb-6">
                      <div className="absolute w-3 h-3 rounded-3xl border-3 border-tbg liTop"></div>
                      <div className="absolute liH1 border-l-2 border-tbg border-opacity-50 border-dotted"></div>
                      <div className="absolute liH2 border-l-2 border-tbg border-opacity-50 border-dotted"></div>
                      <div className="relative ml-6">
                        <div className='flex items-center mb-1'>
                          <FieldTimeOutlined className='text-gray-300' />
                          <div className='font-medium ml-1 text-white text-opacity-70'>{formatTimeAgo(flow.action_time)}</div>
                        </div>
                        <div className='bg-con px-4 py-3 rounded-lg flex'>
                          <div className='flex items-center mr-6'>
                            <div className='bg-per-gre bg-opacity-10 rounded text-per-gre px-2 py-1 text-xs font-medium'>{titleCase(flow.platform)}</div>
                          </div>
                          <div className='flex-1 font-medium'>
                            {flow.msg}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </TabPane>
        </Tabs>
      </div>}
    </div>
  )
}

export default Projects
