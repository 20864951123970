import { Trans } from '@lingui/macro'
import { Button, Form, Input, message } from 'antd'
import axios from 'config/axios'
import { mediaImages } from 'config/media'
import { useEffect, useState } from 'react'
import { isUrl } from 'utils'
import TwitterLink from './RelateLinks/TwitterLink'

const Link = ({ urls, setUpdate }: any) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = async (fieldValues: any) => {
    for (const key of Object.keys(fieldValues)) {
      if (!fieldValues[key]) delete fieldValues[key]
    }
    if (!Object.keys(fieldValues).length) return
    setLoading(true)
    const res = await axios.post('/api/admin/space/profile', {
      type: 'link',
      url: JSON.stringify(fieldValues),
    })
    setLoading(false)
    if (res.data.code === 0) {
      message.success('succees!')
    } else {
      message.error(res.data.message)
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      twitter: urls?.twitter ? urls?.twitter : '',
      telegram: urls?.telegram ? urls?.telegram : '',
      discord: urls?.discord ? urls?.discord : '',
      homepage: urls?.homepage ? urls?.homepage : '',
      github: urls?.github ? urls?.github : '',
      medium: urls?.medium ? urls?.medium : '',
      nftscan: urls?.nftscan ? urls?.nftscan : '',
      opensea: urls?.opensea ? urls?.opensea : '',
    })
  }, [urls, form])

  return (
    <div className="bg-ncon rounded-lg px-5 py-5 border border-bord">
      <div>
        <Trans>Relate Links:</Trans>
      </div>
      {/* links v2 */}
      <div className="flex flex-col gap-6 mt-6 mb-6">
        <TwitterLink setUpdate={setUpdate} prefix={<img src={mediaImages['twitter']} className="w-5 h-5" alt="" />} historyLink={urls?.twitter ? urls?.twitter : ''} />
      </div>
      {/* end links */}
      <div className="mt-4">
        <Form form={form} name="basic" layout="horizontal" onFinish={onFinish} autoComplete="off">
          {/* <Form.Item
            label=""
            name="twitter"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['twitter']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item> */}
          <Form.Item
            label=""
            name="telegram"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['telegram']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label=""
            name="discord"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['discord']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label=""
            name="homepage"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['homepage']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label=""
            name="github"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['github']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label=""
            name="medium"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['medium']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label=""
            name="opensea"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['opensea']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Form.Item
            label=""
            name="nftscan"
            rules={[
              {
                validator: (_, value) => {
                  if (value && !isUrl(value)) {
                    return Promise.reject('Please enter the correct url format!')
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <Input prefix={<img src={mediaImages['nftscan']} className="w-5 h-5" alt="" />} className="bg-nibg border-bord hover:border-ci focus:border-ci rounded-md w-96 pl-2" />
          </Form.Item>
          <Button loading={loading} htmlType="submit" className="mt-2 md:mt-2 rounded px-8" type="primary">
            <Trans>Save</Trans>
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Link
